import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { API_BASE_URL } from '../../../config';
import { Flex, Box, List, ListItem, Heading, Text } from '@chakra-ui/react'; // Asumiendo que estás usando Chakra UI
import CannavaApiService from '../../../utils/CannavaApiService';
import { useAppContext } from '../../../contexts/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import CenteredAuth from '../../../layouts/auth/variants/Centered';

const AuthCallback: React.FC = () => {
    const { authToken, setAuthToken, backendUserData, setBackendUserData } = useAppContext();
    const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
    const navigate = useNavigate();
    const [redirectPath, setRedirectPath] = useState<string | null>(null);
    const location = useLocation();

    useEffect(() => {
        async function handleAuthentication() {
            if (isLoading) {
                return;
            }

            if (isAuthenticated) {
                try {
                    // Obtener el token de acceso de Auth0
                    const token: string = await getAccessTokenSilently();
                    setAuthToken(token);

                    CannavaApiService.request(
                        'POST',
                        '/auth/callback',
                        user,
                        token,
                        data => {
                            if (data.status === 1) {
                                setBackendUserData(data);

                                const prevPath = localStorage.getItem('prevPath');
                                navigate(prevPath ?? '/admin/home', { replace: true });
                                return;
                            } else if (data.status === 50) {
                                //console.log("status: usuario invalido");
                                logout({
                                    logoutParams: {
                                        returnTo: 'http://localhost:3000/usuario-invalido'
                                    }
                                });

                                return;
                            }

                            // console.log(data);
                            // console.log("data usuario: " + data);
                        },
                        error => {
                            throw new Error(`HTTP error! Status: ${error}`);
                        }
                    );

                    return;
                } catch (error) {
                    console.error('Error durante la autenticación:', error);
                }
            } else {
                // Si no está autenticado, redirige al usuario al login
                window.location.href = '/auth/sign-in/default';
            }
        }

        handleAuthentication();
    }, [isLoading, isAuthenticated, getAccessTokenSilently]);

    // @ts-ignore
    return (
        <CenteredAuth
            cardTop={{ base: '140px', md: '14vh' }}
            cardBottom={{ base: '50px', lg: '100px' }}
        >
            <Flex
                direction={{ base: 'column', xl: 'column' }}
                pt={{ base: '130px', md: '80px', xl: '80px' }}
            >
                <Box className={'flex'}>Procesando autenticación...</Box>
                {backendUserData && (
                    <Box className={'flex'}>
                        <List>
                            <ListItem>{backendUserData.name}</ListItem>
                            <ListItem>{backendUserData.email}</ListItem>
                            <ListItem>{backendUserData.picture}</ListItem>
                        </List>
                    </Box>
                )}
            </Flex>
        </CenteredAuth>
    );
};

export default AuthCallback;
