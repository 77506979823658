// Chakra Imports
import {
    Avatar,
    Box,
    Button,
    Flex,
    Icon,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
import { FaEthereum } from 'react-icons/fa';
import Configurator from 'components/navbar/Configurator';
import routes from 'routes';
import { useAuth0 } from '@auth0/auth0-react';
import PanelNotificaciones from './PanelNotificaciones';
import { useAppContext } from '../../contexts/AppContext';
import { Area } from '../../modules/auth/types/Area';

export default function HeaderLinks(props: { secondary: boolean; [x: string]: any }) {
    const { secondary, theme, setTheme } = props;
    // Chakra Color Mode
    const navbarIcon = useColorModeValue('gray.400', 'white');
    let menuBg = useColorModeValue('white', 'navy.800');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const textColorBrand = useColorModeValue('brand.700', 'brand.400');
    const ethColor = useColorModeValue('gray.700', 'white');
    const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
    const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
    const ethBox = useColorModeValue('white', 'navy.800');
    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
    );
    const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');
    const { user, isAuthenticated, logout } = useAuth0();
    const { backendUserData } = useAppContext();

    return (
        <Flex
            w={{ sm: '100%', md: 'auto' }}
            alignItems="center"
            flexDirection="row"
            bg={menuBg}
            flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
            p="10px"
            borderRadius="999px"
            boxShadow={shadow}
        >
            <SearchBar
                mb={() => {
                    if (secondary) {
                        return { base: '10px', md: 'unset' };
                    }
                    return 'unset';
                }}
                me="10px"
                borderRadius="20px"
            />
            <SidebarResponsive routes={routes} />
            <PanelNotificaciones />

            <Configurator
                mini={props.mini}
                setMini={props.setMini}
                theme={theme}
                setTheme={setTheme}
            />

            <Menu>
                <MenuButton p="0px">
                    <Avatar
                        _hover={{ cursor: 'pointer' }}
                        color={textColor}
                        name="Adela Parkson"
                        bg="#11047A"
                        size="sm"
                        w="40px"
                        h="40px"
                        src={isAuthenticated ? user.picture : null}
                    />
                </MenuButton>
                <MenuList
                    boxShadow={shadow}
                    p="0px"
                    mt="10px"
                    borderRadius="20px"
                    bg={menuBg}
                    border="none"
                >
                    <Flex w="100%" mb="0px">
                        <Text
                            ps="20px"
                            pt="16px"
                            pb="10px"
                            w="100%"
                            borderBottom="1px solid"
                            borderColor={borderColor}
                            fontSize="sm"
                            fontWeight="700"
                            color={textColor}
                        >
                            👋&nbsp; Hola, {isAuthenticated && user.name}
                        </Text>
                    </Flex>
                    <Flex w="100%" mb="0px">
                        <Box
                            ps="20px"
                            pt="16px"
                            pb="10px"
                            w="100%"
                            borderBottom="1px solid"
                            borderColor={borderColor}
                            fontSize="sm"
                            fontWeight="400"
                            color={textColor}
                        >
                            {backendUserData && backendUserData.roles && (
                                <Box>
                                    <Text>Rol: {backendUserData.roles}</Text>
                                </Box>
                            )}

                            {backendUserData && backendUserData.areas.length > 0 && (
                                <Box>
                                    Áreas:
                                    {backendUserData.areas.map((area: Area) => (
                                        <Text key={area.id}>{area.nombre}</Text>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    </Flex>
                    <Flex flexDirection="column" p="10px">
                        {/*<MenuItem
                            _hover={{ bg: 'none' }}
                            _focus={{ bg: 'none' }}
                            borderRadius="8px"
                            px="14px"
                        >
                            <Text fontSize="sm">Profile Settings</Text>
                        </MenuItem>
                        <MenuItem
                            _hover={{ bg: 'none' }}
                            _focus={{ bg: 'none' }}
                            borderRadius="8px"
                            px="14px"
                        >
                            <Text fontSize="sm">Newsletter Settings</Text>
                        </MenuItem>*/}
                        <MenuItem
                            _hover={{ bg: 'none' }}
                            _focus={{ bg: 'none' }}
                            color="red.400"
                            borderRadius="8px"
                            px="14px"
                        >
                            <Link
                                onClick={() =>
                                    logout({ logoutParams: { returnTo: window.location.origin } })
                                }
                            >
                                Log Out
                            </Link>
                        </MenuItem>
                    </Flex>
                </MenuList>
            </Menu>
        </Flex>
    );
}
