// Chakra imports
import { Avatar, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';

import React, { useEffect, useState } from 'react';
import { Usuario } from '../../../types/Usuario';
import { useLayoutContext } from '../../../../../contexts/LayoutContext';
import { useFetchData } from '../../../../../hooks/useFetchData';
import PageLoader from '../../../../../components/actions/PageLoader';
import { MenuItemProps } from '../../../../../types/MenuItemProps';
import { MdAddCircle } from 'react-icons/md';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import Listado, {
    crearCelda,
    crearCeldaConLink,
    crearHeader
} from '../../../../../components/cannava/listado';

export default function UsersOverview() {
    const transformUsuarios = (data: any[]): Usuario[] => {
        const imagenesRandom = [
            'https://i.ibb.co/zPxBHYv/241143773-8212166459343985239-7834018950652403662-n-1.jpg',
            'https://i.ibb.co/5r8xc6T/218987537-368849674583041-6903848186366518125-n.jpg',
            'https://i.ibb.co/7p0d1Cd/Frame-24.png',
            'https://images.unsplash.com/photo-1628157588553-5eeea00af15c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80',
            'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80'
        ];

        return data.map(user => {
            let nombreCompleto = user.nombre + ' ' + user.apellido;
            let imagen1 = user.imagen
                ? user.imagen
                : imagenesRandom[Math.floor(Math.random() * imagenesRandom.length)];
            return {
                id: user.id,
                nombre: user.nombre,
                apellido: user.apellido,
                nombre_completo: nombreCompleto,
                nombre_completo_imagen: [nombreCompleto, imagen1],
                email: user.email,
                nombre_usuario: user.nombre_usuario,
                fecha_creacion: user.fecha_creacion,
                rol: user.rol,
                imagen: imagen1,
                fecha_nacimiento: user.fecha_nacimiento,
                actions: 'Actions' // Esto es es
            };
        });
    };

    const { data: usersData, isLoading } = useFetchData<Usuario>('usuarios', transformUsuarios);
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const navigate = useNavigate();

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Administración', href: '/admin/home/' },
            { label: 'Usuarios', href: '/admin/usuarios/' }
        ]);

        setTitle('Usuarios');
    }, []);

    if (isLoading) {
        return <PageLoader message={'Cargando usuarios'} />;
    }

    const menuItems: MenuItemProps[] = [
        {
            icono: MdAddCircle,
            label: 'Agregar nuevo',
            onClick: () => navigate('/admin/usuarios/nuevo')
        }
    ];

    const textColor = 'navy.700';
    const columnHelper = createColumnHelper<Usuario>();
    const columns = [
        columnHelper.accessor('nombre_completo_imagen', {
            id: 'nombre_completo_imagen',
            header: crearHeader('Usuario'),
            cell: (info: any) => (
                <Flex align="center">
                    <Avatar src={info.getValue()[1]} h="60px" w="60px" me="10px" />
                    <Text color={textColor} fontSize="md" fontWeight="500">
                        {info.getValue()[0]}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('email', {
            id: 'email',
            header: crearHeader('Email'),
            cell: crearCelda()
        }),
        columnHelper.accessor('nombre_usuario', {
            id: 'nombre_usuario',
            header: crearHeader('Nombre usuario'),
            cell: crearCelda()
        }),
        columnHelper.accessor('fecha_creacion', {
            id: 'fecha_creacion',
            header: crearHeader('Fecha creación'),
            cell: crearCelda()
        }),
        columnHelper.accessor('rol', {
            id: 'rol',
            header: crearHeader('Rol'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/usuarios/editar', 'Editar usuario', navigate)
        })
    ];

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Card px="0px">
                <Listado tableData={usersData} menuItems={menuItems} columns={columns} />
            </Card>
        </Flex>
    );
}
