// Chakra imports
import { Flex } from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import React, { useEffect, useState } from 'react';
import { useLayoutContext } from '../../../../../contexts/LayoutContext';
import { Proveedor } from '../../../../genetica/types/Proveedor';
import { useFetchData } from '../../../../../hooks/useFetchData';
import PageLoader from '../../../../../components/actions/PageLoader';
import { MenuItemProps } from '../../../../../types/MenuItemProps';
import { MdAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import Listado, {
    crearCelda,
    crearCeldaConLink,
    crearHeader
} from '../../../../../components/cannava/listado';
import { Establecimiento } from '../../../types/Establecimiento';
import { useConfigurarNavegacion } from '../../../../../hooks/useConfigurarNavegacion';

export default function EstablecimientosIndex() {
    const transformData = (data: any[]): Establecimiento[] => {
        return data.map(data => ({
            id: data.id,
            nombre: data.nombre,
            descripcion: data.descripcion,
            slug: data.slug,
            codigo: data.codigo,
            latitud: data.latitud,
            longitud: data.longitud,
            superficie_total: data.superficie_total,
            fecha_creacion: data.slug,
            fecha_actualizacion: data.slug,
            fecha_borrado: data.slug,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };

    const { data: proveedoresData, isLoading } = useFetchData<Establecimiento>(
        'establecimientos',
        transformData
    );
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const navigate = useNavigate();

    // configuro layout
    const configurarNavegacion = useConfigurarNavegacion({
        breadcrumbs: [
            { label: 'Inicio', href: '/' },
            { label: 'Ubicaciones', href: '/admin/establecimientos/' }
        ],
        titulo: 'Establecimientos'
    });

    const menuItems: MenuItemProps[] = [
        {
            icono: MdAddCircle,
            label: 'Agregar nuevo',
            onClick: () => navigate('/admin/establecimientos/nuevo')
        }
    ];

    const columnHelper = createColumnHelper<Establecimiento>();
    const columns = [
        columnHelper.accessor('nombre', {
            id: 'nombre',
            header: crearHeader('Proveedor'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('ID'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'editar',
            header: crearHeader('Editar'),
            cell: crearCeldaConLink('/admin/establecimientos/editar', 'Editar', navigate)
        }),
        columnHelper.accessor('id', {
            id: 'actions',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/establecimientos/ver', 'Ver establecimiento', navigate)
        })
    ];

    if (isLoading) {
        return <PageLoader message={'Cargando establecimientos'} />;
    }

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Card px="0px">
                <Listado tableData={proveedoresData} menuItems={menuItems} columns={columns} />
            </Card>
        </Flex>
    );
}
