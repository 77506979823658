// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    useColorModeValue,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useDisclosure
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import Index from 'components/cannava/dangerZone';
import { useLayoutContext } from 'contexts/LayoutContext';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import { Proveedor } from 'modules/genetica/types/Proveedor';
import { useFetchData } from 'hooks/useFetchData';
import { Variedad } from 'modules/genetica/types/Variedad';
import { useEnvioFormularioAntiguo } from 'hooks/useEnvioFormularioAntiguo';
import DangerZone from 'components/cannava/dangerZone';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import { Establecimiento } from 'modules/locacion/types/Establecimiento';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import CannavaTextareaField from 'components/fields/CannavaTextareaField';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import { LotePoderGerminativo } from 'modules/trazabilidad/types/LotePoderGerminativo';

type UserFormData = {
    nombre: string;
    apellido: string;
    email: string;
    nombre_usuario: string;
    rol: string;
    fecha_nacimiento: string;
};

type LoteData = {
    id: number;
    lote_semilla_id?: string;
    peso: number;
    unidades: number;
    fecha_recepcion: string;
    cantidad_bajas: number;
    peso_promedio: number;
    stock: number;
    genetica_id: number;
    locacion_id: number;
};

type OpcionesSelect = {
    usoLote: any[];
};

/**
 * Pantalla para crear un nuevo lote de poder germinativo asociado a un lote de semillas.
 * El diseno del mismo se encuentra aquí:
 * https://www.figma.com/proto/k3jQhs4SxEk6HRhwCXzUvD/Cannava-wireframes?type=design&node-id=295-7808&t=Amd8BLLm2lH6sReA-0&scaling=scale-down-width&page-id=105%3A1102&starting-point-node-id=111%3A11647&show-proto-sidebar=1&hide-ui=1
 *
 * @constructor
 */
export default function NewLote() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { loteSemillasId } = useParams();
    const id = 0;
    const isEditMode = false;
    const { setBreadcrumbs, setTitle } = useLayoutContext();

    const [isDeleting, setIsDeleting] = useState(false);

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = useState({
        general: true,
        permissions: false
    });

    const navigate = useNavigate();
    const generalTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const permissionsTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);
    const [cargandoData, setCargandoData] = useState(false);
    const [loteData, setLoteData] = useState(null);
    const { setFlashMessage, setFlashType } = useAppContext();

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // configuro layout
    const configurarNavegacion = useConfigurarNavegacion({
        breadcrumbs: [
            { label: 'Inicio', href: '/' },
            { label: 'Lotes de semillas', href: '/admin/lote-semillas/' },
            { label: 'Lote Id', href: '/admin/lote-semillas/ver' }
        ],
        titulo: 'Nuevo análisis de poder germinativo'
    });

    const fetchLoteData = async (loteId: string) => {
        setCargandoData(true);
        CannavaApiService.lotesSemillasObtener(
            loteId,
            authToken,
            data => {
                console.log('data lote');
                console.log(data);
                setLoteData(data);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-semillas');
            }
        );
    };

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'lotePoderGerminativo',
        formMethods: methods,
        onSuccess: data => {
            // @todo: reemplazar para que redireccione a la pantalla de ver lote semillas
            console.log(loteSemillasId);
            navigate('/admin/lotes-semillas/ver/' + loteSemillasId);
        },
        onError: error => {
            //navigate('/admin/lotes-semillas/ver/' + loteSemillasId);
            if (generalTab.current != undefined) generalTab.current.click();
        },
        onErrorDelete: error => {
            // @todo: reemplazar para que redireccione a la pantalla de ver lote semillas
            navigate('/admin/lotes-semillas');
        }
    });

    const [opcionesSelect, setOpcionesSelect] = useState<OpcionesSelect>({
        usoLote: [
            {
                id: 'investigacion',
                nombre: 'Investigación'
            }
        ]
    });

    const onSubmit: SubmitHandler<LotePoderGerminativo> = (data: LotePoderGerminativo) => {
        data.lote_semillas_id = loteSemillasId;
        useEnvioFormulario1.envioFormularioCrear(data);
    };

    const mb: string = '0px';
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    useEffect(() => {
        if (loteSemillasId) {
            fetchLoteData(loteSemillasId);
        }
    }, [loteSemillasId]);

    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    if (cargandoData) {
        return <PageLoader message="Cargando opciones de formulario..." />;
    }

    return (
        <>
            {!isDeleting && (
                <Flex
                    direction="column"
                    minH="100vh"
                    align="center"
                    pt={{ sm: '125px', lg: '75px' }}
                    position="relative"
                >
                    <Box
                        h="45vh"
                        bgGradient="linear(to-b, brand.400, brand.600)"
                        position="absolute"
                        w="100%"
                        borderRadius="20px"
                    />

                    <FormProvider {...methods}>
                        <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <Card p="30px" mt={'60px'}>
                                    <Text
                                        color={textColor}
                                        fontSize="2xl"
                                        fontWeight="700"
                                        mb="20px"
                                    >
                                        Crear nuevo análisis
                                    </Text>
                                    {errorMessage && (
                                        <Alert status="error">
                                            <AlertIcon />
                                            <AlertTitle>{errorMessage}</AlertTitle>
                                        </Alert>
                                    )}
                                    <Flex direction="column" w="100%">
                                        <Stack direction="column" spacing="10px">
                                            {/* @todo: reemplazar campos formulario */}
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaInputField
                                                    id={'cantidad_semillas'}
                                                    label={'Cantidad de semillas utilizadas (u.)'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                    onBlur={(
                                                        e: React.FocusEvent<HTMLInputElement>
                                                    ) => {
                                                        const unidades = +e.target.value;

                                                        if (loteData.genetica) {
                                                            let peso = Math.ceil(
                                                                (unidades /
                                                                    loteData.genetica
                                                                        .cantidad_semillas_por_100_gramos) *
                                                                    100
                                                            );
                                                            methods.setValue(
                                                                'peso_total_semillas',
                                                                peso
                                                            );
                                                        }
                                                    }}
                                                />

                                                <CannavaInputField
                                                    id={'peso_total_semillas'}
                                                    label={'Peso semillas (gr)'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                <CannavaSelectField
                                                    id={'uso_lote'}
                                                    label={'Uso de lote'}
                                                    opciones={opcionesSelect.usoLote}
                                                    placeholder={'Seleccione un uso'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                <CannavaDatePickerField
                                                    id={'fecha_finalizacion'}
                                                    label={'Fecha de finalización'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                {/*  <CannavaInputField
                                            id={"cantidad_bajas"}
                                            label={"Cantidad Bajas"}
                                            validationRules={{required: "Campo obligatorio"}}
                                        />

                                        <CannavaInputField
                                            id={"peso_promedio"}
                                            label={"Peso promedio"}
                                            validationRules={{required: "Campo obligatorio"}}
                                        />

                                        <CannavaInputField
                                            id={"stock"}
                                            label={"Stock"}
                                        />*/}
                                            </SimpleGrid>

                                            <CannavaTextareaField
                                                id={'observaciones'}
                                                label={'Observaciones'}
                                                validationRules={
                                                    {
                                                        // required: 'Campo obligatorio'
                                                    }
                                                }
                                            />
                                        </Stack>
                                        <Flex justify="space-between" mt="24px">
                                            <Button
                                                variant="darkBrand"
                                                fontSize="sm"
                                                borderRadius="16px"
                                                w={{ base: '128px', md: '148px' }}
                                                h="46px"
                                                isLoading={methods.formState.isSubmitting}
                                                type="submit"
                                            >
                                                Enviar
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Card>
                            </form>
                        </Box>
                    </FormProvider>
                    {/* Danger Zone */}
                    {isEditMode && (
                        <DangerZone
                            elemento={'producto'}
                            onConfirmDelete={() => {
                                setIsDeleting(true);
                                useEnvioFormulario1.eliminarElemento(id);
                            }}
                        />
                    )}
                </Flex>
            )}

            {isDeleting && <PageLoader message="Eliminando lote de poder germinativo..." />}
        </>
    );
}
