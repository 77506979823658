// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import React, { useCallback, useEffect, useState } from 'react';
import { Variedad } from '../../../types/Variedad';
import { useLayoutContext } from '../../../../../contexts/LayoutContext';
import { useFetchData } from '../../../../../hooks/useFetchData';
import PageLoader from '../../../../../components/actions/PageLoader';
import { MenuItemProps } from '../../../../../types/MenuItemProps';
import { MdAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import Listado, {
    crearCelda,
    crearCeldaConLink,
    crearHeader
} from '../../../../../components/cannava/listado';
import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';

export default function VariedadesOverview() {
    const transformLote = (data: any[]): LoteSemillas[] => {
        return data.map(lote => ({
            id: lote.id,
            codigo_lote: lote.codigo_lote,
            peso: lote.peso,
            unidades: lote.unidades,
            fecha_recepcion: lote.fecha_recepcion,
            cantidad_bajas: lote.cantidad_bajas,
            peso_promedio: lote.peso_promedio,
            genetica_id: lote.genetica_id,
            establecimiento_id: lote.establecimiento_id,
            stock: lote.stock,
            semillasDisponibles: lote.semillasDisponibles,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };

    const transformVariedades = (data: any[]): Variedad[] => {
        return data.map(variedad => ({
            id: variedad.id,
            nombre: variedad.nombre,
            slug: variedad.slug,
            proveedor_id: variedad.proveedor_id,
            cannabinoide_dominante_id: variedad.cannabinoide_dominante_id,
            poder_germinativo_promedio: variedad.poder_germinativo_promedio,
            cantidad_semillas_por_100_gramos: variedad.cantidad_semillas_por_100_gramos,
            densidad_siembra_planta_m2: variedad.densidad_siembra_planta_m2,
            semillasTotales: 0,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };

    const [variedadesData, setVariedadesData] = useState<Variedad[]>([]);
    const { data: lotesData, isLoading: isLotesLoading } = useFetchData<LoteSemillas>(
        'lotesSemillas',
        transformLote
    );
    const { data: fetchedVariedadesData, isLoading: isVariedadesLoading } = useFetchData<Variedad>(
        'variedades',
        transformVariedades
    );

    useEffect(() => {
        console.log('lotes' + lotesData);
        console.log('variedades' + fetchedVariedadesData);
        const updatedVariedadesData: Variedad[] = [];

        if (fetchedVariedadesData.length > 0 && lotesData) {
            fetchedVariedadesData.forEach(variedad => {
                let semillasTotales = 0;

                lotesData.forEach(lote => {
                    if (lote.genetica_id === variedad.id) {
                        semillasTotales += lote.unidades;
                    }
                });

                variedad.semillasTotales = semillasTotales;
                console.log(semillasTotales);

                updatedVariedadesData.push(variedad);
            });

            setVariedadesData(updatedVariedadesData);
        }
    }, [fetchedVariedadesData, lotesData]);

    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const navigate = useNavigate();

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Genéticas', href: '/admin/variedades/' }
        ]);

        setTitle('Variedades');
    }, []);

    if (isVariedadesLoading) {
        return <PageLoader message={'Cargando variedades'} />;
    }

    const menuItems: MenuItemProps[] = [
        {
            icono: MdAddCircle,
            label: 'Agregar nuevo',
            onClick: () => navigate('/admin/variedades/nuevo')
        }
    ];

    const columnHelper = createColumnHelper<Variedad>();
    const columns = [
        columnHelper.accessor('nombre', {
            id: 'nombre',
            header: crearHeader('Variedad'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('ID'),
            cell: crearCelda()
        }),
        columnHelper.accessor('semillasTotales', {
            id: 'semillasTotales',
            header: crearHeader('Semillas totales'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'action_editar',
            header: crearHeader('Editar'),
            cell: crearCeldaConLink('/admin/variedades/editar', 'Editar', navigate)
        }),
        columnHelper.accessor('id', {
            id: 'actions',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/variedades/ver', 'Ver genética', navigate)
        })
    ];

    return (
        <>
            {variedadesData.length > 0 && (
                <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
                    <Card px="0px">
                        <Listado
                            tableData={variedadesData}
                            menuItems={menuItems}
                            columns={columns}
                            key={variedadesData[0].semillasTotales}
                        />
                    </Card>
                </Flex>
            )}
        </>
    );
}
