// Custom components
import Card from 'components/card/Card';
import { useColorModeValue } from '@chakra-ui/react';
import Map from 'react-map-gl';

import 'mapbox-gl/dist/mapbox-gl.css';

//const MAPBOX_TOKEN = 'pk.eyJ1Ijoic2ltbW1wbGUiLCJhIjoiY2wxeG1hd24xMDEzYzNrbWs5emFkdm16ZiJ9.q9s0sSKQFFaT9fyrC-7--g'; // Set your mapbox token her

const MAPBOX_TOKEN =
    'pk.eyJ1IjoiZ29uemFiIiwiYSI6ImNqbjdhb3QwbzAzeWEzcGxtenkwbWlqc20ifQ.C9DxM7XAF7rA48AXnzqmgQ';

type MapCardProps = {
    lat: number;
    lng: number;
    mapZoom?: number;
    gridArea?: string;
};

export default function MapCard(props: MapCardProps) {
    const { lat, lng, mapZoom, ...rest } = props;
    const mapStyles = useColorModeValue(
        'mapbox://styles/gonzab/clpicgv9o00ll01pkgnvd7y6r',
        'mapbox://styles/gonzab/clpicgv9o00ll01pkgnvd7y6r'
    );
    return (
        <Card
            justifyContent="start"
            flexDirection="column"
            w="100%"
            pb="20px"
            minH={{ base: '600px', lg: '100%' }}
            {...rest}
        >
            <Map
                initialViewState={{
                    latitude: lat,
                    longitude: lng,
                    zoom: mapZoom ?? 10
                }}
                style={{ borderRadius: '20px', width: '100%', minHeight: '400px' }}
                mapStyle={mapStyles}
                mapboxAccessToken={MAPBOX_TOKEN}
            />
        </Card>
    );
}
