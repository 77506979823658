// Chakra imports
import {
    Flex,
    Box,
    Grid,
    SimpleGrid,
    useColorModeValue,
    Icon,
    Text,
    Badge,
    Alert,
    AlertIcon,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td
} from '@chakra-ui/react';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useReactToPrint } from 'react-to-print';
import OrderStatus from 'views/admin/main/ecommerce/orderDetails/components/OrderStatus';
import Receipt from 'views/admin/main/ecommerce/orderDetails/components/Receipt';
import Details from 'views/admin/main/ecommerce/orderDetails/components/Details';
import { useLayoutContext } from 'contexts/LayoutContext';
import { useParams } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';
import { useAppContext } from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import PageLoader from 'components/actions/PageLoader';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { MdShowChart } from 'react-icons/md';
import { HSeparator, VSeparator } from 'components/separator/Separator';
import EvolucionProcesoAnalisis from '../../lotesSemillas/verLote/components/EvolucionProcesoAnalisis';
import Card from 'components/card/Card';
import MapCard from 'views/admin/dashboards/smartHome/components/MapCard';
import SessionBadge from 'components/dataDisplay/SessionBadge';
import { Inventario } from 'modules/trazabilidad/types/Inventario';
import Listado, { crearCelda, crearCeldaConLink, crearHeader } from 'components/cannava/listado';
import { useFetchData } from 'hooks/useFetchData';
import { MenuItemProps } from 'types/MenuItemProps';
import InfoRegistro from '../../lotesSemillas/verLote/components/InfoRegistro';
import AnalisisCalidadSemilla from './components/AnalisisCalidadSemilla';
import { LotePoderGerminativo } from 'modules/trazabilidad/types/LotePoderGerminativo';
import {
    PaginationState,
    createColumnHelper,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
    CellContext
} from '@tanstack/react-table';
import { ResultadoReporte } from 'modules/trazabilidad/types/ResultadoReporte';
import EvaluacionViabilidad from './components/EvaluacionViabilidad';
import Anexos from './components/Anexos';
import PanelAnotaciones from 'components/chat/PanelAnotaciones';

export default function VerLotePoderGenerativo() {
    const textColor = useColorModeValue('gray.700', 'white');
    const bgButton = 'rgba(255,255,255,0.12)';
    const bgHover = { bg: 'whiteAlpha.50' };
    const bgFocus = { bg: 'rgba(255,255,255,0.12)' };
    const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    const navigate = useNavigate();
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const { authToken } = useAppContext();
    const { id } = useParams();
    const [cargandoData, setCargandoData] = useState(false);
    const componentRef = useRef();
    const { setFlashMessage, setFlashType } = useAppContext();
    const [loteData, setLoteData] = useState(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const fetchLoteData = async (loteId: string) => {
        setCargandoData(true);
        CannavaApiService.obtener(
            'lotePoderGerminativo',
            loteId,
            authToken,
            data => {
                console.log('data lote');
                console.log(data);
                setLoteData(data);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del poder germinativo.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-poder-germinativo');
            }
        );
    };

    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: () => {
                let _breadcrumbs = [];
                if (!loteData) {
                    return [];
                } else {
                    _breadcrumbs = [
                        // Version TEST - Configurar rutas correctamente!
                        { label: 'Trazabilidad', href: '/admin/home/' },
                        { label: 'Lotes de semillas', href: '/admin/lotes-semillas/' },
                        {
                            label: loteData.loteSemillas.codigo_lote,
                            href: '/admin/lotes-semillas/ver/' + loteData.loteSemillas.id
                        }
                    ];
                }

                return _breadcrumbs;
            },
            titulo: () => {
                if (!loteData) {
                    return '';
                }

                return `Lote ${loteData.codigo_lote}`;
            }
        },
        loteData
    );

    useEffect(() => {
        if (id && authToken) {
            fetchLoteData(id);
        }
    }, [id, authToken]);

    if (cargandoData || !loteData) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Grid
                templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
                gap={4}
                mb={'30px'}
            >
                {/* Fila 1 */}
                <Box>
                    <MiniStatistics
                        name="Variedad"
                        value={loteData.loteSemillas.genetica.nombre}
                        click={() => {
                            navigate('/admin/variedades/ver/' + loteData.genetica.id);
                        }}
                    />
                </Box>
                <Box>
                    <MiniStatistics
                        name="Cannabinoide dominante"
                        value={loteData.loteSemillas.genetica.cannabinoideDominante.tipo}
                    />
                </Box>
                <Box>
                    <MiniStatistics name="Cantidad semillas" value={loteData.cantidad_semillas} />
                </Box>
                {loteData.estado == 'en-curso' && (
                    <Box>
                        <MiniStatistics
                            name="En proceso"
                            value={'Poder germinativo'}
                            formato={'lotePG'}
                        />
                    </Box>
                )}
                {/*<Box>
                    <MiniStatistics name="Peso" value={loteData.peso} />
                </Box>*/}
            </Grid>

            {/* Fila 2 */}
            <Grid
                templateColumns={{ base: 'repeat(1, 1fr)', md: '75% 25%' }}
                gridColumn="span 4"
                gap={4}
            >
                {/* Columna 1 de Fila 2 */}
                <Flex flexDirection="column">
                    {/* Fila 2.1.1 */}
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} my={'20px'}>
                        <Box w="100%">
                            <EvolucionProcesoAnalisis loteData={loteData.loteSemillas} />
                        </Box>
                        <Box w="100%">
                            {loteData.reportePoderGerminativo ? (
                                <AnalisisCalidadSemilla
                                    loteData={loteData.loteSemillas}
                                    lotePoderGerminativo={loteData}
                                />
                            ) : (
                                <InfoRegistro
                                    loteData={loteData.loteSemillas}
                                    mostrarLoteSemillas={true}
                                />
                            )}
                        </Box>
                    </SimpleGrid>
                    {/* Fila 2.1.2 */}

                    {loteData.reportePoderGerminativo && (
                        <ListadoRepeticiones loteData={loteData} />
                    )}

                    {/* Fila 2.1.1 */}
                    {loteData.reportePoderGerminativo && (
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} my={'20px'}>
                            <Box w="100%">
                                <EvaluacionViabilidad
                                    loteData={loteData.loteSemillas}
                                    lotePoderGerminativo={loteData}
                                />
                            </Box>
                            <Box w="100%">
                                <Anexos
                                    loteData={loteData.loteSemillas}
                                    lotePoderGerminativo={loteData}
                                />
                            </Box>
                        </SimpleGrid>
                    )}
                    {/* Fila 2.1.2 */}

                    <ListadoInventario loteSemillasID={loteData.lote_semillas_id} />

                    <PanelAnotaciones modelo="lotePoderGerminativo" id={loteData.id} />
                </Flex>
                {/* Columna 2 de Fila 2 */}

                <Box w="100%">
                    <MapCard gridArea="1 / 2 / 2 / 3" />
                </Box>
            </Grid>
        </Box>
    );
}

type ListadoInventarioProps = {
    loteSemillasID: string;
};

function ListadoInventario(props: ListadoInventarioProps) {
    const { loteSemillasID } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    const transformInventarios = (data: any[]): Inventario[] => {
        return data.map(inventario_registro => ({
            id: inventario_registro.id,
            lote_semillas_id: inventario_registro.lote_semillas_id,
            lote_semillas: inventario_registro.loteSemillas,
            cantidad: inventario_registro.cantidad,
            stock: inventario_registro.stock,
            data: inventario_registro.data,
            fecha_creacion: inventario_registro.fecha_creacion,
            fecha_actualizacion: inventario_registro.fecha_actualizacion,
            actions: 'actions'
        }));
    };

    const { data: inventarioData, isLoading } = useFetchData<Inventario>(
        'inventarios',
        transformInventarios,
        [['lote-semillas', loteSemillasID]]
    );
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const navigate = useNavigate();

    const textColor = 'navy.700';
    const columnHelper = createColumnHelper<Inventario>();
    const columns = [
        columnHelper.accessor('lote_semillas_id', {
            id: 'lote_semillas_id',
            header: crearHeader('ID Lote'),
            cell: crearCelda((info: CellContext<any, any>) => {
                return info.row.original.lote_semillas.codigo_lote;
            })
        }),
        columnHelper.accessor('cantidad', {
            id: 'cantidad',
            header: crearHeader('Movimiento'),
            cell: crearCelda()
        }),
        columnHelper.accessor('stock', {
            id: 'stock',
            header: crearHeader('Stock'),
            cell: crearCelda()
        }),
        columnHelper.accessor('fecha_creacion', {
            id: 'fecha_creacion',
            header: crearHeader('Fecha de movimiento'),
            // cell: crearCelda()
            cell: crearCelda((info: CellContext<any, any>) => {
                return info.row.original.fecha_creacion.split(' ')[0];
            })
        })
    ];

    if (isLoading) {
        return <PageLoader message={'Cargando registro de inventario'} />;
    }

    return (
        <Flex direction="column" mt={2}>
            <Card px="0px">
                <Flex justify="space-between" align="center" px="30px" py="15px">
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Registro de inventario
                    </Text>
                </Flex>
                <Listado tableData={inventarioData} menuItems={[]} columns={columns} />
            </Card>
        </Flex>
    );
}

type ListadoResultadosProps = {
    loteData: LotePoderGerminativo;
};

function ListadoRepeticiones(props: ListadoResultadosProps) {
    const { loteData } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const [data, setData] = React.useState(() => []);

    const columnHelper = createColumnHelper<ResultadoReporte>();
    const columns = [
        columnHelper.accessor('posicion', {
            id: 'lote_semillas_id',
            header: crearHeader('Posicion'),
            cell: crearCelda()
        }),
        columnHelper.accessor('semillas_germinadas', {
            id: 'cantidad',
            header: crearHeader('Germinadas'),
            cell: crearCelda()
        }),
        columnHelper.accessor('semillas_no_germinadas', {
            id: 'stock',
            header: crearHeader('No Germinadas'),
            cell: crearCelda()
        }),
        columnHelper.accessor('poderGerminativo', {
            id: 'poderGerminativo',
            header: crearHeader('PG'),
            cell: crearCelda()
        }),
        columnHelper.accessor('observaciones', {
            id: 'fecha_creacion',
            header: crearHeader('Observaciones'),
            // cell: crearCelda()
            cell: crearCelda()
        })
    ];

    useEffect(() => {
        setData(loteData.reportePoderGerminativo.resultados);
    }, [loteData]);

    const textColor = useColorModeValue('navy.700', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const brandColor = useColorModeValue('brand.500', 'brand.400');

    const table = useReactTable({
        data,
        columns,
        state: {},
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false
    });

    return (
        <Flex direction="column" my={2}>
            <Card px="0px">
                <Flex justify="space-between" align="center" px="30px" py="15px">
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Resultados
                    </Text>
                </Flex>
                <Table variant="simple" color="gray.500" mb="24px">
                    <Thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <Th
                                            pe="10px"
                                            borderColor={borderColor}
                                            key={header.id}
                                            colSpan={header.colSpan}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <Flex
                                                    {...{
                                                        className: header.column.getCanSort()
                                                            ? 'cursor-pointer select-none'
                                                            : '',
                                                        onClick:
                                                            header.column.getToggleSortingHandler()
                                                    }}
                                                    justify="space-between"
                                                    align="center"
                                                    fontSize={{ sm: '10px', lg: '12px' }}
                                                    color="gray.400"
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {{
                                                        asc: '',
                                                        desc: ''
                                                    }[header.column.getIsSorted() as string] ??
                                                        null}
                                                </Flex>
                                            )}
                                        </Th>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <Tr px="20px" key={row.id}>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <Td
                                                key={cell.id}
                                                fontSize={{ sm: '14px' }}
                                                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                                                borderColor={borderColor}
                                            >
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>

                <HSeparator />
            </Card>
        </Flex>
    );
}
