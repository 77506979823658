import {
    Flex,
    Box,
    Grid,
    useColorModeValue,
    Text,
    Divider,
    GridItem,
    Button
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLayoutContext } from 'contexts/LayoutContext';
import { useParams } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';
import { useAppContext } from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import PageLoader from 'components/actions/PageLoader';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import MiniStatistics from 'components/card/MiniStatistics';
import Card from 'components/card/Card';
import { FilaInfoDetalleLimpieza } from './Components/FilaInfoDetalleLimpieza';

export default function VerDetallesLimpieza() {
    const infoDetallesLimpieza = [
        {
            fecha: '05/06/2024',
            hora: '11:39',
            personal: 'Juan',
            encargado: 'Toni',
            productos: 'KH7'
        },
        {
            fecha: '04/06/2024',
            hora: '12:39',
            personal: 'Juan',
            encargado: 'Manoli',
            productos: 'KH7'
        },
        {
            fecha: '03/06/2024',
            hora: '16:39',
            personal: 'Pepe',
            encargado: 'Toni',
            productos: 'TenVerde'
        }
    ];

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    const navigate = useNavigate();
    const { authToken } = useAppContext();
    const { id } = useParams();
    const [cargandoData, setCargandoData] = useState(false);
    const { setFlashMessage, setFlashType } = useAppContext();
    const [loteData, setLoteData] = useState(null);

    const fetchLoteData = async (loteId: string) => {
        setCargandoData(true);
        CannavaApiService.lotesGerminacionObtener(
            loteId,
            authToken,
            data => {
                console.log('data lote germinacion');
                console.log(data);
                setLoteData(data);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion');
            }
        );
    };

    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: [
                { label: 'Inicio', href: '/admin/home/' },
                { label: 'Trazabilidad', href: '/admin/home/' },
                { label: 'Lotes de germinación', href: '/admin/lotes-germinacion/' }
            ],
            titulo: () => {
                // Generar título basado en data cargada
                return !loteData ? '' : `Lote ${loteData.codigo_lote}`;
            }
        },
        loteData
    );

    const [infoDetallesLimpiezaDataFinal, setinfoDetallesLimpiezaDataFinal] =
        useState(infoDetallesLimpieza);
    const rotateStyle = {
        transform: 'rotate(-90deg)'
    };

    const sortFecha = () => {
        const dataFinal = [...infoDetallesLimpieza].sort((a, b) => {
            // Extract and transform the date to YYYY/MM/DD format
            const [dayA, monthA, yearA] = a.fecha.split('/');
            const [dayB, monthB, yearB] = b.fecha.split('/');

            const formattedDateA = `${yearA}/${monthA}/${dayA}`;
            const formattedDateB = `${yearB}/${monthB}/${dayB}`;

            // Combine date and time into a single string
            const dateTimeA = `${formattedDateA} ${a.hora}`;
            const dateTimeB = `${formattedDateB} ${b.hora}`;

            // Compare the combined date and time strings
            if (dateTimeA < dateTimeB) {
                return -1;
            }
            if (dateTimeA > dateTimeB) {
                return 1;
            }
            return 0;
        });

        setinfoDetallesLimpiezaDataFinal(dataFinal);
    };

    const sortHora = () => {
        const dataFinal = [...infoDetallesLimpieza].sort((a, b) => {
            const [hoursA, minutesA] = a.hora.split(':').map(Number);
            const [hoursB, minutesB] = b.hora.split(':').map(Number);

            const totalMinutesA = hoursA * 60 + minutesA;
            const totalMinutesB = hoursB * 60 + minutesB;

            return totalMinutesA - totalMinutesB;
        });
        setinfoDetallesLimpiezaDataFinal(dataFinal);
    };

    const sortPersonal = () => {
        const dataFinal = [...infoDetallesLimpieza].sort((a, b) => {
            return a.personal.localeCompare(b.personal);
        });
        setinfoDetallesLimpiezaDataFinal(dataFinal);
    };

    const sortEncargado = () => {
        const dataFinal = [...infoDetallesLimpieza].sort((a, b) => {
            return a.encargado.localeCompare(b.encargado);
        });
        setinfoDetallesLimpiezaDataFinal(dataFinal);
    };

    const sortProducto = () => {
        const dataFinal = [...infoDetallesLimpieza].sort((a, b) => {
            return a.productos.localeCompare(b.productos);
        });
        setinfoDetallesLimpiezaDataFinal(dataFinal);
    };

    useEffect(() => {
        console.log(infoDetallesLimpiezaDataFinal);
    }, [infoDetallesLimpiezaDataFinal]);

    useEffect(() => {
        console.log(infoDetallesLimpieza);
    }, []);

    useEffect(() => {
        if (id) {
            fetchLoteData(id);
        }
    }, [id]);

    if (cargandoData || !loteData) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Grid
                templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
                gap={4}
                mb={'30px'}
            >
                {/* Fila 1 */}
                <Box>
                    <MiniStatistics name="Fecha de emisión" value={'05/09/2023'} />
                </Box>
                <Box>
                    <MiniStatistics name="Variedad" value={loteData.codigo_lote} />
                </Box>
                <Box>
                    <MiniStatistics name="Variedad" value={'Lote sano'} />
                </Box>
            </Grid>

            {/* Fila 2 */}
            <Card p="30px">
                <Flex justify="space-between" align="center" mb="5">
                    <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                        Detalles Limpieza
                    </Text>
                </Flex>
                <Grid
                    templateColumns={{
                        base: '18% 18% 18% 18% 18% 10%',
                        md: '18% 18% 18% 18% 18% 10%'
                    }}
                    gap={0}
                >
                    <GridItem display="flex" justifyContent="start" alignItems="center">
                        <Text fontSize={13} textAlign="center" marginRight={2}>
                            Fecha de realización
                        </Text>
                        <Button
                            onClick={sortFecha}
                            height={10}
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                        >
                            <Text textAlign="center" style={rotateStyle}>
                                {'<'}
                            </Text>
                        </Button>
                    </GridItem>
                    <GridItem display="flex" justifyContent="start" alignItems="center">
                        <Text fontSize={13} textAlign="center" marginRight={2}>
                            Hora de realización
                        </Text>
                        <Button
                            onClick={sortHora}
                            height={10}
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                        >
                            <Text textAlign="center" style={rotateStyle}>
                                {'<'}
                            </Text>
                        </Button>
                    </GridItem>
                    <GridItem display="flex" justifyContent="start" alignItems="center">
                        <Text fontSize={13} textAlign="center" marginRight={2}>
                            Limpió
                        </Text>
                        <Button
                            onClick={sortPersonal}
                            height={10}
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                        >
                            <Text textAlign="center" style={rotateStyle}>
                                {'<'}
                            </Text>
                        </Button>
                    </GridItem>
                    <GridItem display="flex" justifyContent="start" alignItems="center">
                        <Text fontSize={13} textAlign="center" marginRight={2}>
                            Supervisó
                        </Text>
                        <Button
                            onClick={sortEncargado}
                            height={10}
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                        >
                            <Text textAlign="center" style={rotateStyle}>
                                {'<'}
                            </Text>
                        </Button>
                    </GridItem>
                    <GridItem display="flex" justifyContent="start" alignItems="center">
                        <Text fontSize={13} textAlign="center" marginRight={2}>
                            Productos utilizados
                        </Text>
                        <Button
                            onClick={sortProducto}
                            height={10}
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                        >
                            <Text textAlign="center" style={rotateStyle}>
                                {'<'}
                            </Text>
                        </Button>
                    </GridItem>
                </Grid>
                <Divider border="1px" borderColor="lightgray" />
                {infoDetallesLimpiezaDataFinal.map((detalle, index) => (
                    <FilaInfoDetalleLimpieza detallesLimpieza={detalle} loteId={id} key={index} />
                ))}
            </Card>
        </Box>
    );
}
