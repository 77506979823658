// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    useColorModeValue,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useDisclosure,
    Switch,
    Checkbox
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import Index from 'components/cannava/dangerZone';
import { useLayoutContext } from 'contexts/LayoutContext';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import { Proveedor } from 'modules/genetica/types/Proveedor';
import { useFetchData } from 'hooks/useFetchData';
import { Variedad } from 'modules/genetica/types/Variedad';
import { useEnvioFormularioAntiguo } from 'hooks/useEnvioFormularioAntiguo';
import DangerZone from 'components/cannava/dangerZone';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import { Establecimiento } from 'modules/locacion/types/Establecimiento';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import CannavaTextAreaField from 'components/fields/CannavaTextareaField';
import { Insumos } from 'modules/catalogo/types/Insumos';
import { MonitoreoPlantinesData } from 'modules/trazabilidad/types/MonitoreoPlantinesData';

type OpcionesSelect = {
    insumos: any[];
};

export default function MonitoreoPlantines() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const opcionesActividad = [
        'riego',
        'poda',
        'fertirriego',
        'trasplante',
        'limpieza',
        'sistemaIluminacion',
        'cultivoServicio',
        'inspeccion',
        'cosecha',
        'fitosanitarios',
        'descarteLabranza',
        'sistemaConduccion'
    ];

    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const navigate = useNavigate();
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);

    const [opcionesSelect, setOpcionesSelect] = useState<OpcionesSelect>({
        insumos: []
    });

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { setFlashMessage, setFlashType } = useAppContext();
    const [plantineraData, setPlantineraData] = useState(null);
    const [cargandoData, setCargandoData] = useState(false);

    const { data: insumosData, isLoading } = useFetchData<Insumos>('insumos');

    const fetchPlantineraData = async (id: string) => {
        setCargandoData(true);
        CannavaApiService.reportePlantineraPorGerminacionIDObtener(
            id,
            authToken,
            data => {
                console.log('data plantinera');
                console.log(data);
                setPlantineraData(data);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos de la plantinera.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion');
            }
        );
    };

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'monitoreoPlantines',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/lotes-germinacion/monitoreo-temperatura-humedad/' + id);
        },
        onError: error => {
            console.log(error);
        },
        onErrorDelete: error => {
            navigate('/admin/lotes-germinacion');
        }
    });

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Trazabilidad', href: '/admin/trazabilidad/' },
            { label: 'Lotes de germinacion', href: '/admin/lotes-germinacion' }
            /* Añadir para volver al lote  { label: 'Lotes de germinacion', href: '/admin/lotes-germinacion/:id' } */
        ]);

        setTitle('Lote');
    }, []);

    const onSubmit: SubmitHandler<MonitoreoPlantinesData> = (data: MonitoreoPlantinesData) => {
        data.campos = {
            plantinera_id: plantineraData[0].id,
            insumo_id: parseInt(data.insumo_id),
            riego: data.riego,
            poda: data.poda,
            fertirriego: data.fertirriego,
            trasplante: data.trasplante,
            limpieza: data.limpieza,
            'sistema de iluminacion': data.sistemaIluminacion,
            'cultivo de servicio': data.cultivoServicio,
            inspeccion: data.inspeccion,
            cosecha: data.cosecha,
            fitosanitarios: data.fitosanitarios,
            'descarte labranza de suelo': data.descarteLabranza,
            'sistema de conduccion': data.sistemaConduccion
        };

        data.lote_germinacion_id = id;

        useEnvioFormulario1.envioFormularioCrear(data);
        console.log(data);
    };

    const onCancel: any = () => {
        navigate('/admin/lotes-germinacion');
    };

    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    useEffect(() => {
        if (!insumosData) {
            return;
        }

        const insumosActivos: Insumos[] = [];

        insumosData.forEach(insumo => {
            if (insumo.estado === 'activo') {
                insumosActivos.push(insumo);
            }
        });

        let opciones: OpcionesSelect = {
            insumos: insumosActivos
            // si no se quiere filtrar solo los insumos activos:
            /* insumos: insumosData */
        };

        setOpcionesSelect(opciones);
    }, [insumosData]);

    useEffect(() => {
        if (id) {
            fetchPlantineraData(id);
        }
    }, [id]);

    // endregion

    if (cargandoData || !plantineraData) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            <Flex
                direction="column"
                minH="100vh"
                align="center"
                pt={{ sm: '125px', lg: '75px' }}
                position="relative"
            >
                <Box
                    h="45vh"
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    position="absolute"
                    w="100%"
                    borderRadius="20px"
                />

                <FormProvider {...methods}>
                    <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Card p="30px" mt={'60px'}>
                                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                    Monitoreo de los plantines
                                </Text>
                                {errorMessage && (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertTitle>{errorMessage}</AlertTitle>
                                    </Alert>
                                )}
                                <Flex direction="column" w="100%">
                                    <Stack direction="column" spacing="20px">
                                        <SimpleGrid gap="20px">
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaDatePickerField
                                                    id={'fecha_inicio'}
                                                    label={'Fecha de inicio'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'hora_inicio'}
                                                    label={'Hora de inicio'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            </SimpleGrid>
                                            <Text
                                                color={textColor}
                                                fontSize="sm"
                                                marginLeft={3}
                                                fontWeight="700"
                                            >
                                                Actividad
                                            </Text>
                                            <Flex
                                                flexWrap="wrap"
                                                height={20}
                                                mb={5}
                                                justify="space-between"
                                            >
                                                {opcionesActividad.map((opcion, index) => (
                                                    <FormControl
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                        width="fit-content"
                                                        key={index}
                                                    >
                                                        <Checkbox
                                                            size="lg"
                                                            colorScheme="green"
                                                            {...methods.register(opcion)}
                                                            id={opcion}
                                                        />
                                                        <FormLabel
                                                            mb="0"
                                                            fontSize="md"
                                                            color={textColor}
                                                            ms="10px"
                                                            fontWeight="medium"
                                                        >
                                                            {opcion}
                                                        </FormLabel>
                                                    </FormControl>
                                                ))}
                                            </Flex>
                                            <CannavaSelectField
                                                id={'insumo_id'}
                                                label={'En el caso de uso indicar el insumo'}
                                                opciones={opcionesSelect.insumos}
                                                placeholder={'Seleccioná un insumo'}
                                                /* validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }} */
                                            />
                                            <CannavaTextAreaField
                                                id={'observaciones_generales'}
                                                label={'Observaciones'}
                                                placeholder={
                                                    '¿Tenés alguna observación para agregar?'
                                                }
                                                validationRules={{
                                                    required: 'Campo obligatorio'
                                                }}
                                                mb="10px"
                                            />
                                        </SimpleGrid>
                                    </Stack>
                                    <Flex justify="space-between" mt="24px">
                                        <Button
                                            variant="outline"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            //navegar a lote
                                            onClick={onCancel}
                                            type="button"
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="darkBrand"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            isLoading={methods.formState.isSubmitting}
                                            type="submit"
                                        >
                                            Continuar
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Card>
                        </form>
                    </Box>
                </FormProvider>
            </Flex>
        </>
    );
}
