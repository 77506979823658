// chakra imports
import { Avatar, Box, Flex, Icon, Stack, Text, useColorModeValue } from '@chakra-ui/react';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from '../../../contexts/AppContext';

// FUNCTIONS

function SidebarContent(props: { routes: RoutesType[]; hovered?: boolean; mini?: boolean }) {
    const { routes, mini, hovered } = props;

    const textColor = useColorModeValue('navy.700', 'white');
    const { user, isAuthenticated, logout } = useAuth0();
    const { backendUserData, menuRoutes } = useAppContext();

    // SIDEBAR
    return (
        <Flex direction="column" height="100%" pt="25px" borderRadius="30px">
            <Brand mini={mini} hovered={hovered} />
            <Stack direction="column" mb="auto" mt="8px">
                <Box
                    ps={
                        mini === false
                            ? '20px'
                            : mini === true && hovered === true
                            ? '20px'
                            : '16px'
                    }
                    pe={{ md: '16px', '2xl': '1px' }}
                    ms={mini && hovered === false ? '-16px' : 'unset'}
                >
                    {menuRoutes && <Links mini={mini} hovered={hovered} routes={menuRoutes} />}
                </Box>
            </Stack>

            {isAuthenticated && (
                <Flex mt="75px" mb="56px" justifyContent="center" alignItems="center">
                    <Avatar
                        h="48px"
                        w="48px"
                        src={user.picture}
                        me={
                            mini === false
                                ? '20px'
                                : mini === true && hovered === true
                                ? '20px'
                                : '0px'
                        }
                    />
                    <Box
                        display={
                            mini === false
                                ? 'block'
                                : mini === true && hovered === true
                                ? 'block'
                                : 'none'
                        }
                    >
                        <Text color={textColor} fontSize="md" fontWeight="700">
                            {user.name}
                        </Text>
                        <Text color="secondaryGray.600" fontSize="sm" fontWeight="400">
                            {backendUserData !== null && backendUserData.roles}
                        </Text>
                    </Box>
                </Flex>
            )}
        </Flex>
    );
}

export default SidebarContent;
