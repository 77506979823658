// src/utils/CannavaApiService.ts
import axios, { AxiosResponse, AxiosProgressEvent } from 'axios';
import { API_BASE_URL } from '../config';
import { ElementoBackend } from 'types/ElementosBackend';
import { configElementosBackend } from 'variables/elementosBackend';

const USE_XDEBUG = true; // Cambia a false si no quieres usar xdebug

interface Callback {
    (data: any): void;
}

interface CallbackError {
    (data: any, status?: any): void;
}

class CannavaApiService {
    // region Metodos generales

    /**
     * Metodo generico para hacer llamadas a la api
     *
     * @param method
     * @param endpoint
     * @param data
     * @param token
     * @param onSuccess
     * @param onError
     */
    static async request(
        method: 'GET' | 'POST' | 'PUT' | 'DELETE',
        endpoint: string,
        data: object = {},
        token?: string,
        onSuccess?: Callback,
        onError?: CallbackError,
        onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
    ): Promise<void> {
        // check if endpoint has ?
        let char = endpoint.includes('?') ? '&' : '?';
        const url = `${API_BASE_URL}${endpoint}${
            USE_XDEBUG ? char + 'XDEBUG_SESSION_START=1' : ''
        }`;
        // console.log("LLAMANDO A: "+ url);

        let requestData: string | FormData | null = null;
        let headers: { [key: string]: string } = {
            'Content-Type': 'application/json'
        };

        if (data !== null && Object.keys(data).length > 0) {
            if (data instanceof File) {
                requestData = new FormData();
                requestData.append('file', data);
                headers['Content-Type'] = 'multipart/form-data';
            } else {
                requestData = JSON.stringify(data);
            }
            // requestData = JSON.stringify(data);
        }

        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
            // headers['Origin'] = "http://localhost:3000";
        }

        try {
            // console.log("llamo a la api: " + url);
            const response: AxiosResponse = await axios({
                method: method,
                url: url,
                data: requestData,
                headers: headers,
                withCredentials: true,
                onUploadProgress: onUploadProgress
            });

            if (response.status >= 200 && response.status < 300) {
                if (onSuccess) {
                    onSuccess(response.data);
                }
            } else {
                alert('hubo un error');
                if (onError) {
                    onError(response.data, response.status);
                }
            }
        } catch (error: any) {
            // console.log("error!");
            console.log(error);

            if (onError) {
                onError(error, error.response.status);
            } else {
                if (error.response.status === 401) {
                    alert('No estás logueado');
                }

                if (error.response.status === 403) {
                    alert('No tienes permisos para hacer esa llamada');
                }
            }
        }
    }

    /**
     * Metodo generico para obtener todos los elementos de un tipo
     * @param elemento
     * @param id
     * @param token
     * @param onSuccess
     * @param onError
     */
    static async obtener(
        elemento: ElementoBackend,
        id: number | string,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        // console.log("por crear ruta para", elemento);
        console.log('elemento', elemento);
        let endpoint = configElementosBackend[elemento].urlBase;
        let expand = configElementosBackend[elemento].expand;
        let endpointUrl = `${endpoint}/${id}`;

        if (expand) {
            endpointUrl += `?expand=${expand}`;
        }

        return this.request('GET', endpointUrl, {}, token, onSuccess, onError);
    }

    /**
     * Metodo generico para crear un elemento de un tipo
     * @param elemento
     * @param data
     * @param token
     * @param onSuccess
     * @param onError
     */
    static async crear(
        elemento: ElementoBackend,
        data: object,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        // console.log("por crear ruta para", elemento);
        let endpoint = configElementosBackend[elemento].urlBase;
        return this.request('POST', endpoint, data, token, onSuccess, onError);
    }

    /**
     * Metodo generico para actualizar un elemento de un tipo
     * @param elemento
     * @param id
     * @param data
     * @param token
     * @param onSuccess
     * @param onError
     */
    static async actualizar(
        elemento: ElementoBackend,
        id: number | string,
        data: object,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        // console.log("por crear ruta para", elemento);
        let endpoint = configElementosBackend[elemento].urlBase;
        return this.request('PUT', `${endpoint}/${id}`, data, token, onSuccess, onError);
    }

    /**
     * Metodo generico para eliminar un elemento de un tipo
     * @param elemento
     * @param id
     * @param token
     * @param onSuccess
     * @param onError
     */
    static async eliminar(
        elemento: ElementoBackend,
        id: number | string,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        let endpoint = configElementosBackend[elemento].urlBase;
        return this.request('DELETE', `${endpoint}/${id}`, {}, token, onSuccess, onError);
    }

    // endregion

    static async archivoSubir(
        archivo: File,
        token: string,
        onSuccess?: Callback,
        onError?: CallbackError,
        onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
    ) {
        return this.request(
            'POST',
            '/uploader/api/archivos/subir',
            archivo,
            token,
            onSuccess,
            onError,
            onUploadProgress
        );
    }

    // region Usuarios

    /**
     * Metodo para crear un elemento de tipo usuario
     * @param data
     * @param token
     * @param onSuccess
     * @param onError
     */
    static async usuarioCrear(
        data: object,
        token: string,
        onSuccess?: Callback,
        onError?: CallbackError
    ) {
        return this.request('POST', '/auth/api/usuarios', data, token, onSuccess, onError);
    }

    static async usuarioActualizar(
        id: number,
        data: object,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request('PUT', `/auth/api/usuarios/${id}`, data, token, onSuccess, onError);
    }

    static async usuarioEliminar(
        id: number,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request('DELETE', `/auth/api/usuarios/${id}`, {}, token, onSuccess, onError);
    }

    static async usuarioObtenerTodos(token: string, onSuccess?: Callback, onError?: Callback) {
        return this.request('GET', '/auth/api/usuarios', {}, token, onSuccess, onError);
    }

    static async areaObtenerTodas(token: string, onSuccess?: Callback, onError?: Callback) {
        return this.request('GET', '/auth/api/areas', {}, token, onSuccess, onError);
    }

    static async usuarioObtener(
        id: number,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request('GET', `/auth/api/usuarios/${id}`, {}, token, onSuccess, onError);
    }

    // endregion Usuarios

    // region LoteSemillas

    static async lotesSemillasObtenerTodos(
        token: string,
        onSuccess?: Callback,
        onError?: Callback,
        filterParams: [string, string] | null = null
    ) {
        let urlEndpoint = '/trazabilidad/api/lote-semillas';
        urlEndpoint += '?expand=planificaciones.campana';
        if (filterParams !== null) {
            const [filterKey, filterValue] = filterParams;
            urlEndpoint += `&filter[${filterKey}]=${filterValue}`;
        }

        return this.request('GET', urlEndpoint, {}, token, onSuccess, onError);
    }

    static async lotesSemillasCrear(
        data: object,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'POST',
            '/trazabilidad/api/lote-semillas/create',
            data,
            token,
            onSuccess,
            onError
        );
    }

    static async lotesSemillasObtener(
        id: string,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'GET',
            `/trazabilidad/api/lote-semillas/${id}?expand=lastInventarioSortedByID`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    static async lotesSemillasActualizar(
        id: string,
        data: object,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'PUT',
            `/trazabilidad/api/lote-semillas/update/?id=${id}`,
            data,
            token,
            onSuccess,
            onError
        );
    }

    static async lotesSemillasEliminar(
        id: string,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'DELETE',
            `/trazabilidad/api/lote-semillas/delete/?id=${id}`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    // endregion LoteSemillas

    // region LotePoderGerminativo

    static async lotesPoderGerminativoObtenerTodos(
        token: string,
        onSuccess?: Callback,
        onError?: Callback,
        filterParams: [string, string] | null = null
    ) {
        let urlEndpoint = '/trazabilidad/api/lote-poder-germinativo';

        urlEndpoint += '?expand=loteSemillas';

        if (filterParams !== null) {
            const [filterKey, filterValue] = filterParams;
            urlEndpoint += `&filter[${filterKey}]=${filterValue}`;
        }

        return this.request('GET', urlEndpoint, {}, token, onSuccess, onError);
    }

    // endregion LotePoderGerminativo

    // region Proveedores

    static async proveedoresObtenerTodos(token: string, onSuccess?: Callback, onError?: Callback) {
        return this.request('GET', '/genetica/api/proveedor', {}, token, onSuccess, onError);
    }

    static async proveedoresObtener(
        id: number,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'GET',
            `/genetica/api/proveedor/${id}?expand=geneticas`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    static async proveedoresCrear(
        data: object,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'POST',
            '/genetica/api/proveedor/create',
            data,
            token,
            onSuccess,
            onError
        );
    }

    // endregion Proveedores

    // region Ubicaciones

    static async ubicacionesObtenerTodos(token: string, onSuccess?: Callback, onError?: Callback) {
        return this.request(
            'GET',
            '/locacion/api/locacion?expand=establecimiento',
            {},
            token,
            onSuccess,
            onError
        );
    }

    static async ubicacionesObtener(
        id: number,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'GET',
            `/locacion/api/locacion/${id}?expand=establecimiento`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    static async ubicacionesCrear(
        data: object,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'POST',
            '/locacion/api/locacion/create',
            data,
            token,
            onSuccess,
            onError
        );
    }

    // endregion Ubicaciones

    // region Geneticas

    static async variedadesObtenerTodos(token: string, onSuccess?: Callback, onError?: Callback) {
        return this.request('GET', '/genetica/api/genetica', {}, token, onSuccess, onError);
    }

    static async variedadesObtener(
        id: number,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request('GET', `/genetica/api/genetica/${id}`, {}, token, onSuccess, onError);
    }

    static async variedadesCrear(
        data: object,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request('POST', '/genetica/api/genetica', data, token, onSuccess, onError);
    }

    // endregion Geneticas

    // region Campanas

    static async campanasObtenerTodos(token: string, onSuccess?: Callback, onError?: Callback) {
        return this.request('GET', '/planificacion/api/campanas', {}, token, onSuccess, onError);
    }

    static async campanasObtener(
        id: string,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'GET',
            `/planificacion/api/campanas/${id}?expand=planificaciones.lotesSemillas`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    // endregion Campanas

    static async etapasObtenerTodos(token: string, onSuccess?: Callback, onError?: Callback) {
        return this.request('GET', '/planificacion/api/etapas', {}, token, onSuccess, onError);
    }

    static async productosObtenerTodos(token: string, onSuccess?: Callback, onError?: Callback) {
        return this.request('GET', '/catalogo/api/productos', {}, token, onSuccess, onError);
    }

    static async objetivosCampanaCrear(
        data: object,
        token: string,
        onSuccess?: Callback,
        onError?: CallbackError
    ) {
        return this.request(
            'POST',
            '/planificacion/api/objetivos-campana',
            data,
            token,
            onSuccess,
            onError
        );
    }

    static async objetivosCampanaEliminar(
        id: number,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'DELETE',
            `/planificacion/api/objetivos-campana/${id}`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    static async planificacionesCampanaCrear(
        data: object,
        token: string,
        onSuccess?: Callback,
        onError?: CallbackError
    ) {
        return this.request(
            'POST',
            '/planificacion/api/planificaciones',
            data,
            token,
            onSuccess,
            onError
        );
    }

    static async planificacionesCampanaActivar(
        id: number,
        token: string,
        onSuccess?: Callback,
        onError?: CallbackError
    ) {
        return this.request(
            'GET',
            `/planificacion/api/planificaciones/activar/${id}`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    static async planificacionesCampanaEliminar(
        id: number,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'DELETE',
            `/planificacion/api/planificaciones/${id}`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    // region Establecimientos

    static async establecimientosObtenerTodos(
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request('GET', '/locacion/api/establecimiento', {}, token, onSuccess, onError);
    }

    static async establecimientosObtener(
        id: number,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'GET',
            `/locacion/api/establecimiento/${id}`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    // endregion Establecimientos

    // region Inventario

    static async inventariosObtenerTodos(
        token: string,
        onSuccess?: Callback,
        onError?: Callback,
        filterParams: [string, string] | null = null
    ) {
        let urlEndpoint = '/trazabilidad/api/inventario';
        if (filterParams !== null) {
            const [filterKey, filterValue] = filterParams;
            urlEndpoint += `?filter[${filterKey}]=${filterValue}`;
        }

        return this.request('GET', urlEndpoint, {}, token, onSuccess, onError);
    }

    static async inventariosObtener(
        id: number,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'GET',
            `/trazabilidad/api/lote-semillas/inventario/${id}`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    // endregion Inventario

    static async anotacionesObtenerTodos(
        token: string,
        onSuccess?: Callback,
        onError?: Callback,
        filterParams: [[string, string], [string, string]] | null = null
    ) {
        let urlEndpoint = '/anotacion/api/anotacion';
        if (filterParams[0] !== null) {
            const [filterKeyModelo, filterValueModelo] = filterParams[0];
            urlEndpoint += `?filter[${filterKeyModelo}]=${filterValueModelo}`;
        }

        if (filterParams[1] !== null) {
            const [filterKeyModelo_id, filterValueModelo_id] = filterParams[1];
            urlEndpoint += `&filter[${filterKeyModelo_id}]=${filterValueModelo_id}`;
        }

        return this.request('GET', urlEndpoint, {}, token, onSuccess, onError);
    }

    static async anotacionesCrear(
        data: object,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'POST',
            '/anotacion/api/anotacion/create',
            data,
            token,
            onSuccess,
            onError
        );
    }

    static async cannabinoidesObtenerTodos(
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request('GET', '/genetica/api/cannabinoide', {}, token, onSuccess, onError);
    }

    static async insumosObtenerTodos(token: string, onSuccess?: Callback, onError?: Callback) {
        return this.request('GET', '/catalogo/api/insumo', {}, token, onSuccess, onError);
    }

    // region Lotes Germinacion

    static async lotesGerminacionObtenerTodos(
        token: string,
        onSuccess?: Callback,
        onError?: Callback,
        filterParams: [string, string] | null = null
    ) {
        let urlEndpoint = '/trazabilidad/api/lote-germinacion';

        if (filterParams !== null) {
            const [filterKey, filterValue] = filterParams;
            urlEndpoint += `?filter[${filterKey}]=${filterValue}`;
        }

        return this.request('GET', urlEndpoint, {}, token, onSuccess, onError);
    }

    static async lotesGerminacionCrear(
        data: object,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'POST',
            '/trazabilidad/api/lote-germinacion/create',
            data,
            token,
            onSuccess,
            onError
        );
    }

    static async lotesGerminacionObtener(
        id: string,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        const expand =
            'loteSemillas,reporteControlSiembra,reporteControlGerminacion,ordenSiembra,plantinera';
        return this.request(
            'GET',
            `/trazabilidad/api/lote-germinacion/${id}?expand=${expand}`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    // endregion Lotes Germinacion

    static async notificacionesObtenerTodas(
        token: string,
        onSuccess?: Callback,
        onError?: Callback,
        filterParams: [string, string] | null = null
    ) {
        let urlEndpoint = '/notificaciones';

        if (filterParams !== null) {
            const [filterKey, filterValue] = filterParams;
            urlEndpoint += `?filter[${filterKey}]=${filterValue}`;
        }

        return this.request('GET', urlEndpoint, {}, token, onSuccess, onError);
    }

    static async reporteSiembraObtener(
        id: string,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'GET',
            `/trazabilidad/api/reporte-control-siembra/${id}?expand=lote,lote.genetica`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    static async reportePlantineraObtener(
        id: string,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'GET',
            `/trazabilidad/api/plantinera/${id}`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    static async reportePlantineraPorGerminacionIDObtener(
        id: string,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'GET',
            `/trazabilidad/api/lote-germinacion/plantinera/${id}`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    static async reporteGerminacionObtener(
        id: string,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'GET',
            `/trazabilidad/api/reporte-control-germinacion/${id}?expand=lote,lote.genetica`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    static async reportesAmbientalesPorLoteObtener(
        id: string,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'GET',
            `/trazabilidad/api/reporte-control-ambiental?filter[lote]=${id}`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    // region Lotes Planta Productiva
    static async lotesPlantaProductivaCrear(
        data: object,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'POST',
            '/trazabilidad/api/lote-germinacion/create',
            data,
            token,
            onSuccess,
            onError
        );
    }

    static async lotesPlantaProductivaObtenerTodos(
        token: string,
        onSuccess?: Callback,
        onError?: Callback,
        filterParams: [string, string] | null = null
    ) {
        let urlEndpoint = '/trazabilidad/api/lote-productivo';

        if (filterParams !== null) {
            const [filterKey, filterValue] = filterParams;
            urlEndpoint += `?filter[${filterKey}]=${filterValue}`;
        }

        return this.request('GET', urlEndpoint, {}, token, onSuccess, onError);
    }

    static async lotesPlantaProductivaObtener(
        id: string,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'GET',
            `/trazabilidad/api/lote-productivo/${id}`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    static async trasplantesPlantaProductivaObtenerTodos(
        token: string,
        onSuccess?: Callback,
        onError?: Callback,
        filterParams: [string, string] | null = null
    ) {
        let urlEndpoint = '/trazabilidad/api/trasplante-lote-productivo';

        if (filterParams !== null) {
            const [filterKey, filterValue] = filterParams;
            urlEndpoint += `?filter[${filterKey}]=${filterValue}`;
        }

        return this.request('GET', urlEndpoint, {}, token, onSuccess, onError);
    }

    static async trasplantePlantaProductivaObtener(
        id: string,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'GET',
            `/trazabilidad/api/trasplante-lote-productivo/${id}`,
            {},
            token,
            onSuccess,
            onError
        );
    }

    static async ordenAplicacionCrear(
        data: object,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'POST',
            '/trazabilidad/api/orden-aplicacion-trasplante-l-p/create',
            data,
            token,
            onSuccess,
            onError
        );
    }

    static async riegoPlantaProductivaObtener(
        id: string,
        token: string,
        onSuccess?: Callback,
        onError?: Callback
    ) {
        return this.request(
            'GET',
            `/trazabilidad/api/lote-productivo/obtener-riego-pre-trasplante/${id}`,
            {},
            token,
            onSuccess,
            onError
        );
    }
}

export default CannavaApiService;
