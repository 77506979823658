import { Flex, SimpleGrid, Text, useColorModeValue, Divider } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { FilaInfoRegistro } from './FilaInfoRegistro';
import { ControlSiembra } from 'modules/trazabilidad/types/ControlSiembra';

type InfoControlProps = {
    controlDataSemillado: ControlSiembra;
    label: string;
};

export default function InfoControlSemillado(props: InfoControlProps) {
    const { controlDataSemillado, label } = props;
    console.log('data semillado:');
    console.log(controlDataSemillado);
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const fecha_inicio_semillado =
        controlDataSemillado.fecha_inicio_semillado == null
            ? 'Aún no realizado'
            : controlDataSemillado.fecha_inicio_semillado.split(' ')[0];
    const hora_inicio_semillado =
        controlDataSemillado.hora_inicio_semillado == null
            ? 'Aún no realizado'
            : controlDataSemillado.hora_inicio_semillado;
    const hora_finalizacion_semillado =
        controlDataSemillado.hora_finalizacion_semillado == null
            ? 'Aún no realizado'
            : controlDataSemillado.hora_finalizacion_semillado;

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center" mb="5">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    {label}
                </Text>
            </Flex>
            <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing={{ base: '20px', xl: '5px' }}>
                <FilaInfoRegistro label={'Fecha inicial'} value={fecha_inicio_semillado} />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro label={'Hora inicial'} value={hora_inicio_semillado} />
                {/*<Divider border="1px" borderColor="lightgray" />*/}
                {/*<FilaInfoRegistro*/}
                {/*    label={'Fecha final'}*/}
                {/*    value={''/* controlDataSemillado.fecha_final *!/*/}
                {/*/>*/}
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro label={'Hora final'} value={hora_finalizacion_semillado} />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de operarios al inicio'}
                    value={controlDataSemillado.numero_operarios_inicio_semillado}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de operarios a la finalización'}
                    value={controlDataSemillado.numero_operarios_finalizacion_semillado}
                />

                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de bandejas armadas a la finalización'}
                    value={controlDataSemillado.numero_bandejas_armadas_finalizacion_semillado}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de semillas usadas a la finalización'}
                    value={controlDataSemillado.numero_semillas_usadas_finalizacion_semillado}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'¿Se verificó el orden y limpieza del área?'}
                    value={
                        controlDataSemillado.verificacion_orden_y_limpieza_area_finalizacion_semillado
                            ? 'SI'
                            : 'NO'
                    }
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={
                        '¿Se realizó inspección visual para verificar que todas las bandejas fueron sembradas correctamente?'
                    }
                    value={
                        controlDataSemillado.inspeccion_visual_correcto_sembrado_finalizacion_semillado
                            ? 'SI'
                            : 'NO'
                    }
                />
            </SimpleGrid>
        </Card>
    );
}
