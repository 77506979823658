import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import UsuarioInvalidoCentered from 'views/auth/usuarioInvalido/UsuarioInvalidoCentered';
import {
    ChakraProvider
    // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
import SinPermisosCentered from './views/auth/sinPermisos/SinPermisosCentered';
import CallbackAuth from './modules/auth/views/callbackAuth';
import Welcome from './views/welcome';
import AuthenticationGuard from './components/auth/AuthenticationGuard';
import DashboardsDefault from './views/admin/dashboards/default';
import { LayoutProvider } from './contexts/LayoutContext';
// Chakra imports

export default function Main() {
    // eslint-disable-next-line
    const [currentTheme, setCurrentTheme] = useState(initialTheme);
    return (
        <ChakraProvider theme={currentTheme}>
            <LayoutProvider>
                <Routes>
                    <Route path="auth/*" element={<AuthLayout />} />
                    <Route path="usuario-invalido" element={<UsuarioInvalidoCentered />} />
                    <Route path="no-autorizado" element={<SinPermisosCentered />} />
                    <Route path="callback" element={<CallbackAuth />} />
                    <Route
                        path="admin/*"
                        element={<AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />}
                    />
                    <Route
                        path="rtl/*"
                        element={<RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />}
                    />
                    <Route
                        path="/*"
                        element={<AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />}
                    />
                    <Route path="/" element={<AuthenticationGuard component={Welcome} />} />
                </Routes>
            </LayoutProvider>
        </ChakraProvider>
    );
}
