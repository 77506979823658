/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    useColorModeValue,
    SimpleGrid,
    Select,
    Grid,
    Heading,
    UnorderedList,
    ListItem
} from '@chakra-ui/react';

// Custom components
import Banner from './components/Banner';
import NFT from '../../../../../components/card/NFT';
import { SearchBar } from '../../../../../views/admin/nfts/collection/components/Search';

// Assets
import Nft2 from '../../../../../assets/img/nfts/Nft2.png';
import Nft4 from '../../../../../assets/img/nfts/Nft4.png';
import Nft5 from '../../../../../assets/img/nfts/Nft5.png';
import Nft6 from '../../../../../assets/img/nfts/Nft6.png';
import NftProfile from '../../../../../assets/img/nfts/NftProfile.png';
import NftBanner2 from '../../../../../assets/img/nfts/NftBanner2.png';
import Avatar1 from '../../../../../assets/img/avatars/avatar1.png';
import Avatar2 from '../../../../../assets/img/avatars/avatar2.png';
import Avatar3 from '../../../../../assets/img/avatars/avatar3.png';
import Avatar4 from '../../../../../assets/img/avatars/avatar4.png';

import { MdDashboard, MdApps } from 'react-icons/md';
import PageLoader from '../../../../../components/actions/PageLoader';
import React, { useEffect, useMemo, useState } from 'react';
import CannavaApiService from '../../../../../utils/CannavaApiService';
import { useAppContext } from '../../../../../contexts/AppContext';
import { Variedad } from '../../../types/Variedad';
import { useNavigate, useParams } from 'react-router-dom';
import { LoteSemillas } from '../../../../trazabilidad/types/LoteSemilllas';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { createColumnHelper } from '@tanstack/react-table';
import Listado, {
    crearCelda,
    crearCeldaConLink,
    crearHeader
} from '../../../../../components/cannava/listado';
import Card from '../../../../../components/card/Card';
import { useConfigurarNavegacion } from '../../../../../hooks/useConfigurarNavegacion';

export default function GeneticaVer() {
    const { authToken, setFlashMessage, setFlashType } = useAppContext();
    const { id } = useParams();
    const [cargandoData, setCargandoData] = useState(true);
    const [data, setData] = useState<Variedad>(null);
    const navigate = useNavigate();
    const obtenerData = (mostrarLoading = true) => {
        const fetchData = async (id: string) => {
            if (mostrarLoading) {
                setCargandoData(true);
            }

            CannavaApiService.variedadesObtener(
                parseInt(id),
                authToken,
                data => {
                    console.log('data variedad');
                    console.log(data);
                    setData(data);
                    if (mostrarLoading) {
                        setCargandoData(false);
                    }
                },
                error => {
                    //throw new Error(`HTTP error! Status: ${error}`);
                    setFlashMessage('Hubo un error al obtener los datos del usuario.');
                    setFlashType('error');
                    if (mostrarLoading) {
                        setCargandoData(false);
                    }
                    navigate('/admin/variedades');
                }
            );
        };

        fetchData(id);
    };

    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: () => {
                let _breadcrumbs = [];
                if (!data) {
                    return [];
                } else {
                    _breadcrumbs = [
                        // Version TEST - Configurar rutas correctamente!
                        { label: 'Inicio', href: '/admin/home/' },
                        { label: 'Genéticas', href: '/admin/variedades/' }
                    ];
                }

                return _breadcrumbs;
            },
            titulo: () => {
                if (!data) {
                    return '';
                }

                return data.nombre;
            }
        },
        data
    );

    useEffect(() => {
        if (id && authToken) {
            obtenerData();
        }
    }, [id, authToken]);

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const buttonBg = useColorModeValue('transparent', 'navy.800');
    const hoverButton = useColorModeValue({ bg: 'gray.100' }, { bg: 'whiteAlpha.100' });
    const activeButton = useColorModeValue({ bg: 'gray.200' }, { bg: 'whiteAlpha.200' });
    let borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');

    if (cargandoData) {
        return <PageLoader message={'Cargando variedad'} />;
    }

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            {/* Main Fields */}
            <Box mb="20px" display={{ base: 'block', lg: 'grid' }}>
                <Flex flexDirection="column">
                    <Banner
                        image={NftBanner2}
                        profile={NftProfile}
                        creator={data.proveedor.nombre}
                        name={data.nombre}
                        desc="La genética de una cepa de cannabis Sativa se caracteriza por su enfoque en potenciar la energía y la creatividad del usuario. Estas plantas suelen ser más altas, con hojas más delgadas, y se adaptan bien a climas cálidos. Su perfil de terpenos a menudo presenta notas cítricas o herbales. Los efectos son generalmente más cerebrales que físicos, ideal para actividades sociales y creativas."
                        floor={data.cannabinoideDominante.slug}
                        volume={data.cantidad_semillas_por_100_gramos}
                        owners={data.densidad_siembra_planta_m2}
                        items={data.cantidadLotes}
                    />
                </Flex>
            </Box>

            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: '75% 25%' }} gap={4} mb={'30px'}>
                <ListadoLotes geneticaId={id} />
                <Card>
                    <Heading fontSize={'2xl'}>Estimaciones</Heading>
                    {data.estimaciones &&
                        data.estimaciones.map((estimacion, index) => (
                            <Box my={'5px'} p={'20px'} key={index}>
                                <Heading fontSize={'md'} pl={'0'}>
                                    {estimacion.tipoLocacion.nombre}
                                </Heading>
                                <UnorderedList ml={'20px'}>
                                    <ListItem>
                                        Tiempo vegetativo: {estimacion.tiempo_vegetativo_promedio}
                                    </ListItem>
                                    <ListItem>
                                        Tiempo floración: {estimacion.tiempo_floracion_promedio}
                                    </ListItem>
                                    <ListItem>
                                        <Text title={'En kgs cada 100 semillas'} display={'inline'}>
                                            Rendimiento promedio
                                        </Text>
                                        : {estimacion.rendimiento_promedio_por_100_semillas_en_KG}
                                    </ListItem>
                                </UnorderedList>
                            </Box>
                        ))}
                </Card>
            </Grid>

            {/* Search Bar 
            <Flex w='100%'>
                <SearchBar/>
                <Select
                    fontSize='sm'
                    id='edit_product'
                    variant='main'
                    h='44px'
                    maxH='44px'
                    me={{base: '10px', md: '20px'}}>
                    <option value='single'>Single Items</option>
                    <option value='multiple'>Multiple Items</option>
                </Select>
                <Select fontSize='sm' variant='main' h='44px' maxH='44px'
                        me={{base: '10px', md: '20px'}}>
                    <option value='low_to_high'>Low to high</option>
                    <option value='high_to_low'>High to low</option>
                </Select>
                <Button
                    me={{base: '10px', md: '20px'}}
                    bg={buttonBg}
                    border='1px solid'
                    color='secondaryGray.600'
                    borderColor={borderColor}
                    borderRadius='16px'
                    _placeholder={{color: 'secondaryGray.600'}}
                    _hover={hoverButton}
                    _active={activeButton}
                    _focus={activeButton}>
                    <Icon color={textColor} as={MdDashboard}/>
                </Button>
                <Button
                    bg={buttonBg}
                    border='1px solid'
                    color='secondaryGray.600'
                    borderColor={borderColor}
                    borderRadius='16px'
                    _placeholder={{color: 'secondaryGray.600'}}
                    _hover={hoverButton}
                    _active={activeButton}
                    _focus={activeButton}>
                    <Icon color={textColor} as={MdApps}/>
                </Button>
            </Flex>
            <Text mt='25px' mb='36px' color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
                More from this Collection
            </Text>
            <SimpleGrid columns={{base: 1, md: 2, xl: 4}} gap='20px'>
                <NFT
                    name='Swipe Circles'
                    author='By Peter Will'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft4}
                    currentbid='0.91 ETH'
                    download='#'
                />
                <NFT
                    name='Colorful Heaven'
                    author='By Mark Benjamin'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft5}
                    currentbid='0.91 ETH'
                    download='#'
                />
                <NFT
                    name='3D Cubes Art'
                    author='By Manny Gates'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft6}
                    currentbid='0.91 ETH'
                    download='#'
                />
                <NFT
                    name='ETH AI Brain'
                    author='By Nick Wilson'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft2}
                    currentbid='0.91 ETH'
                    download='#'
                />
                <NFT
                    name='Swipe Circles'
                    author='By Peter Will'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft4}
                    currentbid='0.91 ETH'
                    download='#'
                />
                <NFT
                    name='Colorful Heaven'
                    author='By Mark Benjamin'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft5}
                    currentbid='0.91 ETH'
                    download='#'
                />
                <NFT
                    name='3D Cubes Art'
                    author='By Manny Gates'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft6}
                    currentbid='0.91 ETH'
                    download='#'
                />
                <NFT
                    name='ETH AI Brain'
                    author='By Nick Wilson'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft2}
                    currentbid='0.91 ETH'
                    download='#'
                />
            </SimpleGrid>
               */}
            {/* Delete Product */}
        </Box>
    );
}

type ListadoLotesProps = {
    geneticaId: string;
};

function ListadoLotes(props: ListadoLotesProps) {
    const { authToken } = useAppContext();
    const navigate = useNavigate();
    const { geneticaId } = props;
    const transformLote = (data: any[]): LoteSemillas[] => {
        return data.map(lote => ({
            id: lote.id,
            codigo_lote: lote.codigo_lote,
            peso: lote.peso,
            unidades: lote.unidades,
            fecha_recepcion: lote.fecha_recepcion,
            cantidad_bajas: lote.cantidad_bajas,
            peso_promedio: lote.peso_promedio,
            genetica_id: lote.genetica_id,
            establecimiento_id: lote.establecimiento_id,
            stock: lote.stock,
            semillasDisponibles: lote.semillasDisponibles,
            actions: 'Actions'
        }));
    };

    const { data: lotesData, isLoading } = useFetchData<LoteSemillas>(
        'lotesSemillas',
        transformLote,
        [['genetica', geneticaId]]
    );
    const columnHelper = createColumnHelper<LoteSemillas>();
    const columns = [
        columnHelper.accessor('codigo_lote', {
            id: 'codigo_lote',
            header: crearHeader('Lote'),
            cell: crearCelda()
        }),
        columnHelper.accessor('establecimiento_id', {
            id: 'locacion_id',
            header: crearHeader('Locacion'),
            cell: crearCelda()
        }),
        columnHelper.accessor('peso', {
            id: 'date',
            header: crearHeader('Cantidad'),
            cell: crearCelda()
        }),
        columnHelper.accessor('semillasDisponibles', {
            id: 'type',
            header: crearHeader('Semillas disponibles'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'editar',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/lotes-semillas/editar', 'Editar', navigate)
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/lotes-semillas/ver', 'Ver lote', navigate)
        })
    ];

    if (isLoading) {
        return <PageLoader message={'Cargando lotes'} />;
    }

    return (
        <Card px="0px">
            <Listado tableData={lotesData} menuItems={[]} columns={columns} />
        </Card>
    );
}
