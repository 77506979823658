import {
    Alert,
    AlertIcon,
    Box,
    Flex,
    Link,
    SimpleGrid,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import { useLayoutContext } from '../../contexts/LayoutContext';
import { useNavigate } from 'react-router-dom';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { Inventario } from '../../modules/trazabilidad/types/Inventario';
import { crearCelda, crearHeader } from '../cannava/listado';
import Card from '../card/Card';
import FechaItem from '../cannava/dataDisplay/FechaItem';
import React, { useState, useEffect } from 'react';
import { LoteSemillas } from '../../modules/trazabilidad/types/LoteSemilllas';
import { useAutenticacionUsuario } from '../../modules/auth/hooks/useAutenticacionUsuario';

type CronogramaParams = {
    loteData: LoteSemillas;
    [x: string]: any;
};

export default function Cronograma(props: CronogramaParams) {
    const { loteData } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const [planificacionesVisibles, setPlanificacionesVisibles] = useState<string[]>([]);

    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const navigate = useNavigate();

    const textColor = 'navy.700';

    const verCronogramaCompleto = () => {
        setPlanificacionesVisibles(new Array(planificacionesVisibles.length).fill('visible'));
    };

    const asignarPlanificacion = () => {
        navigate('/admin/lotes-semillas/asignar-planificacion/' + loteData.id);
    };

    const canviarVisibilidad = (index: number) => {
        setPlanificacionesVisibles(prevVisibles => {
            const nuevasVisibilidades = [...prevVisibles];
            nuevasVisibilidades[index] =
                nuevasVisibilidades[index] === 'visible' ? 'noVisible' : 'visible';
            return nuevasVisibilidades;
        });
    };

    const obtenerDia = (fecha: string) => {
        // Date.parse convierte la cadena de fecha en milisegundos desde la época Unix
        const fechaMilisegundos = Date.parse(fecha);

        // new Date convierte los milisegundos desde la época Unix en un objeto Date de JavaScript
        const fechaObjeto = new Date(fechaMilisegundos);
        return fechaObjeto.getDate();
    };

    const obtenerNombreMes = (fecha: string) => {
        // Date.parse convierte la cadena de fecha en milisegundos desde la época Unix
        const fechaMilisegundos = Date.parse(fecha);

        // new Date convierte los milisegundos desde la época Unix en un objeto Date de JavaScript
        const fechaObjeto = new Date(fechaMilisegundos);

        // getDay obtiene el número del día de la semana (0 para domingo, 1 para lunes, etc.)
        const numeroMes = fechaObjeto.getMonth();
        const meses = [
            'Ene',
            'Feb',
            'Mar',
            'Abr',
            'May',
            'Jun',
            'Jul',
            'Ago',
            'Sep',
            'Oct',
            'Nov',
            'Dic'
        ];
        return meses[numeroMes];
    };

    const columnHelper = createColumnHelper<Inventario>();
    const columns = [
        columnHelper.accessor('lote_semillas_id', {
            id: 'lote_semillas_id',
            header: crearHeader('ID Lote'),
            cell: crearCelda((info: CellContext<any, any>) => {
                return info.row.original.lote_semillas.lote_semilla_id;
            })
        }),
        columnHelper.accessor('cantidad', {
            id: 'cantidad',
            header: crearHeader('Movimiento'),
            cell: crearCelda()
        }),
        columnHelper.accessor('stock', {
            id: 'stock',
            header: crearHeader('Stock'),
            cell: crearCelda()
        }),
        columnHelper.accessor('fecha_creacion', {
            id: 'fecha_creacion',
            header: crearHeader('Fecha de movimiento'),
            // cell: crearCelda()
            cell: crearCelda((info: CellContext<any, any>) => {
                return info.row.original.fecha_creacion.split(' ')[0];
            })
        })
    ];

    const [permisosAcceso, setPermisosAcceso] = useState<Record<string, boolean>>({});
    const { obtenerPermisos } = useAutenticacionUsuario();

    useEffect(() => {
        const permisos = [
            {
                key: 'botonAsignarCampana',
                roles: ['usuario-basico', 'administrador'],
                areas: ['planificacion']
            }
        ];
        const nuevosPermisos = obtenerPermisos(permisos);
        setPermisosAcceso(nuevosPermisos);
    }, [obtenerPermisos]);

    useEffect(() => {
        const visibilidadPlanificaciones: string[] = [];

        loteData.planificaciones.map((planificacion, index) => {
            if (index === 0) {
                visibilidadPlanificaciones.push('visible');
            } else {
                visibilidadPlanificaciones.push('noVisible');
            }
        });

        setPlanificacionesVisibles(visibilidadPlanificaciones);
    }, []);

    return (
        <Box w="100%" bg="" mt={'20px'}>
            <Card p="30px">
                <Flex justify="space-between" align="center">
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Cronograma
                    </Text>
                    <Flex>
                        <Text
                            fontSize="sm"
                            color={'blue.400'}
                            onClick={verCronogramaCompleto}
                            cursor="pointer"
                        >
                            Ver cronograma completo
                        </Text>
                        {permisosAcceso.botonAsignarCampana && (
                            <>
                                <Text fontSize="sm" color={'blue.400'} mx={2}>
                                    |
                                </Text>
                                <Text
                                    fontSize="sm"
                                    color={'blue.400'}
                                    onClick={asignarPlanificacion}
                                    cursor="pointer"
                                >
                                    Asignar planificación
                                </Text>
                            </>
                        )}
                    </Flex>
                </Flex>
                <Text color={'gray.500'} fontSize="sm" mb={10}>
                    Aquí encontrarás la agenda de las siguientes etapas
                </Text>

                {loteData.planificaciones.length > 0 ? (
                    <Box width={'full'}>
                        {loteData.planificaciones.map((planificacion, index) => (
                            <Box key={index}>
                                <Flex width={'full'} justify={'space-between'}>
                                    <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                                        Planificación {index + 1}
                                    </Text>
                                    <Text
                                        fontSize="xl"
                                        color={textColorPrimary}
                                        fontWeight="bold"
                                        onClick={() => canviarVisibilidad(index)}
                                        cursor="pointer"
                                        transform={
                                            planificacionesVisibles[index] === 'visible'
                                                ? 'rotate(90deg)'
                                                : 'rotate(-90deg)'
                                        }
                                    >
                                        {'<'}
                                    </Text>
                                </Flex>
                                {planificacionesVisibles[index] === 'visible' && (
                                    <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px">
                                        {planificacion.etapas.map((etapa, index) => (
                                            <FechaItem
                                                mb="16px"
                                                key={index}
                                                title={etapa.etapa}
                                                fecha={etapa.fecha_inicio}
                                                day={obtenerDia(etapa.fecha_inicio).toString()}
                                                weekday={obtenerNombreMes(
                                                    etapa.fecha_inicio
                                                ).toString()}
                                            />
                                        ))}
                                    </SimpleGrid>
                                )}
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Alert status="warning" mt={'20px'}>
                        <AlertIcon />
                        <Text>
                            Este lote no está asignado a ninguna planificación.{' '}
                            {permisosAcceso.botonAsignarCampana && (
                                <Link
                                    textDecoration={'underline'}
                                    onClick={() =>
                                        navigate(
                                            '/admin/lotes-semillas/asignar-planificacion/' +
                                                loteData.id
                                        )
                                    }
                                >
                                    Asignar
                                </Link>
                            )}
                        </Text>
                    </Alert>
                )}
            </Card>
        </Box>
    );
}
