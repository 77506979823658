import { Notificacion } from '../../modules/notificaciones/types/Notificacion';
import { MenuItem } from '@chakra-ui/react';
import { ItemContent } from '../menu/ItemContent';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

type FilaNotificacionProps = {
    notificacion: Notificacion;
    marcarComoVista?: CallableFunction;
    onClick: () => void;
};

export default function FilaNotificacion(props: FilaNotificacionProps) {
    const { notificacion, marcarComoVista, onClick } = props;
    const [timer, setTimer] = useState<number | null>(null);

    const timerRef = useRef<number | null>(null);
    const navigate = useNavigate();

    const handleClick = () => {
        if (notificacion.seen === 0 && marcarComoVista) {
            marcarComoVista(notificacion.id);
        }

        if (notificacion.link) {
            navigate(notificacion.link);
        }
    };

    const handleMouseEnter = () => {
        if (notificacion.seen === 1 || timerRef.current !== null) {
            return;
        }

        timerRef.current = window.setTimeout(() => {
            if (marcarComoVista) {
                marcarComoVista(notificacion.id);
            }
        }, 2000);
    };

    const handleMouseLeave = () => {
        if (timerRef.current !== null) {
            window.clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    };

    useEffect(() => {
        return () => {
            if (timerRef.current !== null) {
                window.clearTimeout(timerRef.current);
            }
        };
    }, []);

    return (
        <MenuItem
            _hover={{ bg: 'none' }}
            _focus={{ bg: 'none' }}
            px="0"
            borderRadius="8px"
            mb="10px"
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <ItemContent
                info={notificacion.message}
                detalle={notificacion.content}
                leido={notificacion.seen}
            />
        </MenuItem>
    );
}
