import {
    Flex,
    SimpleGrid,
    Text,
    useColorModeValue,
    Divider,
    Grid,
    GridItem,
    Button,
    Box,
    Spinner
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { FilaInfoRegistro } from './FilaInfoRegistro';
import { ControlAmbiental } from 'modules/trazabilidad/types/ControlAmbiental';
import { FilaInfoRegistroAmbiental } from './FilaInfoRegistroAmbiental';
import { useEffect, useState } from 'react';
import { useAppContext } from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';

type InfoControlProps = {
    idLote: string;
};

export default function InfoControl(props: InfoControlProps) {
    const { idLote } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const { authToken } = useAppContext();
    const navigate = useNavigate();
    const rotateStyle = {
        transform: 'rotate(-90deg)'
    };
    const { setFlashMessage, setFlashType } = useAppContext();
    const [controlAmbientalData, setControlAmbientalData] = useState(null);
    const [controlAmbientalDataFinal, setControlAmbientalDataFinal] =
        useState<ControlAmbiental[]>(controlAmbientalData);
    const [cargandoData, setCargandoData] = useState(false);

    const fetchReportesAmbientales = async (idLote: string) => {
        setCargandoData(true);
        CannavaApiService.reportesAmbientalesPorLoteObtener(
            idLote,
            authToken,
            data => {
                console.log('reportes ambientales');
                console.log(data);
                setControlAmbientalData(data);
                setControlAmbientalDataFinal(data);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion');
            }
        );
    };

    const sortFecha = () => {
        const dataFinal = [...controlAmbientalData].sort((a, b) => {
            if (a.fecha_hora < b.fecha_hora) return -1;
            if (a.fecha_hora > b.fecha_hora) return 1;
            return 0;
        });
        setControlAmbientalDataFinal(dataFinal);
    };

    const sortHora = () => {
        const dataFinal = [...controlAmbientalData].sort((a, b) => {
            if (a.fecha_hora < b.fecha_hora) return -1;
            if (a.fecha_hora > b.fecha_hora) return 1;
            return 0;
        });
        setControlAmbientalDataFinal(dataFinal);
    };

    const sortTemperatura = () => {
        const dataFinal = [...controlAmbientalData].sort((a, b) => a.temperatura - b.temperatura);
        setControlAmbientalDataFinal(dataFinal);
    };

    const sortHumedad = () => {
        const dataFinal = [...controlAmbientalData].sort((a, b) => a.humedad - b.humedad);
        setControlAmbientalDataFinal(dataFinal);
    };

    const sortOscuridad = () => {
        const dataFinal = [...controlAmbientalData].sort((a, b) => {
            if (a.cumple_oscuridad < b.cumple_oscuridad) return -1;
            if (a.cumple_oscuridad > b.cumple_oscuridad) return 1;
            return 0;
        });
        setControlAmbientalDataFinal(dataFinal);
    };

    useEffect(() => {
        if (idLote && !controlAmbientalData) {
            fetchReportesAmbientales(idLote);
        }
    }, [idLote, controlAmbientalData]);

    if (cargandoData /* || !controlData */) {
        return (
            <Box textAlign="center">
                <Spinner size="lg" />
                <Box mt={4}>Cargando datos ambientales...</Box>
            </Box>
        );
    }

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center" mb="5">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Control ambiental
                </Text>
            </Flex>
            <Grid templateColumns={{ base: 'repeat(5, 1fr)', md: '15% 10% 30% 25% 20%' }} gap={0}>
                <GridItem display="flex" justifyContent="center">
                    <Button
                        onClick={sortFecha}
                        height={10}
                        bg="transparent"
                        _hover={{ bg: 'transparent' }}
                    >
                        <Text fontSize={13} textAlign="center" marginRight={2}>
                            Fecha
                        </Text>
                        <Text textAlign="center" style={rotateStyle}>
                            {'<'}
                        </Text>
                    </Button>
                </GridItem>
                <GridItem display="flex" justifyContent="center">
                    <Button
                        onClick={sortHora}
                        height={10}
                        bg="transparent"
                        _hover={{ bg: 'transparent' }}
                    >
                        <Text fontSize={13} textAlign="center" marginRight={2}>
                            Hora
                        </Text>
                        <Text textAlign="center" style={rotateStyle}>
                            {'<'}
                        </Text>
                    </Button>
                </GridItem>
                <GridItem display="flex" justifyContent="center">
                    <Button
                        onClick={sortTemperatura}
                        height={10}
                        bg="transparent"
                        _hover={{ bg: 'transparent' }}
                    >
                        <Text fontSize={13} textAlign="center" marginRight={2}>
                            Temperatura (24-26ºC)
                        </Text>
                        <Text textAlign="center" style={rotateStyle}>
                            {'<'}
                        </Text>
                    </Button>
                </GridItem>
                <GridItem display="flex" justifyContent="center">
                    <Button
                        onClick={sortHumedad}
                        height={10}
                        bg="transparent"
                        _hover={{ bg: 'transparent' }}
                    >
                        <Text fontSize={13} textAlign="center" marginRight={2}>
                            Humedad (70-90%)
                        </Text>
                        <Text textAlign="center" style={rotateStyle}>
                            {'<'}
                        </Text>
                    </Button>
                </GridItem>
                <GridItem display="flex" justifyContent="center">
                    <Button
                        onClick={sortOscuridad}
                        height={10}
                        bg="transparent"
                        _hover={{ bg: 'transparent' }}
                    >
                        <Text fontSize={13} textAlign="center" marginRight={2}>
                            Oscuridad
                        </Text>
                        <Text textAlign="center" style={rotateStyle}>
                            {'<'}
                        </Text>
                    </Button>
                </GridItem>
            </Grid>
            <Divider border="1px" borderColor="lightgray" />
            {controlAmbientalDataFinal &&
                controlAmbientalDataFinal.map((control, index) => (
                    <FilaInfoRegistroAmbiental controlData={control} key={index} />
                ))}
        </Card>
    );
}
