import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import { LotePoderGerminativo } from 'modules/trazabilidad/types/LotePoderGerminativo';
import { Flex, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FilaInfoRegistro } from '../../../lotesSemillas/verLote/components/FilaInfoRegistro';

type Props = {
    loteData: LoteSemillas;
    lotePoderGerminativo: LotePoderGerminativo;
};

export default function AnalisisCalidadSemilla(props: Props) {
    const { loteData, lotePoderGerminativo } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const navigate = useNavigate();

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Análisis de calidad de la semilla
                </Text>
            </Flex>
            <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing={{ base: '20px', xl: '5px' }}>
                <FilaInfoRegistro
                    label={'Informe Nº'}
                    value={lotePoderGerminativo.reportePoderGerminativo.numero_informe}
                />
                <FilaInfoRegistro
                    label={'Fecha de toma de la muestra'}
                    value={lotePoderGerminativo.reportePoderGerminativo.fecha_toma_muestra}
                />
                <FilaInfoRegistro
                    label={'Peso de la muestra'}
                    value={lotePoderGerminativo.reportePoderGerminativo.peso_muestra}
                />
                <FilaInfoRegistro
                    label={'Tamaño de la muestra'}
                    value={lotePoderGerminativo.reportePoderGerminativo.tamanio_muestra}
                />
                <FilaInfoRegistro
                    label={'Lote de precedencia'}
                    value={loteData.codigo_lote}
                    click={() => {
                        navigate(
                            '/admin/lotes-semillas/ver/' + lotePoderGerminativo.lote_semillas_id
                        );
                    }}
                />
                <FilaInfoRegistro
                    label={'Fecha de análisis de la muestra'}
                    value={lotePoderGerminativo.reportePoderGerminativo.fecha_analisis_muestra}
                />
            </SimpleGrid>
        </Card>
    );
}
