import { Campana } from '../../../types/Campana';
import { Box, Button, Grid, Icon, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import PageLoader from 'components/actions/PageLoader';
import { MenuItemProps } from '../../../../../types/MenuItemProps';
import { MdAddCircle, MdDownload, MdPrint, MdShare } from 'react-icons/md';
import { createColumnHelper } from '@tanstack/react-table';
import Listado, { crearCelda, crearCeldaConLink, crearHeader } from 'components/cannava/listado';
import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useLayoutContext } from '../../../../../contexts/LayoutContext';
import CannavaApiService from '../../../../../utils/CannavaApiService';
import { useAppContext } from '../../../../../contexts/AppContext';

import VisualizadorEstados, { Estado } from 'components/cannava/visualizadorEstados';
import CumplimientoObjetivos from './components/CumplimientoObjetivos';
// Assets
import { MdEdit, MdCheck, MdClose, MdFlag, MdCheckCircle } from 'react-icons/md';

import ListadoObjetivos from './components/ListadoObjetivos';
import ListadoPlanificaciones from './components/ListadoPlanificaciones';
import EventCalendar from 'components/calendar/EventCalendar';
import CalendarioLocaciones from './components/CalendarioLocaciones';
import { useConfigurarNavegacion } from '../../../../../hooks/useConfigurarNavegacion';
import PanelAnotaciones from 'components/chat/PanelAnotaciones';

// Custom hooks
import { useAutenticacionUsuario } from '../../../../../modules/auth/hooks/useAutenticacionUsuario';

type headerCampanaProps = {
    data: Campana;
};

/**
 * Componente principal para visualizar una campaña e interactuar con ellas.
 * Las principales responsabilidades son:
 *  + creacion de objetivos de campaña
 *  + creacion de planificaciones
 *  + poder cambiar el estado de la campaña
 *
 * Obtiene el ID de un parametro de la URL.
 * @constructor
 */
export default function CampanasView() {
    // region Variables

    const { authToken, setFlashMessage, setFlashType } = useAppContext();
    const [cargandoData, setCargandoData] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState<Campana>(null);
    const [dataSecundaria, setDataSecundaria] = useState<any>(null);
    const [estados, setEstados] = useState<Estado[]>([]);

    // endregion

    // region Hooks y callbacks

    const obtenerDataCampana = async (mostrarLoading = false) => {
        CannavaApiService.campanasObtener(
            id,
            authToken,
            data => {
                console.log('data campana');
                console.log(data);
                setData(data);
                if (mostrarLoading) {
                    setCargandoData(false);
                }
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                if (mostrarLoading) {
                    setCargandoData(false);
                }
                navigate('/admin/campanas');
            }
        );
    };

    const obtenerDataEtapas = async (mostrarLoading = false) => {
        CannavaApiService.etapasObtenerTodos(
            authToken,
            data => {
                console.log('data campana');
                console.log(data);
                setDataSecundaria(data);
                if (mostrarLoading) {
                    setCargandoData(false);
                }
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                if (mostrarLoading) {
                    setCargandoData(false);
                }
                navigate('/admin/campanas');
            }
        );
    };

    const cargarData = async () => {
        const promesa1 = obtenerDataCampana(false);
        const promesa2 = obtenerDataEtapas(false);

        await Promise.all([promesa1, promesa2]);
        setCargandoData(false);
    };

    const actualizarCampana = (mostrarLoading = true) => {
        const fetchData = async (id: string) => {
            if (mostrarLoading) {
                setCargandoData(true);
            }

            CannavaApiService.campanasObtener(
                id,
                authToken,
                data => {
                    console.log('data campana');
                    console.log(data);
                    setData(data);
                    if (mostrarLoading) {
                        setCargandoData(false);
                    }
                },
                error => {
                    //throw new Error(`HTTP error! Status: ${error}`);
                    setFlashMessage('Hubo un error al obtener los datos del usuario.');
                    setFlashType('error');
                    if (mostrarLoading) {
                        setCargandoData(false);
                    }
                    navigate('/admin/campanas');
                }
            );
        };

        fetchData(id);
    };

    const activarCampana = async () => {
        await CannavaApiService.request(
            'GET',
            '/planificacion/api/campanas/activar/' + id,
            {},
            authToken,
            data => {
                setFlashMessage('Campaña activada correctamente.');
                setFlashType('success');
                actualizarCampana(true);
            },
            error => {
                setFlashMessage('Hubo un error al activar la campaña.');
                setFlashType('error');
                actualizarCampana(true);
            }
        );
    };

    // Gestion de accesos por componente
    const [permisosAcceso, setPermisosAcceso] = useState<Record<string, boolean>>({});
    const { obtenerPermisos } = useAutenticacionUsuario();

    useEffect(() => {
        const permisos = [
            {
                key: 'botonActivarCampana',
                roles: ['usuario-basico', 'administrador'],
                areas: ['planificacion', 'finanzas']
            },
            ,
        ];
        const nuevosPermisos = obtenerPermisos(permisos);
        setPermisosAcceso(nuevosPermisos);
    }, [obtenerPermisos]);

    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: [
                { label: 'Inicio', href: '/' },
                { label: 'Planificación', href: '/admin/campanas/' },
                { label: 'Campañas', href: '/admin/campanas' }
            ],
            titulo: () => {
                // Generar título basado en data cargada
                return !data ? 'Ver Campaña' : data.nombre;
            }
        },
        data
    );

    // endregion

    // region useEffect

    useEffect(() => {
        if (id && authToken) {
            cargarData();
        }
    }, [id, authToken]);

    useEffect(() => {
        let estados: Estado[] = [
            {
                name: 'draft',
                icon: MdEdit,
                done: false
            },
            {
                name: 'activa',
                icon: MdCheck,
                done: false
            },
            {
                name: 'cerrada',
                icon: MdFlag,
                done: false
            }
            // ,
            // {
            //     name: "necesita-revision",
            //     icon: null,
            //     done: false
            // },
            // {
            //     name: "en-revision",
            //     icon: null,
            //     done: false
            // }
        ];

        setEstados(estados);
    }, []);

    // endregion

    if (cargandoData || !data) {
        return <PageLoader message={'Cargando campaña'} />;
    }

    return (
        <Box>
            <Grid
                mb="20px"
                templateColumns={{ base: '2.4fr 1fr', lg: '2.4fr 1fr' }}
                flexDirection="column"
                pt={{ base: '130px', md: '80px', xl: '80px' }}
            >
                <Box>
                    <HeaderCampana data={data} />

                    <ListadoObjetivos campana={data} actualizarCampana={actualizarCampana} />

                    <ListadoPlanificaciones
                        campana={data}
                        etapas={dataSecundaria}
                        actualizarCampana={actualizarCampana}
                        planificaciones={data.planificaciones}
                    />

                    <CalendarioLocaciones campana={data} />

                    {data.estado === 'draft' && permisosAcceso.botonActivarCampana && (
                        <Button
                            leftIcon={<Icon as={MdCheckCircle} color="white" w="18px" h="18px" />}
                            colorScheme={'green'}
                            w={'100%'}
                            onClick={activarCampana}
                        >
                            Activar campaña
                        </Button>
                    )}

                    <PanelAnotaciones modelo="campana" id={data.id.toString()} />
                </Box>

                <Box>
                    <CumplimientoObjetivos
                        ms={{ base: '0px', lg: '20px' }}
                        mt={{ base: '20px', lg: '0px' }}
                        objetivos={data.objetivos}
                        planificaciones={data.planificaciones}
                    />

                    <VisualizadorEstados
                        estados={estados}
                        estadoSeleccionado={'draft'}
                        titulo={'Estado campaña'}
                        ms={{ base: '0px', lg: '20px' }}
                        mt={{ base: '20px' }}
                        zIndex="0"
                        gridArea={{ base: '2 / 1 / 3 / 3', lg: '1 / 2 / 2 / 3' }}
                    />
                </Box>
            </Grid>
        </Box>
    );
}

/**
 * Header de la campaña
 * @constructor
 */
function HeaderCampana(props: headerCampanaProps) {
    const { data } = props;

    return (
        <Card
            bgGradient="linear(to-b, brand.400, brand.600)"
            backgroundRepeat="no-repeat"
            bgSize="cover"
            bgPosition="10%"
            flexDirection="row"
            p={{ base: '20px', md: '50px' }}
            py="50px"
            mb="40px"
            borderRadius="20px"
        >
            <Flex direction="column" color="white" h="100%" w="100%">
                <Text fontSize={{ base: 'md', md: 'xl' }} fontWeight="regular">
                    Campana {data.tipo}
                </Text>
                <Text
                    lineHeight="100%"
                    fontSize={{ sm: 'lg', md: '30px', lg: '36px', xl: '40px' }}
                    fontWeight="bold"
                >
                    {data.nombre}
                </Text>
                <Text fontSize={{ base: 'md', md: 'xl' }} fontWeight="regular">
                    Del {data.fecha_inicio} al {data.fecha_fin}
                </Text>
            </Flex>
            {/*<Flex alignSelf="start" my={{ base: 'auto', md: '0px' }}>
                            <Button
                                onClick={() => console.log("imprimo")}
                                ms="auto"
                                me="10px"
                                alignItems="center"
                                justifyContent="center"
                                p="7px"
                                minW="unset"
                                h="32px"
                                lineHeight="100%"
                                borderRadius="10px"
                            >
                                <Icon as={MdPrint} color="white" w="18px" h="18px" />
                            </Button>
                            <Button
                                alignItems="center"
                                me="10px"
                                justifyContent="center"
                                bg={bgButton}
                                _hover={bgHover}
                                _focus={bgFocus}
                                _active={bgFocus}
                                p="7px"
                                minW="unset"
                                h="32px"
                                lineHeight="100%"
                                borderRadius="10px"
                            >
                                <Icon as={MdDownload} color="white" w="18px" h="18px" />
                            </Button>
                            <Button
                                alignItems="center"
                                justifyContent="center"
                                bg="linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)"
                                _hover={{
                                    bg: 'linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)',
                                }}
                                _focus={{
                                    bg: 'linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)',
                                }}
                                _active={{
                                    bg: 'linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)',
                                }}
                                p="7px"
                                minW="unset"
                                h="32px"
                                lineHeight="100%"
                                borderRadius="10px"
                            >
                                <Icon as={MdShare} color="white" w="18px" h="18px" />
                            </Button>
                        </Flex>*/}
        </Card>
    );
}
