// Chakra imports
import { Flex } from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import React, { useEffect, useState } from 'react';
import { useLayoutContext } from '../../../../../contexts/LayoutContext';
import { Proveedor } from '../../../types/Proveedor';
import { useFetchData } from '../../../../../hooks/useFetchData';
import PageLoader from '../../../../../components/actions/PageLoader';
import { MenuItemProps } from '../../../../../types/MenuItemProps';
import { MdAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import Listado, {
    crearCelda,
    crearCeldaConLink,
    crearHeader
} from '../../../../../components/cannava/listado';

export default function ProveedoresOverview() {
    const transformProveedores = (data: any[]): Proveedor[] => {
        return data.map(proveedor => ({
            id: proveedor.id,
            nombre: proveedor.nombre,
            codigo: proveedor.codigo,
            slug: proveedor.slug,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };

    const { data: proveedoresData, isLoading } = useFetchData<Proveedor>(
        'proveedores',
        transformProveedores
    );
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const navigate = useNavigate();

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Administración', href: '/admin/home/' },
            { label: 'Proveedores', href: '/admin/proveedores/' }
        ]);

        setTitle('Proveedores');
    }, []);

    const menuItems: MenuItemProps[] = [
        {
            icono: MdAddCircle,
            label: 'Agregar nuevo',
            onClick: () => navigate('/admin/proveedores/nuevo')
        }
    ];

    const columnHelper = createColumnHelper<Proveedor>();
    const columns = [
        columnHelper.accessor('nombre', {
            id: 'nombre',
            header: crearHeader('Proveedor'),
            cell: crearCelda()
        }),
        columnHelper.accessor('codigo', {
            id: 'codigo',
            header: crearHeader('Código'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('ID'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'editar',
            header: crearHeader('Editar'),
            cell: crearCeldaConLink('/admin/proveedores/editar', 'Editar', navigate)
        }),
        columnHelper.accessor('id', {
            id: 'actions',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/proveedores/ver', 'Ver proveedor', navigate)
        })
    ];

    if (isLoading) {
        return <PageLoader message={'Cargando proveedores'} />;
    }

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Card px="0px">
                <Listado tableData={proveedoresData} menuItems={menuItems} columns={columns} />
            </Card>
        </Flex>
    );
}
