import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import { LotePoderGerminativo } from 'modules/trazabilidad/types/LotePoderGerminativo';
import { Box, Flex, Text, Card, useColorModeValue } from '@chakra-ui/react';
import CircularProgress from 'components/charts/CircularProgress';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import NumberFormatter from 'utils/NumberFormatter';

type EvolucionReporteCompletadoParams = {
    loteData: LoteSemillas;
    lotePoderGerminativo: LotePoderGerminativo;
    [x: string]: any;
};

export default function EvolucionReporteCompletado(props: EvolucionReporteCompletadoParams) {
    const { loteData, lotePoderGerminativo, ...rest } = props;
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const navigate = useNavigate();

    return (
        <Box>
            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500" mb="10px">
                {lotePoderGerminativo.reportePoderGerminativo.conclusion}
            </Text>

            <Flex justifyContent="center" alignItems="center" w="100%" px="10px" mb="8px" />

            <Box w="140px" mx="auto" textAlign={'center'} mb="30px" mt="10px">
                <CircularProgress
                    title="PG"
                    percentage={lotePoderGerminativo.reportePoderGerminativo.poderGerminativo}
                />
            </Box>

            <Card mx="auto" w={'75%'} mt={4} p={3} textAlign={'center'}>
                <Text color={textColor} fontSize="sm" fontWeight="300">
                    El lote es:
                </Text>
                <Text
                    color="green.600"
                    fontSize="xl"
                    fontWeight="600"
                    onClick={() =>
                        navigate('/admin/lotes-poder-germinativo/ver/' + lotePoderGerminativo.id)
                    }
                >
                    APTO
                </Text>
            </Card>
        </Box>
    );
}
