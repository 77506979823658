// Chakra imports
import { Flex, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';

import SearchTableLotesGerminacion from './components/SearchTableLotesGerminacion';
import React, { useEffect, useState } from 'react';
import { useLayoutContext } from 'contexts/LayoutContext';
import { LoteGerminacion } from 'modules/trazabilidad/types/LoteGerminacion';
import { useFetchData } from 'hooks/useFetchData';
import PageLoader from 'components/actions/PageLoader';
import { MenuItemProps } from 'types/MenuItemProps';
import { MdAddCircle } from 'react-icons/md';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import Listado, { crearCelda, crearCeldaConLink, crearHeader } from 'components/cannava/listado';
import { useAppContext } from 'contexts/AppContext';
import { useAutenticacionUsuario } from '../../../../auth/hooks/useAutenticacionUsuario';

export default function LotesGerminacion() {
    // region Variables

    const { backendUserData } = useAppContext();

    const transformLote = (data: any[]): LoteGerminacion[] => {
        return data.map(lote => ({
            id: lote.id,
            codigo_lote: lote.codigo_lote,
            lote_padre_id: lote.lote_padre_id,
            lote_padre: lote.LoteSemillas,
            tipo: lote.tipo,
            cantidad_semillas: lote.cantidad_semillas,
            bandejas_estimadas: lote.bandejas_estimadas,
            fecha_siembra: lote.fecha_siembra,
            orden_siembra_id: lote.orden_siembra_id,
            lote_poder_germinativo_id: lote.lote_poder_germinativo_id,
            lotePoderGerminativo: lote.lotePoderGerminativo,
            locacion_id: lote.locacion_id,
            establecimiento_id: lote.establecimiento_id,
            establecimiento: lote.establecimiento,
            estado: lote.estado,
            tipo_siembra: lote.tipo_siembra,
            tipo_uso: lote.tipo_uso,
            actions: 'Actions',
            creador: lote.creador,
            creado_por: lote.creado_por,
            actualizado_por: lote.actualizado_por
        }));
    };

    const { validarAutenticacion, error } = useAutenticacionUsuario();

    const { data: lotesData, isLoading } = useFetchData<LoteGerminacion>(
        'lotesGerminacion',
        transformLote
    );
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const navigate = useNavigate();

    const [menuItems, setMenuItems] = useState<MenuItemProps[]>([]);

    const columnHelper = createColumnHelper<LoteGerminacion>();
    const columns = [
        columnHelper.accessor('codigo_lote', {
            id: 'codigo_lote',
            header: crearHeader('Lote'),
            cell: crearCelda()
        }),
        columnHelper.accessor('cantidad_semillas', {
            id: 'peso_lote',
            header: crearHeader('Cantidad semillas'),
            cell: crearCelda()
        }),
        /* columnHelper.accessor('stock'o unidades?, {
            id: 'producto_stock',
            header: crearHeader('Producto en stock'),
            cell: crearCelda()
        }), */
        columnHelper.accessor('estado', {
            id: 'estado',
            header: crearHeader('Estado'),
            cell: crearCelda()
        }),
        columnHelper.accessor('fecha_siembra', {
            id: 'fecha_siembra',
            header: crearHeader('Fecha de llegada'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/lotes-germinacion/ver', 'Ver lote', navigate)
        })
    ];

    // endregion Variables

    // region Hooks y callbacks

    // endregion Hooks y callbacks

    // region useEffects

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Trazabilidad', href: '/admin/trazabilidad/' },
            { label: 'Lotes de germinación', href: '/admin/lotes-germinacion' }
        ]);

        setTitle('Lotes de germinación');
    }, []);

    useEffect(() => {
        if (!backendUserData) return;

        if (validarAutenticacion(null, 'trazabilidad')) {
            setMenuItems([
                {
                    icono: MdAddCircle,
                    label: 'Agregar nuevo',
                    onClick: () => navigate('/admin/lotes-germinacion/nuevo')
                }
            ]);
        }
    }, [backendUserData]);

    // endregion

    if (isLoading) {
        return <PageLoader message={'Cargando lotes'} />;
    }

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Card px="0px">
                <Listado tableData={lotesData} menuItems={menuItems} columns={columns} />
            </Card>
        </Flex>
    );
}
