import { LoteGerminacion } from 'modules/trazabilidad/types/LoteGerminacion';
import { Box, Button, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import Card from 'components/card/Card';
import { FilaInfoRegistro } from '../../verLote/components/FilaInfoRegistro';
import { useNavigate } from 'react-router-dom';

type CardControlGerminacionProps = {
    lote: LoteGerminacion;
};

export default function CardControlGerminacion(props: CardControlGerminacionProps /* : Props */) {
    const { lote } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const navigate = useNavigate();
    const estadosValidos = [
        'germinacion-iniciada',
        'germinacion-finalizada',
        'finalizado',
        'enviado-a-plantinera',
        'plantinera-recibida',
        'plantinera-iniciada',
        'plantinera-monitoreada',
        'plantinera-finalizada'
    ];

    const generarCodigoReporte = (num: number) => {
        let strNum = num.toString();
        return strNum.padStart(4, '0');
    };

    const verReporteGerminacion = () => {
        navigate(
            '/admin/lotes-germinacion/informe-control-germinacion/' +
                lote.reporteControlGerminacion.id
        );
    };

    if (!estadosValidos.includes(lote.estado)) return <></>;

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center" mb="5">
                <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                    Germinación y control ambiental
                </Text>
            </Flex>

            <FilaInfoRegistro
                label={'Nro. de control'}
                value={generarCodigoReporte(lote.reporteControlGerminacion.id)}
                click={verReporteGerminacion}
            />
        </Card>
    );
}
