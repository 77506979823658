import { Box, Flex, Text, useColorModeValue, Grid } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageLoader from '../../../../../components/actions/PageLoader';
import CannavaApiService from '../../../../../utils/CannavaApiService';
import { useAppContext } from '../../../../../contexts/AppContext';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import Listado, {
    crearCelda,
    crearCeldaConLink,
    crearHeader
} from '../../../../../components/cannava/listado';
import Card from '../../../../../components/card/Card';
import { useConfigurarNavegacion } from '../../../../../hooks/useConfigurarNavegacion';
import MapCard from '../../../../../components/cannava/mapCard';
import { Ubicacion } from '../../../types/Ubicacion';
import { LoteGerminacion } from 'modules/trazabilidad/types/LoteGerminacion';
import { LotePlantaProductiva } from 'modules/trazabilidad/types/LotePlantaProductiva';
import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';

export default function UbicacionVer() {
    const { authToken, setFlashMessage, setFlashType } = useAppContext();
    const { id } = useParams();
    const [cargandoData, setCargandoData] = useState(true);
    const [data, setData] = useState<Ubicacion>(null);
    const [lotesData, setLotesData] = useState<any[]>([]);
    const navigate = useNavigate();
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [cargandoLotes, setCargandoLotes] = useState(true);

    const transformLoteGerminacion = (data: any[]): LoteGerminacion[] => {
        return data.map(lote => ({
            ...lote,
            actions: 'Actions'
        }));
    };

    const { data: lotesGerminacionData, isLoading } = useFetchData<LoteGerminacion>(
        'lotesGerminacion',
        transformLoteGerminacion
    );

    const transformLoteProductivo = (data: any[]): LotePlantaProductiva[] => {
        return data.map(lote => ({
            ...lote,
            actions: 'Actions'
        }));
    };

    const { data: lotesProductivosData } = useFetchData<LotePlantaProductiva>(
        'lotesPlantaProductiva',
        transformLoteProductivo
    );

    const columnHelper = createColumnHelper<LoteSemillas>();
    const columns = useMemo(
        () => [
            columnHelper.accessor('codigo_lote', {
                id: 'codigo_lote',
                header: crearHeader('Lote'),
                cell: crearCelda()
            }),
            columnHelper.accessor('estado', {
                id: 'estado',
                header: crearHeader('Estado'),
                cell: crearCelda()
            }),
            columnHelper.accessor('fecha_recepcion', {
                id: 'fecha_recepcion',
                header: crearHeader('Fecha llegada'),
                cell: crearCelda((info: CellContext<any, any>) => {
                    const fechaRecepcion = info.row.original.fecha_recepcion;
                    return fechaRecepcion ? fechaRecepcion.split(' ')[0] : 'N/A';
                })
            }),
            columnHelper.accessor('id', {
                id: 'id',
                header: crearHeader('Acciones'),
                cell: crearCeldaConLink('/admin/lotes-semillas/ver', 'Ver más', navigate)
            })
        ],
        [columnHelper, navigate]
    );

    const obtenerData = (mostrarLoading = true) => {
        const fetchData = async (id: string) => {
            if (mostrarLoading) {
                setCargandoData(true);
            }

            CannavaApiService.ubicacionesObtener(
                parseInt(id),
                authToken,
                data => {
                    setData(data);
                    if (mostrarLoading) {
                        setCargandoData(false);
                    }
                },
                error => {
                    setFlashMessage('Hubo un error al obtener los datos del usuario.');
                    setFlashType('error');
                    if (mostrarLoading) {
                        setCargandoData(false);
                    }
                    navigate('/admin/variedades');
                }
            );
        };

        fetchData(id);
    };

    useConfigurarNavegacion(
        {
            breadcrumbs: () => {
                if (!data) return [];
                return [
                    { label: 'Inicio', href: '/' },
                    { label: 'Ubicaciones', href: '/admin/ubicaciones/' }
                ];
            },
            titulo: () => {
                if (!data) return '';
                return `${data.establecimiento.nombre} | ${data.nombre}`;
            }
        },
        data
    );

    useEffect(() => {
        if (id && authToken) {
            obtenerData();
        }
    }, [id, authToken]);

    useEffect(() => {
        setCargandoLotes(true);
        const newLotesData: any[] = [];

        lotesGerminacionData.forEach(lote => {
            if (lote.locacion_id === parseInt(id)) {
                newLotesData.push(lote);
            }
        });

        lotesProductivosData.forEach(lote => {
            if (lote.locacion_id === parseInt(id)) {
                newLotesData.push(lote);
            }
        });

        // Logging to ensure new data is created and set
        console.log('Setting lotesData:', newLotesData);

        setLotesData(newLotesData);
        setCargandoLotes(false);
    }, [lotesGerminacionData, lotesProductivosData, id]);

    useEffect(() => {
        console.log('lotesData changed:', lotesData); // Debug log
    }, [lotesData]);

    if (cargandoData) {
        return <PageLoader message={'Cargando ubicación'} />;
    }

    if (isLoading) {
        return <PageLoader message={'Cargando lotes'} />;
    }

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            <Grid
                templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
                gap={4}
                mb={'30px'}
            >
                {data.latitud && data.longitud && (
                    <Flex direction={'row'} height={500}>
                        <MapCard
                            lat={data.latitud}
                            lng={data.longitud}
                            mapZoom={16}
                            gridArea="1 / 2 / 2 / 3"
                        />
                    </Flex>
                )}
                <Flex flexDirection={'column'}>
                    <Flex justify={'space-between'} align={'center'} mb={10}>
                        <Text fontSize={'5xl'} fontWeight={'bold'} color={textColor}>
                            {data.nombre}
                        </Text>
                        <Text
                            fontSize={'sm'}
                            color={'blue.400'}
                            onClick={() => navigate(`/admin/ubicaciones/editar/${id}`)}
                        >
                            Editar
                        </Text>
                    </Flex>
                    <Card>
                        <Flex flexDirection={'column'} justify={'center'} align={'center'} m={10}>
                            <Text fontSize={'lg'} fontWeight={'semibold'} color={textColor}>
                                Densidad siembra (ha)
                            </Text>
                            <Text fontSize={'4xl'} fontWeight={'bold'} color={textColor}>
                                {data.superficie_total}K
                            </Text>
                        </Flex>
                        <Grid
                            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
                            gap={4}
                            mt={10}
                            mb={'30px'}
                        >
                            <Flex flexDirection={'column'} justify={'center'} align={'center'}>
                                <Text fontSize={'4xl'} fontWeight={'bold'} color={textColor}>
                                    {data.estado}
                                </Text>
                                <Text fontSize={'lg'} fontWeight={'semibold'} color={textColor}>
                                    Estado
                                </Text>
                            </Flex>
                            <Flex flexDirection={'column'} justify={'center'} align={'center'}>
                                <Text fontSize={'4xl'} fontWeight={'bold'} color={textColor}>
                                    {data.tipo.nombre}
                                </Text>
                                <Text fontSize={'lg'} fontWeight={'semibold'} color={textColor}>
                                    Tipo
                                </Text>
                            </Flex>
                            <Flex flexDirection={'column'} justify={'center'} align={'center'}>
                                <Text fontSize={'4xl'} fontWeight={'bold'} color={textColor}>
                                    {'49'}
                                </Text>
                                <Text fontSize={'lg'} fontWeight={'semibold'} color={textColor}>
                                    Kg / 100 Unds.
                                </Text>
                            </Flex>
                        </Grid>
                    </Card>
                </Flex>
            </Grid>
            <Card px="0px">
                <Listado tableData={lotesData} menuItems={[]} columns={columns} />
            </Card>
        </Box>
    );
}
