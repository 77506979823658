// Chakra imports
import { Avatar, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';

import React, { useEffect, useState } from 'react';
import { Inventario } from '../../../types/Inventario';
import { useLayoutContext } from '../../../../../contexts/LayoutContext';
import { useFetchData } from '../../../../../hooks/useFetchData';
import PageLoader from '../../../../../components/actions/PageLoader';
import { MenuItemProps } from '../../../../../types/MenuItemProps';
import { MdAddCircle } from 'react-icons/md';
import { createColumnHelper, CellContext } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import Listado, {
    crearCelda,
    crearCeldaConLink,
    crearHeader
} from '../../../../../components/cannava/listado';
import { useConfigurarNavegacion } from '../../../../../hooks/useConfigurarNavegacion';

export default function InventariosOverview() {
    const transformInventarios = (data: any[]): Inventario[] => {
        return data.map(inventario_registro => {
            return {
                id: inventario_registro.id,
                lote_semillas_id: inventario_registro.lote_semillas_id,
                lote_semillas: inventario_registro.loteSemillas,
                cantidad: inventario_registro.cantidad,
                stock: inventario_registro.stock,
                data: inventario_registro.data,
                fecha_creacion: inventario_registro.fecha_creacion,
                fecha_actualizacion: inventario_registro.fecha_actualizacion,
                actions: 'actions' // Esto es es
            };
        });
    };

    const { data: inventarioData, isLoading } = useFetchData<Inventario>(
        'inventarios',
        transformInventarios
    );
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const navigate = useNavigate();

    // configuro layout
    const configurarNavegacion = useConfigurarNavegacion({
        breadcrumbs: [
            { label: 'Inicio', href: '/' },
            { label: 'Inventarios', href: '/admin/inventarios/' }
        ],
        titulo: 'Registro de inventario'
    });

    if (isLoading) {
        return <PageLoader message={'Cargando inventarios'} />;
    }

    const menuItems: MenuItemProps[] = [];

    const textColor = 'navy.700';
    const columnHelper = createColumnHelper<Inventario>();
    const columns = [
        columnHelper.accessor('lote_semillas_id', {
            id: 'lote_semillas_id',
            header: crearHeader('ID Lote'),
            cell: crearCelda((info: CellContext<any, any>) => {
                return info.row.original.lote_semillas.codigo_lote;
            })
        }),
        columnHelper.accessor('cantidad', {
            id: 'cantidad',
            header: crearHeader('Movimiento'),
            cell: crearCelda()
        }),
        columnHelper.accessor('stock', {
            id: 'stock',
            header: crearHeader('Stock'),
            cell: crearCelda()
        }),
        columnHelper.accessor('fecha_creacion', {
            id: 'fecha_creacion',
            header: crearHeader('Fecha de movimiento'),
            // cell: crearCelda()
            cell: crearCelda((info: CellContext<any, any>) => {
                return info.row.original.fecha_creacion.split(' ')[0];
            })
        }),
        columnHelper.accessor('lote_semillas_id', {
            id: 'actions',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/lotes-semillas/ver', 'Ver detalle', navigate)
        })
    ];

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Card px="0px">
                <Listado tableData={inventarioData} menuItems={menuItems} columns={columns} />
            </Card>
        </Flex>
    );
}
