import { LoteGerminacion } from 'modules/trazabilidad/types/LoteGerminacion';
import { Button, Flex, Text, useColorModeValue, Link } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useNavigate } from 'react-router-dom';

type CardLimpiezaAreaProps = {
    lote: LoteGerminacion;
};

export default function CardLimpiezaArea(props: CardLimpiezaAreaProps /* : Props */) {
    const { lote } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const navigate = useNavigate();
    const limpiezaHardCoded: boolean = true;

    const estadosValidos = [
        'siembra-iniciada',
        'bandejas-preparadas',
        'bandejas-finalizadas',
        'semillado-iniciado',
        'semillado-finalizado',
        'germinacion-iniciada',
        'germinacion-finalizada',
        'finalizado',
        'enviado-a-plantinera',
        'plantinera-recibida',
        'plantinera-iniciada',
        'plantinera-monitoreada',
        'plantinera-finalizada'
    ];

    const nuevoReporteLimpieza = () => {
        navigate('/admin/lotes-germinacion/limpieza-area/' + lote.id);
    };

    const verDetallesLimpieza = () => {
        navigate('/admin/lotes-germinacion/limpieza-area-detalles/' + lote.id);
    };

    /* if (!estadosValidos.includes(lote.estado) && que el lote tenga alguna limpieza realizada) return <></>; */
    if (!estadosValidos.includes(lote.estado) && !limpiezaHardCoded) return <></>;

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center" mb="5">
                <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                    Limpieza de área
                </Text>
                <Flex align="center">
                    <Link color="blue.400" fontWeight="400" me="10px" onClick={verDetallesLimpieza}>
                        Ver detalles
                    </Link>
                    <Button colorScheme="green" m="1" onClick={nuevoReporteLimpieza}>
                        Iniciar limpieza de área
                    </Button>
                </Flex>
            </Flex>
        </Card>
    );
}
