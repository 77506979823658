import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import {
    Flex,
    SimpleGrid,
    Text,
    useColorModeValue,
    Divider,
    Button,
    Box,
    Input,
    Textarea
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import React, { useState, useEffect } from 'react';
import { FilaInfoRegistro } from './FilaInfoRegistro';
import { useNavigate } from 'react-router-dom';
import { LoteGerminacion } from 'modules/trazabilidad/types/LoteGerminacion';
// Custom hooks
import { useAutenticacionUsuario } from '../../../../../auth/hooks/useAutenticacionUsuario';

type InfoLoteProps = {
    loteData: LoteGerminacion;
    estado: string;
};

type DataRegistroBaja = {
    fecha: string;
    hora: string;
    cantidad_bajas: number;
    razon_descarte: string;
};

export default function CardRegistroBajas(props: InfoLoteProps /* : Props */) {
    const { loteData, estado } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const navigate = useNavigate();
    const estadosValidos: string[] = [
        'siembra-iniciada',
        'bandejas-preparadas',
        'bandejas-finalizadas',
        'semillado-iniciado',
        'semillado-finalizado',
        'germinacion-iniciada',
        'germinacion-finalizada',
        'finalizado',
        'enviado-a-plantinera',
        'plantinera-recibida',
        'plantinera-iniciada',
        'plantinera-monitoreada',
        'plantinera-finalizada'
    ];
    const [estadoCard, setEstadoCard] = useState<string>('sin-registrar');
    const [cantidadBajas, setCantidadBajas] = useState('');
    const [razonDescarte, setRazonDescarte] = useState('');

    // Gestion de accesos por componente
    const [permisosAcceso, setPermisosAcceso] = useState<Record<string, boolean>>({});
    const { obtenerPermisos } = useAutenticacionUsuario();

    const handleCantidadBajasChange = (e: any) => setCantidadBajas(e.target.value);
    const handleRazonDescarteChange = (e: any) => setRazonDescarte(e.target.value);

    const empezarNuevoRegistro = () => {
        setEstadoCard('registrando');
    };

    const guardarNuevaBaja = () => {
        setEstadoCard('guardando');
    };

    const registrarNuevaBaja = () => {
        console.log('Fecha y hora:', new Date());
        console.log('Cantidad de bajas:', cantidadBajas);
        console.log('Razón de descarte:', razonDescarte);
        setEstadoCard('sin-registrar');
        setCantidadBajas('');
        setRazonDescarte('');
    };

    const verDetalles = () => {
        console.log('abrir pagina detalles bajas');
    };

    const cancelarNuevaBaja = () => {
        setEstadoCard('sin-registrar');
        setCantidadBajas('');
        setRazonDescarte('');
    };

    return (
        <>
            {estadosValidos.includes(estado) && (
                <>
                    {estadoCard === 'sin-registrar' && (
                        <Card p="30px" mb={5}>
                            <Flex flexDirection="column" justify="space-between" mb="5">
                                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                                    Registro de bajas
                                </Text>
                                <Text
                                    fontSize="md"
                                    color={textColorPrimary}
                                    fontWeight="semibold"
                                    my={4}
                                >
                                    Desde acá podés generar un registro de las bajas que no se hayan
                                    generado en los distintos procesos.
                                </Text>
                                <Button
                                    colorScheme="green"
                                    m="1"
                                    w="47%"
                                    onClick={empezarNuevoRegistro}
                                >
                                    Registrar
                                </Button>
                            </Flex>
                        </Card>
                    )}
                    {estadoCard === 'registrando' && (
                        <Card p="30px" mb={5}>
                            <Flex flexDirection="column" justify="space-between" mb="5">
                                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                                    Registro de bajas
                                </Text>
                                <Flex alignItems="center" justify="space-between">
                                    <Text
                                        fontSize="md"
                                        color={textColorPrimary}
                                        fontWeight="semibold"
                                        my={4}
                                    >
                                        Cantidad de bajas a registrar (Uds).
                                    </Text>
                                    <Input
                                        w="30%"
                                        value={cantidadBajas}
                                        onChange={handleCantidadBajasChange}
                                    />
                                </Flex>
                                <Text
                                    fontSize="md"
                                    color={textColorPrimary}
                                    fontWeight="semibold"
                                    my={4}
                                >
                                    Razón de descarte:
                                </Text>
                                <Textarea
                                    placeholder="Observaciones"
                                    fontWeight="500"
                                    variant="main"
                                    border={'1px solid #E2E8F0'}
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    value={razonDescarte}
                                    onChange={handleRazonDescarteChange}
                                />
                                <Flex mt={4}>
                                    <Button
                                        colorScheme="green"
                                        m="1"
                                        w="47%"
                                        onClick={guardarNuevaBaja}
                                    >
                                        Guardar
                                    </Button>
                                    <Button
                                        colorScheme="green"
                                        m="1"
                                        w="47%"
                                        onClick={cancelarNuevaBaja}
                                    >
                                        Cancelar
                                    </Button>
                                </Flex>
                            </Flex>
                        </Card>
                    )}
                    {estadoCard === 'guardando' && (
                        <Card p="30px" mb={5}>
                            <Flex flexDirection="column" justify="space-between" mb="5">
                                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                                    Registro de bajas
                                </Text>
                                <Text
                                    fontSize="md"
                                    color={textColorPrimary}
                                    fontWeight="semibold"
                                    my={4}
                                >
                                    Desde acá podés generar un registro de las bajas que no se hayan
                                    generado en los distintos procesos.
                                </Text>
                                <Flex justify="space-between">
                                    <Text
                                        fontSize="md"
                                        color={textColorPrimary}
                                        fontWeight="semibold"
                                        my={4}
                                    >
                                        Total de descarte registrado (Uds).
                                    </Text>
                                    <Text
                                        fontSize="md"
                                        color={textColorPrimary}
                                        fontWeight="semibold"
                                        my={4}
                                    >
                                        10
                                    </Text>
                                </Flex>
                                <Flex mt={4}>
                                    <Button
                                        colorScheme="green"
                                        m="1"
                                        w="47%"
                                        onClick={registrarNuevaBaja}
                                    >
                                        Registrar
                                    </Button>
                                    <Button
                                        colorScheme="green"
                                        m="1"
                                        w="47%"
                                        onClick={cancelarNuevaBaja}
                                    >
                                        Cancelar
                                    </Button>
                                </Flex>
                                <Button colorScheme="green" m="1" w="47%" onClick={verDetalles}>
                                    Ver Detalle
                                </Button>
                            </Flex>
                        </Card>
                    )}
                </>
            )}
        </>
    );
}
