// Chakra imports
import {
    Box,
    Flex,
    Text,
    Input,
    InputGroup,
    InputRightElement,
    useColorModeValue,
    Avatar,
    Button,
    ButtonGroup,
    Center,
    Spinner,
    Textarea,
    Alert,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Image,
    useDisclosure
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { AtSignIcon, AttachmentIcon, LinkIcon } from '@chakra-ui/icons';
import React, { useEffect, useState, useRef, ChangeEvent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Anotaciones } from '../../modules/anotacion/types/Anotaciones';
import { useFetchData } from 'hooks/useFetchData';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import { useNavigate } from 'react-router-dom';
import PageLoader from 'components/actions/PageLoader';
import { HSeparator } from '../separator/Separator';
import { MentionsInput, Mention } from 'react-mentions';
import './TextareaMentions.css';
import mentionStyle from './mentionStyle';
import mentionsInputStyle from './mentionsInputStyle';
import CannavaApiService from '../../utils/CannavaApiService';
import { Usuario } from '../../modules/auth/types/Usuario';
import { useAppContext } from '../../contexts/AppContext';
import { LoteSemillas } from '../../modules/trazabilidad/types/LoteSemilllas';
import UploaderZone from 'components/cannava/uploaderZone';
import FilaAnotacion from './FilaAnotacion';

type AnotacionesProps = {
    modelo: string;
    id: string;
};

type AnotacionData = {
    id: string;
    usuario_id: number;
    modelo: string;
    modelo_id: string;
    texto: string;
    fecha_creacion: string;
    fecha_actualizacion: string | null;
    usuario: Array<string | number>;
    actions: string;
};

export default function PanelAnotaciones({ modelo, id }: AnotacionesProps) {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const { user, isAuthenticated } = useAuth0();
    const { authToken, setFlashMessage, setFlashType } = useAppContext();
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [anotacion, setAnotacion] = useState<string>();
    const [usuarios, setUsuarios] = useState<Usuario[]>([]);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [cargandoData, setCargandoData] = useState(true);
    const methods = useForm();
    const navigate = useNavigate();
    const archivoRef = useRef<HTMLInputElement>(null);
    const [incluyendoArchivo, setIncluyendoArchivo] = useState<boolean>(false);
    const [files, setFiles] = useState([]);

    const transformUsuario = (data: any[]): Usuario[] => {
        return data.map(usuario => ({
            id: usuario.id,
            nombre: usuario.nombre,
            apellido: usuario.apellido,
            nombre_completo: usuario.nombre_completo,
            email: usuario.email,
            nombre_usuario: usuario.nombre_usuario,
            rol: usuario.rol,
            fecha_nacimiento: usuario.fecha_nacimiento,
            fecha_creacion: usuario.fecha_creacion,
            imagen: usuario.imagen,
            actions: usuario.actions,
            display: usuario.nombre_usuario
        }));
    };

    const cargaUsuarios = useFetchData<Usuario>('usuarios', transformUsuario);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [imagenUrl, setImagenUrl] = useState('');

    const abrirModalConImagen = (url: string, esImagen: boolean = true) => {
        if (esImagen) {
            setImagenUrl(url);
            onOpen();
        } else {
            window.open(url, '_blank');
        }
    };

    const transformAnotacion = (data: any[]): Anotaciones[] => {
        const rtf = new Intl.RelativeTimeFormat('es-AR', { numeric: 'auto' });

        return data.map(anotacion => {
            const date = new Date(anotacion.fecha_creacion);
            const now = new Date();
            const diff = now.getTime() - date.getTime();
            const seconds = Math.floor(diff / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            let formattedTime;
            if (-days === -1) {
                formattedTime = 'ayer';
            } else if (-days < -1) {
                const month = date.toLocaleString('es-AR', { month: 'short' });
                formattedTime = `${month} ${date.getDate()}`;
            } else if (seconds < 60) {
                formattedTime = rtf.format(-seconds, 'second');
            } else if (minutes < 60) {
                formattedTime = rtf.format(-minutes, 'minute');
            } else if (hours < 24) {
                formattedTime = rtf.format(-hours, 'hour');
            } else {
                formattedTime = rtf.format(-days, 'day');
            }

            return {
                id: anotacion.id,
                usuario_id: anotacion.usuario_id,
                modelo: anotacion.modelo,
                modelo_id: anotacion.modelo_id,
                texto: anotacion.texto,
                textoConLinks: anotacion.textoConLinks,
                fecha_creacion: formattedTime,
                fecha_actualizacion: anotacion.fecha_actualizacion,
                usuario: anotacion.usuario,
                adjuntos: anotacion.adjuntos,
                actions: 'Actions'
            };
        });
    };

    const {
        data: anotacionesData,
        isLoading,
        refetch
    } = useFetchData<Anotaciones>('anotaciones', transformAnotacion, [
        ['modelo', modelo],
        ['modelo_id', id]
    ]);

    const modificarAnotacionMentions = (
        event: { target: { value: string } },
        newValue: string,
        newPlainTextValue: string,
        mentions: any[]
    ) => {
        setAnotacion(newPlainTextValue);
    };

    const handleClearInput = () => {
        setAnotacion('');
        setFiles([]);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        if (!incluyendoArchivo) {
            setIsFocused(false);
        }
    };

    const modificarAnotacion = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAnotacion(event.target.value);
    };

    const incluirClick = () => {
        if (!incluyendoArchivo) {
            setIncluyendoArchivo(true);
            setIsFocused(true);
        } else {
            setIncluyendoArchivo(false);
            setIsFocused(false);
        }
    };

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'anotacion',
        formMethods: methods,
        onSuccess: data => {
            refetch();
        },
        onError: error => {},
        onErrorDelete: error => {}
    });

    const onSubmit: SubmitHandler<AnotacionData> = (data: AnotacionData) => {
        let archivosAdjuntos = files.map(file => file.response.archivoId);

        const formData = {
            modelo: modelo,
            modelo_id: id,
            texto: anotacion,
            archivosAdjuntos: archivosAdjuntos
        };

        console.log(formData);
        useEnvioFormulario1.envioFormularioCrear(formData);

        handleClearInput();
        handleBlur();
    };

    const cancelarAnotacion = () => {
        handleClearInput();
        handleBlur();
    };

    return (
        <Box w="100%" bg="" my={'20px'}>
            <Card p="30px">
                <Flex justify="space-between" align="center" mb="5">
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Anotaciones
                    </Text>
                </Flex>
                <Flex flexDirection="column" pl="15px">
                    {(isLoading || useEnvioFormulario1.isLoading) && (
                        <Center height="20vh">
                            <Box textAlign="center">
                                <Spinner size="lg" />
                                <Box mt={4}>Cargando anotaciones...</Box>
                            </Box>
                        </Center>
                    )}
                    {!isLoading &&
                        !useEnvioFormulario1.isLoading &&
                        (anotacionesData.length > 0 ? (
                            anotacionesData.map((elemento, index) => (
                                <FilaAnotacion
                                    key={index}
                                    index={index}
                                    anotacion={elemento}
                                    onClick={abrirModalConImagen}
                                    usuarios={cargaUsuarios.data}
                                />
                            ))
                        ) : (
                            <Box>
                                <Alert status="warning">
                                    <Text fontSize="md">
                                        No hay anotaciones por el momento. Sé el primero en
                                        comentar!
                                    </Text>
                                </Alert>
                            </Box>
                        ))}

                    <Modal isOpen={isOpen} onClose={onClose} size="xl">
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Imagen</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Image src={imagenUrl} alt="Imagen Anexo" />
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </Flex>
                <Flex pl="15px" pt="50px">
                    <Avatar
                        name={isAuthenticated ? user.name : null}
                        h="40px"
                        w="40px"
                        mr="5"
                        src={isAuthenticated ? user.picture : null}
                    />
                    <Flex flexDirection="column" w="100%">
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <Flex w="100%" mb="5" flexDirection="column">
                                    <InputGroup zIndex={'100'}>
                                        {/*<Textarea
                                            ref={inputRef}
                                            borderRadius="20px"
                                            variant="filled"
                                            placeholder="Escribir anotación..."
                                            w="100%"
                                            onFocus={handleFocus}
                                            onChange={modificarAnotacion}
                                             onBlur={handleBlur}
                                        />*/}

                                        <MentionsInput
                                            style={mentionsInputStyle}
                                            value={anotacion}
                                            onChange={modificarAnotacionMentions}
                                            onFocus={handleFocus}
                                            placeholder="Escribir anotación..."
                                        >
                                            <Mention
                                                trigger={'@'}
                                                style={mentionStyle}
                                                className={'mentions__mention'}
                                                data={cargaUsuarios.data ? cargaUsuarios.data : []}
                                                displayTransform={(id, display) => `@${display} `}
                                                renderSuggestion={(
                                                    suggestion,
                                                    search,
                                                    highlightedDisplay,
                                                    index,
                                                    focused
                                                ) => (
                                                    <div
                                                        className={`user ${
                                                            focused ? 'focused' : ''
                                                        }`}
                                                    >
                                                        {highlightedDisplay}
                                                    </div>
                                                )}
                                            />
                                        </MentionsInput>
                                        <InputRightElement
                                            mr="10px"
                                            mt={'10px'}
                                            onClick={() => incluirClick()}
                                        >
                                            <AttachmentIcon
                                                color={incluyendoArchivo ? 'green.400' : 'gray.400'}
                                                cursor="pointer"
                                                fontSize={'2xl'}
                                            />
                                        </InputRightElement>

                                        {/* <InputRightElement mr="12">
                                <LinkIcon color='gray.400' />
                            </InputRightElement>
                            <InputRightElement mr="6">
                                <AttachmentIcon color='gray.400' />
                            </InputRightElement>
                            <InputRightElement>
                                <AtSignIcon color='gray.400' />
                            </InputRightElement> */}
                                    </InputGroup>
                                    {incluyendoArchivo && (
                                        <Flex w="100%">
                                            <UploaderZone
                                                files={files}
                                                setFiles={setFiles}
                                                formato={'input'}
                                            ></UploaderZone>
                                        </Flex>
                                    )}
                                </Flex>
                                {(isFocused || incluyendoArchivo) && (
                                    <ButtonGroup gap="4">
                                        <Button
                                            w="200px"
                                            colorScheme="gray"
                                            variant="outline"
                                            type="button"
                                            onClick={cancelarAnotacion}
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            w="200px"
                                            colorScheme="green"
                                            variant="solid"
                                            type="submit"
                                        >
                                            Agregar comentario
                                        </Button>
                                    </ButtonGroup>
                                )}
                            </form>
                        </FormProvider>
                    </Flex>
                </Flex>
            </Card>
        </Box>
    );
}
