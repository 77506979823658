// Chakra imports
import { Flex, Box, Text, useColorModeValue } from '@chakra-ui/react';
import CircularProgress from 'components/charts/CircularProgress';
import { VSeparator } from 'components/separator/Separator';
import { useState, useEffect } from 'react';

// Custom components
import Card from 'components/card/Card';
import { ObjetivoCampana } from '../../../../types/ObjetivoCampana';
import { Planificacion } from '../../../../types/Planificacion';

type CumplimientoObjetivosParams = {
    [x: string]: any;
    objetivos: ObjetivoCampana[];
    planificaciones: Planificacion[];
};

export default function CumplimientoObjetivos(props: CumplimientoObjetivosParams) {
    const { objetivos, planificaciones, ...rest } = props;
    const [totalObjetivos, setTotalObjetivos] = useState(0);
    const [totalPlanificado, setTotalPlanificado] = useState(0);
    const [totalCumplido, setTotalCumplido] = useState(0);

    // Chakra Color Mode
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const cardColor = useColorModeValue('white', 'navy.700');

    useEffect(() => {
        let totalObjetivosInt = 0;
        for (let i = 0; i < objetivos.length; i++) {
            for (let j = 0; j < objetivos[i].filas.length; j++) {
                totalObjetivosInt += objetivos[i].filas[j].cantidad;
            }
        }

        setTotalObjetivos(totalObjetivosInt);
    }, [objetivos]);

    useEffect(() => {
        let totalPlanificadoInt = 0;
        for (let i = 0; i < planificaciones.length; i++) {
            for (let j = 0; j < planificaciones[i].filas.length; j++) {
                totalPlanificadoInt += Number(planificaciones[i].filas[j].cantidad);
            }
        }

        setTotalPlanificado(totalPlanificadoInt);
    }, [planificaciones]);

    useEffect(() => {
        if (totalObjetivos === 0) {
            return;
        }

        setTotalCumplido(totalPlanificado / totalObjetivos);
    }, [totalPlanificado, totalObjetivos]);

    return (
        <Card p="20px" alignItems="center" flexDirection="column" w="100%" {...rest}>
            <Text color={textColor} fontSize="lg" fontWeight="700" mb="10px" mx="auto">
                Objetivos cumplidos
            </Text>
            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500" mx="auto" mb="10px">
                Analizá el porcentaje de los objetivos cumplidos
            </Text>
            <Flex justifyContent="center" alignItems="center" w="100%" px="10px" mb="8px" />
            <Box w="140px" mx="auto" mb="10px" mt="10px">
                <CircularProgress title="Cumplimiento" percentage={totalCumplido} />
            </Box>
            <Card bg={cardColor} flexDirection="row" p="15px" px="20px" mt="auto">
                <Flex direction="column" py="5px">
                    <Text fontSize="xs" color="secondaryGray.600" fontWeight="700" mb="5px">
                        Producción est.
                    </Text>
                    <Text fontSize="lg" color={textColor} fontWeight="700">
                        {totalObjetivos}
                    </Text>
                </Flex>
                <VSeparator ms="70px" me="20px" />
                <Flex direction="column" py="5px">
                    <Text fontSize="xs" color="secondaryGray.600" fontWeight="700" mb="5px">
                        Planificado
                    </Text>
                    <Text fontSize="lg" color={textColor} fontWeight="700">
                        {totalPlanificado}
                    </Text>
                </Flex>
            </Card>
        </Card>
    );
}
