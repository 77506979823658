import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import { Flex, SimpleGrid, Text, useColorModeValue, Box } from '@chakra-ui/react';
import Card from 'components/card/Card';
import React from 'react';
import { FilaInfoRegistro } from './FilaInfoRegistro';
import { useNavigate } from 'react-router-dom';
import FilaEtapaPlanificacion from 'modules/planificacion/views/campanas/view/components/FilaEtapaPlanificacion';

type Props = {
    loteData: LoteSemillas;
    mostrarLoteSemillas?: boolean;
};

export default function InfoRegistro(props: Props) {
    const { loteData, mostrarLoteSemillas } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const navigate = useNavigate();

    const generarTextoLote = (rendimientos: any[]): string => {
        let texto = '';
        rendimientos.forEach((rendimiento, index) => {
            texto += rendimiento.tipo_locacion_id + ': ' + rendimiento.rendimiento;
            if (index < rendimientos.length - 1) {
                texto += ' \n<br /> ';
            }
        });

        return texto;
    };

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Información de registro
                </Text>
            </Flex>
            <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing={{ base: '20px', xl: '5px' }}>
                <FilaInfoRegistro
                    label={'Proveedor'}
                    value={loteData.genetica.proveedor.nombre}
                    click={() => {
                        navigate('/admin/proveedores/ver/' + loteData.genetica.proveedor.id);
                    }}
                />

                {mostrarLoteSemillas && (
                    <FilaInfoRegistro
                        label={'Lote Semillas'}
                        value={loteData.codigo_lote}
                        click={() => {
                            navigate('/admin/lotes-semillas/ver/' + loteData.id);
                        }}
                    />
                )}

                <FilaInfoRegistro label={'Peso en gramos'} value={loteData.peso} />
                <FilaInfoRegistro label={'Cantidad de unidades'} value={loteData.unidades} />

                {loteData.rendimientoEsperado.map((rendimiento, index) => (
                    <FilaInfoRegistro
                        key={index}
                        label={
                            'Rendimiento esperado en ' +
                            (rendimiento.tipo_locacion_id == 1 ? 'Invernadero ' : 'Campo')
                        }
                        title={'Calculado en base al rendimiento de la genética'}
                        value={rendimiento.rendimiento + ' kg'}
                    />
                ))}

                <FilaInfoRegistro
                    label={'Ubicación recepción'}
                    value={loteData.establecimiento.nombre}
                    click={() => {
                        navigate('/admin/establecimientos/ver/' + loteData.establecimiento.id);
                    }}
                />
                {mostrarLoteSemillas ? (
                    <FilaInfoRegistro
                        label={'Creado por'}
                        value={
                            loteData.lotePoderGerminativo[0].creador.nombre +
                            ' ' +
                            loteData.lotePoderGerminativo[0].creador.apellido
                        }
                    />
                ) : (
                    <FilaInfoRegistro
                        label={'Creado por'}
                        value={loteData.creador.nombre + ' ' + loteData.creador.apellido}
                    />
                )}
            </SimpleGrid>
        </Card>
    );
}
