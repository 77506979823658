import {
    Flex,
    Box,
    Text,
    Grid,
    SimpleGrid,
    Icon,
    useColorModeValue,
    List,
    Image,
    Skeleton,
    Tag
} from '@chakra-ui/react';
import { useConfigurarNavegacion } from '../../hooks/useConfigurarNavegacion';
import OverallRevenue from '../admin/dashboards/default/components/OverallRevenue';
import Balance from '../admin/dashboards/default/components/Balance';
import Card from '../../components/card/Card';
import { FaChild, FaFan } from 'react-icons/fa';
import {
    MdLibraryMusic,
    MdLiveTv,
    MdLock,
    MdPhoneInTalk,
    MdCalendarMonth,
    MdTask,
    MdNature,
    MdVerifiedUser,
    MdMap,
    MdAssignment,
    MdAddCircle,
    MdOutlineBarChart,
    MdCropFree,
    MdPerson
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { MenuItemProps } from '../../types/MenuItemProps';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { LoteSemillas } from '../../modules/trazabilidad/types/LoteSemilllas';
import Listado, {
    crearCelda,
    crearCeldaConLink,
    crearHeader
} from '../../components/cannava/listado';
import { useFetchData } from '../../hooks/useFetchData';
import { Campana } from '../../modules/planificacion/types/Campana';
import Statistics from '../admin/main/account/application/components/MiniStatistics';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import IconBox from '../../components/icons/IconBox';
import FakeBarChart from '../../assets/img/account/FakeBarChart.png';
import CannavaApiService from '../../utils/CannavaApiService';
import { useAppContext } from '../../contexts/AppContext';

export default function CannavaHomePrincipal() {
    // configuro layout
    const configurarNavegacion = useConfigurarNavegacion({
        breadcrumbs: [{ label: 'Inicio', href: '/admin/home' }],
        titulo: 'Home'
    });
    const { authToken, setFlashMessage, setFlashType, backendUserData, tieneAccesoAArea } =
        useAppContext();

    const navigate = useNavigate();

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.700', 'white');

    const [dataStats, setDataStats] = useState<any>(null);

    const iconBg = useColorModeValue('secondaryGray.300', 'navy.700');
    const iconColor = useColorModeValue('brand.500', 'white');

    const [data, setData] = useState<any>(null);

    const obtenerStats = async (mostrarLoading = false) => {
        CannavaApiService.request(
            'GET',
            '/api/default/dashboard',
            {},
            authToken,
            data => {
                console.log(data);
                setDataStats(data);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
            }
        );
    };

    useEffect(() => {
        if (backendUserData) {
            obtenerStats();
        }
    }, [backendUserData]);

    /**
     * ejemplos de estadisticas
     * frontend/src/views/admin/main/account/application/index.tsx
     */

    return (
        <Flex direction={{ base: 'column' }} pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Box>
                {dataStats ? (
                    <SimpleGrid
                        columns={{ sm: 1, md: 2, lg: 2, xl: 2, '2xl': 4 }}
                        gap="20px"
                        mb="20px"
                    >
                        <Flex>
                            <Statistics
                                title={'Lotes de semillas'}
                                value={dataStats.lotesSemillas}
                                detail={<Flex align="center"></Flex>}
                                illustration={
                                    <IconBox
                                        w="80px"
                                        h="80px"
                                        bg={iconBg}
                                        icon={
                                            <Icon
                                                color={iconColor}
                                                as={MdNature}
                                                w="38px"
                                                h="38px"
                                            />
                                        }
                                    />
                                }
                            />
                        </Flex>
                        <Flex>
                            <Statistics
                                title={'Campañas'}
                                value={dataStats.campanas}
                                detail={
                                    <Flex align="center">
                                        {/* <Icon as={RiArrowUpSFill} color="green.500" />
                                <Text color="green.500" fontSize="sm" mx="4px" fontWeight="700">
                                    +16%
                                </Text>
                                <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
                                    Since last month
                                </Text>*/}
                                    </Flex>
                                }
                                illustration={
                                    <IconBox
                                        w="80px"
                                        h="80px"
                                        bg={iconBg}
                                        icon={
                                            <Icon
                                                color={iconColor}
                                                as={MdPerson}
                                                w="38px"
                                                h="38px"
                                            />
                                        }
                                    />
                                }
                            />
                        </Flex>
                    </SimpleGrid>
                ) : (
                    <>
                        <Skeleton height={'100px'} mb={'20px'} />
                    </>
                )}
            </Box>

            <Box>
                <SimpleGrid
                    columns={{ base: 1, md: 2 }}
                    width={{ base: '100%', md: '100%' }}
                    gap="20px"
                >
                    {backendUserData &&
                        (backendUserData.roles == 'super-administrador' ||
                            tieneAccesoAArea([
                                'gerencia',
                                'comercial',
                                'aseguramiento-calidad',
                                'control-calidad',
                                'trazabilidad',
                                'planificacion',
                                'finanzas',
                                'campo',
                                'poscosecha'
                            ])) && <ListadoLoteSemillas />}

                    {backendUserData &&
                        (backendUserData.roles == 'super-administrador' ||
                            tieneAccesoAArea([
                                'comercial',
                                'planificacion',
                                'finanzas',
                                'campo',
                                'poscosecha'
                            ])) && <ListadoCampanas />}
                </SimpleGrid>
            </Box>
        </Flex>
    );
}

function ListadoLoteSemillas() {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const brandColor = useColorModeValue('brand.500', 'brand.400');
    const navigate = useNavigate();

    const transformLote = (data: any[]): LoteSemillas[] => {
        return data.map(lote => ({
            id: lote.id,
            codigo_lote: lote.codigo_lote,
            peso: lote.peso,
            unidades: lote.unidades,
            fecha_recepcion: lote.fecha_recepcion,
            cantidad_bajas: lote.cantidad_bajas,
            peso_promedio: lote.peso_promedio,
            genetica_id: lote.genetica_id,
            genetica: lote.genetica,
            establecimiento: lote.establecimiento,
            establecimiento_id: lote.establecimiento_id,
            lotePoderGerminativo: lote.lotePoderGerminativo,
            stock: lote.stock,
            actions: 'Actions'
        }));
    };

    const { data: lotesData, isLoading } = useFetchData<LoteSemillas>(
        'lotesSemillas',
        transformLote
    );

    const menuItems: MenuItemProps[] = [
        {
            icono: MdAddCircle,
            label: 'Agregar nuevo',
            onClick: () => navigate('/admin/lotes-semillas/nuevo')
        }
    ];

    const columnHelper = createColumnHelper<LoteSemillas>();
    const columns = [
        columnHelper.accessor('codigo_lote', {
            id: 'codigo_lote',
            header: crearHeader('Lote'),
            cell: crearCelda((info: CellContext<any, any>) => {
                return (
                    <Text
                        cursor="pointer"
                        color={brandColor}
                        textDecoration="none"
                        fontSize="md"
                        fontWeight="500"
                        onClick={() =>
                            navigate(`/admin/lotes-semillas/ver/${info.row.original.id}`)
                        }
                    >
                        {info.row.original.codigo_lote}
                    </Text>
                );
            })
        }),
        columnHelper.accessor('id', {
            id: 'lote_pg',
            header: crearHeader('Estado'),
            cell: crearCelda((info: CellContext<any, any>) => {
                if (
                    !info.row.original.lotePoderGerminativo ||
                    info.row.original.lotePoderGerminativo.length == 0
                ) {
                    return <Tag colorScheme={'orange'}>sin-pg</Tag>;
                }

                let lote = info.row.original.lotePoderGerminativo[0];
                if (lote.estado == 'en-curso') {
                    return <Tag colorScheme={'green'}>pg-en-curso</Tag>;
                } else if (lote.estado == 'finalizado') {
                    return <Tag colorScheme={'blue'}>pg-finalizado</Tag>;
                }

                return lote.estado;
            })
        }),
        columnHelper.accessor('unidades', {
            id: 'unidades',
            header: crearHeader('Unidades'),
            cell: crearCelda()
        }),
        columnHelper.accessor('fecha_recepcion', {
            id: 'fecha_recepcion',
            header: crearHeader('Fecha Recepción'),
            cell: crearCelda((info: CellContext<any, any>) => {
                return info.row.original.fecha_recepcion.split(' ')[0];
            })
        })
        /*columnHelper.accessor('id', {
            id: 'editar',
            header: crearHeader("Acciones"),
            cell: crearCeldaConLink("/admin/lotes-semillas/editar", "Editar", navigate)
        }),*/
        /*columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/lotes-semillas/ver', 'Ver lote', navigate)
        })*/
    ];

    if (isLoading) {
        return (
            <>
                <Skeleton height={'200px'} mb={'20px'} />
            </>
        );
    }

    return (
        <Card w={'100%'}>
            <Flex justify="space-between" align="center">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Lotes de semillas
                </Text>
            </Flex>

            <Listado
                tableData={lotesData}
                menuItems={menuItems}
                columns={columns}
                hideSearch={true}
            />
        </Card>
    );
}

function ListadoCampanas() {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const brandColor = useColorModeValue('brand.500', 'brand.400');
    const navigate = useNavigate();

    const transformUsuarios = (data: any[]): Campana[] => {
        return data.map(campana => {
            return {
                id: campana.id,
                nombre: campana.nombre,
                slug: campana.slug,
                estado: campana.estado,
                descripcion: campana.descripcion,
                tipo: campana.tipo,
                data_json: campana.data_json,
                fecha_inicio: campana.fecha_inicio,
                fecha_fin: campana.fecha_fin,
                fecha_creacion: campana.fecha_creacion,
                fecha_actualizacion: campana.fecha_actualizacion,
                fecha_borrado: campana.fecha_borrado,
                actions: 'Actions' // Esto es es
            };
        });
    };

    const { data: campanasData, isLoading } = useFetchData<Campana>('campanas', transformUsuarios);

    const menuItems: MenuItemProps[] = [
        {
            icono: MdAddCircle,
            label: 'Agregar nuevo',
            onClick: () => navigate('/admin/lotes-semillas/nuevo')
        }
    ];

    const columnHelper = createColumnHelper<Campana>();
    const columns = [
        columnHelper.accessor('nombre', {
            id: 'nombre',
            header: crearHeader('Nombre'),
            cell: crearCelda((info: CellContext<any, any>) => {
                return (
                    <Text
                        cursor="pointer"
                        color={brandColor}
                        textDecoration="none"
                        fontSize="md"
                        fontWeight="500"
                        onClick={() => navigate(`/admin/campanas/ver/${info.row.original.id}`)}
                    >
                        {info.row.original.nombre}
                    </Text>
                );
            })
        }),
        columnHelper.accessor('estado', {
            id: 'estado',
            header: crearHeader('estado'),
            cell: crearCelda((info: CellContext<any, any>) => {
                switch (info.row.original.estado) {
                    case 'activa':
                        return <Tag colorScheme={'green'}>activa</Tag>;
                    case 'draft':
                        return <Tag colorScheme={'yellow'}>draft</Tag>;
                    case 'finalizada':
                        return <Tag colorScheme={'blue'}>finalizada</Tag>;
                    default:
                        return <Tag>info.row.original.estado</Tag>;
                }
            })
        }),
        columnHelper.accessor('fecha_inicio', {
            id: 'fecha_inicio',
            header: crearHeader('Fecha Inicio'),
            cell: crearCelda()
        }),
        columnHelper.accessor('fecha_fin', {
            id: 'fecha_fin',
            header: crearHeader('Fecha Fin'),
            cell: crearCelda()
        })
    ];

    if (isLoading) {
        return (
            <>
                <Skeleton height={'200px'} mb={'20px'} />
            </>
        );
    }

    return (
        <Card w={'100%'}>
            <Flex justify="space-between" align="center">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Campañas productivas
                </Text>
            </Flex>

            <Listado
                tableData={campanasData}
                menuItems={menuItems}
                columns={columns}
                hideSearch={true}
            />
        </Card>
    );
}
