// AppContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CannavaApiService from '../utils/CannavaApiService';
import routes from '../routes';
// @ts-ignore
import TagManager from 'react-gtm-module';
import packageJson from '../../package.json';

type FlashType = 'info' | 'warning' | 'success' | 'error' | 'loading';

type AppState = {
    authToken: string | null;
    backendUserData: any;
    flashMessage: string | null;
    flashType: FlashType | null;
};

type AppActions = {
    setAuthToken: React.Dispatch<React.SetStateAction<string | null>>;
    setBackendUserData: React.Dispatch<React.SetStateAction<any>>;
    setFlashMessage: React.Dispatch<React.SetStateAction<string | null>>;
    setFlashType: React.Dispatch<React.SetStateAction<string | null>>;
};

type AppProviderProps = {
    children: React.ReactNode;
};

type AppContextType = {
    authToken: string | null;
    setAuthToken: React.Dispatch<React.SetStateAction<string | null>>;
    backendUserData: any;
    setBackendUserData: React.Dispatch<React.SetStateAction<any>>;
    flashMessage: string | null;
    setFlashMessage: React.Dispatch<React.SetStateAction<string | null>>;
    flashType: FlashType | null;
    setFlashType: React.Dispatch<React.SetStateAction<string | null>>;
    menuRoutes: any | null;
    setMenuRoutes: React.Dispatch<React.SetStateAction<any | null>>;
    appVersion: string | null;
    dataLayerData: any | null;
    setDataLayerData: React.Dispatch<React.SetStateAction<any | null>>;
    tieneAccesoAArea: (areas: any[]) => boolean;
};

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
    const [authToken, setAuthToken] = useState<string | null>(null);
    const [backendUserData, setBackendUserData] = useState<any | null>(null);
    const [flashMessage, setFlashMessage] = useState(null);
    const [flashType, setFlashType] = useState<FlashType | null>(null);
    const [menuRoutes, setMenuRoutes] = useState<any | null>(null);
    const [dataLayerData, setDataLayerData] = useState<any | null>(null);

    const appVersion = packageJson.version;

    // Inicializa otras variables de estado aquí
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchToken = async () => {
            if (authToken !== null) {
                return;
            }

            if (isAuthenticated) {
                const accessToken = await getAccessTokenSilently();
                setAuthToken(accessToken);

                if (backendUserData === null) {
                    CannavaApiService.request(
                        'POST',
                        '/auth/callback',
                        user,
                        accessToken,
                        data => {
                            // console.log(data);
                            // console.log('data usuario: ', data);
                            setBackendUserData(data);

                            let newDatalayer: any = {
                                dataLayer: {
                                    userId: data.userId
                                }
                            };

                            setDataLayerData(newDatalayer);
                            newDatalayer.dataLayer.event = 'login';
                            TagManager.dataLayer(newDatalayer);

                            let rutas = procesarRutasPorRolYArea(data);
                            setMenuRoutes(rutas);
                        },
                        error => {
                            throw new Error(`HTTP error! Status: ${error}`);
                        }
                    );
                }

                // console.log("seteo auth token: " + accessToken);
            }
        };

        fetchToken();
    }, [isAuthenticated, getAccessTokenSilently]);

    const filtrarSubmenues = (routes: any, data: any, i: number) => {
        const route = routes[i];
        if (!data.roles.includes('super-administrador') && route.items) {
            const filtroPorRol = route.items.flatMap((item: any) => {
                const requiredRole = item.component.props.requiredRole;
                if (requiredRole) {
                    const roles = Array.isArray(requiredRole) ? requiredRole : [requiredRole];
                    if (roles.includes(data.roles)) {
                        return item;
                    }
                } else {
                    return item;
                }
                return [];
            });

            const itemsVisibles = filtroPorRol.flatMap((item: any) => {
                const requiredArea = item.component.props.requiredArea;
                if (requiredArea) {
                    const areas = Array.isArray(requiredArea) ? requiredArea : [requiredArea];
                    if (
                        areas.some((area: string) =>
                            data.areas.some((userArea: { slug: string }) => userArea.slug === area)
                        )
                    ) {
                        return item;
                    }
                }
                return [];
            });
            route.items = itemsVisibles;
        }
        return route;
    };

    const procesarRutasPorRolYArea = (data: any) => {
        let rol = data.roles;
        let areas = data.areas;

        // console.log("rol: " + rol);
        let rutas = [];
        for (let i = 0; i < routes.length; i++) {
            // aca tenemos que hacer el chequeo de roles y áreas
            if ('rol' in routes[i] || 'areas' in routes[i]) {
                // console.log(routes[i]);
                let agregar = false;

                if (rol.includes('super-administrador')) {
                    agregar = true;
                } else {
                    if (!('rol' in routes[i]) || routes[i].rol.includes(rol)) {
                        agregar = true;
                    }

                    if ('areas' in routes[i] && agregar) {
                        // console.log('chequeo de áreas', routes[i].areas);
                        console.log('Rutas', routes);
                        console.log('Ruta', routes[i]);
                        console.log('Areas', backendUserData ? backendUserData.areas : data.areas);
                        agregar = tieneAccesoAArea(routes[i].areas, data.areas);
                    }
                }

                if (agregar) {
                    // Filtro por Area y rol los submenues
                    const route = filtrarSubmenues(routes, data, i);
                    rutas.push(route);
                }
            } else {
                // Filtro por Area y rol los submenues
                const route = filtrarSubmenues(routes, data, i);
                rutas.push(route);
            }
        }

        return rutas;
    };

    const tieneAccesoAArea = (areas: any[], areasUsuario: any[] = null) => {
        if (backendUserData === null && areasUsuario === null) {
            return false;
        }

        areasUsuario = backendUserData ? backendUserData.areas : areasUsuario;
        console.log(areas);
        //console.log(areasUsuario);

        for (let j = 0; j < areasUsuario.length; j++) {
            console.log('comparando: ' + areasUsuario[j].slug);
            if (areas.includes(areasUsuario[j].slug)) {
                console.log('tiene acceso a area: ' + areasUsuario[j].slug);
                return true;
            }
        }

        return false;
    };

    const value = {
        authToken: authToken,
        backendUserData: backendUserData,
        flashMessage: flashMessage,
        flashType: flashType,
        menuRoutes: menuRoutes,
        appVersion: appVersion,
        dataLayerData: dataLayerData,
        // Otras variables de estado
        setAuthToken: setAuthToken,
        setBackendUserData: setBackendUserData,
        setFlashMessage: setFlashMessage,
        setFlashType: setFlashType,
        setMenuRoutes: setMenuRoutes,
        setDataLayerData: setDataLayerData,
        tieneAccesoAArea: tieneAccesoAArea
    };

    // @ts-ignore
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext debe ser usado dentro de un AppProvider');
    }
    return context;
};
