// Chakra imports
import { Flex, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';

import SearchTableLotesGerminacion from './components/SearchTableLotesGerminacion';
import React, { useEffect, useState } from 'react';
import { useLayoutContext } from 'contexts/LayoutContext';
import { LotePlantaProductiva } from 'modules/trazabilidad/types/LotePlantaProductiva';
import { TrasplanteLPP } from 'modules/trazabilidad/types/TrasplanteLPP';
import { useFetchData } from 'hooks/useFetchData';
import PageLoader from 'components/actions/PageLoader';
import { MenuItemProps } from 'types/MenuItemProps';
import { MdAddCircle } from 'react-icons/md';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import Listado, { crearCelda, crearCeldaConLink, crearHeader } from 'components/cannava/listado';
import { useAppContext } from 'contexts/AppContext';
import { useAutenticacionUsuario } from '../../../../auth/hooks/useAutenticacionUsuario';

export default function LotesPlantasProductivas() {
    // region Variables

    const { backendUserData } = useAppContext();

    const transformLote = (data: any[]): LotePlantaProductiva[] => {
        return data.map(lote => ({
            id: lote.id,
            codigo_lote: lote.codigo_lote,
            genetica_id: lote.genetica_id,
            fecha_inicio_siembra: lote.fecha_inicio_siembra,
            fecha_fin_siembra: lote.fecha_fin_siembra,
            estado: lote.estado,
            actions: lote.actions,
            creado_por: lote.creado_por,
            actualizado_por: lote.actualizado_por,
            fecha_creacion: lote.fecha_creacion,
            fecha_actualizacion: lote.fecha_actualizacion,
            fecha_borrado: lote.fecha_borrado
        }));

        /* return data.map(lote => ({
            id: lote.id,
            lote_productivo_id: lote.lote_productivo_id,
            locacion_id: lote.locacion_id,
            cantidad_plantas: lote.cantidad_plantas,
            fecha_inicio_trasplante: lote.fecha_inicio_trasplante,
            verificacion_limpieza_y_desinfeccion: lote.verificacion_limpieza_y_desinfeccion,
            observaciones_verificacion_limpieza_y_desinfeccion:
                lote.observaciones_verificacion_limpieza_y_desinfeccion,
            verificacion_vestimenta_operador: lote.verificacion_vestimenta_operador,
            verificacion_sistema_iluminacion: lote.verificacion_sistema_iluminacion,
            numero_orden_solucion_nutritiva: lote.numero_orden_solucion_nutritiva,
            verificacion_riego_con_solucion: lote.verificacion_riego_con_solucion,
            va_a_macrotunel: lote.va_a_macrotunel,
            fecha_creacion: lote.fecha_creacion,
            fecha_actualizacion: lote.fecha_actualizacion,
            fecha_borrado: lote.fecha_borrado,
            creado_por: lote.creado_por,
            actualizado_por: lote.actualizado_por,
            tipo_trasplante: lote.tipo_trasplante,
            fecha_fin_trasplante: lote.fecha_fin_trasplante,
            estado: lote.estado,
            descarte: lote.descarte,
            observaciones: lote.observaciones,
            datos_tipo_trasplante: {
                id: lote.datos_tipo_trasplante.id,
                trasplante_lote_productivo_id:
                    lote.datos_tipo_trasplante.trasplante_lote_productivo_id,
                verificacion_plantines_en_maceta:
                    lote.datos_tipo_trasplante.verificacion_plantines_en_maceta,
                observaciones_verificacion_plantines_en_maceta:
                    lote.datos_tipo_trasplante.observaciones_verificacion_plantines_en_maceta,
                verificacion_sustrato_humedecido:
                    lote.datos_tipo_trasplante.verificacion_sustrato_humedecido,
                verificacion_maceta_rellena: lote.datos_tipo_trasplante.verificacion_maceta_rellena,
                fecha_creacion: lote.datos_tipo_trasplante.fecha_creacion,
                fecha_actualizacion: lote.datos_tipo_trasplante.fecha_actualizacion,
                fecha_borrado: lote.datos_tipo_trasplante.fecha_borrado,
                creado_por: lote.datos_tipo_trasplante.creado_por,
                actualizado_por: lote.datos_tipo_trasplante.actualizado_por
            },
            codigo_lote: lote.codigo_lote
        })); */
    };

    const { validarAutenticacion, error } = useAutenticacionUsuario();

    //arreglar con endpoint para cargar todos los LPP
    const { data: lotesData, isLoading } = useFetchData<LotePlantaProductiva>(
        'lotesPlantaProductiva',
        transformLote
    );

    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const navigate = useNavigate();

    const [menuItems, setMenuItems] = useState<MenuItemProps[]>([]);

    const columnHelper = createColumnHelper<LotePlantaProductiva>();
    const columns = [
        columnHelper.accessor('codigo_lote', {
            id: 'codigo_lote',
            header: crearHeader('Lote'),
            cell: crearCelda()
        }),
        /* columnHelper.accessor('cantidad_plantas', {
            id: 'cantidad_plantas',
            header: crearHeader('Cantidad plantas'),
            cell: crearCelda()
        }),
        columnHelper.accessor('tipo_trasplante', {
            id: 'tipo_trasplante',
            header: crearHeader('Tipo trasplante'),
            cell: crearCelda()
        }), */
        columnHelper.accessor('estado', {
            id: 'estado',
            header: crearHeader('Estado'),
            cell: crearCelda()
        }),
        columnHelper.accessor('fecha_creacion', {
            id: 'fecha_creacion',
            header: crearHeader('Fecha de trasplante'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/lotes-plantas-productivas/ver', 'Ver lote', navigate)
        })
    ];

    // endregion Variables

    // region Hooks y callbacks

    // endregion Hooks y callbacks

    // region useEffects

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Trazabilidad', href: '/admin/trazabilidad/' },
            { label: 'Lotes de germinación', href: '/admin/lotes-germinacion' }
        ]);

        setTitle('Lotes de plantas productivas');
    }, []);

    useEffect(() => {
        if (!backendUserData) return;

        if (validarAutenticacion(null, 'campo')) {
            setMenuItems([
                {
                    icono: MdAddCircle,
                    label: 'Agregar nuevo',
                    onClick: () => navigate('/admin/lotes-plantas-productivas/nuevo')
                }
            ]);
        }
    }, [backendUserData]);

    // endregion

    if (isLoading) {
        return <PageLoader message={'Cargando lotes'} />;
    }

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Card px="0px">
                <Listado tableData={lotesData} menuItems={menuItems} columns={columns} />
            </Card>
        </Flex>
    );
}
