// Chakra imports
import {
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
// Custom components

export default function Default(props: {
    id?: string;
    label?: string;
    extra?: JSX.Element;
    placeholder?: string;
    type?: string;
    validationRules?: any;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void; // Agrega esta línea
    [x: string]: any;
}) {
    const { id, label, extra, placeholder, type, mb, validationRules, onBlur, ...rest } = props;
    const {
        register,
        formState: { errors },
        control
    } = useFormContext(); // retrieve all hook methods
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    return (
        <Flex direction="column" mb={mb ? mb : '30px'}>
            <FormControl isInvalid={id in errors}>
                <FormLabel
                    display="flex"
                    ms="10px"
                    fontSize="sm"
                    color={textColorPrimary}
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                    htmlFor={id}
                >
                    {label}
                </FormLabel>
                <Controller
                    name={id}
                    control={control} // control viene de useFormContext()
                    rules={validationRules}
                    defaultValue=""
                    render={({ field }) => (
                        <Input
                            {...field}
                            id={id}
                            placeholder={placeholder}
                            fontWeight="500"
                            variant="main"
                            _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                            h="44px"
                            maxH="44px"
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                field.onBlur(); // Llama al onBlur original del Controller
                                if (onBlur) onBlur(e); // Llama a tu manejador de onBlur personalizado
                            }}
                        />
                    )}
                />
                <Text fontSize="sm" fontWeight="400" ms="2px">
                    {extra}
                </Text>
                <FormErrorMessage>{errors[id] && (errors as any)[id].message}</FormErrorMessage>
            </FormControl>
        </Flex>
    );
}
