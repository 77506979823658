import { extendTheme, HTMLChakraProps, ThemingProps } from '@chakra-ui/react';
import { CardComponent } from './additions/card/card';
import { buttonStyles } from './components/button';
import { badgeStyles } from './components/badge';
import { inputStyles } from './components/input';
import { progressStyles } from './components/progress';
import { sliderStyles } from './components/slider';
import { textareaStyles } from './components/textarea';
import { switchStyles } from './components/switch';
import { linkStyles } from './components/link';
import { breakpoints } from './foundations/breakpoints';
import { globalStyles } from './styles';
// import { globalStyles as globalStylesRed } from './stylesRed';

let estilos = globalStyles;
estilos.colors.brand = {
    50: '#E1FFF4',
    100: '#BDFFE7',
    200: '#7BFECE',
    300: '#39FEB6',
    400: '#01F99E',
    500: '#01B574',
    600: '#01935D',
    700: '#016B44',
    800: '#00472D',
    900: '#002417'
};

estilos.colors.brandScheme = {
    50: '#E1FFF4',
    100: '#BDFFE7',
    200: '#7BFECE',
    300: '#39FEB6',
    400: '#01F99E',
    500: '#01B574',
    600: '#01935D',
    700: '#016B44',
    800: '#00472D',
    900: '#002417'
};

estilos.colors.brandTabs = {
    50: '#E1FFF4',
    100: '#BDFFE7',
    200: '#7BFECE',
    300: '#39FEB6',
    400: '#01F99E',
    500: '#01B574',
    600: '#01935D',
    700: '#016B44',
    800: '#00472D',
    900: '#002417'
};

let theme = extendTheme(
    { breakpoints }, // Breakpoints
    estilos, // Global styles
    badgeStyles, // badge styles
    buttonStyles, // button styles
    linkStyles, // link styles
    progressStyles, // progress styles
    sliderStyles, // slider styles
    inputStyles, // input styles
    textareaStyles, // textarea styles
    switchStyles, // switch styles
    CardComponent // card component
);

export default theme;

export interface CustomCardProps extends HTMLChakraProps<'div'>, ThemingProps {}
