// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import React, { useCallback, useEffect, useState } from 'react';
import { Variedad } from '../../../../genetica/types/Variedad';
import { useLayoutContext } from '../../../../../contexts/LayoutContext';
import { useFetchData } from '../../../../../hooks/useFetchData';
import PageLoader from '../../../../../components/actions/PageLoader';
import { MenuItemProps } from '../../../../../types/MenuItemProps';
import { MdAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import Listado, {
    crearCelda,
    crearCeldaConLink,
    crearHeader
} from '../../../../../components/cannava/listado';
import { Producto } from '../../../types/Producto';
import { useConfigurarNavegacion } from '../../../../../hooks/useConfigurarNavegacion';

export default function ProductosIndex() {
    const transformData = (data: any[]): Producto[] => {
        return data.map(data => ({
            id: data.id,
            nombre: data.nombre,
            slug: data.slug,
            genetica_id: data.genetica_id,
            tipo_producto_id: data.tipo_producto_id,
            descripcion: data.descripcion,
            json_data: data.json_data,
            fecha_creacion: data.fecha_creacion,
            fecha_actualizacion: data.fecha_actualizacion,
            fecha_borrado: data.fecha_borrado,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };

    const { data: variedadesData, isLoading } = useFetchData<Producto>('productos', transformData);
    const navigate = useNavigate();

    // configuro layout
    const configurarNavegacion = useConfigurarNavegacion({
        breadcrumbs: [
            { label: 'Inicio', href: '/' },
            { label: 'Catálogo', href: '/admin/home/' }
        ],
        titulo: 'Productos'
    });

    if (isLoading) {
        return <PageLoader message={'Cargando productos'} />;
    }

    const menuItems: MenuItemProps[] = [
        {
            icono: MdAddCircle,
            label: 'Agregar nuevo',
            onClick: () => navigate('/admin/productos/nuevo')
        }
    ];

    const columnHelper = createColumnHelper<Variedad>();
    const columns = [
        columnHelper.accessor('nombre', {
            id: 'nombre',
            header: crearHeader('Variedad'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('ID'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'action_editar',
            header: crearHeader('Editar'),
            cell: crearCeldaConLink('/admin/productos/editar', 'Editar', navigate)
        }),
        columnHelper.accessor('id', {
            id: 'actions',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/productos/ver', 'Ver producto', navigate)
        })
    ];

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Card px="0px">
                <Listado tableData={variedadesData} menuItems={menuItems} columns={columns} />
            </Card>
        </Flex>
    );
}
