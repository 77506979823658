import React from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Text,
    useDisclosure
} from '@chakra-ui/react';

interface DangerZoneProps {
    onConfirmDelete: () => void;
}

const DangerZone: React.FC<DangerZoneProps> = ({ onConfirmDelete }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();

    const handleConfirmDelete = () => {
        onConfirmDelete();
        onClose();
    };

    return (
        <Box
            mt={8}
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p={5}
            borderWidth="1px"
            borderRadius="lg"
            bg="red.50"
        >
            <Text fontWeight="bold" color="red.600">
                Danger Zone
            </Text>
            <Text mt={2} color="red.600">
                Acciones irreversibles. Procede con precaución.
            </Text>
            <Button mt={4} colorScheme="red" onClick={onOpen}>
                Eliminar Usuario
            </Button>

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Eliminar Usuario
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            ¿Estás seguro de que deseas eliminar a este usuario? Esta acción es
                            irreversible.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancelar
                            </Button>
                            <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                                Eliminar
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    );
};

export default DangerZone;
