// chakra imports
import { Icon, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { MdUpgrade } from 'react-icons/md';

type ItemContentProps = {
    info: string;
    detalle?: string;
    leido?: number;
};

export function ItemContent(props: ItemContentProps) {
    const textColor = useColorModeValue('navy.700', 'white');
    return (
        <>
            <Flex
                justify="center"
                align="center"
                borderRadius="16px"
                minH={{ base: '60px', md: '70px' }}
                h={{ base: '60px', md: '70px' }}
                minW={{ base: '60px', md: '70px' }}
                w={{ base: '60px', md: '70px' }}
                me="14px"
                bgGradient="linear(to-b, brand.400, brand.600)"
            >
                <Icon as={MdUpgrade} color="white" w={8} h={14} />
            </Flex>
            <Flex flexDirection="column">
                <Text
                    mb="5px"
                    fontWeight={!props.leido ? 'bold' : 'normal'}
                    color={textColor}
                    fontSize={{ base: 'md', md: 'md' }}
                >
                    {props.info}
                </Text>
                {props.detalle && (
                    <Flex alignItems="center">
                        <Text
                            fontSize={{ base: 'sm', md: 'sm' }}
                            lineHeight="100%"
                            color={textColor}
                        >
                            {props.detalle}
                        </Text>
                    </Flex>
                )}
            </Flex>
        </>
    );
}
