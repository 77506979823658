// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    useColorModeValue,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useDisclosure,
    Switch
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import Index from 'components/cannava/dangerZone';
import { useLayoutContext } from 'contexts/LayoutContext';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import { Proveedor } from 'modules/genetica/types/Proveedor';
import { useFetchData } from 'hooks/useFetchData';
import { Variedad } from 'modules/genetica/types/Variedad';
import { useEnvioFormularioAntiguo } from 'hooks/useEnvioFormularioAntiguo';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import CannavaTextAreaField from 'components/fields/CannavaTextareaField';

/* type UserFormData = {
    nombre: string;
    apellido: string;
    email: string;
    nombre_usuario: string;
    rol: string;
    fecha_nacimiento: string;
}; */

type ControlLoteData = {
    lote_padre_id: string;
    bioInsumos: boolean;
    observaciones: string;
    inspeccionVisual: boolean;
    hora_inicial: string;
    hora_final: string;
    bandejas_armadas: number;
    semillas_usadas: number;
    operarios: number;
};

export default function ControlLote() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const isEditMode = !!id;
    const { setBreadcrumbs, setTitle } = useLayoutContext();

    const [isDeleting, setIsDeleting] = useState(false);

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = useState({
        general: true,
        permissions: false
    });

    const navigate = useNavigate();
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);
    const [cargandoData, setCargandoData] = useState(false);

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const generalTab = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
    const permissionsTab = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'loteGerminacion',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/lotes-germinacion');
        },
        onError: error => {
            console.log(error);
            //generalTab.current.click();
        },
        onErrorDelete: error => {
            navigate('/admin/lotes-germinacion');
        }
    });

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Trazabilidad', href: '/admin/trazabilidad/' },
            { label: 'Lotes de germinacion', href: '/admin/lotes-germinacion' }
            /* Añadir para volver al lote  { label: 'Lotes de germinacion', href: '/admin/lotes-germinacion/:id' } */
        ]);

        setTitle('Control del lote');
    }, []);

    /* useEffect(() => {
        if (!lotesData) {
            return;
        }

        let opciones: OpcionesSelect = {
            lotesSemillas: lotesData,
            tipoLote: [{ nombre: 'lote germinación' }]
        };

        setOpcionesSelect(opciones);
    }, [lotesData]);

    useEffect(() => {
        if (!opcionesSelect) {
            return;
        }

        if (id) {
            useEnvioFormulario1.cargarFormulario(id);
        } else {
            useEnvioFormulario1.setIsLoading(false);
        }
    }, [opcionesSelect]); */

    const onSubmit: SubmitHandler<ControlLoteData> = (data: ControlLoteData) => {
        //useEnvioFormulario1.envioFormularioCrear(data);
        console.log(data);
    };

    const onCancel: any = () => {
        navigate('/admin/lotes-germinacion');
    };

    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            <Flex
                direction="column"
                minH="100vh"
                align="center"
                pt={{ sm: '125px', lg: '75px' }}
                position="relative"
            >
                <Box
                    h="45vh"
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    position="absolute"
                    w="100%"
                    borderRadius="20px"
                />

                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Tabs
                            variant="unstyled"
                            zIndex="0"
                            mt={{ base: '60px', md: '165px' }}
                            display="flex"
                            flexDirection="column"
                        >
                            <TabList
                                display="flex"
                                alignItems="center"
                                alignSelf="center"
                                justifySelf="center"
                            >
                                <Tab
                                    _focus={{ border: '0px', boxShadow: 'unset' }}
                                    ref={generalTab}
                                    w={{ sm: '120px', md: '250px', lg: '300px' }}
                                    onClick={() =>
                                        setActiveBullets({
                                            general: true,
                                            permissions: false
                                        })
                                    }
                                ></Tab>
                                <Tab
                                    _focus={{ border: '0px', boxShadow: 'unset' }}
                                    ref={permissionsTab}
                                    w={{ sm: '120px', md: '250px', lg: '300px' }}
                                    onClick={() =>
                                        setActiveBullets({
                                            general: true,
                                            permissions: true
                                        })
                                    }
                                ></Tab>
                            </TabList>
                            <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
                                <TabPanel
                                    w={{ sm: '330px', md: '700px', lg: '850px' }}
                                    p="0px"
                                    mx="auto"
                                >
                                    <Card p="30px" mt={'60px'}>
                                        <Text
                                            color={textColor}
                                            fontSize="2xl"
                                            fontWeight="700"
                                            mb="20px"
                                        >
                                            Preparación de bandejas y semillado
                                        </Text>
                                        {errorMessage && (
                                            <Alert status="error">
                                                <AlertIcon />
                                                <AlertTitle>{errorMessage}</AlertTitle>
                                            </Alert>
                                        )}
                                        <Flex direction="column" w="100%">
                                            <Stack direction="column" spacing="20px">
                                                <SimpleGrid gap="20px">
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaInputField
                                                            id={'hora_inicial'}
                                                            label={'Hora Inicial'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'hora_final'}
                                                            label={'Hora Final'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>

                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 3 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaInputField
                                                            id={'bandejas_armadas'}
                                                            label={'Nº de bandejas armadas (u.)'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'semillas_usadas'}
                                                            label={'Nº de semillas usadas (u.)'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'operarios'}
                                                            label={'Nº de operarios (u.)'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                </SimpleGrid>
                                            </Stack>
                                            <Flex justify="space-between" mt="24px">
                                                <Button
                                                    variant="outline"
                                                    fontSize="sm"
                                                    borderRadius="16px"
                                                    w={{ base: '128px', md: '148px' }}
                                                    h="46px"
                                                    //navegar a lote
                                                    onClick={onCancel}
                                                    type="button"
                                                >
                                                    Cancelar
                                                </Button>
                                                <Button
                                                    variant="darkBrand"
                                                    fontSize="sm"
                                                    borderRadius="16px"
                                                    w={{ base: '128px', md: '148px' }}
                                                    h="46px"
                                                    onClick={() => {
                                                        permissionsTab.current.click();
                                                    }}
                                                >
                                                    Continuar
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Card>
                                </TabPanel>
                                <TabPanel
                                    w={{ sm: '330px', md: '700px', lg: '850px' }}
                                    p="0px"
                                    mx="auto"
                                >
                                    <Card p="30px" mt={'60px'}>
                                        <Text
                                            color={textColor}
                                            fontSize="2xl"
                                            fontWeight="700"
                                            mb="20px"
                                        >
                                            Preparación de bandejas y semillado
                                        </Text>
                                        {errorMessage && (
                                            <Alert status="error">
                                                <AlertIcon />
                                                <AlertTitle>{errorMessage}</AlertTitle>
                                            </Alert>
                                        )}
                                        <Flex direction="column" w="100%">
                                            <Stack direction="column" spacing="20px">
                                                <SimpleGrid gap="20px">
                                                    <FormControl
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        <FormLabel
                                                            mb="0"
                                                            fontSize="sm"
                                                            color={textColor}
                                                            ms="10px"
                                                            fontWeight="bold"
                                                        >
                                                            ¿Se realizaron aplicaciones de bio
                                                            insumos?
                                                        </FormLabel>
                                                        <Switch
                                                            {...methods.register('bioInsumos')}
                                                            id="bioInsumos"
                                                        />
                                                    </FormControl>

                                                    <FormControl
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        <FormLabel
                                                            mb="0"
                                                            fontSize="sm"
                                                            color={textColor}
                                                            ms="10px"
                                                            fontWeight="bold"
                                                        >
                                                            ¿Se realizo la inspección visual para
                                                            verificar que todas las bandejas fueron
                                                            sembradas correctamente?
                                                        </FormLabel>
                                                        <Switch
                                                            {...methods.register(
                                                                'inspeccionVisual'
                                                            )}
                                                            id="inspeccionVisual"
                                                        />
                                                    </FormControl>

                                                    <CannavaTextAreaField
                                                        id={'obsevaciones'}
                                                        label={'Observaciones generales'}
                                                        placeholder={
                                                            '¿Tenés alguna observación para agregar?'
                                                        }
                                                        validationRules={{
                                                            required: 'Campo obligatorio'
                                                        }}
                                                        mb="10px"
                                                    />
                                                </SimpleGrid>
                                            </Stack>
                                            <Flex justify="space-between" mt="24px">
                                                <Button
                                                    variant="outline"
                                                    fontSize="sm"
                                                    borderRadius="16px"
                                                    w={{ base: '128px', md: '148px' }}
                                                    h="46px"
                                                    //navegar a lote
                                                    onClick={onCancel}
                                                    type="button"
                                                >
                                                    Cancelar
                                                </Button>
                                                <Button
                                                    variant="darkBrand"
                                                    fontSize="sm"
                                                    borderRadius="16px"
                                                    w={{ base: '128px', md: '148px' }}
                                                    h="46px"
                                                    isLoading={methods.formState.isSubmitting}
                                                    type="submit"
                                                >
                                                    Continuar
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Card>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </form>
                </FormProvider>
            </Flex>
        </>
    );
}
