import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import {
    Alert,
    Box,
    Flex,
    SimpleGrid,
    Text,
    useColorModeValue,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    Link,
    Icon,
    // modal
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    Image,
    Button
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import React, { useState } from 'react';
import { LotePoderGerminativo } from 'modules/trazabilidad/types/LotePoderGerminativo';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { HSeparator } from 'components/separator/Separator';

type Props = {
    loteData: LoteSemillas;
    lotePoderGerminativo: LotePoderGerminativo;
};

export default function Anexos(props: Props) {
    const { loteData, lotePoderGerminativo } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [imagenUrl, setImagenUrl] = useState('');

    const abrirModalConImagen = (url: string) => {
        setImagenUrl(url);
        onOpen();
    };

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Anexos
                </Text>
            </Flex>

            <Table variant="simple" color="gray.500" p={'0px'}>
                {/*<Thead>
                    <Tr>
                        <Td>Nombre</Td>
                        <Td>Link</Td>
                    </Tr>
                </Thead>*/}
                <Tbody>
                    {lotePoderGerminativo.reportePoderGerminativo.anexos.map(
                        (anexo: any, index: any) => (
                            <Tr key={anexo.id}>
                                <Td
                                    textAlign={'left'}
                                    fontSize={{ sm: '14px' }}
                                    borderColor={borderColor}
                                >
                                    {anexo.archivo.archivo_nombre}
                                </Td>
                                <Td
                                    textAlign={'right'}
                                    fontSize={{ sm: '14px' }}
                                    borderColor={borderColor}
                                >
                                    <Button
                                        size={'sm'}
                                        onClick={() => abrirModalConImagen(anexo.archivo.url)}
                                    >
                                        <Icon as={FaExternalLinkAlt} mr={'5px'} />
                                        Ver imagen
                                    </Button>
                                </Td>
                            </Tr>
                        )
                    )}
                </Tbody>
            </Table>

            {lotePoderGerminativo.reportePoderGerminativo.observaciones_anexos && (
                <Box mt={'20px'}>
                    <Text fontSize="md" color={textColorPrimary} fontWeight="bold">
                        Observaciones
                    </Text>
                    <Text>{lotePoderGerminativo.reportePoderGerminativo.observaciones_anexos}</Text>
                </Box>
            )}

            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Imagen</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image src={imagenUrl} alt="Imagen Anexo" />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Card>
    );
}

type FilaInfoRegistroProps = {
    label: string;
    value: string | number;
};

export function FilaInfoRegistro(props: FilaInfoRegistroProps) {
    const { label, value } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColor = useColorModeValue('gray.700', 'white');
    const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');

    return (
        <Flex justifyContent="space-between" alignItems="center" w="100%" my={'10px'}>
            <Text color={textColor} fontSize="md" me="6px" fontWeight="500">
                {label}
            </Text>
            <Flex align="center" ms="auto">
                <Text color="secondaryGray.600" fontWeight="400" me="10px">
                    {value}
                </Text>
            </Flex>
        </Flex>
    );
}
