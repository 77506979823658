import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import { Flex, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import React from 'react';
import { LotePoderGerminativo } from 'modules/trazabilidad/types/LotePoderGerminativo';

type Props = {
    loteData: LoteSemillas;
    lotePoderGerminativo: LotePoderGerminativo;
};

export default function EvaluacionViabilidad(props: Props) {
    const { loteData, lotePoderGerminativo } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Evaluación de viabilidad
                </Text>
            </Flex>
            <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing={{ base: '20px', xl: '5px' }}>
                <FilaInfoRegistro label={'Uso Lote'} value={lotePoderGerminativo.uso_lote} />
                <FilaInfoRegistro
                    label={'Temperatura'}
                    value={lotePoderGerminativo.reportePoderGerminativo.temperatura}
                />
                <FilaInfoRegistro
                    label={'Humedad relativa'}
                    value={lotePoderGerminativo.reportePoderGerminativo.humedad_relativa}
                />
                <FilaInfoRegistro
                    label={'Método'}
                    value={lotePoderGerminativo.reportePoderGerminativo.metodo}
                />
                <FilaInfoRegistro
                    label={'Diseño'}
                    value={lotePoderGerminativo.reportePoderGerminativo.tamanio_muestra}
                />
            </SimpleGrid>
        </Card>
    );
}

type FilaInfoRegistroProps = {
    label: string;
    value: string | number;
};

export function FilaInfoRegistro(props: FilaInfoRegistroProps) {
    const { label, value } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColor = useColorModeValue('gray.700', 'white');
    const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');

    return (
        <Flex justifyContent="space-between" alignItems="center" w="100%" my={'10px'}>
            <Text color={textColor} fontSize="md" me="6px" fontWeight="500">
                {label}
            </Text>
            <Flex align="center" ms="auto">
                <Text color="secondaryGray.600" fontWeight="400" me="10px">
                    {value}
                </Text>
            </Flex>
        </Flex>
    );
}
