import { Grid, GridItem, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export type FilaInfoDetalleLimpiezaProps = {
    detallesLimpieza: {
        fecha: string;
        hora: string;
        personal: string;
        encargado: string;
        productos: string;
    };
    loteId: string;
};

export function FilaInfoDetalleLimpieza(props: FilaInfoDetalleLimpiezaProps) {
    const { detallesLimpieza, loteId } = props;

    return (
        <>
            <Grid
                templateColumns={{ base: '18% 18% 18% 18% 18% 10%', md: '18% 18% 18% 18% 18% 10%' }}
                gap={0}
                height={10}
                justifyItems="start"
                alignItems="center"
            >
                <GridItem fontSize={15} fontWeight="medium" textAlign="center">
                    {detallesLimpieza.fecha}
                </GridItem>
                <GridItem fontSize={15} fontWeight="medium" textAlign="center">
                    {detallesLimpieza.hora}
                </GridItem>
                <GridItem fontSize={15} fontWeight="medium" textAlign="center">
                    {detallesLimpieza.personal}
                </GridItem>
                <GridItem fontSize={15} fontWeight="medium" textAlign="center">
                    {detallesLimpieza.encargado}
                </GridItem>
                <GridItem fontSize={15} fontWeight="medium" textAlign="center">
                    {detallesLimpieza.productos}
                </GridItem>
                <GridItem fontSize={15} fontWeight="medium" textAlign="center">
                    <Link
                        as={RouterLink}
                        color="blue.400"
                        fontWeight="400"
                        me="10px"
                        to={'/admin/lotes-germinacion/limpieza-area/' + loteId}
                        state={{ detalles: detallesLimpieza }}
                    >
                        Editar
                    </Link>
                </GridItem>
            </Grid>
        </>
    );
}
