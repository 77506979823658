// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    useColorModeValue,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
    AlertTitle,
    useDisclosure
} from '@chakra-ui/react';
// Custom components
import Card from '../../../../../components/card/Card';
import InputField from '../../../../../components/fields/InputField';
import TextField from '../../../../../components/fields/TextField';
import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from '../../../../../config';
import axios from 'axios';

import { useNavigate, useParams } from 'react-router-dom';
import CannavaApiService from '../../../../../utils/CannavaApiService';
import { useAppContext } from '../../../../../contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from '../../../../../components/fields/CannavaInputField';
import CannavaSelectField from '../../../../../components/fields/CannavaSelectField';
import PageLoader from '../../../../../components/actions/PageLoader';
import DangerZone from '../../../../../components/cannava/dangerZone';
import { Ubicacion } from '../../../../locacion/types/Ubicacion';
import { useLayoutContext } from '../../../../../contexts/LayoutContext';
import { Variedad } from '../../../types/Variedad';
import { Proveedor } from '../../../types/Proveedor';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { useEnvioFormulario } from '../../../../../hooks/useEnvioFormulario';
import { useConfigurarNavegacion } from '../../../../../hooks/useConfigurarNavegacion';

type OpcionesSelect = {
    proveedores: any[];
    cannabinoides: any[];
};

export default function NewVariedad() {
    // inicializacion form, id y estados
    const methods = useForm();
    const { id } = useParams();
    const isEditMode = !!id;
    const [opcionesSelect, setOpcionesSelect] = useState<OpcionesSelect>({
        proveedores: [],
        cannabinoides: []
    });

    const [isDeleting, setIsDeleting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // variables de layout y estilo
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    // helpers
    const navigate = useNavigate();
    const transformProveedores = (data: any[]): Proveedor[] => {
        return data.map(proveedor => ({
            id: proveedor.id,
            nombre: proveedor.nombre,
            codigo: proveedor.codigo,
            slug: proveedor.slug,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };

    const { data: proveedoresData, isLoading } = useFetchData<Proveedor>(
        'proveedores',
        transformProveedores
    );

    const transformCannabinoide = (data: any[]): any[] => {
        return data.map(cannabinoide => ({
            id: cannabinoide.id,
            // @todo: podria llamarse nombre en la base de datos
            nombre: cannabinoide.tipo,
            slug: cannabinoide.slug,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };

    const { data: cannabinoidesData } = useFetchData<any>('cannabinoides', transformCannabinoide);

    // const { data: proveedoresData, isLoading } = useFetchData<Proveedor>('proveedores', transformProveedores);

    // configuro layout
    const configurarNavegacion = useConfigurarNavegacion({
        breadcrumbs: [
            { label: 'Inicio', href: '/' },
            { label: 'Administración', href: '/admin/home/' },
            { label: 'Genéticas', href: '/admin/variedades/' }
        ],
        titulo: isEditMode ? 'Editar genética' : 'Nueva genética'
    });

    useEffect(() => {
        let opciones: OpcionesSelect = {
            proveedores: [],
            cannabinoides: []
        };

        setOpcionesSelect(opcionesSelect);
    }, []);

    useEffect(() => {
        if (!proveedoresData || !cannabinoidesData) {
            return;
        }

        let opciones: OpcionesSelect = {
            proveedores: proveedoresData,
            cannabinoides: cannabinoidesData
        };

        setOpcionesSelect(opciones);
        console.log('opciones select', opciones);
    }, [proveedoresData, cannabinoidesData]);

    // inicializacion hook useEnvioFormulario
    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'genetica',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/variedades');
        },
        onError: error => {
            console.log(error);
        },
        onErrorDelete: error => {
            navigate('/admin/variedades');
        }
    });

    // capturo el envio de formulario y puedo manipularla data para prepararla
    const onSubmit = (data: any) => {
        if (isEditMode) {
            useEnvioFormulario1.envioFormularioActualizar(id, data);
            return;
        }

        data['estado'] = 'draft';
        useEnvioFormulario1.envioFormularioCrear(data);
    };

    // useEffect para precargar el formulario si estamos editando o terminar la carga
    useEffect(() => {
        if (!opcionesSelect) {
            return;
        }

        if (id) {
            useEnvioFormulario1.cargarFormulario(id);
        } else {
            useEnvioFormulario1.setIsLoading(false);
        }
    }, [opcionesSelect]);

    // useEffect para capturar errores del formulario
    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }

        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            // Error callback
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [useEnvioFormulario1.isLoading, methods.formState.submitCount]);

    // si estoy cargando muestro el spinner
    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    if (isLoading) {
        return <PageLoader message="Cargando opciones de formulario..." />;
    }

    return (
        <>
            {!isDeleting && (
                <Flex
                    direction="column"
                    minH="100vh"
                    align="center"
                    pt={{ sm: '125px', lg: '75px' }}
                    position="relative"
                >
                    <Box
                        h="45vh"
                        bgGradient="linear(to-b, brand.400, brand.600)"
                        position="absolute"
                        w="100%"
                        borderRadius="20px"
                    />

                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Tabs
                                variant="unstyled"
                                zIndex="0"
                                mt={{ base: '60px', md: '165px' }}
                                display="flex"
                                flexDirection="column"
                            >
                                <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
                                    <TabPanel
                                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                                        p="0px"
                                        mx="auto"
                                    >
                                        <Card p="30px">
                                            <Text
                                                color={textColor}
                                                fontSize="2xl"
                                                fontWeight="700"
                                                mb="20px"
                                            >
                                                Información general
                                            </Text>
                                            {errorMessage && (
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    <AlertTitle>{errorMessage}</AlertTitle>
                                                </Alert>
                                            )}
                                            <Flex direction="column" w="100%">
                                                <Stack direction="column" spacing="20px">
                                                    <SimpleGrid columns={{ base: 1 }} gap="20px">
                                                        <CannavaInputField
                                                            id={'nombre'}
                                                            label={'Nombre'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaSelectField
                                                            id={'proveedor_id'}
                                                            label={'Proveedor'}
                                                            opciones={opcionesSelect.proveedores}
                                                            placeholder={'Seleccione un proveedor'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaSelectField
                                                            id={'cannabinoide_dominante_id'}
                                                            label={'Cannabinoie dominante'}
                                                            opciones={opcionesSelect.cannabinoides}
                                                            placeholder={
                                                                'Seleccione un cannabinoide'
                                                            }
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'poder_germinativo_promedio'}
                                                            label={'Poder germinativo promedio (%)'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'cantidad_semillas_por_100_gramos'}
                                                            label={
                                                                'Cantidad semillas x 100 gramos (u.)'
                                                            }
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'densidad_siembra_planta_m2'}
                                                            label={'Densidad siembra (plantas/m2)'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                </Stack>
                                                <Flex justify="space-between" mt="24px">
                                                    <Button
                                                        variant="darkBrand"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        isLoading={methods.formState.isSubmitting}
                                                        type="submit"
                                                    >
                                                        Enviar
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </form>
                    </FormProvider>

                    {/* Danger Zone */}
                    {isEditMode && (
                        <DangerZone
                            elemento={'genetica'}
                            onConfirmDelete={() => {
                                setIsDeleting(true);
                                useEnvioFormulario1.eliminarElemento(id);
                            }}
                        />
                    )}
                </Flex>
            )}

            {isDeleting && <PageLoader message="Eliminando genética..." />}
        </>
    );
}
