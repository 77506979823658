import { Avatar, Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { Usuario } from 'modules/auth/types/Usuario';

type Props = {
    usuarioData: Usuario;
    children?: React.ReactNode | React.ReactNode[];
};

export default function Responsable(props: Props) {
    const { usuarioData } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Realizado por
                </Text>
            </Flex>

            <Flex mt={'20px'}>
                <Avatar
                    name={usuarioData.nombre_usuario}
                    h="75px"
                    w="75px"
                    mr="5"
                    src={usuarioData.imagen}
                />
                <Flex flexDirection="column" justify="center">
                    <Text fontSize={25} fontWeight="bold">
                        {usuarioData.nombre_usuario}
                    </Text>
                    <Text fontSize={15}>{usuarioData.rol}</Text>
                </Flex>
            </Flex>
        </Card>
    );
}
