/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    useColorModeValue,
    SimpleGrid,
    Select,
    Heading
} from '@chakra-ui/react';

// Custom components
import Banner from './components/Banner';
import NFT from '../../../../../components/card/NFT';
import { SearchBar } from '../../../../../views/admin/nfts/collection/components/Search';

// Assets
import Nft2 from '../../../../../assets/img/nfts/Nft2.png';
import Nft4 from '../../../../../assets/img/nfts/Nft4.png';
import Nft5 from '../../../../../assets/img/nfts/Nft5.png';
import Nft6 from '../../../../../assets/img/nfts/Nft6.png';
import NftProfile from '../../../../../assets/img/nfts/NftProfile.png';
import NftBanner2 from '../../../../../assets/img/nfts/NftBanner2.png';
import Avatar1 from '../../../../../assets/img/avatars/avatar1.png';
import Avatar2 from '../../../../../assets/img/avatars/avatar2.png';
import Avatar3 from '../../../../../assets/img/avatars/avatar3.png';
import Avatar4 from '../../../../../assets/img/avatars/avatar4.png';

import { MdDashboard, MdApps, MdAddCircle } from 'react-icons/md';
import PageLoader from '../../../../../components/actions/PageLoader';
import React, { useEffect, useMemo, useState } from 'react';
import CannavaApiService from '../../../../../utils/CannavaApiService';
import { useAppContext } from '../../../../../contexts/AppContext';
import { Variedad } from '../../../../genetica/types/Variedad';
import { useNavigate, useParams } from 'react-router-dom';
import { LoteSemillas } from '../../../../trazabilidad/types/LoteSemilllas';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import Listado, {
    crearCelda,
    crearCeldaConLink,
    crearHeader
} from '../../../../../components/cannava/listado';
import Card from '../../../../../components/card/Card';
import { useConfigurarNavegacion } from '../../../../../hooks/useConfigurarNavegacion';
import { Establecimiento } from '../../../types/Establecimiento';
import { MenuItemProps } from '../../../../../types/MenuItemProps';
import { Ubicacion } from '../../../types/Ubicacion';
import { HSeparator } from '../../../../../components/separator/Separator';
import MapCard from '../../../../../components/cannava/mapCard';

export default function EstablecimientoVer() {
    const { authToken, setFlashMessage, setFlashType } = useAppContext();
    const { id } = useParams();
    const [cargandoData, setCargandoData] = useState(true);
    const [data, setData] = useState<Establecimiento>(null);
    const navigate = useNavigate();

    const obtenerData = (mostrarLoading = true) => {
        const fetchData = async (id: string) => {
            if (mostrarLoading) {
                setCargandoData(true);
            }

            CannavaApiService.establecimientosObtener(
                parseInt(id),
                authToken,
                data => {
                    console.log('data proveedor');
                    console.log(data);
                    setData(data);
                    if (mostrarLoading) {
                        setCargandoData(false);
                    }
                },
                error => {
                    //throw new Error(`HTTP error! Status: ${error}`);
                    setFlashMessage('Hubo un error al obtener los datos del usuario.');
                    setFlashType('error');
                    if (mostrarLoading) {
                        setCargandoData(false);
                    }
                    navigate('/admin/variedades');
                }
            );
        };

        fetchData(id);
    };

    // configuro layout
    const configurarNavegacion = useConfigurarNavegacion({
        breadcrumbs: [
            { label: 'Inicio', href: '/' },
            { label: 'Ubicaciones', href: '/admin/establecimientos/' },
            { label: 'Establecimientos', href: '/admin/establecimientos/' }
        ],
        titulo: 'Ver establecimiento'
    });

    useEffect(() => {
        if (id && authToken) {
            obtenerData();
        }
    }, [id, authToken]);

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const buttonBg = useColorModeValue('transparent', 'navy.800');
    const hoverButton = useColorModeValue({ bg: 'gray.100' }, { bg: 'whiteAlpha.100' });
    const activeButton = useColorModeValue({ bg: 'gray.200' }, { bg: 'whiteAlpha.200' });
    let borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');

    const menuItems: MenuItemProps[] = [
        {
            icono: MdAddCircle,
            label: 'Agregar nuevo',
            onClick: () => navigate('/admin/ubicaciones/nuevo')
        }
    ];
    const columnHelper = createColumnHelper<Ubicacion>();
    const columns = [
        columnHelper.accessor('tipo_id', {
            id: 'tipo_id',
            header: crearHeader('Tipo'),
            cell: crearCelda((info: CellContext<any, any>) => {
                return info.row.original.tipo.nombre;
            })
        }),
        columnHelper.accessor('nombre', {
            id: 'nombre',
            header: crearHeader('Ubicación'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('ID'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'editar',
            header: crearHeader('Editar'),
            cell: crearCeldaConLink('/admin/ubicaciones/editar', 'Editar', navigate)
        }),

        columnHelper.accessor('id', {
            id: 'actions',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/ubicaciones/ver', 'Ver ubicación', navigate)
        })
    ];

    if (cargandoData) {
        return <PageLoader message={'Cargando establecimiento'} />;
    }

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            {/* Main Fields */}
            <Box mb="20px" display={{ base: 'block', lg: 'grid' }}>
                <Flex flexDirection="column">
                    <Banner
                        image={NftBanner2}
                        profile={NftProfile}
                        creator={''}
                        name={data.nombre}
                        desc=""
                        floor={data.locaciones.length}
                        volume={0}
                        owners={0}
                        items={0}
                    />
                </Flex>

                <Card px="0px" mb={'20px'}>
                    <Heading fontSize={'24px'} px={'20px'} pb={'20px'}>
                        Ubicaciones
                    </Heading>
                    <Listado tableData={data.locaciones} menuItems={menuItems} columns={columns} />
                </Card>

                {data.latitud && data.longitud && (
                    <Flex direction={'row'}>
                        <MapCard
                            lat={data.latitud}
                            lng={data.longitud}
                            mapZoom={15}
                            gridArea="1 / 2 / 2 / 3"
                        />
                    </Flex>
                )}
            </Box>

            {/*<ListadoGeneticas geneticaId={id}/>*/}

            {/* Search Bar 
            <Flex w='100%'>
                <SearchBar/>
                <Select
                    fontSize='sm'
                    id='edit_product'
                    variant='main'
                    h='44px'
                    maxH='44px'
                    me={{base: '10px', md: '20px'}}>
                    <option value='single'>Single Items</option>
                    <option value='multiple'>Multiple Items</option>
                </Select>
                <Select fontSize='sm' variant='main' h='44px' maxH='44px'
                        me={{base: '10px', md: '20px'}}>
                    <option value='low_to_high'>Low to high</option>
                    <option value='high_to_low'>High to low</option>
                </Select>
                <Button
                    me={{base: '10px', md: '20px'}}
                    bg={buttonBg}
                    border='1px solid'
                    color='secondaryGray.600'
                    borderColor={borderColor}
                    borderRadius='16px'
                    _placeholder={{color: 'secondaryGray.600'}}
                    _hover={hoverButton}
                    _active={activeButton}
                    _focus={activeButton}>
                    <Icon color={textColor} as={MdDashboard}/>
                </Button>
                <Button
                    bg={buttonBg}
                    border='1px solid'
                    color='secondaryGray.600'
                    borderColor={borderColor}
                    borderRadius='16px'
                    _placeholder={{color: 'secondaryGray.600'}}
                    _hover={hoverButton}
                    _active={activeButton}
                    _focus={activeButton}>
                    <Icon color={textColor} as={MdApps}/>
                </Button>
            </Flex>
            <Text mt='25px' mb='36px' color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
                More from this Collection
            </Text>
            <SimpleGrid columns={{base: 1, md: 2, xl: 4}} gap='20px'>
                <NFT
                    name='Swipe Circles'
                    author='By Peter Will'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft4}
                    currentbid='0.91 ETH'
                    download='#'
                />
                <NFT
                    name='Colorful Heaven'
                    author='By Mark Benjamin'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft5}
                    currentbid='0.91 ETH'
                    download='#'
                />
                <NFT
                    name='3D Cubes Art'
                    author='By Manny Gates'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft6}
                    currentbid='0.91 ETH'
                    download='#'
                />
                <NFT
                    name='ETH AI Brain'
                    author='By Nick Wilson'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft2}
                    currentbid='0.91 ETH'
                    download='#'
                />
                <NFT
                    name='Swipe Circles'
                    author='By Peter Will'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft4}
                    currentbid='0.91 ETH'
                    download='#'
                />
                <NFT
                    name='Colorful Heaven'
                    author='By Mark Benjamin'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft5}
                    currentbid='0.91 ETH'
                    download='#'
                />
                <NFT
                    name='3D Cubes Art'
                    author='By Manny Gates'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft6}
                    currentbid='0.91 ETH'
                    download='#'
                />
                <NFT
                    name='ETH AI Brain'
                    author='By Nick Wilson'
                    bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
                    image={Nft2}
                    currentbid='0.91 ETH'
                    download='#'
                />
            </SimpleGrid>
               */}
            {/* Delete Product */}
        </Box>
    );
}

type ListadoLotesProps = {
    geneticaId: string;
};

function ListadoGeneticas(props: ListadoLotesProps) {
    const { authToken } = useAppContext();
    const navigate = useNavigate();
    const { geneticaId } = props;
    const transformLote = (data: any[]): LoteSemillas[] => {
        return data.map(lote => ({
            id: lote.id,
            codigo_lote: lote.codigo_lote,
            peso: lote.peso,
            unidades: lote.unidades,
            fecha_recepcion: lote.fecha_recepcion,
            cantidad_bajas: lote.cantidad_bajas,
            peso_promedio: lote.peso_promedio,
            genetica_id: lote.genetica_id,
            establecimiento_id: lote.establecimiento_id,
            stock: lote.stock,
            actions: 'Actions'
        }));
    };

    const { data: lotesData, isLoading } = useFetchData<LoteSemillas>(
        'lotesSemillas',
        transformLote
    );
    //const [lotesFiltrados, setLotesFiltrados] = useState<LoteSemillas[]|null>(null);
    const columnHelper = createColumnHelper<LoteSemillas>();
    const columns = [
        columnHelper.accessor('codigo_lote', {
            id: 'codigo_lote',
            header: crearHeader('Lote'),
            cell: crearCelda()
        }),
        columnHelper.accessor('establecimiento_id', {
            id: 'locacion_id',
            header: crearHeader('Locacion'),
            cell: crearCelda()
        }),
        columnHelper.accessor('peso', {
            id: 'date',
            header: crearHeader('Cantidad'),
            cell: crearCelda()
        }),
        columnHelper.accessor('unidades', {
            id: 'type',
            header: crearHeader('Unidades'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'editar',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/lotes-semillas/editar', 'Editar', navigate)
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/lotes-semillas/ver', 'Ver lote', navigate)
        })
    ];

    const lotesFiltrados = useMemo(() => {
        if (!lotesData) {
            return;
        }
        // @todo: falta filtrar en base al proveedor de la genetica y necesitamos los cambios de javi
        // lo oculto mientras tanto
        let lotes = lotesData.filter(lote => lote.genetica.proveedor_id === parseInt(geneticaId));
        console.log(lotes);
        return lotes;
    }, [lotesData]);

    if (isLoading || !lotesFiltrados) {
        return <PageLoader message={'Cargando lotes'} />;
    }

    return (
        <Card px="0px">
            <Listado tableData={lotesFiltrados} menuItems={[]} columns={columns} />
        </Card>
    );
}
