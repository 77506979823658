import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Flex,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Progress,
    SimpleGrid,
    Spinner,
    Link,
    Tag,
    HStack,
    VStack,
    Grid,
    Text,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    useColorModeValue,
    useDisclosure
} from '@chakra-ui/react';
import { FaTrash, FaCalendarAlt } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption } from '@chakra-ui/react';
import { Planificacion } from '../../../../types/Planificacion';
import { MdAddCircle, MdDelete } from 'react-icons/md';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppContext } from 'contexts/AppContext';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import CannavaInputField from 'components/fields/CannavaInputField';
import { Campana } from '../../../../types/Campana';
import { Producto } from '../../../../../catalogo/types/Producto';
import { useFetchData } from 'hooks/useFetchData';
import { Ubicacion } from '../../../../../locacion/types/Ubicacion';
import CannavaApiService from 'utils/CannavaApiService';
import Card from 'components/card/Card';
import { EtapaProceso } from '../../../../types/EtapaProceso';
import FilaEtapaPlanificacion from './FilaEtapaPlanificacion';
import { ObjetivoCampana } from '../../../../types/ObjetivoCampana';
import { Variedad } from '../../../../../genetica/types/Variedad';
import { HSeparator } from 'components/separator/Separator';
import CalculadoraPlanificacion from '../../../../utils/CalculadoraPlanificacion';
import { LoteSemillas } from '../../../../../trazabilidad/types/LoteSemilllas';
import { useNavigate } from 'react-router-dom';
import { useAutenticacionUsuario } from '../../../../../../modules/auth/hooks/useAutenticacionUsuario';

type ListadoPlanificacionesProps = {
    campana: Campana;
    etapas: EtapaProceso[];
    actualizarCampana: CallableFunction;
    [x: string]: any;
};

type PlanificacionProps = {
    planificacion: Planificacion;
    campana: Campana;
    callbackEliminar: CallableFunction;
    callbackActivar: CallableFunction;
};

type OpcionesSelect = {
    locaciones: any[];
    tipoLocaciones: any[];
    objetivos: any[];
};

/**
 * Listado de planificaciones. Tiene que mostrar todas las planificaciones asociadas a una campaña
 * y un objetivo.
 *
 * @param props
 * @constructor
 */
export default function ListadoPlanificaciones(props: ListadoPlanificacionesProps) {
    const { authToken } = useAppContext();
    const { campana, actualizarCampana, etapas, ...rest } = props;
    console.log('Christian', campana.planificaciones, campana.estado);
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'secondaryGray.600';
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const modal = useDisclosure();
    const alertEliminar = useDisclosure();
    const cancelRef = React.useRef();

    const methods = useForm();
    const [opcionesSelect, setOpcionesSelect] = useState<OpcionesSelect>({
        objetivos: [],
        tipoLocaciones: [],
        locaciones: []
    });
    const { setFlashMessage, setFlashType } = useAppContext();
    const [enviandoLoading, setEnviandoLoading] = useState(false);
    const [planificacionParaEliminar, setPlanificacionParaEliminar] =
        useState<Planificacion | null>(null);
    const [dataObjetivo, setDataObjetivo] = useState(null);

    const transformUbicaciones = (data: any[]): Ubicacion[] => {
        return data.map(ubicacion => ({
            id: ubicacion.id,
            nombre: ubicacion.nombre,
            tipo_id: ubicacion.tipo_id,
            establecimiento_id: ubicacion.establecimiento_id,
            establecimiento: ubicacion.establecimiento.nombre,
            estado: ubicacion.estado,
            fecha_creacion: ubicacion.fecha_creacion,
            fecha_actualizacion: ubicacion.fecha_actualizacion,
            fecha_borrado: ubicacion.fecha_borrado,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };

    const [permisosAcceso, setPermisosAcceso] = useState<Record<string, boolean>>({});
    const { obtenerPermisos } = useAutenticacionUsuario();

    useEffect(() => {
        const permisos = [
            {
                key: 'botonEliminarPlanificacion',
                roles: ['usuario-basico', 'administrador'],
                areas: ['planificacion', 'finanzas']
            },
            {
                key: 'botonAgregarPlanificacion',
                roles: ['usuario-basico', 'administrador'],
                areas: ['planificacion', 'finanzas']
            }
        ];
        const nuevosPermisos = obtenerPermisos(permisos);
        setPermisosAcceso(nuevosPermisos);
    }, [obtenerPermisos]);

    const objetivoSeleccionado = methods.watch('objetivo_id');
    const tipoLocacionSeleccionada = methods.watch('tipo_locacion_id');

    const { data: ubicacionesData, isLoading } = useFetchData<Ubicacion>(
        'ubicaciones',
        transformUbicaciones
    );

    const enviarFormulario = async (data: any) => {
        setEnviandoLoading(true);

        data.campana_id = campana.id;
        data.fecha_siembra = data.siembra_fecha_inicio;
        data.fecha_cosecha = data.cosecha_fecha_inicio;
        data.locacion_id = data.siembra_locacion_id;

        data['dataEtapas'] = {};
        etapas.map((etapa, index) => {
            data['dataEtapas'][`${etapa.nombre}_fecha_inicio`] =
                data[`${etapa.nombre}_fecha_inicio`];
            data['dataEtapas'][`${etapa.nombre}_fecha_fin`] = data[`${etapa.nombre}_fecha_fin`];
            data['dataEtapas'][`${etapa.nombre}_locacion_id`] = data[`${etapa.nombre}_locacion_id`];
        });

        data['dataFilaObjetivos'] = [];
        dataObjetivo.filas.map((fila: any, index: any) => {
            data['dataFilaObjetivos'].push({
                fila_objetivo_campana_id: fila.id,
                cantidad: data['rendimiento_' + fila.id]
            });
        });

        CannavaApiService.planificacionesCampanaCrear(
            data,
            authToken,
            data => {
                // Setear los valores en los campos del formulario
                setFlashMessage('Se agregó la planificación correctamente!');
                setFlashType('success');
                resetearFormulario();

                setEnviandoLoading(false);
                modal.onClose();
                actualizarCampana(false);
            },
            (error: any) => {
                if (error.response.status == 422) {
                    console.log(error.response.data);
                    for (let i = 0; i < error.response.data.length; i++) {
                        const errorValidacion: any = error.response.data[i];
                        methods.setError(errorValidacion.field, {
                            message: errorValidacion.message
                        });
                    }
                } else {
                    alert('Error desconocido');
                    console.log(error);
                }

                setEnviandoLoading(false);
            }
        );
    };

    const activarPlanificacion = (planificacion: Planificacion) => {
        CannavaApiService.planificacionesCampanaActivar(
            planificacion.id,
            authToken,
            data => {
                // Setear los valores en los campos del formulario
                setFlashMessage('Se activó la planificación correctamente!');
                setFlashType('success');
                actualizarCampana(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al activar la planificación.');
                setFlashType('error');
                actualizarCampana(false);
            }
        );
    };

    const eliminarPlanificacion = (planificacion: Planificacion) => {
        setPlanificacionParaEliminar(planificacion);
        alertEliminar.onOpen();
    };

    const confirmarEliminarPlanificacion = () => {
        if (planificacionParaEliminar) {
            CannavaApiService.planificacionesCampanaEliminar(
                planificacionParaEliminar.id,
                authToken,
                data => {
                    // Setear los valores en los campos del formulario
                    setFlashMessage('Se eliminó la planificación correctamente!');
                    setFlashType('success');
                    setPlanificacionParaEliminar(null);
                    actualizarCampana(false);
                },
                error => {
                    //throw new Error(`HTTP error! Status: ${error}`);
                    setFlashMessage('Hubo un error al eliminar la planificación.');
                    setFlashType('error');
                    actualizarCampana(false);
                }
            );
        }

        alertEliminar.onClose();
    };

    const filtrarUbicaciones = (etapa: EtapaProceso): Ubicacion[] => {
        if (etapa.nombre == 'floracion') {
            if (!tipoLocacionSeleccionada) {
                return ubicacionesData;
            }

            return ubicacionesData.filter(
                ubicacion => ubicacion.tipo_id == tipoLocacionSeleccionada
            );
        }

        let locacionesFiltradas = [];
        if (etapa.tiposLocacionPermitidos && etapa.tiposLocacionPermitidos.length > 0) {
            return ubicacionesData.filter(ubicacion => {
                return etapa.tiposLocacionPermitidos?.includes(ubicacion.tipo_id);
            });
        }

        return ubicacionesData;
    };

    const obtenerGenetica = (objetivoId: number): Variedad => {
        for (let i = 0; i < campana.objetivos.length; i++) {
            const objetivo: ObjetivoCampana = campana.objetivos[i];
            console.log('comparando ', objetivo.id, objetivoId);
            if (objetivo.id == objetivoId) {
                if (objetivo.genetica) {
                    return objetivo.genetica;
                }
            }
        }

        return {} as Variedad;
    };

    const resetearFormulario = () => {
        methods.reset({
            objetivo_id: '',
            tipo_locacion_id: '',
            hectareas_ocupadas: '',
            semillas_necesarias_unidades: '',
            semillas_necesarias_peso: '',
            cantidad: ''
        });
    };

    useEffect(() => {
        for (let i = 0; i < campana.objetivos.length; i++) {
            const objetivo: ObjetivoCampana = campana.objetivos[i];
            console.log('comparando ', objetivo.id, objetivoSeleccionado);
            if (objetivo.id == objetivoSeleccionado) {
                setDataObjetivo(objetivo);
            }
        }
    }, [objetivoSeleccionado]);

    useEffect(() => {
        let opciones: OpcionesSelect = {
            locaciones: ubicacionesData,
            // @todo: pasarlo a llamada api
            tipoLocaciones: [
                {
                    id: 1,
                    nombre: 'Invernadero',
                    slug: 'invernadero'
                },
                {
                    id: 2,
                    nombre: 'Campo',
                    slug: 'campo'
                },
                {
                    id: 4,
                    nombre: 'Almería',
                    slug: 'almeria'
                }
            ],
            objetivos: campana.objetivos.map(objetivo => ({
                id: objetivo.id,
                nombre: objetivo.genetica.nombre
            }))
        };

        setOpcionesSelect(opciones);
    }, [ubicacionesData, campana]);

    useEffect(() => {
        let fechaActual = new Date();
        if (!etapas) {
            return;
        }

        etapas.map((etapa, index) => {
            methods.setValue(
                `${etapa.nombre}_fecha_inicio`,
                fechaActual.toISOString().split('T')[0]
            );
            fechaActual.setDate(fechaActual.getDate() + 7);
            methods.setValue(`${etapa.nombre}_fecha_fin`, fechaActual.toISOString().split('T')[0]);
            fechaActual.setDate(fechaActual.getDate() + 7);
        });
    }, []);

    if (!etapas) {
        return;
    }

    return (
        <Card colorScheme="whiteAlpha" mb="40px" p="24px" pb={0}>
            <Flex direction="column" mb="30px" ms="10px">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Planificaciones
                </Text>
                <Text fontSize="md" color={textColorSecondary}>
                    Aquí encontrarás las planificaciones definidas para cumplir con los objetivos.
                </Text>

                {campana.planificaciones.map((planificacion, index) => (
                    <FilaPlanificacion
                        key={index}
                        planificacion={planificacion}
                        campana={campana}
                        callbackEliminar={eliminarPlanificacion}
                        callbackActivar={activarPlanificacion}
                    />
                ))}

                {permisosAcceso.botonAgregarPlanificacion && (
                    <Box display="flex" justifyContent="flex-end">
                        <Button onClick={modal.onOpen}>
                            <Icon as={MdAddCircle} w="16px" h="16px" /> Agregar planificación
                        </Button>
                    </Box>
                )}
            </Flex>

            {permisosAcceso.botonEliminarPlanificacion && (
                <AlertDialog
                    isOpen={alertEliminar.isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={alertEliminar.onClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                Eliminar Usuario
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                ¿Estás seguro de que deseas eliminar esta planificación? Esta acción
                                es irreversible.
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={alertEliminar.onClose}>
                                    Cancelar
                                </Button>
                                <Button
                                    colorScheme="red"
                                    onClick={confirmarEliminarPlanificacion}
                                    ml={3}
                                >
                                    Eliminar
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            )}

            {permisosAcceso.botonAgregarPlanificacion && (
                <Modal isOpen={modal.isOpen} onClose={modal.onClose} size={'4xl'}>
                    <ModalOverlay />
                    <ModalContent>
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(enviarFormulario)}>
                                <ModalHeader>Agregar Planificación</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    {enviandoLoading ? (
                                        <Box display={'flex'} mx={'auto'} mb="30px">
                                            <Spinner />
                                        </Box>
                                    ) : (
                                        <Box>
                                            <CannavaSelectField
                                                id={'objetivo_id'}
                                                label={'Objetivo'}
                                                opciones={opcionesSelect.objetivos}
                                                mb={'10px'}
                                                placeholder={'Seleccione un objetivo'}
                                                validationRules={{ required: 'Campo obligatorio' }}
                                            />

                                            <HSeparator my={'20px'} />

                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaSelectField
                                                    id={'tipo_locacion_id'}
                                                    label={'Tipo Locacion'}
                                                    opciones={opcionesSelect.tipoLocaciones}
                                                    mb={'10px'}
                                                    placeholder={'Seleccione un tipo de locación'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                <CannavaInputField
                                                    id={'hectareas_ocupadas'}
                                                    label={'Hectáreas'}
                                                    mb={'10px'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                    onBlur={(
                                                        e: React.FocusEvent<HTMLInputElement>
                                                    ) => {
                                                        const hectareas = +e.target.value;
                                                        let genetica =
                                                            obtenerGenetica(objetivoSeleccionado);
                                                        let cantidad = 0;

                                                        let calculadora =
                                                            new CalculadoraPlanificacion(
                                                                hectareas,
                                                                genetica,
                                                                tipoLocacionSeleccionada
                                                            );
                                                        if (calculadora.cantidad > 0) {
                                                            methods.setValue(
                                                                'cantidad',
                                                                calculadora.cantidad.toString()
                                                            );
                                                        }

                                                        if (calculadora.rendimientosProductos) {
                                                            console.log(
                                                                'calculadora rendimientos',
                                                                calculadora.rendimientosProductos
                                                            );
                                                            dataObjetivo.filas.map(
                                                                (fila: any, index: any) => {
                                                                    methods.setValue(
                                                                        'rendimiento_' + fila.id,
                                                                        Math.ceil(
                                                                            calculadora
                                                                                .rendimientosProductos[
                                                                                fila.tipoProducto
                                                                                    .slug
                                                                            ] / 1000
                                                                        ).toString()
                                                                    );
                                                                }
                                                            );
                                                        }

                                                        if (calculadora.semillasUnidades > 0) {
                                                            methods.setValue(
                                                                'semillas_necesarias_unidades',
                                                                Math.ceil(
                                                                    calculadora.semillasUnidades
                                                                ).toString()
                                                            );
                                                        }

                                                        if (calculadora.semillasPeso > 0) {
                                                            methods.setValue(
                                                                'semillas_necesarias_peso',
                                                                Math.ceil(
                                                                    calculadora.semillasPeso
                                                                ).toString()
                                                            );
                                                        }
                                                    }}
                                                />
                                            </SimpleGrid>

                                            <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px">
                                                <CannavaInputField
                                                    id={'semillas_necesarias_unidades'}
                                                    label={'Semillas (u.)'}
                                                    mb={'10px'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                <CannavaInputField
                                                    id={'semillas_necesarias_peso'}
                                                    label={'Semillas (gr)'}
                                                    mb={'10px'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                <CannavaInputField
                                                    id={'cantidad'}
                                                    label={'Cantidad de plantas (u.)'}
                                                    mb={'10px'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            </SimpleGrid>

                                            {dataObjetivo && (
                                                <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                    {dataObjetivo.filas.map(
                                                        (fila: any, index: any) => (
                                                            <CannavaInputField
                                                                id={`rendimiento_${fila.id}`}
                                                                key={index}
                                                                label={`Rendimiento ${fila.tipoProducto.nombre} (kg)`}
                                                                mb={'10px'}
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />
                                                        )
                                                    )}
                                                </SimpleGrid>
                                            )}

                                            <HSeparator my={'20px'} />

                                            {etapas.map((etapa, index) => (
                                                <Box key={index}>
                                                    <FilaEtapaPlanificacion
                                                        etapa={etapa}
                                                        locaciones={filtrarUbicaciones(etapa)}
                                                    />
                                                </Box>
                                            ))}
                                        </Box>
                                    )}
                                </ModalBody>

                                {!enviandoLoading && (
                                    <ModalFooter>
                                        <Button
                                            colorScheme="green"
                                            mr={3}
                                            isLoading={methods.formState.isSubmitting}
                                            type="submit"
                                        >
                                            Guardar
                                        </Button>
                                        <Button variant="ghost" onClick={modal.onClose}>
                                            Cancelar
                                        </Button>
                                    </ModalFooter>
                                )}
                            </form>
                        </FormProvider>
                    </ModalContent>
                </Modal>
            )}
        </Card>
    );
}

/**
 * Fila de la tabla de planificaciones. Tiene que mostrar los datos particulares de la
 * planificación y el objetivo.
 *
 * @param props
 * @constructor
 */
function FilaPlanificacion(props: PlanificacionProps) {
    const { planificacion, campana, callbackEliminar, callbackActivar } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'secondaryGray.600';
    const navigate = useNavigate();
    const mostrarBotonActivar = campana.estado == 'activa' && planificacion.estado == 'draft';
    const mostrarBotonEliminar = campana.estado == 'draft';

    const [permisosAcceso, setPermisosAcceso] = useState<Record<string, boolean>>({});
    const { obtenerPermisos } = useAutenticacionUsuario();

    useEffect(() => {
        const permisos = [
            {
                key: 'botonEliminarPlanificacion',
                roles: ['usuario-basico', 'administrador'],
                areas: ['planificacion', 'finanzas']
            },
            {
                key: 'botonAgregarPlanificacion',
                roles: ['usuario-basico', 'administrador'],
                areas: ['planificacion', 'finanzas']
            }
        ];
        const nuevosPermisos = obtenerPermisos(permisos);
        setPermisosAcceso(nuevosPermisos);
    }, [obtenerPermisos]);

    const activar = () => {
        callbackActivar(planificacion);
    };

    const eliminar = () => {
        callbackEliminar(planificacion);
    };

    const obtenerStringLotes = (semillas: LoteSemillas[]) => {
        let stringLotes = '';
        for (let i = 0; i < semillas.length; i++) {
            stringLotes += semillas[i].codigo_lote;
            if (i < semillas.length - 1) {
                stringLotes += '\n';
            }
        }

        return stringLotes;
    };

    const obtenerLotes = (semillas: LoteSemillas[]) => {
        return (
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Nombre del lote</Th>
                        <Th>Estado del lote</Th>
                        <Th>Cantidad de semillas</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {semillas.map((semilla, index) => (
                        <Tr key={index}>
                            <Td>
                                <Link
                                    onClick={() => {
                                        navigate(`/admin/lotes-semillas/ver/${semilla.id}`);
                                    }}
                                    color="blue.500"
                                >
                                    {semilla.codigo_lote}
                                </Link>
                            </Td>
                            <Td>
                                <Tag size="md" variant="solid" colorScheme={true ? 'green' : 'red'}>
                                    A completar
                                </Tag>
                            </Td>
                            <Td>{semilla.unidades}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        );
    };

    return (
        <Card colorScheme="whiteAlpha" mb="40px" p="24px" variant={'outline'}>
            <VStack spacing={4} align="stretch">
                <Flex justifyContent="space-between" alignItems="center">
                    <Text fontSize="xl" fontWeight="bold" color="textColorPrimary">
                        Genética: {planificacion.objetivo.genetica.nombre}
                    </Text>
                    {mostrarBotonActivar && (
                        <Button onClick={activar} colorScheme="green" size="sm">
                            Activar
                        </Button>
                    )}
                    {mostrarBotonEliminar && permisosAcceso.botonEliminarPlanificacion && (
                        <Button onClick={eliminar} colorScheme="red" size="sm">
                            <Icon as={FaTrash} h="16px" w="16px" />
                        </Button>
                    )}
                </Flex>

                <HStack justifyContent="space-between" alignItems="center">
                    <Tag
                        size="md"
                        variant="solid"
                        colorScheme={planificacion.estado === 'activo' ? 'green' : 'red'}
                    >
                        Estado: {planificacion.estado}
                    </Tag>
                    <Text fontSize="md" color="textColorSecondary">
                        Cantidad a producir: {planificacion.cantidad}
                    </Text>
                </HStack>

                {/* <Box>
                    <Text fontSize="md" color="textColorSecondary">
                        Semillas necesarias: 200 | Asignadas: 100
                    </Text>
                    <Progress colorScheme={100 / 200 >= 1 ? 'green' : 'orange'} size="sm" value={(100 / 200) * 100} />
                </Box>*/}

                {/* Opcional: Incluir ubicación y fechas si aún son relevantes */}
                <Text fontSize="md" color="textColorSecondary">
                    Ubicación: {planificacion.etapas[2].locacion.nombre}
                </Text>
                <Flex alignItems="center">
                    <Icon as={FaCalendarAlt} h="16px" w="16px" />
                    <Text fontSize="md" color="textColorSecondary" ml="5px">
                        Fecha de inicio: {planificacion.etapas[0].fecha_inicio} | Fecha de cosecha:{' '}
                        {planificacion.etapas[3].fecha_inicio}
                    </Text>
                </Flex>

                <Accordion allowToggle>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    Lotes de semillas: {planificacion.lotesSemillas.length}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            {obtenerLotes(planificacion.lotesSemillas)}
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </VStack>
        </Card>
    );
}
