import { Box, Icon, Text, useColorModeValue, useTheme } from '@chakra-ui/react';
import UploaderZone from '../components/cannava/uploaderZone';
import { MdOutlineCloudUpload } from 'react-icons/md';
import React, { useState } from 'react';

export default function PlaygroundUploader() {
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const theme = useTheme();
    //eslint-disable-next-line
    const [lineColor, setLineColor] = useState(theme.colors.brand[500]);
    //eslint-disable-next-line
    const [lineColorDark, setLineColorDark] = useState(theme.colors.brand[400]);
    const brand = useColorModeValue(lineColor, lineColorDark);
    const [files, setFiles] = useState([]);
    return (
        <Box pt={'80px'}>
            <h1>Hola!</h1>
            <UploaderZone files={files} setFiles={setFiles}></UploaderZone>
        </Box>
    );
}
