// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormLabel,
    FormControl,
    useColorModeValue,
    Alert,
    AlertIcon,
    AlertTitle,
    Switch
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import { useLayoutContext } from 'contexts/LayoutContext';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import CannavaTextAreaField from 'components/fields/CannavaTextareaField';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import CannavaApiService from 'utils/CannavaApiService';

type PrepararBandejasData = {
    lote_id: string;
    id: number;
    fecha_realizacion_limpieza_bandejas: string;
    hora_inicio_limpieza_bandejas: string;
    hora_fin_limpieza_bandejas: string;
    numero_bandejas_bins_acoplados_lavados_limpieza_bandejas: number;
    efectividad_limpieza_bandejas: number;
    efectividad_limpieza_bandejas_formulario: string;
    operario_limpieza_bandejas: string;
    responsable_limpieza_bandejas: string;

    fecha_inicio_preparacion_bandejas: string;
    hora_inicio_preparacion_bandejas: string;
    numero_operarios_preparacion_bandejas: number;
};

export default function PrepararBandejas() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const { setBreadcrumbs, setTitle } = useLayoutContext();

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = useState({
        limpieza: true,
        preparacion: false
    });

    const navigate = useNavigate();

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const limpiezaTab = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
    const preparacionTab = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

    const [cargandoData, setCargandoData] = useState(false);
    const { setFlashMessage, setFlashType } = useAppContext();

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Trazabilidad', href: '/admin/trazabilidad/' },
            { label: 'Lotes de germinacion', href: '/admin/lotes-germinacion/ver/' + id },
            { label: 'Preparar bandejas', href: '#' }
            /* Añadir para volver al lote  { label: 'Lotes de germinacion', href: '/admin/lotes-germinacion/:id' } */
        ]);

        setTitle('Control del lote');
    }, []);

    const onSubmit: SubmitHandler<PrepararBandejasData> = (data: PrepararBandejasData) => {
        data.efectividad_limpieza_bandejas =
            data.efectividad_limpieza_bandejas_formulario === '1' ? 0 : 1;
        data.lote_id = id;
        setCargandoData(true);
        CannavaApiService.request(
            'POST',
            `/trazabilidad/api/lote-germinacion/preparar-bandejas`,
            data,
            authToken,
            data => {
                console.log(data);
                setFlashMessage('Se prepararon las bandejas correctamente.');
                setFlashType('success');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion/ver/' + id);
            },
            (error: any) => {
                if (error.response.status == 422) {
                    console.log(error.response.data);
                    for (let i = 0; i < error.response.data.length; i++) {
                        const errorValidacion: any = error.response.data[i];
                        methods.setError(errorValidacion.field, {
                            message: errorValidacion.message
                        });
                    }
                } else {
                    alert('Error desconocido');
                    console.log(error);
                }

                setCargandoData(false);
            }
        );
    };

    const onCancel: any = () => {
        navigate('/admin/lotes-germinacion/ver/' + id);
    };

    useEffect(() => {
        if (cargandoData) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [cargandoData, methods.formState.submitCount]);

    if (cargandoData) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            <Flex
                direction="column"
                minH="100vh"
                align="center"
                pt={{ sm: '125px', lg: '75px' }}
                position="relative"
            >
                <Box
                    h="45vh"
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    position="absolute"
                    w="100%"
                    borderRadius="20px"
                />

                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Tabs
                            variant="unstyled"
                            zIndex="0"
                            mt={{ base: '60px', md: '165px' }}
                            display="flex"
                            flexDirection="column"
                        >
                            <TabList
                                display="flex"
                                alignItems="center"
                                alignSelf="center"
                                justifySelf="center"
                            >
                                <Tab
                                    _focus={{ border: '0px', boxShadow: 'unset' }}
                                    ref={limpiezaTab}
                                    w={{ sm: '120px', md: '250px', lg: '300px' }}
                                    onClick={() =>
                                        setActiveBullets({
                                            limpieza: true,
                                            preparacion: false
                                        })
                                    }
                                ></Tab>
                                <Tab
                                    _focus={{ border: '0px', boxShadow: 'unset' }}
                                    ref={preparacionTab}
                                    w={{ sm: '120px', md: '250px', lg: '300px' }}
                                    onClick={() =>
                                        setActiveBullets({
                                            limpieza: true,
                                            preparacion: true
                                        })
                                    }
                                ></Tab>
                            </TabList>
                            <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
                                <TabPanel
                                    w={{ sm: '330px', md: '700px', lg: '850px' }}
                                    p="0px"
                                    mx="auto"
                                >
                                    <Card p="30px" mt={'60px'}>
                                        <Text
                                            color={textColor}
                                            fontSize="2xl"
                                            fontWeight="700"
                                            mb="20px"
                                        >
                                            Limpieza de bandejas, bins y acoplados
                                        </Text>
                                        {errorMessage && (
                                            <Alert status="error">
                                                <AlertIcon />
                                                <AlertTitle>{errorMessage}</AlertTitle>
                                            </Alert>
                                        )}
                                        <Flex direction="column" w="100%">
                                            <Stack direction="column" spacing="20px">
                                                <SimpleGrid gap="20px">
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaDatePickerField
                                                            id={
                                                                'fecha_realizacion_limpieza_bandejas'
                                                            }
                                                            label={'Fecha de realización'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                        <CannavaInputField
                                                            id={'hora_inicio_limpieza_bandejas'}
                                                            label={'Hora de inicio'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaInputField
                                                            id={'hora_fin_limpieza_bandejas'}
                                                            label={'Hora de fin'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                        <CannavaInputField
                                                            id={
                                                                'numero_bandejas_bins_acoplados_lavados_limpieza_bandejas'
                                                            }
                                                            label={
                                                                'Nº de bandejas, bins, acoplados lavados (u.)'
                                                            }
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaSelectField
                                                            id={
                                                                'efectividad_limpieza_bandejas_formulario'
                                                            }
                                                            label={'Efectividad'}
                                                            opciones={[
                                                                { id: 0, nombre: 'SI' },
                                                                { id: 1, nombre: 'NO' }
                                                            ]}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                        <CannavaInputField
                                                            id={'operario_limpieza_bandejas'}
                                                            label={'Operario'}
                                                            placeholder={
                                                                'Operario que realizó la limpieza'
                                                            }
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaInputField
                                                            id={'responsable_limpieza_bandejas'}
                                                            label={'Responsable'}
                                                            placeholder={'Responsable'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                </SimpleGrid>
                                            </Stack>
                                            <Flex justify="space-between" mt="24px">
                                                <Button
                                                    variant="outline"
                                                    fontSize="sm"
                                                    borderRadius="16px"
                                                    w={{ base: '128px', md: '148px' }}
                                                    h="46px"
                                                    //navegar a lote
                                                    onClick={onCancel}
                                                    type="button"
                                                >
                                                    Cancelar
                                                </Button>
                                                <Button
                                                    variant="darkBrand"
                                                    fontSize="sm"
                                                    borderRadius="16px"
                                                    w={{ base: '128px', md: '148px' }}
                                                    h="46px"
                                                    onClick={() => {
                                                        preparacionTab.current.click();
                                                    }}
                                                >
                                                    Continuar
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Card>
                                </TabPanel>
                                <TabPanel
                                    w={{ sm: '330px', md: '700px', lg: '850px' }}
                                    p="0px"
                                    mx="auto"
                                >
                                    <Card p="30px" mt={'60px'}>
                                        <Text
                                            color={textColor}
                                            fontSize="2xl"
                                            fontWeight="700"
                                            mb="20px"
                                        >
                                            Preparación de bandejas
                                        </Text>
                                        {errorMessage && (
                                            <Alert status="error">
                                                <AlertIcon />
                                                <AlertTitle>{errorMessage}</AlertTitle>
                                            </Alert>
                                        )}
                                        <Flex direction="column" w="100%">
                                            <Stack direction="column" spacing="20px">
                                                <SimpleGrid gap="20px">
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaDatePickerField
                                                            id={'fecha_inicio_preparacion_bandejas'}
                                                            label={'Fecha inicial'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                        <CannavaInputField
                                                            id={'hora_inicio_preparacion_bandejas'}
                                                            label={'Hora de inicio'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaInputField
                                                            id={
                                                                'numero_operarios_preparacion_bandejas'
                                                            }
                                                            label={'Nº de operarios'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                </SimpleGrid>
                                            </Stack>
                                            <Flex justify="space-between" mt="24px">
                                                <Button
                                                    variant="outline"
                                                    fontSize="sm"
                                                    borderRadius="16px"
                                                    w={{ base: '128px', md: '148px' }}
                                                    h="46px"
                                                    //navegar a lote
                                                    onClick={() => {
                                                        limpiezaTab.current.click();
                                                    }}
                                                    type="button"
                                                >
                                                    Atrás
                                                </Button>
                                                <Button
                                                    variant="darkBrand"
                                                    fontSize="sm"
                                                    borderRadius="16px"
                                                    w={{ base: '128px', md: '148px' }}
                                                    h="46px"
                                                    isLoading={methods.formState.isSubmitting}
                                                    type="submit"
                                                >
                                                    Continuar
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Card>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </form>
                </FormProvider>
            </Flex>
        </>
    );
}
