// Chakra imports
import { Flex, Text } from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import React, { useEffect, useState } from 'react';
import { useLayoutContext } from '../../../../../contexts/LayoutContext';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { Ubicacion } from '../../../types/Ubicacion';
import PageLoader from '../../../../../components/actions/PageLoader';
import { MenuItemProps } from '../../../../../types/MenuItemProps';
import { MdAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import Listado, {
    crearHeader,
    crearCelda,
    crearCeldaConLink
} from '../../../../../components/cannava/listado';

export default function UbicacionesOverview() {
    const transformUbicaciones = (data: any[]): Ubicacion[] => {
        return data.map(ubicacion => ({
            id: ubicacion.id,
            nombre: ubicacion.nombre,
            tipo_id: ubicacion.tipo_id,
            tipo: ubicacion.tipo,
            establecimiento_id: ubicacion.establecimiento_id,
            establecimiento: ubicacion.establecimiento.nombre,
            estado: ubicacion.estado,
            fecha_creacion: ubicacion.fecha_creacion,
            fecha_actualizacion: ubicacion.fecha_actualizacion,
            fecha_borrado: ubicacion.fecha_borrado,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };

    const { data: ubicacionesData, isLoading } = useFetchData<Ubicacion>(
        'ubicaciones',
        transformUbicaciones
    );
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const navigate = useNavigate();

    useEffect(() => {
        setBreadcrumbs([{ label: 'Inicio', href: '/' }]);

        setTitle('Ubicaciones');
    }, []);

    const menuItems: MenuItemProps[] = [
        {
            icono: MdAddCircle,
            label: 'Agregar nuevo',
            onClick: () => navigate('/admin/ubicaciones/nuevo')
        }
    ];

    const columnHelper = createColumnHelper<Ubicacion>();
    const textColor = 'navy.700';
    const brandColor = 'brand.500';

    const columns = [
        columnHelper.accessor('tipo_id', {
            id: 'tipo_id',
            header: crearHeader('Tipo'),
            cell: crearCelda((info: CellContext<any, any>) => {
                return info.row.original.tipo.nombre;
            })
        }),
        columnHelper.accessor('nombre', {
            id: 'nombre',
            header: crearHeader('Ubicación'),
            cell: crearCelda()
        }),
        columnHelper.accessor('establecimiento', {
            id: 'establecimiento',
            header: crearHeader('Establecimiento'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('ID'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'editar',
            header: crearHeader('Editar'),
            cell: crearCeldaConLink('/admin/ubicaciones/editar', 'Editar', navigate)
        }),

        columnHelper.accessor('id', {
            id: 'actions',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/ubicaciones/ver', 'Ver ubicación', navigate)
        })
    ];

    if (isLoading) {
        return <PageLoader message={'Cargando ubicaciones'} />;
    }

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Card px="0px">
                <Listado tableData={ubicacionesData} menuItems={menuItems} columns={columns} />
            </Card>
        </Flex>
    );
}
