// Chakra imports
import { Flex, Box, Text, useColorModeValue, Button, Link } from '@chakra-ui/react';
import { Progress } from '@chakra-ui/react';
import CircularProgress from 'components/charts/CircularProgress';
import { VSeparator } from 'components/separator/Separator';
import React, { useState, useEffect } from 'react';

// Custom components
import Card from 'components/card/Card';
import { ObjetivoCampana } from 'modules/planificacion/types/ObjetivoCampana';
import { Planificacion } from 'modules/planificacion/types/Planificacion';
import { useNavigate } from 'react-router-dom';
import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import { LotePoderGerminativo } from 'modules/trazabilidad/types/LotePoderGerminativo';
import EvolucionReporteCompletado from './EvolucionReporteCompletado';

// Custom hooks
import { useAutenticacionUsuario } from '../../../../../../modules/auth/hooks/useAutenticacionUsuario';

type CumplimientoObjetivosParams = {
    loteData: LoteSemillas;
    mostrarLinkLote?: boolean;
    [x: string]: any;
};

export default function EvolucionProcesoAnalisis(props: CumplimientoObjetivosParams) {
    const { loteData, mostrarLinkLote, ...rest } = props;
    const navigate = useNavigate();
    const [totalObjetivos, setTotalObjetivos] = useState(0);
    const [totalPlanificado, setTotalPlanificado] = useState(0);
    const [totalCumplido, setTotalCumplido] = useState(0);

    // Gestion de accesos por componente
    const [permisosAcceso, setPermisosAcceso] = useState<Record<string, boolean>>({});
    const { obtenerPermisos } = useAutenticacionUsuario();

    useEffect(() => {
        const permisos = [
            {
                key: 'botonIniciarAnalisisPG',
                roles: ['usuario-basico', 'administrador'],
                areas: ['trazabilidad']
            },
            ,
            {
                key: 'botonGenerarReportePG',
                roles: ['usuario-basico', 'administrador'],
                areas: ['trazabilidad']
            },
            ,
        ];
        const nuevosPermisos = obtenerPermisos(permisos);
        setPermisosAcceso(nuevosPermisos);
    }, [obtenerPermisos]);

    // Chakra Color Mode
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const cardColor = useColorModeValue('white', 'navy.700');
    const buttonBg = useColorModeValue('transparent', 'navy.800');
    const hoverButton = useColorModeValue({ bg: 'gray.100' }, { bg: 'whiteAlpha.100' });
    const activeButton = useColorModeValue({ bg: 'gray.200' }, { bg: 'whiteAlpha.200' });
    let borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');

    /**
     * Genera el titulo al rollover de la barra de progreso
     * @param lotePoderGerminativo
     */
    const generarTituloProgreso = (lotePoderGerminativo: LotePoderGerminativo): string => {
        return (
            'Transcurrieron ' +
            lotePoderGerminativo.diasTranscurridos +
            ' días de ' +
            lotePoderGerminativo.duracion +
            '. Fin estimado: ' +
            lotePoderGerminativo.fecha_finalizacion
        );
    };

    return (
        <Card p="30px" flexDirection="column" w="100%" {...rest}>
            <Flex justify="space-between" align="center">
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Análisis poder germinativo
                </Text>
            </Flex>
            {loteData.lotePoderGerminativo.length > 0 ? (
                <Box>
                    {!loteData.lotePoderGerminativo[0].reportePoderGerminativo ? (
                        <Box>
                            <Text
                                color="secondaryGray.600"
                                fontSize="sm"
                                fontWeight="500"
                                mb="10px"
                            >
                                El análisis del poder germinativo ya ha comenzado, tan pronto
                                finalize te avisaremos.
                            </Text>
                            <Text color="textColor" fontSize="sm" fontWeight="700" mb="10px">
                                Progreso de análisis
                                {/*( {loteData.lotePoderGerminativo[0].diasTranscurridos} / {loteData.lotePoderGerminativo[0].duracion} )*/}
                            </Text>

                            <Box w="100%" mb="10px" mt="10px">
                                <Progress
                                    w={'100%'}
                                    title={generarTituloProgreso(loteData.lotePoderGerminativo[0])}
                                    value={loteData.lotePoderGerminativo[0].diasTranscurridos}
                                    max={loteData.lotePoderGerminativo[0].duracion}
                                    colorScheme={'brand'}
                                    bgColor={'#EDEDED'}
                                />
                            </Box>

                            {permisosAcceso.botonGenerarReportePG && (
                                <Flex justify="space-between" align="center">
                                    <Button
                                        me={{ base: '10px', md: '20px' }}
                                        mt="10px"
                                        variant={'brand'}
                                        border="1px solid"
                                        onClick={() => {
                                            navigate(
                                                '/admin/reportes-poder-germinativo/nuevo/' +
                                                    loteData.lotePoderGerminativo[0].id
                                            );
                                        }}
                                    >
                                        Generar reporte
                                    </Button>
                                    {mostrarLinkLote && (
                                        <Link
                                            color={'brand.500'}
                                            onClick={() => {
                                                navigate(
                                                    '/admin/lotes-poder-germinativo/ver/' +
                                                        loteData.lotePoderGerminativo[0].id
                                                );
                                            }}
                                        >
                                            Lote {loteData.lotePoderGerminativo[0].codigo_lote}
                                        </Link>
                                    )}
                                </Flex>
                            )}
                        </Box>
                    ) : (
                        <EvolucionReporteCompletado
                            lotePoderGerminativo={loteData.lotePoderGerminativo[0]}
                            loteData={loteData}
                        />
                    )}
                </Box>
            ) : permisosAcceso.botonIniciarAnalisisPG ? (
                <Box>
                    <Text color="secondaryGray.600" fontSize="sm" fontWeight="500" mb="10px">
                        Inicia el análisis de poder germinativo para completar la creación del lote
                        de semillas
                    </Text>

                    <Button
                        me={{ base: '10px', md: '20px' }}
                        mt="10px"
                        variant={'brand'}
                        border="1px solid"
                        onClick={() => {
                            navigate('/admin/lotes-poder-germinativo/nuevo/' + loteData.id);
                        }}
                    >
                        Iniciar análisis
                    </Button>
                </Box>
            ) : (
                <Box>
                    <Text color="secondaryGray.600" fontSize="sm" fontWeight="500" mb="10px">
                        El análisis aún no ha sido iniciado.
                    </Text>
                </Box>
            )}

            {/*<Card bg={cardColor} flexDirection="row" p="15px" px="20px" mt="auto">
                <Flex direction="column"
                      py="5px">
                    <Text
                        fontSize="xs"
                        color="secondaryGray.600"
                        fontWeight="700"
                        mb="5px"
                    >
                        Producción est.
                    </Text>
                    <Text fontSize="lg" color={textColor} fontWeight="700">
                        {totalObjetivos}
                    </Text>
                </Flex>
                <VSeparator ms="70px" me="20px"/>
                <Flex direction="column" py="5px">
                    <Text
                        fontSize="xs"
                        color="secondaryGray.600"
                        fontWeight="700"
                        mb="5px"
                    >
                        Planificado
                    </Text>
                    <Text fontSize="lg" color={textColor} fontWeight="700">
                        {totalPlanificado}
                    </Text>
                </Flex>
            </Card>*/}
        </Card>
    );
}
