export default class NumberFormatter {
    private locale: string;

    constructor(locale: string = 'es-ES') {
        this.locale = locale;
    }

    formatCurrency(amount: number, currency: string = 'USD'): string {
        const formatter = new Intl.NumberFormat(this.locale, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        return formatter.format(amount);
    }

    formatPercentage(value: number): string {
        const formatter = new Intl.NumberFormat(this.locale, {
            style: 'percent',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
        });
        return formatter.format(value);
    }
}
