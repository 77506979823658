// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    useColorModeValue,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
    AlertTitle,
    useDisclosure,
    Icon
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from 'config';
import axios, { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import DangerZone from 'components/cannava/dangerZone';
import { Usuario } from 'modules/auth/types/Usuario';
import { useLayoutContext } from 'contexts/LayoutContext';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import FilaRepeticion from './components/FilaRepeticion';
import { MdOutlineCloudUpload } from 'react-icons/md';
import Dropzone from 'views/admin/main/ecommerce/settingsProduct/components/Dropzone';
import CannavaTextareaField from 'components/fields/CannavaTextareaField';
import UploaderZone from 'components/cannava/uploaderZone';

type OpcionesSelect = {
    forma: any[];
    metodo: any[];
};

export default function NuevoReportePoderGerminativo() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { lotePoderGerminativoId } = useParams();
    const id: string = '0';
    const isEditMode = false;
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [files, setFiles] = useState([]);

    const navigate = useNavigate();

    // <editor-fold desc="Tabs">
    const generalTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const permissionsTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const resultadosTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const anexosTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const conclusionesTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;

    const tabs = ['general', 'permissions', 'resultados', 'anexos'];

    const [activeBullets, setActiveBullets] = useState({
        general: true,
        permissions: false,
        resultados: false,
        anexos: false,
        conclusiones: false
    });
    // </editor-fold>

    // <editor-fold desc="Resultados por repeticion">

    const totalRepeticiones = 4;

    const obtenerFilasRepeticiones = () => {
        let data = methods.getValues();
        console.log('dale que levanto la datita', data);
        // generate array from 1 to data.cantidad_repeticiones
        let cantidadRepeticiones = data.cantidad_repeticiones ? data.cantidad_repeticiones : '4';
        return Array.from(Array(parseInt(cantidadRepeticiones)).keys()).map(i => i + 1);
    };

    // </editor-fold>

    const { setFlashMessage, setFlashType } = useAppContext();
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);
    const [cargandoData, setCargandoData] = useState(false);
    const [loteData, setLoteData] = useState(null);

    // <editor-fold desc="Formularios">

    const fetchLoteData = async (loteId: string) => {
        setCargandoData(true);
        CannavaApiService.obtener(
            'lotePoderGerminativo',
            lotePoderGerminativoId,
            authToken,
            data => {
                console.log('data lote');
                console.log(data);
                setLoteData(data);
                methods.setValue('peso_muestra', data.peso_total_semillas);
                methods.setValue('tamanio_muestra', data.cantidad_semillas);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-semillas');
            }
        );
    };

    const [opcionesSelect, setOpcionesSelect] = useState<OpcionesSelect>({
        forma: [],
        metodo: []
    });

    useEffect(() => {
        let opciones: OpcionesSelect = {
            forma: [
                {
                    id: 'evaluacion-directa',
                    nombre: 'Evaluación directa mediante prueba de germinación'
                }
            ],
            metodo: [
                {
                    id: 'bp',
                    nombre: 'BP (Entre papel)'
                }
            ]
        };
        setOpcionesSelect(opciones);
    }, []);

    useEffect(() => {
        if (lotePoderGerminativoId) {
            fetchLoteData(lotePoderGerminativoId);
        }
    }, [lotePoderGerminativoId]);

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'reportePoderGerminativo',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/lotes-poder-germinativo/ver/' + loteData.id);
        },
        onError: error => {
            //navigate('/admin/lotes-poder-germinativo/ver/' + loteData.id);
            if (generalTab.current != undefined) generalTab.current.click();
        },
        onErrorDelete: error => {
            navigate('/admin/lotes-poder-germinativo');
        }
    });

    const onSubmit: SubmitHandler<any> = (data: any) => {
        data.lote_poder_germinativo_id = lotePoderGerminativoId;
        data.resultadosReporte = [];
        data.archivosAnexos = files.map(file => file.response.archivoId);

        // recorrer los resultados por repeticion y agregarlos al array de resultados
        for (let i = 1; i <= data.cantidad_repeticiones; i++) {
            data.resultadosReporte.push({
                posicion: i,
                semillas_germinadas: data[i + '_semillas_germinadas'],
                semillas_no_germinadas: data[i + '_semillas_no_germinadas'],
                observaciones: data[i + '_observaciones']
            });

            // @todo: eliminar las llaves default que fueron agregadas al array
            delete data[i + '_semillas_germinadas'];
            delete data[i + '_semillas_no_germinadas'];
            delete data[i + '_observaciones'];
        }

        // if (isEditMode) {
        //     useEnvioFormulario1.envioFormularioActualizar(id, data);
        //     return;
        // }

        console.log(data);
        useEnvioFormulario1.envioFormularioCrear(data);
    };

    // </editor-fold>

    // configuro layout
    const configurarNavegacion = useConfigurarNavegacion({
        breadcrumbs: [
            { label: 'Inicio', href: '/' },
            { label: 'Lotes de semillas', href: '/admin/lote-semillas/' },
            { label: 'Lote Id', href: '/admin/lote-semillas/ver' }
        ],
        titulo: 'Nuevo reporte de poder germinativo'
    });

    const handleDeleteUser = () => {
        CannavaApiService.usuarioEliminar(
            parseInt(id),
            authToken,
            data => {
                // Setear los valores en los campos del formulario
                setFlashMessage('Se eliminó el usuario correctamente!');
                setFlashType('success');
                navigate('/admin/usuarios');
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al eliminar el usuario.');
                setFlashType('error');
                navigate('/admin/usuarios');
            }
        );
    };

    const fetchUserData = async (userId: number) => {
        setCargandoData(true);
        CannavaApiService.usuarioObtener(
            userId,
            authToken,
            data => {
                // Setear los valores en los campos del formulario
                methods.setValue('nombre', data.nombre);
                methods.setValue('apellido', data.apellido);
                methods.setValue('email', data.email);
                methods.setValue('nombre_usuario', data.nombre_usuario);
                methods.setValue('rol', data.rol);
                methods.setValue('fecha_nacimiento', data.fecha_nacimiento);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/usuarios');
            }
        );
    };

    const mb: string = '0px';
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    useEffect(() => {
        if (enviandoFormulario) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            // Error callback
            // setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    /*
    useEffect(() => {
        console.log('cargo el use effect por id');
        if (id) {
            fetchUserData(parseInt(id));
        }
    }, [id]);
    */

    if (isEditMode && cargandoData) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            {!isDeleting && (
                <Flex
                    direction="column"
                    minH="100vh"
                    align="center"
                    pt={{ sm: '125px', lg: '75px' }}
                    position="relative"
                >
                    <Box
                        h="45vh"
                        bgGradient="linear(to-b, brand.400, brand.600)"
                        position="absolute"
                        w="100%"
                        borderRadius="20px"
                    />

                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Tabs
                                variant="unstyled"
                                zIndex="0"
                                mt={{ base: '60px', md: '64px' }}
                                display="flex"
                                flexDirection="column"
                            >
                                <TabList
                                    display="flex"
                                    alignItems="center"
                                    alignSelf="center"
                                    justifySelf="center"
                                >
                                    <Tab
                                        _focus={{ border: '0px', boxShadow: 'unset' }}
                                        ref={generalTab}
                                        w={{ sm: '120px', md: '250px', lg: '250px' }}
                                        onClick={() =>
                                            setActiveBullets({
                                                general: true,
                                                permissions: false,
                                                resultados: false,
                                                anexos: false,
                                                conclusiones: false
                                            })
                                        }
                                    >
                                        <Flex
                                            direction="column"
                                            justify="center"
                                            align="center"
                                            position="relative"
                                            _before={{
                                                content: "''",
                                                width: { sm: '120px', md: '250px', lg: '250px' },
                                                height: '3px',
                                                bg: activeBullets.permissions
                                                    ? 'white'
                                                    : 'brand.400',
                                                left: { sm: '12px', md: '80px' },
                                                top: {
                                                    sm: activeBullets.general ? '6px' : '4px',
                                                    md: null
                                                },
                                                position: 'absolute',
                                                bottom: activeBullets.general ? '40px' : '38px',

                                                transition: 'all .3s ease'
                                            }}
                                        >
                                            <Box
                                                zIndex="1"
                                                border="2px solid"
                                                borderColor={
                                                    activeBullets.general ? 'white' : 'brand.400'
                                                }
                                                bgGradient="linear(to-b, brand.400, brand.600)"
                                                w="16px"
                                                h="16px"
                                                mb="8px"
                                                borderRadius="50%"
                                            />
                                            <Text
                                                color={activeBullets.general ? 'white' : 'gray.300'}
                                                fontWeight={
                                                    activeBullets.general ? 'bold' : 'normal'
                                                }
                                                display={{ sm: 'none', md: 'block' }}
                                            >
                                                Análisis de calidad
                                            </Text>
                                        </Flex>
                                    </Tab>
                                    <Tab
                                        _focus={{ border: '0px', boxShadow: 'unset' }}
                                        ref={permissionsTab}
                                        w={{ sm: '120px', md: '250px', lg: '260px' }}
                                        onClick={() =>
                                            setActiveBullets({
                                                general: true,
                                                permissions: true,
                                                resultados: false,
                                                anexos: false,
                                                conclusiones: false
                                            })
                                        }
                                    >
                                        <Flex
                                            direction="column"
                                            justify="center"
                                            align="center"
                                            position="relative"
                                            _before={{
                                                content: "''",
                                                width: { sm: '120px', md: '250px', lg: '260px' },
                                                height: '3px',
                                                bg: activeBullets.resultados
                                                    ? 'white'
                                                    : 'brand.400',
                                                left: { sm: '12px', md: '85px' },
                                                top: {
                                                    sm: activeBullets.permissions ? '6px' : '4px',
                                                    md: null
                                                },
                                                position: 'absolute',
                                                bottom: activeBullets.permissions ? '40px' : '38px',

                                                transition: 'all .3s ease'
                                            }}
                                        >
                                            <Box
                                                zIndex="1"
                                                border="2px solid"
                                                borderColor={
                                                    activeBullets.permissions
                                                        ? 'white'
                                                        : 'brand.400'
                                                }
                                                bgGradient="linear(to-b, brand.400, brand.600)"
                                                w="16px"
                                                h="16px"
                                                mb="8px"
                                                borderRadius="50%"
                                            />
                                            <Text
                                                color={
                                                    activeBullets.permissions ? 'white' : 'gray.300'
                                                }
                                                fontWeight={
                                                    activeBullets.permissions ? 'bold' : 'normal'
                                                }
                                                display={{ sm: 'none', md: 'block' }}
                                            >
                                                Evaluación de viabilidad
                                            </Text>
                                        </Flex>
                                    </Tab>
                                    <Tab
                                        _focus={{ border: '0px', boxShadow: 'unset' }}
                                        ref={resultadosTab}
                                        w={{ sm: '120px', md: '250px', lg: '220px' }}
                                        onClick={() =>
                                            setActiveBullets({
                                                general: true,
                                                permissions: true,
                                                resultados: true,
                                                anexos: false,
                                                conclusiones: false
                                            })
                                        }
                                    >
                                        <Flex
                                            direction="column"
                                            justify="center"
                                            align="center"
                                            position="relative"
                                            _before={{
                                                content: "''",
                                                width: { sm: '120px', md: '250px', lg: '220px' },
                                                height: '3px',
                                                bg: activeBullets.anexos ? 'white' : 'brand.400',
                                                left: { sm: '12px', md: '40px' },
                                                top: {
                                                    sm: activeBullets.resultados ? '6px' : '4px',
                                                    md: null
                                                },
                                                position: 'absolute',
                                                bottom: activeBullets.resultados ? '40px' : '38px',

                                                transition: 'all .3s ease'
                                            }}
                                        >
                                            <Box
                                                zIndex="1"
                                                border="2px solid"
                                                borderColor={
                                                    activeBullets.resultados ? 'white' : 'brand.400'
                                                }
                                                bgGradient="linear(to-b, brand.400, brand.600)"
                                                w="16px"
                                                h="16px"
                                                mb="8px"
                                                borderRadius="50%"
                                            />
                                            <Text
                                                color={
                                                    activeBullets.resultados ? 'white' : 'gray.300'
                                                }
                                                fontWeight={
                                                    activeBullets.resultados ? 'bold' : 'normal'
                                                }
                                                display={{ sm: 'none', md: 'block' }}
                                            >
                                                Resultados
                                            </Text>
                                        </Flex>
                                    </Tab>
                                    <Tab
                                        _focus={{ border: '0px', boxShadow: 'unset' }}
                                        ref={anexosTab}
                                        w={{ sm: '120px', md: '250px', lg: '220px' }}
                                        onClick={() =>
                                            setActiveBullets({
                                                general: true,
                                                permissions: true,
                                                resultados: true,
                                                anexos: true,
                                                conclusiones: false
                                            })
                                        }
                                    >
                                        <Flex
                                            direction="column"
                                            justify="center"
                                            align="center"
                                            position="relative"
                                            _before={{
                                                content: "''",
                                                width: { sm: '120px', md: '250px', lg: '220px' },
                                                height: '3px',
                                                bg: activeBullets.conclusiones
                                                    ? 'white'
                                                    : 'brand.400',
                                                left: { sm: '12px', md: '30px' },
                                                top: {
                                                    sm: activeBullets.resultados ? '6px' : '4px',
                                                    md: null
                                                },
                                                position: 'absolute',
                                                bottom: activeBullets.anexos ? '40px' : '38px',

                                                transition: 'all .3s ease'
                                            }}
                                        >
                                            <Box
                                                zIndex="1"
                                                border="2px solid"
                                                borderColor={
                                                    activeBullets.anexos ? 'white' : 'brand.400'
                                                }
                                                bgGradient="linear(to-b, brand.400, brand.600)"
                                                w="16px"
                                                h="16px"
                                                mb="8px"
                                                borderRadius="50%"
                                            />
                                            <Text
                                                color={activeBullets.anexos ? 'white' : 'gray.300'}
                                                fontWeight={
                                                    activeBullets.anexos ? 'bold' : 'normal'
                                                }
                                                display={{ sm: 'none', md: 'block' }}
                                            >
                                                Anexos
                                            </Text>
                                        </Flex>
                                    </Tab>
                                    <Tab
                                        _focus={{ border: '0px', boxShadow: 'unset' }}
                                        ref={conclusionesTab}
                                        w={{ sm: '120px', md: '250px', lg: '220px' }}
                                        onClick={() =>
                                            setActiveBullets({
                                                general: true,
                                                permissions: true,
                                                resultados: true,
                                                anexos: true,
                                                conclusiones: true
                                            })
                                        }
                                    >
                                        <Flex
                                            direction="column"
                                            justify="center"
                                            align="center"
                                            position="relative"
                                        >
                                            <Box
                                                zIndex="1"
                                                border="2px solid"
                                                borderColor={
                                                    activeBullets.conclusiones
                                                        ? 'white'
                                                        : 'brand.400'
                                                }
                                                bgGradient="linear(to-b, brand.400, brand.600)"
                                                w="16px"
                                                h="16px"
                                                mb="8px"
                                                borderRadius="50%"
                                            />
                                            <Text
                                                color={
                                                    activeBullets.conclusiones
                                                        ? 'white'
                                                        : 'gray.300'
                                                }
                                                fontWeight={
                                                    activeBullets.conclusiones ? 'bold' : 'normal'
                                                }
                                                display={{ sm: 'none', md: 'block' }}
                                            >
                                                Conclusiones
                                            </Text>
                                        </Flex>
                                    </Tab>
                                </TabList>
                                <TabPanels mt="64px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
                                    <TabPanel
                                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                                        p="0px"
                                        mx="auto"
                                    >
                                        <Card p="30px">
                                            <Text
                                                color={textColor}
                                                fontSize="2xl"
                                                fontWeight="700"
                                                mb="20px"
                                            >
                                                Análisis de calidad
                                            </Text>
                                            {errorMessage && (
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    <AlertTitle>{errorMessage}</AlertTitle>
                                                </Alert>
                                            )}
                                            <Flex direction="column" w="100%">
                                                <Stack direction="column" spacing="20px">
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaInputField
                                                            id={'numero_informe'}
                                                            label={'Informe N°'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'encargado_muestra'}
                                                            label={
                                                                'Encargado de solicitar la muestra'
                                                            }
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaDatePickerField
                                                            id={'fecha_toma_muestra'}
                                                            label={'Fecha de toma de muestra'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'peso_muestra'}
                                                            label={'Peso de la muestra (gr)'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'tamanio_muestra'}
                                                            label={'Tamaño de la muestra (u.)'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaDatePickerField
                                                            id={'fecha_analisis_muestra'}
                                                            label={'Fecha de análisis de muestra'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                </Stack>
                                                <Flex justify="space-between" mt="24px">
                                                    <Button
                                                        variant="darkBrand"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        ms="auto"
                                                        onClick={() => {
                                                            permissionsTab.current.click();
                                                        }}
                                                    >
                                                        Siguiente
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    </TabPanel>
                                    <TabPanel
                                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                                        p="0px"
                                        mx="auto"
                                    >
                                        <Card p="30px">
                                            <Text
                                                color={textColor}
                                                fontSize="2xl"
                                                fontWeight="700"
                                                mb="20px"
                                            >
                                                Evaluación de viabilidad
                                            </Text>
                                            {errorMessage && (
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    <AlertTitle>{errorMessage}</AlertTitle>
                                                </Alert>
                                            )}
                                            <Flex direction="column" w="100%">
                                                <Stack direction="column" spacing="20px">
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaSelectField
                                                            id={'forma_evaluacion'}
                                                            label={'Forma'}
                                                            opciones={opcionesSelect.forma}
                                                            placeholder={'Seleccione una forma'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'temperatura'}
                                                            label={'Temperatura (ºC)'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'humedad_relativa'}
                                                            label={'Humedad relativa (%)'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaSelectField
                                                            id={'metodo'}
                                                            label={'Método'}
                                                            opciones={opcionesSelect.metodo}
                                                            placeholder={'Seleccione un método'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>

                                                    <Text
                                                        color={textColor}
                                                        fontSize="sm"
                                                        fontWeight="700"
                                                        mb="20px"
                                                    >
                                                        Diseño
                                                    </Text>

                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaInputField
                                                            id={'cantidad_repeticiones'}
                                                            label={'Cantidad de repeticiones (u.)'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                            onBlur={(
                                                                e: React.FocusEvent<HTMLInputElement>
                                                            ) => {
                                                                const repeticiones =
                                                                    +e.target.value;
                                                                let cantidad_semillas =
                                                                    methods.getValues()
                                                                        .tamanio_muestra;
                                                                let cantidad_semillas_repeticion =
                                                                    Math.floor(
                                                                        cantidad_semillas /
                                                                            repeticiones
                                                                    );
                                                                methods.setValue(
                                                                    'cantidad_semillas_repeticion',
                                                                    cantidad_semillas_repeticion
                                                                );
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'cantidad_semillas_repeticion'}
                                                            label={
                                                                'Cantidad de semillas por repetición (u.)'
                                                            }
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                </Stack>
                                                <Flex justify="space-between" mt="24px">
                                                    <Button
                                                        variant="light"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        onClick={() => generalTab.current.click()}
                                                    >
                                                        Anterior
                                                    </Button>

                                                    {/*  <Button
                                                        variant="darkBrand"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        isLoading={methods.formState.isSubmitting}
                                                        type="submit"
                                                    >
                                                        Enviar
                                                    </Button>*/}

                                                    <Button
                                                        variant="darkBrand"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        ms="auto"
                                                        onClick={() => {
                                                            resultadosTab.current.click();
                                                        }}
                                                    >
                                                        Siguiente
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    </TabPanel>

                                    {/* panel de resultados por repeticion */}
                                    <TabPanel
                                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                                        p="0px"
                                        mx="auto"
                                    >
                                        <Card p="30px">
                                            <Text
                                                color={textColor}
                                                fontSize="2xl"
                                                fontWeight="700"
                                                mb="20px"
                                            >
                                                Resultados
                                            </Text>
                                            {errorMessage && (
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    <AlertTitle>{errorMessage}</AlertTitle>
                                                </Alert>
                                            )}
                                            <Flex direction="column" w="100%">
                                                <Stack direction="column" spacing="20px">
                                                    {obtenerFilasRepeticiones().map(numero => (
                                                        <FilaRepeticion
                                                            key={numero}
                                                            textColor={textColor}
                                                            numero={numero}
                                                            methods={methods}
                                                        />
                                                    ))}
                                                </Stack>
                                                <Flex justify="space-between" mt="24px">
                                                    <Button
                                                        variant="light"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        onClick={() =>
                                                            permissionsTab.current.click()
                                                        }
                                                    >
                                                        Anterior
                                                    </Button>

                                                    <Button
                                                        variant="darkBrand"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        ms="auto"
                                                        onClick={() => {
                                                            anexosTab.current.click();
                                                        }}
                                                    >
                                                        Siguiente
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    </TabPanel>

                                    {/* panel de anexos */}
                                    <TabPanel
                                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                                        p="0px"
                                        mx="auto"
                                    >
                                        <Card p="30px">
                                            <Text
                                                color={textColor}
                                                fontSize="2xl"
                                                fontWeight="700"
                                                mb="20px"
                                            >
                                                Anexos
                                            </Text>
                                            {errorMessage && (
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    <AlertTitle>{errorMessage}</AlertTitle>
                                                </Alert>
                                            )}
                                            <Flex direction="column" w="100%">
                                                <Stack direction="column" spacing="20px">
                                                    <UploaderZone
                                                        files={files}
                                                        setFiles={setFiles}
                                                    ></UploaderZone>

                                                    <CannavaTextareaField
                                                        id={'observaciones_anexos'}
                                                        label={'Observaciones de los anexos'}
                                                        validationRules={{
                                                            required: 'Campo obligatorio'
                                                        }}
                                                    />
                                                </Stack>
                                                <Flex justify="space-between" mt="24px">
                                                    <Button
                                                        variant="light"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        onClick={() =>
                                                            resultadosTab.current.click()
                                                        }
                                                    >
                                                        Anterior
                                                    </Button>
                                                    <Button
                                                        variant="darkBrand"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        ms="auto"
                                                        onClick={() => {
                                                            conclusionesTab.current.click();
                                                        }}
                                                    >
                                                        Siguiente
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    </TabPanel>

                                    {/* panel de anexos */}
                                    <TabPanel
                                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                                        p="0px"
                                        mx="auto"
                                    >
                                        <Card p="30px">
                                            <Text
                                                color={textColor}
                                                fontSize="2xl"
                                                fontWeight="700"
                                                mb="20px"
                                            >
                                                Conclusiones
                                            </Text>
                                            {errorMessage && (
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    <AlertTitle>{errorMessage}</AlertTitle>
                                                </Alert>
                                            )}
                                            <Flex direction="column" w="100%">
                                                <Stack direction="column" spacing="20px">
                                                    <CannavaTextareaField
                                                        id={'conclusion'}
                                                        label={'Conclusiones'}
                                                        validationRules={{
                                                            required: 'Campo obligatorio'
                                                        }}
                                                    />
                                                </Stack>
                                                <Flex justify="space-between" mt="24px">
                                                    <Button
                                                        variant="light"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        onClick={() =>
                                                            resultadosTab.current.click()
                                                        }
                                                    >
                                                        Anterior
                                                    </Button>
                                                    <Button
                                                        variant="darkBrand"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        isLoading={methods.formState.isSubmitting}
                                                        type="submit"
                                                    >
                                                        Enviar
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </form>
                    </FormProvider>

                    {/* Danger Zone */}
                    {isEditMode && (
                        <DangerZone
                            elemento={'usuario'}
                            onConfirmDelete={() => {
                                setIsDeleting(true);
                                //useEnvioFormulario1.eliminarElemento(id);
                            }}
                        />
                    )}
                </Flex>
            )}

            {isDeleting && <PageLoader message="Eliminando usuario..." />}
        </>
    );
}
