import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Text, Icon, Progress, Flex, useColorModeValue, List, Button } from '@chakra-ui/react';
import { MdOutlineCloudUpload } from 'react-icons/md';
import CannavaApiService from 'utils/CannavaApiService';
import axios, { AxiosResponse, AxiosProgressEvent } from 'axios';
import { useAppContext } from '../../../contexts/AppContext';
import { HSeparator } from '../../separator/Separator';
import { Table, Thead, Tbody, Tr, Th, Td, Image } from '@chakra-ui/react';
import FilePreview from './components/FilePreview';

interface ArchivoSubido {
    file: File;
    preview: string;
    progress: number;
    response: any; // Reemplaza 'any' con el tipo correcto si lo conoces
}

interface UploaderZoneProps {
    files: ArchivoSubido[];
    setFiles: React.Dispatch<React.SetStateAction<ArchivoSubido[]>>;
    formato?: string;
}

export default function UploaderZone({ files, setFiles, formato }: UploaderZoneProps) {
    const { authToken } = useAppContext();
    const textColor = 'gray.700'; // Asegúrate de definir el color adecuado
    const brand = 'blue.500'; // Asegúrate de definir el color de la marca adecuado
    const bg = useColorModeValue('gray.100', 'navy.700');
    const bgHover = useColorModeValue('green.100', 'darkgreen.700');
    const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100');
    const [uploadProgress, setUploadProgress] = useState<Record<string, number>>({});
    const [apiResponses, setApiResponses] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState([]);

    const tamanoFuente = !formato ? 'lg' : 'md';

    const onDrop = useCallback(
        (acceptedFiles: any[]) => {
            acceptedFiles.forEach(file => {
                if (!files.some(f => f.file.name === file.name)) {
                    const uploadSpeed = 50000; // Velocidad promedio de carga en bytes por segundo
                    const totalTime = file.size / uploadSpeed; // Tiempo total de carga en segundos

                    let progress = 0;
                    const timer = setInterval(() => {
                        progress += uploadSpeed;

                        if (progress >= file.size) {
                            console.log('termino de subir');
                            progress = file.size;
                            clearInterval(timer);
                        } else {
                            console.log('seguimos subiendo');
                        }

                        setFiles(prevFiles =>
                            prevFiles.map(prevFile =>
                                prevFile.file.name === file.name
                                    ? {
                                          ...prevFile,
                                          progress: (progress / file.size) * 100
                                      }
                                    : prevFile
                            )
                        );
                    }, 1000);

                    CannavaApiService.archivoSubir(
                        file,
                        authToken, // Reemplaza esto con tu token real
                        response => {
                            setFiles(prevFiles =>
                                prevFiles.map(prevFile =>
                                    prevFile.file.name === file.name
                                        ? { ...prevFile, response, progress: 100 }
                                        : prevFile
                                )
                            );

                            if (timer) {
                                clearInterval(timer);
                            }
                        },
                        error => {
                            console.error(error); // Aquí puedes manejar los errores
                        }
                    );

                    setFiles(prevFiles => [
                        ...prevFiles,
                        {
                            file,
                            preview: URL.createObjectURL(file),
                            progress: 0,
                            response: null
                        }
                    ]);
                }
            });
        },
        [files, authToken]
    );

    const obtenerFilesIds = useCallback(() => {
        return files.map(file => (file.response ? file.response.archivoId : null));
    }, [files]);

    const handleDelete = (fileToDelete: File) => {
        setFiles(prevFiles => prevFiles.filter(file => file.file.name !== fileToDelete.name));

        // Aquí puedes hacer una llamada a tu API para eliminar el archivo del backend
        // Por ejemplo:
        // CannavaApiService.archivoEliminar(fileToDelete, authToken);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <>
            <Box w="100%" maxW="100%">
                <Flex
                    align={!formato ? 'center' : 'left'}
                    justify="center"
                    bg={!isDragActive ? bg : bgHover}
                    border="1px dashed"
                    borderColor={!isDragActive ? borderColor : borderColor}
                    borderRadius={!formato ? '16px' : '0'}
                    w="100%"
                    maxW="100%"
                    h="max-content"
                    flexDirection="column"
                    minH={!formato ? '130px' : 'auto'}
                    style={{
                        padding: !formato ? '20px' : '10px',
                        textAlign: !formato ? 'center' : 'left',
                        marginTop: !formato ? 'auto' : '10px'
                    }}
                    cursor="pointer"
                >
                    <div style={{ width: '100%' }} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Flex
                            direction={!formato ? 'column' : 'row'}
                            w={'100%'}
                            gap={'20px'}
                            pl={'20px'}
                        >
                            <Icon
                                as={MdOutlineCloudUpload}
                                w={!formato ? '80px' : '40px'}
                                h={!formato ? '80px' : '40px'}
                                color={textColor}
                                ml={!formato ? '0' : '20px'}
                                mx={'auto'}
                                maxWidth={!formato ? '80px' : '80px'}
                            />
                            <Box>
                                {isDragActive ? (
                                    <Box>
                                        <Text
                                            mb="2px"
                                            fontSize={tamanoFuente}
                                            fontWeight="700"
                                            whiteSpace="pre-wrap"
                                            color={textColor}
                                        >
                                            Soltá tus archivos!
                                        </Text>
                                        <Text
                                            fontSize="sm"
                                            fontWeight="500"
                                            color="secondaryGray.500"
                                        >
                                            PNG, JPG and GIF files are allowed
                                        </Text>
                                    </Box>
                                ) : (
                                    <Box>
                                        <Text
                                            mb="2px"
                                            fontSize={tamanoFuente}
                                            fontWeight="700"
                                            whiteSpace="pre-wrap"
                                            color={textColor}
                                        >
                                            Arrastrá tus archivos acá, o{' '}
                                            <Text
                                                as="span"
                                                fontSize={tamanoFuente}
                                                fontWeight="700"
                                                color={brand}
                                            >
                                                seleccionalos
                                            </Text>
                                        </Text>
                                        <Text
                                            fontSize="sm"
                                            fontWeight="500"
                                            color="secondaryGray.500"
                                        >
                                            PNG, JPG and GIF files are allowed
                                        </Text>
                                    </Box>
                                )}
                            </Box>
                            <Box ml={'50px'} flexGrow={'1'}>
                                {files.length > 0 && (
                                    <Box>
                                        <Flex
                                            direction="row"
                                            overflowX="auto"
                                            justify={'flex-start'}
                                            pt={!formato ? '30px' : '15px'}
                                        >
                                            {files.map(({ file, preview, progress, response }) => (
                                                <FilePreview
                                                    key={file.name}
                                                    file={file}
                                                    progress={progress}
                                                    preview={preview}
                                                    response={response}
                                                    onDelete={handleDelete}
                                                />
                                            ))}
                                        </Flex>
                                        <Text textAlign={'left'} fontSize={'sm'}>
                                            Archivos subidos
                                        </Text>
                                    </Box>
                                )}
                            </Box>
                        </Flex>
                    </div>
                </Flex>

                {/*<Button onClick={() => {console.log(obtenerFilesIds());}}>Ver ids</Button>*/}
            </Box>
        </>
    );
}
