import {
    Box,
    Card,
    Grid,
    Image,
    Text,
    useColorModeValue,
    Flex,
    Center,
    Spinner
} from '@chakra-ui/react';
import { useFetchData } from 'hooks/useFetchData';
import { useEffect, useState } from 'react';
import { Usuario } from 'modules/auth/types/Usuario';
import { useParams } from 'react-router-dom';

export default function PerfilUsuario() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { id } = useParams();

    useEffect(() => {
        console.log(id);
    });

    const transformUsuario = (data: any[]): Usuario[] => {
        const usuariosEncontrados = data.map(usuario => ({
            id: usuario.id,
            nombre: usuario.nombre,
            apellido: usuario.apellido,
            nombre_completo: usuario.nombre_completo,
            email: usuario.email,
            nombre_usuario: usuario.nombre_usuario,
            rol: usuario.rol,
            fecha_nacimiento: usuario.fecha_nacimiento,
            fecha_creacion: usuario.fecha_creacion,
            imagen: usuario.imagen,
            actions: usuario.actions,
            display: usuario.nombre_usuario
        }));

        const usuarioEncontrado = usuariosEncontrados.find(
            (usuario: Usuario) => usuario.id === parseInt(id)
        );

        setIsLoading(false);
        return [usuarioEncontrado];
    };

    const usuarioT = useFetchData<Usuario>('usuarios', transformUsuario);

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    //Alguna forma de recibir las ultimas 10 acciones realizadas por el usuario?
    const acciones = [
        {
            fecha: '05/03/2024',
            hora: '12:07',
            seccion: 'Lote semillas',
            id: 'S-SN-G-29-200224-1',
            accion: 'Nueva anotación'
        },
        {
            fecha: '05/03/2024',
            hora: '11:45',
            seccion: 'Lote germinación',
            id: 'LG-1-SN-G-29',
            accion: 'Lote aprobado'
        },
        {
            fecha: '04/03/2024',
            hora: '13:32',
            seccion: 'Lote germinación',
            id: 'LG-1-SN-G-29',
            accion: 'Nueva lote creado'
        },
        {
            fecha: '01/03/2024',
            hora: '09:17',
            seccion: 'Campaña',
            id: 'C-02-24-2',
            accion: 'Campaña activada'
        },
        {
            fecha: '29/02/2024',
            hora: '16:23',
            seccion: 'Proveedores',
            id: null,
            accion: 'Nuevo proveedor añadido'
        }
    ];

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            {isLoading && (
                <Center height="20vh">
                    <Box textAlign="center">
                        <Spinner size="lg" />
                        <Box mt={4}>Cargando informacion del usuario...</Box>
                    </Box>
                </Center>
            )}
            {usuarioT.data[0] && (
                <Card padding={4} marginBottom={4} /* width="fit-content" */>
                    <Box display="flex" alignItems="center">
                        <Image
                            src={usuarioT.data[0].imagen}
                            width={28}
                            height={28}
                            borderRadius={100}
                            marginRight={4}
                        />
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Text fontSize={30} fontWeight="bold">
                                {usuarioT.data[0].nombre_usuario}
                            </Text>
                            <Text>{usuarioT.data[0].email}</Text>
                        </Box>
                    </Box>
                </Card>
            )}
            {usuarioT.data[0] && (
                <Grid
                    gap={4}
                    mb={'30px'}
                    templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)' }}
                >
                    <Card padding={4}>
                        <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                            Información adicional
                        </Text>
                        <Text>Nombre: {usuarioT.data[0].nombre_usuario}</Text>
                        {/* <Text>Número de teléfono: {usuarioT.data[0]}</Text> */}
                        <Text>Rol: {usuarioT.data[0].rol}</Text>
                        <Text>¿?</Text>
                        {/* <Text>{user.birthdate}</Text>
                    <Text>{user.middle_name}</Text>
                    <Text>{user.zoneinfo}</Text> */}
                    </Card>
                    <Card padding={4} height={500}>
                        <Text
                            fontSize="2xl"
                            color={textColorPrimary}
                            fontWeight="bold"
                            marginBottom={4}
                        >
                            Actividad reciente
                        </Text>
                        <Grid gap={4} overflowY="scroll">
                            {acciones.map((accion, index) => {
                                return (
                                    <Card padding={2} key={index}>
                                        <Flex fontSize={20} fontWeight="medium">
                                            <Text marginRight={2}>{accion.seccion}:</Text>
                                            <Text>{accion.id}</Text>
                                        </Flex>
                                        <Text fontSize={18}>{accion.accion}</Text>
                                        <Flex fontSize={14} marginTop={2}>
                                            <Text marginRight={2}>{accion.fecha}</Text>
                                            <Text>{accion.hora}</Text>
                                        </Flex>
                                    </Card>
                                );
                            })}
                        </Grid>
                    </Card>
                </Grid>
            )}
        </Box>
    );
}
