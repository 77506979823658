import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import {
    API_BASE_URL,
    AUTH0_DOMAIN,
    AUTH0_CLIENT_ID,
    AUTH0_REDIRECT_URI,
    AUTH0_AUDIENCE
} from 'config';
import { useNavigate } from 'react-router-dom';

export const Auth0ProviderWithNavigate = ({ children }) => {
    const navigate = useNavigate();

    const domain = AUTH0_DOMAIN;
    const clientId = AUTH0_CLIENT_ID;
    const audience = AUTH0_AUDIENCE;
    const redirectUri = AUTH0_REDIRECT_URI;

    // console.log("dominio: " + domain);
    // console.log("clientId: " + clientId);
    // console.log("redirectUri: " + redirectUri);

    const onRedirectCallback = appState => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId && redirectUri)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri,
                audience: 'https://api.cannava.local',
                scope: 'openid profile email'
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};
