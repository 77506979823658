// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    useColorModeValue,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useDisclosure,
    Switch,
    Checkbox
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import Index from 'components/cannava/dangerZone';
import { useLayoutContext } from 'contexts/LayoutContext';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import { Proveedor } from 'modules/genetica/types/Proveedor';
import { useFetchData } from 'hooks/useFetchData';
import { Variedad } from 'modules/genetica/types/Variedad';
import { useEnvioFormularioAntiguo } from 'hooks/useEnvioFormularioAntiguo';
import DangerZone from 'components/cannava/dangerZone';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import { Establecimiento } from 'modules/locacion/types/Establecimiento';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import CannavaTextAreaField from 'components/fields/CannavaTextareaField';
import { Producto } from 'modules/catalogo/types/Producto';

type MonitoreoTemperaturaHumedadData = {
    lote_germinacion_id: string;
    fecha_inicio: string;
    hora_inicio: string;
    temperatura: number;
    humedad: number;
    oscuridad: string;
    campos: {
        temperatura: number;
        humedad: number;
        oscuridad: boolean;
    };
};

export default function MonitoreoTemperatura() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const { setBreadcrumbs, setTitle } = useLayoutContext();

    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const navigate = useNavigate();
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);

    const oscuridadOpciones: any[] = [
        { id: 0, nombre: 'No cumple' },
        { id: 1, nombre: 'Cumple' }
    ];

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'monitoreoTemperaturaHumedad',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/lotes-germinacion/monitoreo-ambiental/' + id);
        },
        onError: error => {
            console.log(error);
        },
        onErrorDelete: error => {
            navigate('/admin/lotes-germinacion');
        }
    });

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Trazabilidad', href: '/admin/trazabilidad/' },
            { label: 'Lotes de germinacion', href: '/admin/lotes-germinacion' }
            /* Añadir para volver al lote  { label: 'Lotes de germinacion', href: '/admin/lotes-germinacion/:id' } */
        ]);

        setTitle('Lote');
    }, []);

    const onSubmit: SubmitHandler<MonitoreoTemperaturaHumedadData> = (
        data: MonitoreoTemperaturaHumedadData
    ) => {
        data.campos = {
            temperatura: data.temperatura,
            humedad: data.humedad,
            oscuridad: data.oscuridad ? true : false
        };

        data.lote_germinacion_id = id;

        //obtener ids de plantinera y producto, falta implementar
        useEnvioFormulario1.envioFormularioCrear(data);
        console.log(data);
    };

    const onCancel: any = () => {
        navigate('/admin/lotes-germinacion');
    };

    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    // endregion

    useEffect(() => {
        console.log(oscuridadOpciones);
    }, []);

    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            <Flex
                direction="column"
                minH="100vh"
                align="center"
                pt={{ sm: '125px', lg: '75px' }}
                position="relative"
            >
                <Box
                    h="45vh"
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    position="absolute"
                    w="100%"
                    borderRadius="20px"
                />

                <FormProvider {...methods}>
                    <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Card p="30px" mt={'60px'}>
                                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                    Monitoreo de temperatura y humedad
                                </Text>
                                {errorMessage && (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertTitle>{errorMessage}</AlertTitle>
                                    </Alert>
                                )}
                                <Flex direction="column" w="100%">
                                    <Stack direction="column" spacing="20px">
                                        <SimpleGrid gap="20px">
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaDatePickerField
                                                    id={'fecha_inicio'}
                                                    label={'Fecha de inicio'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'hora_inicio'}
                                                    label={'Hora de inicio'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            </SimpleGrid>
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaInputField
                                                    id={'temperatura'}
                                                    label={'Temperatura (ºC)'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'humedad'}
                                                    label={'Humedad (%)'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            </SimpleGrid>
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaSelectField
                                                    id={'oscuridad'}
                                                    label={'Oscuridad'}
                                                    opciones={oscuridadOpciones}
                                                    placeholder={'-'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            </SimpleGrid>
                                        </SimpleGrid>
                                    </Stack>
                                    <Flex justify="space-between" mt="24px">
                                        <Button
                                            variant="outline"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            //navegar a lote
                                            onClick={onCancel}
                                            type="button"
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="darkBrand"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            isLoading={methods.formState.isSubmitting}
                                            type="submit"
                                        >
                                            Continuar
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Card>
                        </form>
                    </Box>
                </FormProvider>
            </Flex>
        </>
    );
}
