import { LoteGerminacion } from 'modules/trazabilidad/types/LoteGerminacion';
import { Box, Button, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import Card from 'components/card/Card';
import { FilaInfoRegistro } from '../../verLote/components/FilaInfoRegistro';
import { useNavigate } from 'react-router-dom';

type CardControlSiembraProps = {
    lote: LoteGerminacion;
};

export default function CardControlSiembra(props: CardControlSiembraProps /* : Props */) {
    const { lote } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const navigate = useNavigate();
    const estadosValidos = [
        'siembra-iniciada',
        'bandejas-preparadas',
        'bandejas-finalizadas',
        'semillado-iniciado',
        'semillado-finalizado',
        'germinacion-iniciada',
        'germinacion-finalizada',
        'finalizado',
        'enviado-a-plantinera',
        'plantinera-recibida',
        'plantinera-iniciada',
        'plantinera-monitoreada',
        'plantinera-finalizada'
    ];

    const generarCodigoReporte = (num: number) => {
        let strNum = num.toString();
        return strNum.padStart(4, '0');
    };

    const verReporteSiembra = () => {
        navigate(
            '/admin/lotes-germinacion/informe-control-siembra/' + lote.reporteControlSiembra.id
        );
    };

    if (!estadosValidos.includes(lote.estado)) return <></>;

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center" mb="5">
                <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                    Siembra
                </Text>
            </Flex>

            <FilaInfoRegistro
                label={'Nro. de control'}
                value={generarCodigoReporte(lote.reporteControlSiembra.id)}
                click={verReporteSiembra}
            />
        </Card>
    );
}
