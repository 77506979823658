import React from 'react';
import { useFormContext } from 'react-hook-form';
import { EtapaProceso } from '../../../../types/EtapaProceso';
import { Ubicacion } from '../../../../../locacion/types/Ubicacion'; // Asegúrate de importar el tipo desde donde lo tienes definido.
import CannavaSelectField from 'components/fields/CannavaSelectField';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import { Center, Flex, Text } from '@chakra-ui/react';
import { SimpleGrid } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';

type FilaEtapaPlanificacionProps = {
    etapa: EtapaProceso;
    locaciones: Ubicacion[];
};

const FilaEtapaPlanificacion: React.FC<FilaEtapaPlanificacionProps> = props => {
    const { etapa, locaciones, ...rest } = props;
    // Suponiendo que opcionesSelect.locaciones esté disponible en este scope o lo pases como prop
    return (
        <div>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap="20px">
                <Text
                    fontSize={{ base: 'md', md: 'xl' }}
                    fontWeight="regular"
                    textAlign={'right'}
                    mt={'35px'}
                    mr={'20px'}
                >
                    {etapa.titulo}
                </Text>

                {etapa.tieneLocacion && (
                    <CannavaSelectField
                        id={`${etapa.nombre}_locacion_id`}
                        label={'Locación'}
                        opciones={locaciones}
                        mb={'10px'}
                        placeholder={'Seleccione una locación'}
                        validationRules={{ required: 'Campo obligatorio' }}
                    />
                )}

                <CannavaDatePickerField
                    id={`${etapa.nombre}_fecha_inicio`}
                    label={'Fecha de inicio'}
                    mb={'10px'}
                    validationRules={{ required: 'Campo obligatorio' }}
                />

                <CannavaDatePickerField
                    id={`${etapa.nombre}_fecha_fin`}
                    label={'Fecha fin'}
                    mb={'10px'}
                    validationRules={{ required: 'Campo obligatorio' }}
                />
            </SimpleGrid>

            <HSeparator my={2} />
        </div>
    );
};

export default FilaEtapaPlanificacion;
