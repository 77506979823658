import React from 'react';
import { Box, Image, Text, Progress, Center, Circle } from '@chakra-ui/react';
import { FaFile } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

interface FilePreviewProps {
    file: any;
    response: any;
    progress: number;
    preview: string;
    onDelete: CallableFunction;
}

const FilePreview: React.FC<FilePreviewProps> = ({
    file,
    preview,
    progress,
    response,
    onDelete
}) => {
    const isImage = file.type.startsWith('image/');
    const tamanoBox = '64px';

    return (
        <Box width={tamanoBox} textAlign="center" position="relative" key={file.name} mr={'20px'}>
            <Circle
                position="absolute"
                top="-10px"
                right="-10px"
                cursor="pointer"
                bg="white"
                size="24px"
                onClick={() => onDelete(file)}
                _hover={{ bg: 'gray.200' }}
            >
                <MdClose size="16px" />
            </Circle>

            {isImage ? (
                <Image
                    src={response ? response.url : preview}
                    alt="preview"
                    boxSize={tamanoBox}
                    objectFit="cover"
                    title={file.name}
                />
            ) : (
                <FaFile size={tamanoBox} title={file.name} />
            )}
            {/*<Text fontSize="sm">{file.name}</Text>*/}

            {progress < 100 && (
                <Center position="absolute" top="50%" left="0" right="0" w="80%" mx="auto">
                    <Progress value={progress} size="md" colorScheme="blue" isAnimated hasStripe />
                </Center>
            )}
        </Box>
    );
};

export default FilePreview;
