import { Flex, SimpleGrid, Text, useColorModeValue, Divider } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { FilaInfoRegistro } from './FilaInfoRegistro';
import { ControlPlantinera } from 'modules/trazabilidad/types/ControlPlantinera';
import { Plantinera } from 'modules/trazabilidad/types/Plantinera';

type InfoControlProps = {
    controlData: Plantinera;
};

export default function InfoControl(props: InfoControlProps) {
    const { controlData } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center" mb="5">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Plantinera
                </Text>
            </Flex>
            <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing={{ base: '20px', xl: '5px' }}>
                <FilaInfoRegistro label={'Fecha de inicio'} value={controlData.fecha_inicio} />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de bandejas ingresadas'}
                    value={controlData.numero_bandejas_ingresadas}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Ubicación de la plantinera'}
                    value={controlData.ubicacion_id}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'¿Se verificó la limpieza del área de plantinera?'}
                    value={controlData.limpieza_area_plantinera ? 'SI' : 'NO'}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'¿Se realizó el monitoreo de los plantines?'}
                    value={controlData.monitoreo_plantines ? 'SI' : 'NO'}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'¿Fecha de finalización de plantinera?'}
                    value={controlData.fecha_finalizacion}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de bandejas egresadas'}
                    value={controlData.numero_bandejas_egresadas}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de plantines dados de baja'}
                    value={controlData.bajas_plantines}
                />
            </SimpleGrid>
        </Card>
    );
}
