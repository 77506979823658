// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';

// Custom components
import { HorizonLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';
import imgCannavaLogo from 'assets/img/cannava/cannava_logo.png';

export function SidebarBrand(props: { mini: boolean; hovered: boolean }) {
    const { mini, hovered } = props;
    //   Chakra color mode
    let logoColor = useColorModeValue('navy.700', 'white');

    return (
        <Flex alignItems="center" flexDirection="column">
            <Image
                src={imgCannavaLogo}
                alt="Cannava Logo"
                h="62px"
                w="175px"
                my="32px"
                display={
                    mini === false ? 'block' : mini === true && hovered === true ? 'block' : 'none'
                }
            />
            <Text
                display={
                    mini === false ? 'none' : mini === true && hovered === true ? 'none' : 'block'
                }
                fontSize={'30px'}
                fontWeight="800"
                color={logoColor}
            >
                Cannava
            </Text>
            <HSeparator mb="20px" />
        </Flex>
    );
}

export default SidebarBrand;
