// Chakra imports
import { Flex, Stat, StatLabel, StatNumber, useColorModeValue, Text } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';

export default function Default(props: {
    startContent?: JSX.Element;
    endContent?: JSX.Element;
    name: string;
    growth?: string | number;
    value: string | number;
    formato?: string;
    click?: () => void;
}) {
    const { startContent, endContent, name, growth, value, formato, click } = props;
    let primaryColor = useColorModeValue('secondaryGray.900', 'white');
    let primaryColorPG = useColorModeValue('white', 'white');

    const textColor = formato == 'lotePG' ? primaryColorPG : primaryColor;
    const textColorSecondary = formato == 'lotePG' ? 'secondaryGray.400' : 'secondaryGray.600';
    const backgroundColor = formato == 'lotePG' ? 'green.400' : 'white';

    return (
        <Card py="15px" bgColor={backgroundColor}>
            <Flex
                my="auto"
                h="100%"
                align={{ base: 'center', xl: 'start' }}
                justify={{ base: 'center', xl: 'center' }}
            >
                {startContent}

                <Stat my="auto" ms={startContent ? '18px' : '0px'}>
                    <StatLabel
                        lineHeight="100%"
                        color={textColorSecondary}
                        fontSize={{
                            base: 'sm'
                        }}
                    >
                        {name}
                    </StatLabel>
                    <StatNumber
                        color={textColor}
                        fontSize={{
                            base: '2xl'
                        }}
                    >
                        {click ? (
                            <Text onClick={click} cursor="pointer">
                                {value}
                            </Text>
                        ) : (
                            <Text> {value}</Text>
                        )}
                    </StatNumber>
                    {growth ? (
                        <Flex align="center">
                            <Text color="green.500" fontSize="xs" fontWeight="700" me="5px">
                                {growth}
                            </Text>
                            <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                                since last month
                            </Text>
                        </Flex>
                    ) : null}
                </Stat>
                <Flex ms="auto" w="max-content">
                    {endContent}
                </Flex>
            </Flex>
        </Card>
    );
}
