import { Avatar, Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ControlPlantinera } from 'modules/trazabilidad/types/ControlPlantinera';
import { Plantinera } from 'modules/trazabilidad/types/Plantinera';

type Props = {
    controlData: Plantinera;
    children?: React.ReactNode | React.ReactNode[];
};

export default function Responsable(props: Props) {
    const { controlData } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Realizado por
                </Text>
            </Flex>

            <Flex mt={'20px'}>
                <Avatar
                    name={'Usuario Cannava' /* Setear usuario responsable del control */}
                    h="75px"
                    w="75px"
                    mr="5"
                    /* src={anotacion.usuario.imagen} Imagen perfil del usuario*/
                />
                <Flex flexDirection="column" justify="center">
                    <Text fontSize={25} fontWeight="bold">
                        Usuario Cannava {/* Nombre del usuario */}
                    </Text>
                    <Text fontSize={15}>Personal de campo {/* Responsabilidad del usuario */}</Text>
                </Flex>
            </Flex>
        </Card>
    );
}
