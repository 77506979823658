import { Flex, SimpleGrid, Text, useColorModeValue, Divider } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { FilaInfoRegistro } from './FilaInfoRegistro';
import { ControlSiembra } from 'modules/trazabilidad/types/ControlSiembra';

type InfoControlProps = {
    controlDataBandejas: ControlSiembra;
    label: string;
};

export default function InfoControlBandejas(props: InfoControlProps) {
    const { controlDataBandejas, label } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const fecha_inicio_preparacion_bandejas =
        controlDataBandejas.fecha_inicio_preparacion_bandejas == null
            ? 'Aún no realizado'
            : controlDataBandejas.fecha_inicio_preparacion_bandejas.split(' ')[0];

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center" mb="5">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    {label}
                </Text>
            </Flex>
            <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing={{ base: '20px', xl: '5px' }}>
                <FilaInfoRegistro
                    label={'Fecha inicial'}
                    value={fecha_inicio_preparacion_bandejas}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Hora inicial'}
                    value={controlDataBandejas.hora_inicio_preparacion_bandejas}
                />
                {/*<Divider border="1px" borderColor="lightgray" />*/}
                {/*<FilaInfoRegistro*/}
                {/*    label={'Fecha final'}*/}
                {/*    value={''/* controlDataBandejas.fecha_final *!/*/}
                {/*/>*/}
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Hora final'}
                    value={controlDataBandejas.hora_finalizacion_bandejas}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de operarios'}
                    value={controlDataBandejas.numero_operarios_preparacion_bandejas}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de bandejas armadas'}
                    value={controlDataBandejas.numero_bandejas_armadas_finalizacion_bandejas}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de semillas usadas'}
                    value={controlDataBandejas.numero_semillas_usadas_finalizacion_bandejas}
                />
            </SimpleGrid>
        </Card>
    );
}
