import { Campana } from '../../../../types/Campana';
import { Box, Text, Flex, useColorModeValue } from '@chakra-ui/react';
import EventCalendar from 'components/calendar/EventCalendar';
import Card from 'components/card/Card';
import React, { useState, useEffect } from 'react';

type CalendarioLocacionesProps = {
    campana: Campana;
};

type EventoCalendario = {
    title: string;
    borderColor: string;
    start: string;
    end: string;
    backgroundColor: string;
    className: string;
};

/**
 * Componente para mostrar el calendario de locaciones
 * @constructor
 */
export default function CalendarioLocaciones(props: CalendarioLocacionesProps) {
    const { campana } = props;
    const [eventosCalendario, setEventosCalendarios] = useState([]);
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'secondaryGray.600';

    useEffect(() => {
        let eventos: EventoCalendario[] = [];
        let colores = {
            siembra: ['#a3a171', '#d9cf91'],
            transplante: ['rgba(32,162,54,0.96)', 'rgb(35,108,31)', 'rgb(47,126,36)'],
            floracion: ['#2272ab', '#1668a2', '#1a72a2'],
            cosecha: ['#be2525', '#ab2c38', '#b92626'],
            'post-cosecha': ['#672d8f', '#541786', '#3e2880']
        };

        campana.planificaciones.forEach(planificacion => {
            // obtener un numero random del 0 al 6

            planificacion.etapas.forEach(etapa => {
                console.log(etapa);
                // @ts-ignore
                let random = Math.floor(Math.random() * colores[etapa.etapa].length);
                let titulo = '';

                if (planificacion.objetivo) {
                    titulo += planificacion.objetivo.genetica.nombre;
                    titulo += ' x ' + planificacion.cantidad;
                }

                titulo += ' #' + planificacion.id;
                titulo += ' > ' + etapa.etapa + ' - ';

                if (etapa.locacion) {
                    titulo += ' - ' + etapa.locacion.nombre;
                }

                eventos.push({
                    title: titulo,
                    borderColor: 'transparent',
                    start: etapa.fecha_inicio,
                    end: etapa.fecha_fin,
                    // @ts-ignore
                    backgroundColor: colores[etapa.etapa][random],
                    className: 'locacion'
                });
            });
        });

        setEventosCalendarios(eventos);
    }, [campana]);

    return (
        <Card colorScheme="whiteAlpha" mb="40px" p="24px" pb={0}>
            <Flex direction="column" mb="30px" ms="10px">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Reserva de locaciones
                </Text>
                <Text fontSize="md" color={textColorSecondary}>
                    En esta sección podrás revisar como se asignan las distintas locaciones en
                    función de las planificaciones y objetivos.
                </Text>

                <Box h={'600px'} mt={'20px'}>
                    <EventCalendar
                        initialDate={campana.fecha_inicio}
                        calendarData={eventosCalendario}
                    />
                </Box>
            </Flex>
        </Card>
    );
}
