// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue,
    Alert,
    AlertIcon,
    AlertTitle
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import { useLayoutContext } from 'contexts/LayoutContext';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';

type LimpiezaData = {
    fecha: string;
    hora: string;
    encargado: string;
    personal_siembra: string;
    productos_limpieza: string;
};

export default function LimpiezaAreaGerminacion() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const location = useLocation();
    const detallesObject = location.state?.detalles;

    const [hora, setHora] = useState<string>('');
    const [personal, setPersonal] = useState<string>('');
    const [encargado, setEncargado] = useState<string>('');
    const [productos, setProductos] = useState<string>('');

    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const navigate = useNavigate();
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'reporteControlCalidad',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/lotes-germinacion');
        },
        onError: error => {
            console.log(error);
        },
        onErrorDelete: error => {
            navigate('/admin/lotes-germinacion');
        }
    });

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Trazabilidad', href: '/admin/trazabilidad/' },
            { label: 'Lotes de germinacion', href: '/admin/lotes-germinacion' },
            { label: 'Lotes de germinacion', href: '/admin/lotes-germinacion/ver/' + id }
        ]);

        setTitle('Lote');
    }, []);

    const onSubmit: SubmitHandler<LimpiezaData> = (data: LimpiezaData) => {
        //falta descargar el id de la plantinera
        console.log(data);
        //useEnvioFormulario1.envioFormularioCrear(data);
    };

    const onCancel: any = () => {
        navigate('/admin/lotes-germinacion/ver/' + id);
    };

    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    useEffect(() => {
        console.log(detallesObject);
        if (detallesObject) {
            setHora(detallesObject.hora);
            methods.setValue('hora', detallesObject.hora);
            setPersonal(detallesObject.personal);
            methods.setValue('personal_siembra', detallesObject.personal);
            setEncargado(detallesObject.encargado);
            methods.setValue('encargado', detallesObject.encargado);
            setProductos(detallesObject.productos);
            methods.setValue('productos_limpieza', detallesObject.productos);
        }
    }, [detallesObject]);

    // endregion
    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            <Flex
                direction="column"
                minH="100vh"
                align="center"
                pt={{ sm: '125px', lg: '75px' }}
                position="relative"
            >
                <Box
                    h="45vh"
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    position="absolute"
                    w="100%"
                    borderRadius="20px"
                />

                <FormProvider {...methods}>
                    <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Card p="30px" mt={'60px'}>
                                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                    Limpieza de área
                                </Text>
                                {errorMessage && (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertTitle>{errorMessage}</AlertTitle>
                                    </Alert>
                                )}
                                <Flex direction="column" w="100%">
                                    <Stack direction="column" spacing="20px">
                                        <SimpleGrid gap="20px">
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaDatePickerField
                                                    id={'fecha'}
                                                    label={'Fecha de realización'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'hora'}
                                                    label={'Hora de realización'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            </SimpleGrid>
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaInputField
                                                    id={'personal_siembra'}
                                                    label={'Limpió'}
                                                    placeholder={'Personal de siembra'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'encargado'}
                                                    label={'Supervisó'}
                                                    placeholder={'Encargado'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            </SimpleGrid>
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaInputField
                                                    id={'productos_limpieza'}
                                                    label={'Productos utilizados'}
                                                    placeholder={'Productos de limpieza'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            </SimpleGrid>
                                        </SimpleGrid>
                                    </Stack>
                                    <Flex justify="space-between" mt="24px">
                                        <Button
                                            variant="outline"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            //navegar a lote
                                            onClick={onCancel}
                                            type="button"
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="darkBrand"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            isLoading={methods.formState.isSubmitting}
                                            type="submit"
                                        >
                                            Continuar
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Card>
                        </form>
                    </Box>
                </FormProvider>
            </Flex>
        </>
    );
}
