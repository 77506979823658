import { useEffect, useRef } from 'react';

// Hook personalizado
export default function useScrollToTop() {
    const ref = useRef(null); // Crea una referencia al elemento

    const scrollToTop = () => {
        if (ref.current) {
            //console.log("vamo arriba");
            ref.current.scrollIntoView();
            /*setTimeout(() => {
                if (ref.current) {

                }
            }, 500);*/
        }
    };

    return { ref, scrollToTop }; // Retorna la referencia
}
