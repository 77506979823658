import { Avatar, Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ControlPlantinera } from 'modules/trazabilidad/types/ControlPlantinera';
import { Plantinera } from 'modules/trazabilidad/types/Plantinera';

type Props = {
    controlData: Plantinera;
    children?: React.ReactNode | React.ReactNode[];
};

export default function Observaciones(props: Props) {
    const { controlData } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    /* Modificador del string tiempo realizacion observacion */
    /* const transformAnotacion = (data: any[]): Anotaciones[] => {
        const rtf = new Intl.RelativeTimeFormat('es-AR', { numeric: 'auto' });

        return data.map(anotacion => {
            const date = new Date(anotacion.fecha_creacion);
            const now = new Date();
            const diff = now.getTime() - date.getTime();
            const seconds = Math.floor(diff / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            let formattedTime;
            if (-days === -1) {
                formattedTime = 'ayer';
            } else if (-days < -1) {
                const month = date.toLocaleString('es-AR', { month: 'short' });
                formattedTime = `${month} ${date.getDate()}`;
            } else if (seconds < 60) {
                formattedTime = rtf.format(-seconds, 'second');
            } else if (minutes < 60) {
                formattedTime = rtf.format(-minutes, 'minute');
            } else if (hours < 24) {
                formattedTime = rtf.format(-hours, 'hour');
            } else {
                formattedTime = rtf.format(-days, 'day');
            }

            return {
                id: anotacion.id,
                usuario_id: anotacion.usuario_id,
                modelo: anotacion.modelo,
                modelo_id: anotacion.modelo_id,
                texto: anotacion.texto,
                textoConLinks: anotacion.textoConLinks,
                fecha_creacion: formattedTime,
                fecha_actualizacion: anotacion.fecha_actualizacion,
                usuario: anotacion.usuario,
                adjuntos: anotacion.adjuntos,
                actions: 'Actions'
            };
        });
    }; */

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Observaciones
                </Text>
            </Flex>

            <Flex mt={'20px'}>
                <Avatar
                    name={'Usuario Cannava'}
                    h="45px"
                    w="45px"
                    mr="5"
                    /* src={anotacion.usuario.imagen} */
                />
                <Flex flexDirection="column" justify="center" gap={4}>
                    <Text>{controlData.observaciones_generales}</Text>
                    <Text fontSize={13}>2 minutos atras</Text>
                </Flex>
            </Flex>
        </Card>
    );
}
