// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    useColorModeValue,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useDisclosure,
    Switch
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import Index from 'components/cannava/dangerZone';
import { useLayoutContext } from 'contexts/LayoutContext';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import { Proveedor } from 'modules/genetica/types/Proveedor';
import { useFetchData } from 'hooks/useFetchData';
import { Variedad } from 'modules/genetica/types/Variedad';
import { useEnvioFormularioAntiguo } from 'hooks/useEnvioFormularioAntiguo';
import DangerZone from 'components/cannava/dangerZone';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import { Establecimiento } from 'modules/locacion/types/Establecimiento';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import CannavaTextAreaField from 'components/fields/CannavaTextareaField';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';

type FinalizarGerminacionData = {
    lote_id: string;
    id: number;
    fecha_egreso: string;
    hora_final_egreso: string;
    bandejas_egreso: number;
    descarte_egreso: number;
    cantidad_descarte_egreso: number;
    observaciones_generales: string;
};

export default function FinalizacionGerminacion() {
    // region Variables

    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const descarte = methods.watch('descarte', false);

    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const navigate = useNavigate();
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loteData, setLoteData] = useState(null);
    const { setFlashMessage, setFlashType } = useAppContext();
    const [cargandoData, setCargandoData] = useState(false);

    // endregion

    // region Hooks y callbacks

    const fetchLoteData = async (loteId: string) => {
        setCargandoData(true);
        CannavaApiService.lotesGerminacionObtener(
            loteId,
            authToken,
            data => {
                console.log('data lote germinacion');
                console.log(data);

                // @todo: chequear que el estado del lote sea valido
                setLoteData(data);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion');
            }
        );
    };

    // configuro el layout
    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: [
                { label: 'Inicio', href: '/admin/home/' },
                { label: 'Trazabilidad', href: '/admin/home/' },
                { label: 'Lotes de germinación', href: '/admin/lotes-germinacion' },
                { label: 'Lotes de germinación', href: '/admin/lotes-germinacion/ver/' + id },
                { label: 'Finalizar germinación', href: '#' }
            ],
            titulo: () => {
                // Generar título basado en data cargada
                return !loteData ? '' : `Lote ${loteData.codigo_lote}`;
            }
        },
        loteData
    );

    const onSubmit: SubmitHandler<FinalizarGerminacionData> = (data: FinalizarGerminacionData) => {
        data.lote_id = id;
        data.descarte_egreso = descarte ? 1 : 0;
        setCargandoData(true);
        CannavaApiService.request(
            'POST',
            `/trazabilidad/api/lote-germinacion/finalizar-germinacion`,
            data,
            authToken,
            data => {
                console.log(data);
                setFlashMessage('Se finalizó la germinación correctamente.');
                setFlashType('success');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion/ver/' + id);
            },
            (error: any) => {
                if (error.response.status == 422) {
                    console.log(error.response.data);
                    for (let i = 0; i < error.response.data.length; i++) {
                        const errorValidacion: any = error.response.data[i];
                        methods.setError(errorValidacion.field, {
                            message: errorValidacion.message
                        });
                    }
                } else {
                    alert('Error desconocido');
                    console.log(error);
                }

                setCargandoData(false);
            }
        );
    };

    const onCancel: any = () => {
        navigate('/admin/lotes-germinacion');
    };

    // endregion

    // region useEffect

    useEffect(() => {
        if (id) {
            fetchLoteData(id);
        }
    }, [id]);

    useEffect(() => {
        if (cargandoData) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    // endregion

    if (cargandoData) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            <Flex
                direction="column"
                minH="100vh"
                align="center"
                pt={{ sm: '125px', lg: '75px' }}
                position="relative"
            >
                <Box
                    h="45vh"
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    position="absolute"
                    w="100%"
                    borderRadius="20px"
                />

                <FormProvider {...methods}>
                    <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Card p="30px" mt={'60px'}>
                                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                    Finalizar Germinación
                                </Text>
                                {errorMessage && (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertTitle>{errorMessage}</AlertTitle>
                                    </Alert>
                                )}
                                <Flex direction="column" w="100%">
                                    <Stack direction="column" spacing="20px">
                                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                            <CannavaDatePickerField
                                                id={'fecha_egreso'}
                                                label={'Fecha egreso'}
                                                validationRules={{
                                                    required: 'Campo obligatorio'
                                                }}
                                            />

                                            <CannavaInputField
                                                id={'hora_final_egreso'}
                                                label={'Hora egreso'}
                                                validationRules={{
                                                    required: 'Campo obligatorio'
                                                }}
                                            />
                                            <CannavaInputField
                                                id={'bandejas_egreso'}
                                                label={'Nº de bandejas salientes (u.)'}
                                                validationRules={{
                                                    required: 'Campo obligatorio'
                                                }}
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid gap="20px">
                                            <FormControl
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                mb="10px"
                                            >
                                                <FormLabel
                                                    mb="0"
                                                    fontSize="sm"
                                                    color={textColor}
                                                    ms="10px"
                                                    fontWeight="bold"
                                                >
                                                    Descarte
                                                </FormLabel>
                                                <Switch
                                                    {...methods.register('descarte')}
                                                    id="descarte"
                                                />
                                            </FormControl>
                                            {descarte && (
                                                <CannavaInputField
                                                    id={'cantidad_descarte_egreso'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            )}
                                        </SimpleGrid>
                                        <SimpleGrid gap="20px">
                                            <CannavaTextAreaField
                                                id={'observaciones_generales'}
                                                label={'Observaciones generales'}
                                                placeholder={
                                                    '¿Tenés alguna observación para agregar?'
                                                }
                                                validationRules={{
                                                    required: 'Campo obligatorio'
                                                }}
                                                mb="10px"
                                            />
                                        </SimpleGrid>
                                    </Stack>
                                    <Flex justify="space-between" mt="24px">
                                        <Button
                                            variant="outline"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            //navegar a lote
                                            onClick={onCancel}
                                            type="button"
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="darkBrand"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            isLoading={methods.formState.isSubmitting}
                                            type="submit"
                                        >
                                            Continuar
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Card>
                        </form>
                    </Box>
                </FormProvider>
            </Flex>
        </>
    );
}
