/* eslint-disable import/no-anonymous-default-export */
export default {
    control: {
        backgroundColor: '#fff',
        fontSize: 16,
        width: '100%'
        // fontWeight: 'normal',
    },

    '&multiLine': {
        width: '100%',
        control: {
            /*fontFamily: 'monospace',*/
            minHeight: 100,
            width: '100%'
        },
        highlighter: {
            padding: 9,
            border: '1px solid transparent'
        },
        input: {
            padding: 15,
            border: '1px solid silver',
            width: '100%',
            borderRadius: '20px',
            backgroundColor: '#FAFCFE'
        }
    },

    '&singleLine': {
        display: 'inline-block',
        width: '100%',

        highlighter: {
            padding: 1,
            border: '2px inset transparent'
        },
        input: {
            padding: 1,
            border: '2px inset',
            borderRadius: '20px',
            width: '100%'
        }
    },

    suggestions: {
        list: {
            borderRadius: '0px',
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 16
        },
        item: {
            padding: '10px 20px',
            width: '100%',
            margin: '0px',
            borderRadius: '0',
            backgroundColor: '#fff',
            '&focused': {
                backgroundColor: '#EFEFEF'
            }
        }
    }
};
