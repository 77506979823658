import React, { useEffect } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Flex,
    Button,
    Spacer,
    CloseButton
} from '@chakra-ui/react';

const FlashMessage = () => {
    const { flashMessage, setFlashMessage, flashType, setFlashType } = useAppContext();

    useEffect(() => {
        if (flashMessage) {
            const timer = setTimeout(() => {
                setFlashMessage(null);
            }, 5000);

            // Limpia el timer si el componente se desmonta o si el mensaje cambia
            return () => clearTimeout(timer);
        }
    }, [flashMessage]);

    if (!flashMessage) {
        return null;
    }

    return (
        <Flex
            direction="row"
            align="center"
            justify="space-between"
            width="100%"
            mt="80px"
            mb="-50px"
        >
            <Alert status={flashType}>
                <AlertIcon />
                <AlertTitle>{flashMessage}</AlertTitle>
                <Spacer />
                <CloseButton
                    onClick={() => setFlashMessage(null)}
                    alignSelf="flex-start"
                    position="relative"
                    right={-1}
                    top={-1}
                />
            </Alert>
        </Flex>
    );
};

export default FlashMessage;
