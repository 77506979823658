// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    useColorModeValue,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useDisclosure
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import Index from 'components/cannava/dangerZone';
import { useLayoutContext } from 'contexts/LayoutContext';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import { Proveedor } from 'modules/genetica/types/Proveedor';
import { useFetchData } from 'hooks/useFetchData';
import { Variedad } from 'modules/genetica/types/Variedad';
import { useEnvioFormularioAntiguo } from 'hooks/useEnvioFormularioAntiguo';
import DangerZone from 'components/cannava/dangerZone';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import { Establecimiento } from 'modules/locacion/types/Establecimiento';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import { Campana } from 'modules/planificacion/types/Campana';
import { Planificacion } from 'modules/planificacion/types/Planificacion';

type UserFormData = {
    nombre: string;
    apellido: string;
    email: string;
    nombre_usuario: string;
    rol: string;
    fecha_nacimiento: string;
};

type LoteData = {
    id: number;
    lote_semilla_id?: string;
    peso: number;
    unidades: number;
    fecha_recepcion: string;
    cantidad_bajas: number;
    peso_promedio: number;
    stock: number;
    genetica_id: number;
    locacion_id: number;
};

type OpcionesSelect = {
    campanas: Campana[];
    planificaciones: any[];
};

export default function AsignarLoteAPlanificacion() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const isEditMode = false;
    const { setBreadcrumbs, setTitle } = useLayoutContext();

    const [isDeleting, setIsDeleting] = useState(false);

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = useState({
        general: true,
        permissions: false
    });

    const navigate = useNavigate();
    const generalTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const permissionsTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);
    const [cargandoData, setCargandoData] = useState(false);

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'loteSemilla',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/lotes-semillas');
        },
        onError: error => {
            generalTab.current.click();
        },
        onErrorDelete: error => {
            navigate('/admin/lotes-semillas');
        }
    });

    const [opcionesSelect, setOpcionesSelect] = useState<OpcionesSelect>({
        campanas: [],
        planificaciones: []
    });

    const { setFlashMessage, setFlashType } = useAppContext();

    const transformCampanas = (data: any[]): Campana[] => {
        return data.map(campana => ({
            id: campana.id,
            nombre: campana.nombre,
            slug: campana.slug,
            tipo: campana.tipo,
            descripcion: campana.descripcion,
            estado: campana.estado,
            data_json: campana.data_json,
            fecha_inicio: campana.fecha_inicio,
            fecha_fin: campana.fecha_fin,
            fecha_creacion: campana.fecha_creacion,
            fecha_actualizacion: campana.fecha_actualizacion,
            fecha_borrado: campana.fecha_borrado,
            objetivos: campana.objetivos,
            planificaciones: campana.planificaciones,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };

    const campanaId = methods.watch('campana_id');
    const planificacionId = methods.watch('planificacion_id');
    const {
        data: campanasData,
        isLoading,
        setIsLoading
    } = useFetchData<Campana>('campanas', transformCampanas);

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Trazabilidad', href: '/admin/trazabilidad/' },
            { label: 'Lotes de semillas', href: '/admin/lotes-semillas' }
        ]);

        setTitle('Asignar lote a planificación');
    }, []);

    useEffect(() => {
        if (!campanasData) {
            return;
        }

        // filter all the campanas data that dont have estado == "activa"
        let campanasFiltradas = campanasData.filter(campana => campana.estado == 'activa');

        let opciones: OpcionesSelect = {
            campanas: campanasFiltradas,
            planificaciones: []
        };

        setOpcionesSelect(opciones);
    }, [campanasData]);

    useEffect(() => {
        if (!opcionesSelect) {
            return;
        }

        if (id) {
            //useEnvioFormulario1.cargarFormulario(id);
            useEnvioFormulario1.setIsLoading(false);
        } else {
            useEnvioFormulario1.setIsLoading(false);
        }
    }, [opcionesSelect]);

    const onSubmit: SubmitHandler<any> = (data: any) => {
        let dataCurada = {
            planificacion_id: data.planificacion_id,
            peso: data.peso,
            unidades: data.unidades
        };

        setIsLoading(true);

        CannavaApiService.request(
            'POST',
            '/trazabilidad/api/lote-semillas/asignar-planificacion/' + id,
            data,
            authToken,
            (data: any) => {
                setIsLoading(false);
                setFlashMessage('Se asignó el lote correctamente!');
                setFlashType('success');
                navigate('/admin/lotes-semillas/ver/' + id);
            },
            (error: any) => {
                if (error.response.status == 422) {
                    console.log(error.response.data);
                    for (let i = 0; i < error.response.data.length; i++) {
                        const errorValidacion: any = error.response.data[i];
                        methods.setError(errorValidacion.field, {
                            message: errorValidacion.message
                        });
                    }
                } else {
                    alert('Error desconocido');
                    console.log(error);
                }
                setIsLoading(false);
            }
        );
    };

    const mb: string = '0px';
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    useEffect(() => {
        if (isLoading) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    useEffect(() => {
        console.log('cambio: ', campanaId);
        if (!campanaId) {
            return;
        }

        let opcionesPlanificaciones = [];
        for (let i = 0; i < opcionesSelect.campanas.length; i++) {
            if (opcionesSelect.campanas[i].id == campanaId) {
                console.log(opcionesSelect.campanas[i].planificaciones);

                for (let j = 0; j < opcionesSelect.campanas[i].planificaciones.length; j++) {
                    let planificacion = opcionesSelect.campanas[i].planificaciones[j];

                    let cantidad = 0;
                    for (let k = 0; k < planificacion.filas.length; k++) {
                        cantidad += planificacion.filas[k].cantidad;
                    }

                    let nombre =
                        '#' +
                        planificacion.id +
                        ': ' +
                        planificacion.objetivo.genetica.nombre +
                        ' x ' +
                        cantidad;
                    opcionesPlanificaciones.push({
                        id: planificacion.id,
                        nombre: nombre
                    });
                }

                break;
            }
        }

        opcionesSelect.planificaciones = opcionesPlanificaciones;
        setOpcionesSelect(opcionesSelect);
        methods.resetField('planificacion_id');
    }, [campanaId]);

    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    if (isLoading) {
        return <PageLoader message="Cargando opciones de formulario..." />;
    }

    return (
        <>
            {!isDeleting && (
                <Flex
                    direction="column"
                    minH="100vh"
                    align="center"
                    pt={{ sm: '125px', lg: '75px' }}
                    position="relative"
                >
                    <Box
                        h="45vh"
                        bgGradient="linear(to-b, brand.400, brand.600)"
                        position="absolute"
                        w="100%"
                        borderRadius="20px"
                    />

                    <FormProvider {...methods}>
                        <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <Card p="30px" mt={'60px'}>
                                    <Text
                                        color={textColor}
                                        fontSize="2xl"
                                        fontWeight="700"
                                        mb="20px"
                                    >
                                        Información general
                                    </Text>
                                    {errorMessage && (
                                        <Alert status="error">
                                            <AlertIcon />
                                            <AlertTitle>{errorMessage}</AlertTitle>
                                        </Alert>
                                    )}
                                    <Flex direction="column" w="100%">
                                        <Stack direction="column" spacing="20px">
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaSelectField
                                                    id={'campana_id'}
                                                    label={'Campaña de cultivo'}
                                                    opciones={opcionesSelect.campanas}
                                                    placeholder={'Seleccione una campaña'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                <CannavaSelectField
                                                    id={'planificacion_id'}
                                                    label={'Planificación'}
                                                    opciones={opcionesSelect.planificaciones}
                                                    placeholder={'Seleccione una planificación'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                <CannavaInputField
                                                    id={'unidades'}
                                                    label={'Semillas (u.)'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                    onBlur={(
                                                        e: React.FocusEvent<HTMLInputElement>
                                                    ) => {
                                                        const unidades = +e.target.value;

                                                        if (planificacionId) {
                                                            for (
                                                                let i = 0;
                                                                i < campanasData.length;
                                                                i++
                                                            ) {
                                                                for (
                                                                    let j = 0;
                                                                    j <
                                                                    campanasData[i].planificaciones
                                                                        .length;
                                                                    j++
                                                                ) {
                                                                    if (
                                                                        campanasData[i]
                                                                            .planificaciones[j]
                                                                            .id == planificacionId
                                                                    ) {
                                                                        let planificacion =
                                                                            campanasData[i]
                                                                                .planificaciones[j];
                                                                        let peso = Math.ceil(
                                                                            (unidades /
                                                                                planificacion
                                                                                    .objetivo
                                                                                    .genetica
                                                                                    .cantidad_semillas_por_100_gramos) *
                                                                                100
                                                                        );
                                                                        methods.setValue(
                                                                            'peso',
                                                                            peso
                                                                        );
                                                                        break;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }}
                                                />

                                                <CannavaInputField
                                                    id={'peso'}
                                                    label={'Peso (gr)'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                {/*  <CannavaInputField
                                            id={"cantidad_bajas"}
                                            label={"Cantidad Bajas"}
                                            validationRules={{required: "Campo obligatorio"}}
                                        />

                                        <CannavaInputField
                                            id={"peso_promedio"}
                                            label={"Peso promedio"}
                                            validationRules={{required: "Campo obligatorio"}}
                                        />

                                        <CannavaInputField
                                            id={"stock"}
                                            label={"Stock"}
                                        />*/}
                                            </SimpleGrid>
                                        </Stack>
                                        <Flex justify="space-between" mt="24px">
                                            <Button
                                                variant="darkBrand"
                                                fontSize="sm"
                                                borderRadius="16px"
                                                w={{ base: '128px', md: '148px' }}
                                                h="46px"
                                                isLoading={methods.formState.isSubmitting}
                                                type="submit"
                                            >
                                                Enviar
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Card>
                            </form>
                        </Box>
                    </FormProvider>
                    {/* Danger Zone */}
                    {isEditMode && (
                        <DangerZone
                            elemento={'producto'}
                            onConfirmDelete={() => {
                                setIsDeleting(true);
                                useEnvioFormulario1.eliminarElemento(id);
                            }}
                        />
                    )}
                </Flex>
            )}

            {isDeleting && <PageLoader message="Eliminando lote de semillas..." />}
        </>
    );
}
