// Chakra imports
import {
    Flex,
    Box,
    Grid,
    SimpleGrid,
    useColorModeValue,
    Icon,
    Text,
    Badge,
    Alert,
    AlertIcon,
    Link,
    Button
} from '@chakra-ui/react';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useReactToPrint } from 'react-to-print';
import OrderStatus from 'views/admin/main/ecommerce/orderDetails/components/OrderStatus';
import Receipt from 'views/admin/main/ecommerce/orderDetails/components/Receipt';
import Details from 'views/admin/main/ecommerce/orderDetails/components/Details';
import { useLayoutContext } from 'contexts/LayoutContext';
import { useParams } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';
import { useAppContext } from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import PageLoader from 'components/actions/PageLoader';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { MdShowChart } from 'react-icons/md';
import { VSeparator } from 'components/separator/Separator';
import EvolucionProcesoAnalisis from './components/EvolucionProcesoAnalisis';
import Card from 'components/card/Card';
import MapCard from 'components/cannava/mapCard/index';
import SessionBadge from 'components/dataDisplay/SessionBadge';
import { Inventario } from 'modules/trazabilidad/types/Inventario';
import { createColumnHelper, CellContext } from '@tanstack/react-table';
import Listado, { crearCelda, crearCeldaConLink, crearHeader } from 'components/cannava/listado';
import { useFetchData } from 'hooks/useFetchData';
import { MenuItemProps } from 'types/MenuItemProps';
import PanelAnotaciones from 'components/chat/PanelAnotaciones';
import InfoRegistro from './components/InfoRegistro';
import AnalisisCalidadSemilla from '../../lotesPoderGerminativo/verLote/components/AnalisisCalidadSemilla';
import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import FechaItem from 'components/cannava/dataDisplay/FechaItem';
import Cronograma from 'components/cronograma_trazabilidad';
import useScrollToTop from 'hooks/useScrollToTop';

export default function VerLote() {
    const textColor = useColorModeValue('gray.700', 'white');
    const bgButton = 'rgba(255,255,255,0.12)';
    const bgHover = { bg: 'whiteAlpha.50' };
    const bgFocus = { bg: 'rgba(255,255,255,0.12)' };
    const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    const navigate = useNavigate();
    const { authToken } = useAppContext();
    const { id } = useParams();
    const [cargandoData, setCargandoData] = useState(false);
    const componentRef = useRef();
    const { setFlashMessage, setFlashType } = useAppContext();
    const [loteData, setLoteData] = useState(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const { ref, scrollToTop } = useScrollToTop(); // Usa el hook para obtener la ref

    const fetchLoteData = async (loteId: string) => {
        setCargandoData(true);
        CannavaApiService.lotesSemillasObtener(
            loteId,
            authToken,
            data => {
                //console.log('data lote');
                // console.log(data);
                setLoteData(data);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-semillas');
            }
        );
    };

    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: [
                { label: 'Inicio', href: '/admin/home/' },
                { label: 'Trazabilidad', href: '/admin/home/' },
                { label: 'Lotes de semillas', href: '/admin/lotes-semillas/' }
            ],
            titulo: () => {
                // Generar título basado en data cargada
                return !loteData ? '' : loteData.codigo_lote;
            }
        },
        loteData
    );

    useEffect(() => {
        if (id && authToken) {
            fetchLoteData(id);
        }
    }, [id, authToken]);

    if (cargandoData || !loteData) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    scrollToTop();

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }} ref={ref}>
            <Grid
                templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
                gap={4}
                mb={'30px'}
            >
                {/* Fila 1 */}
                <Box>
                    <MiniStatistics
                        name="Variedad"
                        value={loteData.genetica.nombre}
                        click={() => {
                            navigate('/admin/variedades/ver/' + loteData.genetica.id);
                        }}
                    />
                </Box>
                <Box>
                    <MiniStatistics
                        name="Cannabinoide dominante"
                        value={loteData.genetica.cannabinoideDominante.tipo}
                    />
                </Box>
                <Box>
                    <MiniStatistics
                        name="Cantidad semillas disponibles"
                        value={
                            loteData.lastInventarioSortedByID
                                ? loteData.lastInventarioSortedByID.stock
                                : loteData.unidades
                        }
                    />
                </Box>
                {loteData.lotePoderGerminativo.length > 0 &&
                    loteData.lotePoderGerminativo[0].estado == 'en-curso' && (
                        <Box>
                            <MiniStatistics
                                name="En proceso"
                                value={'Poder germinativo'}
                                formato={'lotePG'}
                            />
                        </Box>
                    )}
                {/*<Box>
                    <MiniStatistics name="Peso" value={loteData.peso} />
                </Box>*/}
            </Grid>

            {/* Fila 2 */}
            <Grid
                templateColumns={{ base: 'repeat(1, 1fr)', md: '75% 25%' }}
                gridColumn="span 4"
                gap={4}
            >
                {/* Columna 1 de Fila 2 */}
                <Flex flexDirection="column">
                    {/* Fila 2.1.1 */}
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mb={2}>
                        <Box w="100%">
                            <EvolucionProcesoAnalisis loteData={loteData} mostrarLinkLote={true} />
                        </Box>
                        <Box w="100%">
                            <InfoRegistro loteData={loteData} />
                        </Box>
                    </SimpleGrid>
                    {/* Fila 2.1.2 */}

                    <ListadoInventario loteSemillasID={id} />
                    <Cronograma loteData={loteData} />
                    <PanelAnotaciones modelo="loteSemillas" id={loteData.id} />
                </Flex>
                {/* Columna 2 de Fila 2 */}

                {loteData.establecimiento.latitud && loteData.establecimiento.longitud && (
                    <Box w="100%">
                        <MapCard
                            lat={loteData.establecimiento.latitud}
                            lng={loteData.establecimiento.longitud}
                            gridArea="1 / 2 / 2 / 3"
                        />
                    </Box>
                )}
            </Grid>
            <Button
                onClick={() => {
                    if (ref.current) ref.current.scrollIntoView({ behavior: 'smooth' });
                }}
            >
                Imprimir
            </Button>
        </Box>
    );
}

type ListadoInventarioProps = {
    loteSemillasID: string;
};

function ListadoInventario(props: ListadoInventarioProps) {
    const { loteSemillasID } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    const transformInventarios = (data: any[]): Inventario[] => {
        return data.map(inventario_registro => ({
            id: inventario_registro.id,
            lote_semillas_id: inventario_registro.lote_semillas_id,
            lote_semillas: inventario_registro.loteSemillas,
            cantidad: inventario_registro.cantidad,
            stock: inventario_registro.stock,
            data: inventario_registro.data,
            fecha_creacion: inventario_registro.fecha_creacion,
            fecha_actualizacion: inventario_registro.fecha_actualizacion,
            actions: 'actions'
        }));
    };

    const { data: inventarioData, isLoading } = useFetchData<Inventario>(
        'inventarios',
        transformInventarios,
        [['lote-semillas', loteSemillasID]]
    );
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const navigate = useNavigate();

    const textColor = 'navy.700';
    const columnHelper = createColumnHelper<Inventario>();
    const columns = [
        columnHelper.accessor('lote_semillas_id', {
            id: 'lote_semillas_id',
            header: crearHeader('ID Lote'),
            cell: crearCelda((info: CellContext<any, any>) => {
                return info.row.original.lote_semillas.codigo_lote;
            })
        }),
        columnHelper.accessor('cantidad', {
            id: 'cantidad',
            header: crearHeader('Movimiento'),
            cell: crearCelda()
        }),
        columnHelper.accessor('stock', {
            id: 'stock',
            header: crearHeader('Stock'),
            cell: crearCelda()
        }),
        columnHelper.accessor('fecha_creacion', {
            id: 'fecha_creacion',
            header: crearHeader('Fecha de movimiento'),
            // cell: crearCelda()
            cell: crearCelda((info: CellContext<any, any>) => {
                return info.row.original.fecha_creacion.split(' ')[0];
            })
        })
    ];

    if (isLoading) {
        return <PageLoader message={'Cargando registro de inventario'} />;
    }

    return (
        <Flex direction="column" mt={2}>
            <Card px="0px">
                <Flex justify="space-between" align="center" px="30px" py="15px">
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Registro de inventario
                    </Text>
                </Flex>
                <Listado tableData={inventarioData} menuItems={[]} columns={columns} />
            </Card>
        </Flex>
    );
}
