import { ObjetivoCampana } from '../../../../types/ObjetivoCampana';
import { Box, Button, Flex, Icon, Progress, Text } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { Campana } from '../../../../types/Campana';
import { MdDelete } from 'react-icons/md';

type ObjetivoProps = {
    objetivo: ObjetivoCampana;
    campana: Campana;
    permisoEliminar: boolean;
    callbackEliminar: CallableFunction;
};

export default function FilaObjetivo(props: ObjetivoProps) {
    const { objetivo, campana, permisoEliminar, callbackEliminar } = props;
    const [porcentajeObtenido, setPorcentajeObtenido] = useState(0);
    const [totalObtenido, setTotalObtenido] = useState(0);
    const [totalObjetivo, setTotalObjetivo] = useState(0);

    useEffect(() => {
        let totalObjetivo = 0;
        for (let i = 0; i < objetivo.filas.length; i++) {
            totalObjetivo += Number(objetivo.filas[i].cantidad);
        }

        // console.log('cargo objetivo', totalObjetivo);
        let totalAsignado = 0;
        let planificacion = null;
        for (let i = 0; i < campana.planificaciones.length; i++) {
            console.log(campana.planificaciones[i]);
            planificacion = campana.planificaciones[i];
            if (planificacion.objetivo_id === objetivo.id) {
                for (let j = 0; j < planificacion.filas.length; j++) {
                    totalAsignado += Number(planificacion.filas[j].cantidad);
                }
            }
        }

        setPorcentajeObtenido(Math.floor((totalAsignado * 100) / totalObjetivo));
        setTotalObjetivo(totalObjetivo);
        setTotalObtenido(totalAsignado);
    }, [campana]);

    const eliminar = () => {
        callbackEliminar(objetivo);
    };

    const generarTitleObjetivo = () => {
        let title = '';
        for (let i = 0; i < objetivo.filas.length; i++) {
            if (i > 0) {
                title += '\n';
            }

            title += objetivo.filas[i].tipoProducto.nombre + ': ' + objetivo.filas[i].cantidad;
        }
        return title;
    };

    const getTipoProducto = (id: number) => {
        for (let i = 0; i < objetivo.filas.length; i++) {
            if (objetivo.filas[i].id === id) {
                return objetivo.filas[i].tipoProducto.nombre;
            }
        }

        return '';
    };

    const generarTitlePlanificado = () => {
        let title = '';
        let planificacion = null;
        let resultados = [];

        for (let i = 0; i < campana.planificaciones.length; i++) {
            console.log(campana.planificaciones[i]);
            planificacion = campana.planificaciones[i];
            if (planificacion.objetivo_id === objetivo.id) {
                for (let j = 0; j < planificacion.filas.length; j++) {
                    let tipoProducto = getTipoProducto(
                        planificacion.filas[j]['fila_objetivo_campana_id']
                    );
                    if (resultados[tipoProducto]) {
                        resultados[tipoProducto] += planificacion.filas[j].cantidad;
                    } else {
                        resultados[tipoProducto] = planificacion.filas[j].cantidad;
                    }
                }
            }
        }

        // loop throught all elements in dictionary results and add to title string
        for (let key in resultados) {
            if (title.length > 0) {
                title += '\n';
            }

            title += key + ': ' + resultados[key];
        }

        return title;
    };

    return (
        <Tr>
            <Td>{objetivo.genetica.nombre}</Td>
            <Td>
                <Text title={generarTitleObjetivo()}>{totalObjetivo}</Text>
            </Td>
            <Td>
                <Text title={generarTitlePlanificado()}>{totalObtenido}</Text>
            </Td>
            <Td>
                <Progress
                    display={'inline-block'}
                    colorScheme={'green'}
                    value={porcentajeObtenido}
                    style={{ width: '100%' }}
                    title={totalObtenido + ' de ' + totalObjetivo}
                />
            </Td>
            {campana.estado == 'draft' && permisoEliminar && (
                <Td>
                    <Button onClick={eliminar} colorScheme="red" size="sm">
                        <Icon as={MdDelete} h={'16px'} w={'16px'} />
                    </Button>
                </Td>
            )}
        </Tr>
    );
}
