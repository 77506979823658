// Chakra imports
import {
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Text,
    useColorModeValue
} from '@chakra-ui/react';

// react and hooks
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

// Custom components
import { SingleDatepicker } from 'chakra-dayzed-datepicker';

export default function Default(props: {
    id?: string;
    label?: string;
    extra?: JSX.Element;
    placeholder?: string;
    type?: string;
    validationRules?: any;
    [x: string]: any;
}) {
    const { id, label, extra, placeholder, type, mb, validationRules, ...rest } = props;
    const {
        setValue,
        getValues,
        register,
        formState: { errors }
    } = useFormContext(); // retrieve all hook methods
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const [date, setDate] = useState(null);

    const actualizarFecha = (date: Date) => {
        setDate(date);
        if (!date) return;
        const offset = date.getTimezoneOffset();
        date = new Date(date.getTime() - offset * 60 * 1000);
        setValue(id, date.toISOString().split('T')[0]);
    };

    useEffect(() => {
        register(id, validationRules);
        const fechaForm = getValues(id);
        if (fechaForm) {
            setDate(new Date(fechaForm));
        } else {
            const date = new Date();
            actualizarFecha(date);
        }
    }, []);

    if (date === null) {
        return (
            <Text fontSize="sm" fontWeight="400" ms="2px">
                Cargando...
            </Text>
        );
    }

    return (
        <Flex direction="column" mb={mb ? mb : '30px'}>
            <FormControl isInvalid={id in errors}>
                <FormLabel
                    display="flex"
                    ms="10px"
                    fontSize="sm"
                    color={textColorPrimary}
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                    htmlFor={id}
                >
                    {label}
                </FormLabel>

                <SingleDatepicker
                    id={id}
                    date={date}
                    onDateChange={actualizarFecha}
                    propsConfigs={{
                        inputProps: {
                            placeholder: 'YYYY-mm-dd'
                        }
                    }}
                />

                <Text fontSize="sm" fontWeight="400" ms="2px">
                    {extra}
                </Text>
                <FormErrorMessage>{errors[id] && (errors as any)[id].message}</FormErrorMessage>
            </FormControl>
        </Flex>
    );
}
