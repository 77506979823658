import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

export type FilaInfoRegistroProps = {
    label: string;
    value: string | number;
    title?: string;
    click?: () => void;
};

export function FilaInfoRegistro(props: FilaInfoRegistroProps) {
    const { label, value, click, title } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColor = useColorModeValue('gray.700', 'white');
    const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');

    return (
        <Flex justifyContent="space-between" alignItems="center" w="100%" my={'10px'}>
            <Text color={textColor} fontSize="md" me="6px" fontWeight="500" title={title}>
                {label}
            </Text>
            <Flex align="center" ms="auto">
                {click ? (
                    <Text
                        color="blue.400"
                        fontWeight="400"
                        me="10px"
                        onClick={click}
                        cursor="pointer"
                    >
                        {value}
                    </Text>
                ) : (
                    <Text color="secondaryGray.600" fontWeight="400" me="10px">
                        {value}
                    </Text>
                )}
            </Flex>
        </Flex>
    );
}
