import { LotePlantaProductiva } from 'modules/trazabilidad/types/LotePlantaProductiva';
import { Box, Button, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import Card from 'components/card/Card';
import { FilaInfoRegistro } from '../../verLote/components/FilaInfoRegistro';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

type CardControlTrasplanteProps = {
    lote: LotePlantaProductiva;
};

export default function CardControlTrasplante(props: CardControlTrasplanteProps) {
    const { lote } = props;

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const estadosValidos = [
        'trasplante-iniciado',
        'trasplante-finalizado',
        'riego-realizado',
        'trasplante-conciliado',
        'control-cultivo',
        'cambio-inicializado',
        'cambio-finalizado'
    ];
    const navigate = useNavigate();

    const generarCodigoReporte = (num: number) => {
        let strNum = num.toString();
        return strNum.padStart(4, '0');
    };

    const verReporteTrasplante = () => {};

    const [botonesOpcion, setBotonesOpcion] = useState([]);

    useEffect(() => {
        let opciones = [];

        switch (lote.estado) {
            case 'trasplante-iniciado':
                opciones.push({
                    nombre: 'Finalizar trasplante',
                    callback: () => {
                        switch (lote.tipo_trasplante) {
                            case 'invernadero':
                                navigate(
                                    '/lotes-plantas-productivas/finalizar-trasplante-invernadero/' +
                                        lote.id
                                );
                                break;
                            case 'campo':
                                navigate(
                                    '/lotes-plantas-productivas/finalizar-trasplante-campo/' +
                                        lote.id
                                );
                                break;
                            case 'almeria':
                                navigate(
                                    '/lotes-plantas-productivas/finalizar-trasplante-almeria/' +
                                        lote.id
                                );
                                break;
                            case 'macrotunel':
                                navigate(
                                    '/lotes-plantas-productivas/finalizar-trasplante-macrotunel/' +
                                        lote.id
                                );
                                break;
                        }
                    }
                });
                break;
            case 'trasplante-finalizado':
                opciones.push({
                    nombre: 'Iniciar regado',
                    callback: () => {
                        navigate(
                            '/lotes-plantas-productivas/riego-trasplante/' + lote.id + '/' + 1
                        );
                    }
                });
                break;
            case 'riego-realizado':
                opciones.push({
                    nombre: 'Iniciar conciliacion',
                    callback: () => {
                        navigate(
                            '/lotes-plantas-productivas/conciliacion-trasplante/' /* + loteData.id */
                        );
                    }
                });
                break;
        }

        setBotonesOpcion(opciones);
    }, [lote]);

    if (!estadosValidos.includes(lote.estado)) return <></>;

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center" mb="5">
                <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                    Plantinera
                </Text>
                <Box>
                    {botonesOpcion.length > 0 &&
                        botonesOpcion.map((boton: any, index) => (
                            <Button colorScheme="green" m="1" onClick={boton.callback} key={index}>
                                {boton.nombre}
                            </Button>
                        ))}
                </Box>
            </Flex>

            <FilaInfoRegistro
                label={'Nº. de verificación'}
                value={/* generarCodigoReporte(lote.id) */ 'hardcoded'}
                click={verReporteTrasplante}
            />
        </Card>
    );
}
