import './assets/css/App.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SENTRY_DSN, GTM_CONTAINER } from 'config';
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';
import { AppProvider } from './contexts/AppContext';
import * as Sentry from '@sentry/react';
// @ts-ignore
import TagManager from 'react-gtm-module';
import App from './App';

const tagManagerArgs = {
    gtmId: GTM_CONTAINER // Reemplaza 'GTM-XXXXXXX' con tu ID de GTM.
};

TagManager.initialize(tagManagerArgs);

// console.log("inicializamos sentry: " + SENTRY_DSN);

Sentry.init({
    dsn: SENTRY_DSN,
    environment: 'dev',
    release: '0.9-dev',
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost:3000', 'https:api.cannava.local/']
        }),
        new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <Auth0ProviderWithNavigate>
            <AppProvider>
                <App />
            </AppProvider>
        </Auth0ProviderWithNavigate>
        ,
    </BrowserRouter>
);
