// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    useColorModeValue,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
    AlertTitle,
    useDisclosure
} from '@chakra-ui/react';

// react and hooks
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import { useEnvioFormulario } from '../../../../../hooks/useEnvioFormulario';
import { useConfigurarNavegacion } from '../../../../../hooks/useConfigurarNavegacion';

// Custom components
import Card from 'components/card/Card';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import CannavaTextareaField from 'components/fields/CannavaTextareaField';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import DangerZone from 'components/cannava/dangerZone';

type UserFormData = {
    nombre: string;
    apellido: string;
    email: string;
    nombre_usuario: string;
    rol: string;
    fecha_nacimiento: string;
};

type LoteData = {
    id: number;
    lote_semilla_id?: string;
    cantidad_semillas_gramos: number;
    cantidad_bajas: number;
    peso_promedio: number;
    stock: number;
    genetica_id: number;
    locacion_id: number;
};

type OpcionesSelect = {
    tipos: any[];
};

/**
 * Vista/componente para la pantalla de creación/actualización de campaña así como para la
 * eliminación de las mismas.
 *
 * @constructor
 */
export default function NuevaCampana() {
    // inicializacion form, id y estados
    const methods = useForm();
    const { id } = useParams();
    const isEditMode = !!id;
    const [opcionesSelect, setOpcionesSelect] = useState<OpcionesSelect>({
        tipos: []
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);

    // variables de layout y estilo
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    // helpers
    const navigate = useNavigate();

    // configuro layout
    const configurarNavegacion = useConfigurarNavegacion({
        breadcrumbs: [
            { label: 'Inicio', href: '/' },
            { label: 'Planificación', href: '/admin/campanas/' },
            { label: 'Campañas', href: '/admin/campanas' }
        ],
        titulo: isEditMode ? 'Editar campaña' : 'Nueva Campaña'
    });

    // inicializacion hook useEnvioFormulario
    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'campana',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/campanas');
        },
        onError: error => {
            console.log(error);
        },
        onErrorDelete: error => {
            navigate('/admin/campanas');
        }
    });

    // capturo el envio de formulario y puedo manipularla data para prepararla
    const onSubmit = (data: any) => {
        if (isEditMode) {
            useEnvioFormulario1.envioFormularioActualizar(id, data);
            return;
        }

        data['estado'] = 'draft';
        useEnvioFormulario1.envioFormularioCrear(data);
    };

    // use effect para preparar form (opciones select, etc)
    useEffect(() => {
        let opciones: OpcionesSelect = {
            tipos: [
                {
                    id: 'temporada',
                    nombre: 'Temporada'
                },
                {
                    id: 'extraordinaria',
                    nombre: 'Extraordinaria'
                }
            ]
        };

        setOpcionesSelect(opciones);
    }, []);

    // useEffect para precargar el formulario si estamos editando o terminar la carga
    useEffect(() => {
        if (!opcionesSelect) {
            return;
        }

        if (id) {
            useEnvioFormulario1.cargarFormulario(id);
        } else {
            useEnvioFormulario1.setIsLoading(false);
        }
    }, [opcionesSelect]);

    // useEffect para capturar errores del formulario
    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }

        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            // Error callback
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [useEnvioFormulario1.isLoading, methods.formState.submitCount]);

    // si estoy cargando muestro el spinner
    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            {!isDeleting && (
                <Flex
                    direction="column"
                    minH="100vh"
                    align="center"
                    pt={{ sm: '125px', lg: '75px' }}
                    position="relative"
                >
                    <Box
                        h="45vh"
                        bgGradient="linear(to-b, brand.400, brand.600)"
                        position="absolute"
                        w="100%"
                        borderRadius="20px"
                    />
                    <FormProvider {...methods}>
                        <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <Card p="30px" mt={'60px'}>
                                    <Text
                                        color={textColor}
                                        fontSize="2xl"
                                        fontWeight="700"
                                        mb="20px"
                                    >
                                        Información general
                                    </Text>
                                    {errorMessage && (
                                        <Alert status="error">
                                            <AlertIcon />
                                            <AlertTitle>{errorMessage}</AlertTitle>
                                        </Alert>
                                    )}
                                    <Flex direction="column" w="100%">
                                        <Stack direction="column" spacing="20px">
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaInputField
                                                    id={'nombre'}
                                                    label={'Nombre'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                <CannavaSelectField
                                                    id={'tipo'}
                                                    label={'Tipo'}
                                                    opciones={opcionesSelect.tipos}
                                                    placeholder={'Seleccione un tipo de Campaña'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                <CannavaDatePickerField
                                                    id={'fecha_inicio'}
                                                    label={'Fecha Inicio'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                <CannavaDatePickerField
                                                    id={'fecha_fin'}
                                                    label={'Fecha Fin'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            </SimpleGrid>

                                            <CannavaTextareaField
                                                id={'descripcion'}
                                                label={'Descripción'}
                                                validationRules={{ required: 'Campo obligatorio' }}
                                            />
                                        </Stack>
                                        <Flex justify="space-between" mt="24px">
                                            <Button
                                                variant="darkBrand"
                                                fontSize="sm"
                                                borderRadius="16px"
                                                w={{ base: '128px', md: '148px' }}
                                                h="46px"
                                                ms="auto"
                                                isLoading={methods.formState.isSubmitting}
                                                type="submit"
                                            >
                                                Guardar
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Card>
                            </form>
                        </Box>
                    </FormProvider>
                    {/* Danger Zone */}
                    {isEditMode && (
                        <DangerZone
                            elemento={'campana'}
                            onConfirmDelete={() => {
                                /* useEnvioFormulario1.eliminarElemento(id);  */ setIsDeleting(
                                    true
                                );
                                useEnvioFormulario1.eliminarElemento(id);
                            }}
                        />
                    )}
                </Flex>
            )}

            {isDeleting && <PageLoader message="Eliminando campaña..." />}
        </>
    );
}
