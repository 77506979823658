import { Flex, SimpleGrid, Text, useColorModeValue, Divider } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { FilaInfoRegistro } from './FilaInfoRegistro';
import { ControlPlantinera } from 'modules/trazabilidad/types/ControlPlantinera';

type InfoControlProps = {
    controlCalidadData: Array<{
        altura: string;
        diametro: string;
        numeroPlantas: number;
        numeroFolios: number;
    }>;
};

export default function InfoControlCalidad(props: InfoControlProps) {
    const { controlCalidadData } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center" mb="5">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Plantinera
                </Text>
            </Flex>
            <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing={{ base: '20px', xl: '5px' }}>
                <FilaInfoRegistro label={'Altura'} value={controlCalidadData[0].altura} />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Diámetro de tallo'}
                    value={controlCalidadData[0].diametro}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de hojas por planta'}
                    value={controlCalidadData[0].numeroPlantas}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de folíolos por hoja'}
                    value={controlCalidadData[0].numeroFolios}
                />
            </SimpleGrid>
        </Card>
    );
}
