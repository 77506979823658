// Chakra imports
import {
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Textarea,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
// Custom components

export default function Default(props: {
    id?: string;
    label?: string;
    extra?: JSX.Element;
    placeholder?: string;
    type?: string;
    validationRules?: any;
    [x: string]: any;
}) {
    const { id, label, extra, placeholder, type, mb, validationRules, ...rest } = props;
    const {
        register,
        formState: { errors }
    } = useFormContext(); // retrieve all hook methods
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    return (
        <Flex direction="column" mb={mb ? mb : '30px'}>
            <FormControl isInvalid={id in errors}>
                <FormLabel
                    display="flex"
                    ms="10px"
                    fontSize="sm"
                    color={textColorPrimary}
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                    htmlFor={id}
                >
                    {label}
                </FormLabel>
                <Textarea
                    id={id}
                    placeholder={placeholder}
                    fontWeight="500"
                    variant="main"
                    border={'1px solid #E2E8F0'}
                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                    {...register(id, validationRules)}
                />
                <Text fontSize="sm" fontWeight="400" ms="2px">
                    {extra}
                </Text>
                <FormErrorMessage>{errors[id] && (errors as any)[id].message}</FormErrorMessage>
            </FormControl>
        </Flex>
    );
}
