import { useAppContext } from 'contexts/AppContext';
import { useState } from 'react';
import CannavaApiService from '../utils/CannavaApiService';
import { UseFormReturn } from 'react-hook-form';
import { ElementoBackend } from 'types/ElementosBackend';

type envioFormularioProps = {
    formMethods: UseFormReturn<any>;
    elemento: ElementoBackend;
    onSuccess: (data: any) => void;
    onError: (error: any) => void;
    onErrorDelete?: (error: any) => void;
};

/**
 * Hook que asiste a los componentes que interactúan con la API para simplificar llamadas.
 * Sus principales responsabilidades son:
 *  - obtener la info de un determinado id
 *  - generar la funcion para crear un nuevo elemento
 *  - generar la funcion para actualizar un elemento existente
 *  - generar la funcion para eliminar un elemento existente
 *
 * @param props
 */
export const useEnvioFormulario = (props: envioFormularioProps) => {
    const { formMethods, elemento, onSuccess, onError, onErrorDelete } = props;
    const { authToken } = useAppContext();
    const { setFlashMessage, setFlashType } = useAppContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const envioFormularioCrear = async (data: any) => {
        setIsLoading(true);

        CannavaApiService.crear(
            elemento,
            data,
            authToken,
            (data: any) => {
                setIsLoading(false);
                setFlashMessage('Se guardó el ' + elemento + ' exitosamente!');
                setFlashType('success');
                onSuccess(data);
            },
            (error: any) => {
                if (error.response.status == 422) {
                    console.log(error.response.data);
                    for (let i = 0; i < error.response.data.length; i++) {
                        const errorValidacion: any = error.response.data[i];
                        formMethods.setError(errorValidacion.field, {
                            message: errorValidacion.message
                        });
                    }
                } else {
                    alert('Error desconocido');
                    console.log(error);
                }

                setIsLoading(false);
                onError(error);
            }
        );
    };

    const envioFormularioActualizar = async (id: number | string, data: any) => {
        setIsLoading(true);

        CannavaApiService.actualizar(
            elemento,
            id,
            data,
            authToken,
            (data: any) => {
                setIsLoading(false);
                setFlashMessage('Se actualizó el ' + elemento + ' correctamente!');
                setFlashType('success');
                onSuccess(data);
            },
            (error: any) => {
                if (error.response.status == 422) {
                    console.log(error.response.data);
                    for (let i = 0; i < error.response.data.length; i++) {
                        const errorValidacion: any = error.response.data[i];
                        formMethods.setError(errorValidacion.field, {
                            message: errorValidacion.message
                        });
                    }
                } else {
                    alert('Error desconocido');
                    console.log(error);
                }
                onError(data);
                setIsLoading(false);
            }
        );
    };

    const cargarFormulario = async (id: number | string) => {
        setIsLoading(true);
        CannavaApiService.obtener(
            elemento,
            id,
            authToken,
            data => {
                // console.log(data);

                // Setear los valores en los campos del formulario
                Object.keys(data).forEach(function (key) {
                    formMethods.setValue(key, data[key]);
                });

                setIsLoading(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setIsLoading(false);
            }
        );
    };

    const eliminarElemento = async (id: number | string) => {
        CannavaApiService.eliminar(
            elemento,
            id,
            authToken,
            data => {
                // Setear los valores en los campos del formulario
                setFlashMessage('Se eliminó el ' + elemento + ' correctamente!');
                setFlashType('success');
                onSuccess(data);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('No se pudo eliminar el ' + elemento + ' !');
                setFlashType('error');

                onErrorDelete(error);
            }
        );
    };

    return {
        envioFormularioCrear,
        envioFormularioActualizar,
        cargarFormulario,
        eliminarElemento,
        isLoading,
        setIsLoading
    };
};
