export default class CalculadoraPlanificacion {
    hectareas: number;
    genetica: any;
    tipoLocacionSeleccionada: any;
    cantidad: number;
    rendimiento: number;
    rendimientoSubproducto: number;
    rendimientosProductos: any;
    semillasUnidades: number;
    semillasPeso: number;

    constructor(hectareas: number, genetica: any, tipoLocacionSeleccionada: any) {
        this.hectareas = hectareas;
        this.genetica = genetica;
        this.tipoLocacionSeleccionada = tipoLocacionSeleccionada;
        this.calcular();
    }

    calcular() {
        console.log('calculadoraPlanificacion: calcular()');
        this.cantidad = 0;

        if (this.genetica) {
            if (this.genetica.densidad_siembra_planta_m2) {
                this.cantidad = this.hectareas * 10000 * this.genetica.densidad_siembra_planta_m2;
            }

            console.log(this.genetica);
            console.log(this.genetica.estimaciones);
            if (this.cantidad > 0 && this.tipoLocacionSeleccionada && this.genetica.estimaciones) {
                for (let i = 0; i < this.genetica.estimaciones.length; i++) {
                    if (
                        this.tipoLocacionSeleccionada ==
                        this.genetica.estimaciones[i].tipo_locacion_id
                    ) {
                        let rendimientoPromedio =
                            this.genetica.estimaciones[i]
                                .rendimiento_promedio_por_100_semillas_en_KG;
                        // @todo: corregir esto y traerlo desde el backend
                        let rendimientoDefault = Math.round(
                            (this.cantidad / 100) * rendimientoPromedio
                        );
                        if (this.genetica.cannabinoideDominante.slug == 'thc') {
                            this.rendimientosProductos = {
                                'flor-seca': rendimientoDefault,
                                biomasa: Math.round((rendimientoDefault / 20) * 80)
                            };
                        } else {
                            this.rendimientosProductos = {
                                biomasa: rendimientoDefault
                            };
                        }

                        console.log('rendimientos', this.rendimientosProductos);
                    }
                }
            }

            if (this.cantidad > 0 && this.genetica.poder_germinativo_promedio) {
                this.semillasUnidades =
                    (100 * this.cantidad) / (this.genetica.poder_germinativo_promedio * 100);
                this.semillasPeso =
                    (this.semillasUnidades * 100) / this.genetica.cantidad_semillas_por_100_gramos;
            }
        }
    }
}
