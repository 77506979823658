// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    useColorModeValue,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useDisclosure
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import Index from 'components/cannava/dangerZone';
import { useLayoutContext } from 'contexts/LayoutContext';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import { Proveedor } from 'modules/genetica/types/Proveedor';
import { useFetchData } from 'hooks/useFetchData';
import { Variedad } from 'modules/genetica/types/Variedad';
import { useEnvioFormularioAntiguo } from 'hooks/useEnvioFormularioAntiguo';
import DangerZone from 'components/cannava/dangerZone';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import { Establecimiento } from 'modules/locacion/types/Establecimiento';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';

type UserFormData = {
    nombre: string;
    apellido: string;
    email: string;
    nombre_usuario: string;
    rol: string;
    fecha_nacimiento: string;
};

type LoteData = {
    id: number;
    lote_semilla_id?: string;
    peso: number;
    unidades: number;
    fecha_recepcion: string;
    cantidad_bajas: number;
    peso_promedio: number;
    stock: number;
    genetica_id: number;
    locacion_id: number;
};

type OpcionesSelect = {
    geneticas: any[];
    establecimientos: any[];
};

export default function NewLote() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const isEditMode = !!id;
    const { setBreadcrumbs, setTitle } = useLayoutContext();

    const [isDeleting, setIsDeleting] = useState(false);

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = useState({
        general: true,
        permissions: false
    });

    const navigate = useNavigate();
    const generalTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const permissionsTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);
    const [cargandoData, setCargandoData] = useState(false);

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'loteSemilla',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/lotes-semillas');
        },
        onError: error => {
            if (generalTab.current != undefined) generalTab.current.click();
        },
        onErrorDelete: error => {
            navigate('/admin/lotes-semillas');
        }
    });

    const [opcionesSelect, setOpcionesSelect] = useState<OpcionesSelect>({
        geneticas: [],
        establecimientos: []
    });

    const transformVariedades = (data: any[]): Variedad[] => {
        return data.map(variedad => ({
            id: variedad.id,
            nombre: variedad.nombre,
            slug: variedad.slug,
            proveedor_id: variedad.proveedor_id,
            cannabinoide_dominante_id: variedad.cannabinoide_dominante_id,
            poder_germinativo_promedio: variedad.poder_germinativo_promedio,
            cantidad_semillas_por_100_gramos: variedad.cantidad_semillas_por_100_gramos,
            densidad_siembra_planta_m2: variedad.densidad_siembra_planta_m2,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };

    const { data: variedadesData, isLoading } = useFetchData<Variedad>(
        'variedades',
        transformVariedades
    );

    const transformEstablecimientos = (data: any[]): Establecimiento[] => {
        return data.map(data => ({
            id: data.id,
            nombre: data.nombre,
            slug: data.slug,
            codigo: data.codigo,
            latitud: data.latitud,
            longitud: data.longitud,
            superficie_total: data.superficie_total,
            descripcion: data.descripcion,
            fecha_creacion: data.fecha_creacion,
            fecha_actualizacion: data.fecha_actualizacion,
            fecha_borrado: data.fecha_borrado,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };

    const establecimientosData = useFetchData<Establecimiento>(
        'establecimientos',
        transformEstablecimientos
    );

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Trazabilidad', href: '/admin/trazabilidad/' },
            { label: 'Lotes de semillas', href: '/admin/lotes-semillas' }
        ]);

        if (isEditMode) {
            setTitle('Editar lote');
        } else {
            setTitle('Nuevo lote');
        }
    }, []);

    useEffect(() => {
        if (!variedadesData || !establecimientosData) {
            return;
        }

        let opciones: OpcionesSelect = {
            geneticas: variedadesData,
            establecimientos: establecimientosData.data
        };

        console.log(opciones);
        setOpcionesSelect(opciones);
    }, [variedadesData, establecimientosData.data]);

    useEffect(() => {
        if (!opcionesSelect) {
            return;
        }

        if (id) {
            useEnvioFormulario1.cargarFormulario(id);
        } else {
            useEnvioFormulario1.setIsLoading(false);
        }
    }, [opcionesSelect]);

    const onSubmit: SubmitHandler<LoteData> = (data: LoteData) => {
        if (isEditMode) {
            useEnvioFormulario1.envioFormularioActualizar(id, data);
            return;
        }
        useEnvioFormulario1.envioFormularioCrear(data);
    };

    const mb: string = '0px';
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    const geneticaSeleccionada = methods.watch('genetica_id');

    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    useEffect(() => {
        console.log('cambio: ', geneticaSeleccionada);
    }, [geneticaSeleccionada]);

    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    if (isLoading) {
        return <PageLoader message="Cargando opciones de formulario..." />;
    }

    return (
        <>
            {!isDeleting && (
                <Flex
                    direction="column"
                    minH="100vh"
                    align="center"
                    pt={{ sm: '125px', lg: '75px' }}
                    position="relative"
                >
                    <Box
                        h="45vh"
                        bgGradient="linear(to-b, brand.400, brand.600)"
                        position="absolute"
                        w="100%"
                        borderRadius="20px"
                    />

                    <FormProvider {...methods}>
                        <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <Card p="30px" mt={'60px'}>
                                    <Text
                                        color={textColor}
                                        fontSize="2xl"
                                        fontWeight="700"
                                        mb="20px"
                                    >
                                        Información general
                                    </Text>
                                    {errorMessage && (
                                        <Alert status="error">
                                            <AlertIcon />
                                            <AlertTitle>{errorMessage}</AlertTitle>
                                        </Alert>
                                    )}
                                    <Flex direction="column" w="100%">
                                        <Stack direction="column" spacing="20px">
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaDatePickerField
                                                    id={'fecha_recepcion'}
                                                    label={'Fecha de recepción'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                <CannavaSelectField
                                                    id={'establecimiento_id'}
                                                    label={'Ubicación de recepción'}
                                                    opciones={opcionesSelect.establecimientos}
                                                    placeholder={'Seleccione un establecimiento'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                <CannavaSelectField
                                                    id={'genetica_id'}
                                                    label={'Genetica'}
                                                    opciones={opcionesSelect.geneticas}
                                                    placeholder={'Seleccione una genetica'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                <CannavaInputField
                                                    id={'peso'}
                                                    label={'Peso (gr)'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                    onBlur={(
                                                        e: React.FocusEvent<HTMLInputElement>
                                                    ) => {
                                                        const pesoEnGramos = e.target.value;
                                                        // cast as number
                                                        const pesoEnGramosNumber = +pesoEnGramos;

                                                        if (geneticaSeleccionada) {
                                                            for (
                                                                let i = 0;
                                                                i < opcionesSelect.geneticas.length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    opcionesSelect.geneticas[i]
                                                                        .id ==
                                                                        geneticaSeleccionada &&
                                                                    opcionesSelect.geneticas[i]
                                                                        .cantidad_semillas_por_100_gramos >
                                                                        0
                                                                ) {
                                                                    let unidades = Math.ceil(
                                                                        (pesoEnGramosNumber / 100) *
                                                                            opcionesSelect
                                                                                .geneticas[i]
                                                                                .cantidad_semillas_por_100_gramos
                                                                    );
                                                                    methods.setValue(
                                                                        'unidades',
                                                                        unidades
                                                                    );
                                                                    break;
                                                                }
                                                            }
                                                        }
                                                    }}
                                                />

                                                <CannavaInputField
                                                    id={'unidades'}
                                                    label={'Unidades'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />

                                                {/*  <CannavaInputField
                                            id={"cantidad_bajas"}
                                            label={"Cantidad Bajas"}
                                            validationRules={{required: "Campo obligatorio"}}
                                        />

                                        <CannavaInputField
                                            id={"peso_promedio"}
                                            label={"Peso promedio"}
                                            validationRules={{required: "Campo obligatorio"}}
                                        />

                                        <CannavaInputField
                                            id={"stock"}
                                            label={"Stock"}
                                        />*/}
                                            </SimpleGrid>
                                        </Stack>
                                        <Flex justify="space-between" mt="24px">
                                            <Button
                                                variant="darkBrand"
                                                fontSize="sm"
                                                borderRadius="16px"
                                                w={{ base: '128px', md: '148px' }}
                                                h="46px"
                                                isLoading={methods.formState.isSubmitting}
                                                type="submit"
                                            >
                                                Enviar
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Card>
                            </form>
                        </Box>
                    </FormProvider>
                    {/* Danger Zone */}
                    {isEditMode && (
                        <DangerZone
                            elemento={'producto'}
                            onConfirmDelete={() => {
                                setIsDeleting(true);
                                useEnvioFormulario1.eliminarElemento(id);
                            }}
                        />
                    )}
                </Flex>
            )}

            {isDeleting && <PageLoader message="Eliminando lote de semillas..." />}
        </>
    );
}
