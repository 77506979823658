// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    useColorModeValue,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useDisclosure,
    Switch
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import Index from 'components/cannava/dangerZone';
import { useLayoutContext } from 'contexts/LayoutContext';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import { Proveedor } from 'modules/genetica/types/Proveedor';
import { useFetchData } from 'hooks/useFetchData';
import { Variedad } from 'modules/genetica/types/Variedad';
import { useEnvioFormularioAntiguo } from 'hooks/useEnvioFormularioAntiguo';
import DangerZone from 'components/cannava/dangerZone';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import { Establecimiento } from 'modules/locacion/types/Establecimiento';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import CannavaTextAreaField from 'components/fields/CannavaTextareaField';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import { ReporteControlGerminacion } from 'modules/trazabilidad/types/ReporteControlGerminacion';

type IniciarGerminacionData = {
    lote_id: string;
    id: number;
    fecha_ingreso: string;
    hora_final_ingreso: string;
    bandejas_ingreso: number;
    responsable_ingreso: string;
    verificacion_limpieza_area_germinacion_ingreso: number;

    temperatura_camara_ingreso: number;
    hr_camara_ingreso: number;
    cumple_oscuridad_ingreso: number;
    cumple_oscuridad_ingreso_formulario: string;
};

export default function Germinacion() {
    // region Variables

    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const isEditMode = !!id;
    const { setBreadcrumbs, setTitle } = useLayoutContext();

    const [isDeleting, setIsDeleting] = useState(false);

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = useState({
        germinacion: true,
        condicionesAmbientales: false
    });

    const navigate = useNavigate();
    const [cargandoData, setCargandoData] = useState(false);

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [loteData, setLoteData] = useState(null);
    const { setFlashMessage, setFlashType } = useAppContext();

    const germinacionTab = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
    const condicionesAmbientalesTab = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

    // endregion Variables

    // region Hooks y callbacks

    const fetchLoteData = async (loteId: string) => {
        setCargandoData(true);
        CannavaApiService.lotesGerminacionObtener(
            loteId,
            authToken,
            data => {
                console.log('data lote germinacion');
                console.log(data);

                // @todo: chequear que el estado del lote sea valido
                setLoteData(data);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion');
            }
        );
    };

    // configuro el layout
    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: [
                { label: 'Inicio', href: '/admin/home/' },
                { label: 'Trazabilidad', href: '/admin/home/' },
                { label: 'Lotes de germinación', href: '/admin/lotes-germinacion/ver/' + id },
                { label: 'Iniciar germinación', href: '#' }
            ],
            titulo: () => {
                // Generar título basado en data cargada
                return !loteData ? '' : `Lote ${loteData.codigo_lote}`;
            }
        },
        loteData
    );

    const onSubmit: SubmitHandler<IniciarGerminacionData> = (data: IniciarGerminacionData) => {
        data.lote_id = id;
        data.verificacion_limpieza_area_germinacion_ingreso =
            data.verificacion_limpieza_area_germinacion_ingreso ? 1 : 0;
        data.cumple_oscuridad_ingreso = data.cumple_oscuridad_ingreso_formulario === '1' ? 0 : 1;
        setCargandoData(true);
        CannavaApiService.request(
            'POST',
            `/trazabilidad/api/lote-germinacion/iniciar-germinacion`,
            data,
            authToken,
            data => {
                console.log(data);
                setFlashMessage('Se finalizó el inicio de la germinación correctamente.');
                setFlashType('success');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion/ver/' + id);
            },
            (error: any) => {
                if (error.response.status == 422) {
                    console.log(error.response.data);
                    for (let i = 0; i < error.response.data.length; i++) {
                        const errorValidacion: any = error.response.data[i];
                        methods.setError(errorValidacion.field, {
                            message: errorValidacion.message
                        });
                    }
                } else {
                    alert('Error desconocido');
                    console.log(error);
                }

                setCargandoData(false);
            }
        );
    };

    const onCancel: any = () => {
        navigate('/admin/lotes-germinacion');
    };

    // endregion Hooks y callbacks

    // region useEffect

    useEffect(() => {
        if (id) {
            fetchLoteData(id);
        }
    }, [id]);

    useEffect(() => {
        if (cargandoData) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [cargandoData, methods.formState.submitCount]);

    if (cargandoData) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            <Flex
                direction="column"
                minH="100vh"
                align="center"
                pt={{ sm: '125px', lg: '75px' }}
                position="relative"
            >
                <Box
                    h="45vh"
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    position="absolute"
                    w="100%"
                    borderRadius="20px"
                />

                {/* region Formulario */}

                <FormProvider {...methods}>
                    <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Tabs
                                variant="unstyled"
                                zIndex="0"
                                mt={{ base: '60px', md: '165px' }}
                                display="flex"
                                flexDirection="column"
                            >
                                <TabList
                                    display="flex"
                                    alignItems="center"
                                    alignSelf="center"
                                    justifySelf="center"
                                >
                                    <Tab
                                        _focus={{ border: '0px', boxShadow: 'unset' }}
                                        ref={germinacionTab}
                                        w={{ sm: '120px', md: '250px', lg: '300px' }}
                                        onClick={() =>
                                            setActiveBullets({
                                                germinacion: true,
                                                condicionesAmbientales: false
                                            })
                                        }
                                    ></Tab>
                                    <Tab
                                        _focus={{ border: '0px', boxShadow: 'unset' }}
                                        ref={condicionesAmbientalesTab}
                                        w={{ sm: '120px', md: '250px', lg: '300px' }}
                                        onClick={() =>
                                            setActiveBullets({
                                                germinacion: true,
                                                condicionesAmbientales: true
                                            })
                                        }
                                    ></Tab>
                                </TabList>
                                <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
                                    <TabPanel
                                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                                        p="0px"
                                        mx="auto"
                                    >
                                        <Card p="30px" mt={'60px'}>
                                            <Text
                                                color={textColor}
                                                fontSize="2xl"
                                                fontWeight="700"
                                                mb="20px"
                                            >
                                                Iniciar germinación
                                            </Text>
                                            {errorMessage && (
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    <AlertTitle>{errorMessage}</AlertTitle>
                                                </Alert>
                                            )}
                                            <Flex direction="column" w="100%">
                                                <Stack direction="column" spacing="20px">
                                                    <SimpleGrid gap="20px">
                                                        <SimpleGrid
                                                            columns={{ base: 1, md: 2 }}
                                                            gap="20px"
                                                        >
                                                            <CannavaDatePickerField
                                                                id={'fecha_ingreso'}
                                                                label={'Fecha ingreso'}
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />

                                                            <CannavaInputField
                                                                id={'hora_final_ingreso'}
                                                                label={'Hora ingreso'}
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />
                                                        </SimpleGrid>

                                                        <SimpleGrid
                                                            columns={{ base: 1, md: 2 }}
                                                            gap="20px"
                                                        >
                                                            <CannavaInputField
                                                                id={'bandejas_ingreso'}
                                                                label={
                                                                    'Nº de bandejas ingresadas (u.)'
                                                                }
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />
                                                            <CannavaInputField
                                                                id={'responsable_ingreso'}
                                                                label={'Responsable'}
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />
                                                        </SimpleGrid>

                                                        <FormControl
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                        >
                                                            <FormLabel
                                                                mb="0"
                                                                fontSize="sm"
                                                                color={textColor}
                                                                ms="10px"
                                                                fontWeight="bold"
                                                            >
                                                                ¿Se verificó la limpieza del área de
                                                                germinación?
                                                            </FormLabel>
                                                            <Switch
                                                                {...methods.register(
                                                                    'verificacion_limpieza_area_germinacion_ingreso'
                                                                )}
                                                                id="verificacion_limpieza_area_germinacion_ingreso"
                                                            />
                                                        </FormControl>
                                                    </SimpleGrid>
                                                </Stack>
                                                <Flex justify="space-between" mt="24px">
                                                    <Button
                                                        variant="outline"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        //navegar a lote
                                                        onClick={onCancel}
                                                        type="button"
                                                    >
                                                        Cancelar
                                                    </Button>
                                                    <Button
                                                        variant="darkBrand"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        onClick={() => {
                                                            condicionesAmbientalesTab.current.click();
                                                        }}
                                                    >
                                                        Continuar
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    </TabPanel>
                                    <TabPanel
                                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                                        p="0px"
                                        mx="auto"
                                    >
                                        <Card p="30px" mt={'60px'}>
                                            <Text
                                                color={textColor}
                                                fontSize="2xl"
                                                fontWeight="700"
                                                mb="20px"
                                            >
                                                Condiciones ambientales en cámara
                                            </Text>
                                            {errorMessage && (
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    <AlertTitle>{errorMessage}</AlertTitle>
                                                </Alert>
                                            )}
                                            <Flex direction="column" w="100%">
                                                <Stack direction="column" spacing="20px">
                                                    <SimpleGrid gap="20px">
                                                        <SimpleGrid
                                                            columns={{ base: 1, md: 2 }}
                                                            gap="20px"
                                                        >
                                                            <CannavaInputField
                                                                id={'temperatura_camara_ingreso'}
                                                                label={'Temperatura (24-26°C)'}
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />

                                                            <CannavaInputField
                                                                id={'hr_camara_ingreso'}
                                                                label={'Humedad(70-90%)'}
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />
                                                        </SimpleGrid>

                                                        <SimpleGrid
                                                            columns={{ base: 1, md: 2 }}
                                                            gap="20px"
                                                        >
                                                            <CannavaSelectField
                                                                id={
                                                                    'cumple_oscuridad_ingreso_formulario'
                                                                }
                                                                label={'Cumple oscuridad'}
                                                                opciones={[
                                                                    { id: 1, nombre: 'SI' },
                                                                    { id: 0, nombre: 'NO' }
                                                                ]}
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />
                                                        </SimpleGrid>

                                                        <FormControl
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                        >
                                                            <FormLabel
                                                                mb="0"
                                                                fontSize="sm"
                                                                color={textColor}
                                                                ms="10px"
                                                                fontWeight="bold"
                                                            >
                                                                ¿Se verificó la limpieza del área de
                                                                germinación?
                                                            </FormLabel>
                                                            <Switch
                                                                {...methods.register(
                                                                    'verificacion_limpieza_area_germinacion_ingreso'
                                                                )}
                                                                id="verificacion_limpieza_area_germinacion_ingreso"
                                                            />
                                                        </FormControl>
                                                    </SimpleGrid>
                                                </Stack>
                                                <Flex justify="space-between" mt="24px">
                                                    <Button
                                                        variant="outline"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        //navegar a lote
                                                        onClick={() => {
                                                            germinacionTab.current.click();
                                                        }}
                                                        type="button"
                                                    >
                                                        Atrás
                                                    </Button>
                                                    <Button
                                                        variant="darkBrand"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        isLoading={methods.formState.isSubmitting}
                                                        type="submit"
                                                    >
                                                        Continuar
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </form>
                    </Box>
                </FormProvider>

                {/* endregion Formulario */}
            </Flex>
        </>
    );
}
