// Chakra imports
import {
    Flex,
    Box,
    Grid,
    SimpleGrid,
    useColorModeValue,
    Icon,
    Text,
    Badge,
    Alert,
    AlertIcon,
    Button,
    Divider
} from '@chakra-ui/react';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useReactToPrint } from 'react-to-print';
import OrderStatus from 'views/admin/main/ecommerce/orderDetails/components/OrderStatus';
import Receipt from 'views/admin/main/ecommerce/orderDetails/components/Receipt';
import Details from 'views/admin/main/ecommerce/orderDetails/components/Details';
import { useLayoutContext } from 'contexts/LayoutContext';
import { useParams } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';
import { useAppContext } from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import PageLoader from 'components/actions/PageLoader';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { MdShowChart } from 'react-icons/md';
import { VSeparator } from 'components/separator/Separator';
import EvolucionProcesoAnalisis from './components/EvolucionProcesoAnalisis';
import Card from 'components/card/Card';
import MapCard from 'views/admin/dashboards/smartHome/components/MapCard';
import SessionBadge from 'components/dataDisplay/SessionBadge';
import { Inventario } from 'modules/trazabilidad/types/Inventario';
import { createColumnHelper, CellContext } from '@tanstack/react-table';
import Listado, { crearCelda, crearCeldaConLink, crearHeader } from 'components/cannava/listado';
import { useFetchData } from 'hooks/useFetchData';
import { MenuItemProps } from 'types/MenuItemProps';
import PanelAnotaciones from 'components/chat/PanelAnotaciones';
import InfoLote from './components/InfoLote';
import AnalisisCalidadSemilla from '../../lotesPoderGerminativo/verLote/components/AnalisisCalidadSemilla';
import CardControlSiembra from '../germinacion/components/CardControlSiembra';
import CardControlGerminacion from '../germinacion/components/CardControlGerminacion';
import { FilaInfoRegistro } from './components/FilaInfoRegistro';
import Cronograma from 'components/cronograma_trazabilidad';
import CardControlPlantinera from '../iniciarPlantinera/componentes/CardControlPlantinera';
import FinalizarPlantinera from '../finalizarPlantinera';
import CardRegistroBajas from './components/CardRegistroBajas';
import CardLimpiezaArea from '../limpiezaAreaGerminacion/Components/CardLimpiezaArea';

export default function VerLoteGerminacion() {
    // region Variables

    const textColor = useColorModeValue('gray.700', 'white');
    const bgButton = 'rgba(255,255,255,0.12)';
    const bgHover = { bg: 'whiteAlpha.50' };
    const bgFocus = { bg: 'rgba(255,255,255,0.12)' };
    const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    const navigate = useNavigate();
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const { authToken } = useAppContext();
    const { id } = useParams();
    const [cargandoData, setCargandoData] = useState(false);
    const componentRef = useRef();
    const { setFlashMessage, setFlashType } = useAppContext();
    const [loteData, setLoteData] = useState(null);
    const [estado, setEstado] = useState<string>(null);

    // endregion

    // region Hooks y callbacks
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const fetchLoteData = async (loteId: string) => {
        setCargandoData(true);
        CannavaApiService.lotesGerminacionObtener(
            loteId,
            authToken,
            data => {
                console.log('data lote germinacion');
                console.log(data);
                setLoteData(data);
                setCargandoData(false);
                setEstado(data.estado);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion');
            }
        );
    };

    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: [
                { label: 'Inicio', href: '/admin/home/' },
                { label: 'Trazabilidad', href: '/admin/home/' },
                { label: 'Lotes de germinación', href: '/admin/lotes-germinacion/' }
            ],
            titulo: () => {
                // Generar título basado en data cargada
                return !loteData ? '' : `Lote ${loteData.codigo_lote}`;
            }
        },
        loteData
    );

    const aprobarLote = async () => {
        await CannavaApiService.request(
            'GET',
            '/trazabilidad/api/lote-germinacion/aprobar-lote/' + id,
            {},
            authToken,
            data => {
                setFlashMessage('Lote aprobado correctamente.');
                setFlashType('success');
                fetchLoteData(id);
            },
            error => {
                setFlashMessage('Hubo un error al activar la campaña.');
                setFlashType('error');
                //actualizarCampana(true);
            }
        );
    };

    const entregarLote = async () => {
        await CannavaApiService.request(
            'GET',
            '/trazabilidad/api/lote-germinacion/entregar-lote/' + id,
            {},
            authToken,
            data => {
                setFlashMessage('Lote entregado correctamente.');
                setFlashType('success');
                fetchLoteData(id);
            },
            error => {
                setFlashMessage('Hubo un error al activar la campaña.');
                setFlashType('error');
                //actualizarCampana(true);
            }
        );
    };

    const rechazarLote = async () => {
        await CannavaApiService.request(
            'GET',
            '/trazabilidad/api/lote-germinacion/entregar-lote/' + id,
            {},
            authToken,
            data => {
                setFlashMessage('Lote entregado correctamente.');
                setFlashType('success');
                fetchLoteData(id);
            },
            error => {
                setFlashMessage('Hubo un error al activar la campaña.');
                setFlashType('error');
                //actualizarCampana(true);
            }
        );
    };

    const recibirLote = async () => {
        await CannavaApiService.request(
            'GET',
            '/trazabilidad/api/lote-germinacion/recibir-lote/' + id,
            {},
            authToken,
            data => {
                setFlashMessage('Lote recibido correctamente.');
                setFlashType('success');
                fetchLoteData(id);
            },
            error => {
                setFlashMessage('Hubo un error al activar la campaña.');
                setFlashType('error');
                //actualizarCampana(true);
            }
        );
    };

    const enviarLoteAPlantinera = async () => {
        await CannavaApiService.request(
            'GET',
            '/trazabilidad/api/lote-germinacion/enviar-a-plantinera/' + id,
            {},
            authToken,
            data => {
                setFlashMessage('Lote enviado correctamente.');
                setFlashType('success');
                fetchLoteData(id);
            },
            error => {
                setFlashMessage('Hubo un error al enviar el lote.');
                setFlashType('error');
                //actualizarCampana(true);
            }
        );
    };

    const recibirLoteAPlantinera = async () => {
        await CannavaApiService.request(
            'GET',
            '/trazabilidad/api/lote-germinacion/recibir-plantinera/' + id,
            {},
            authToken,
            data => {
                setFlashMessage('Lote recibido correctamente.');
                setFlashType('success');
                fetchLoteData(id);
            },
            error => {
                setFlashMessage('Hubo un error al recibir el lote.');
                setFlashType('error');
                //actualizarCampana(true);
            }
        );
    };

    // endregion

    // region useEffect

    useEffect(() => {
        if (id) {
            fetchLoteData(id);
        }
    }, [id]);

    // endregion

    if (cargandoData || !loteData) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Grid
                templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
                gap={4}
                mb={'30px'}
            >
                {/* Fila 1 */}
                <Box>
                    <MiniStatistics
                        name="Cannabinoide dominante"
                        value={loteData.loteSemillas.genetica.cannabinoideDominante.slug.toUpperCase()}
                    />
                </Box>
                <Box>
                    <MiniStatistics name="Variedad" value={loteData.codigo_lote} />
                </Box>
                <Box>
                    <MiniStatistics
                        name="Cantidad de semillas estimadas"
                        value={loteData.cantidad_semillas}
                    />
                </Box>
                <Box>
                    {estado === 'sin-aprobacion' || estado === 'aprobado' ? (
                        <MiniStatistics name="Estado" value={loteData.estado} />
                    ) : (
                        <MiniStatistics
                            name="Cantidad de bandejas estimadas"
                            value={loteData.bandejas_estimadas}
                        />
                    )}
                </Box>
            </Grid>

            {/* Fila 2 */}
            <Grid
                templateColumns={{ base: 'repeat(1, 1fr)', md: '75% 25%' }}
                gridColumn="span 4"
                gap={4}
            >
                {/* Columna 1 de Fila 2 */}
                <Flex flexDirection="column">
                    {/* Fila 2.1.1 */}
                    <SimpleGrid columns={{ base: 1, md: 1 }} spacing={4} mb={2}>
                        <Box w="100%">
                            <CardControlPlantinera lote={loteData} />
                        </Box>
                        <Flex
                            flexDirection={{ base: 'column', md: 'row' }}
                            justify="space-between"
                            gap={'20px'}
                        >
                            <CardControlGerminacion lote={loteData} />
                            <CardControlSiembra lote={loteData} />
                        </Flex>
                        <Box w="100%">
                            <CardLimpiezaArea lote={loteData} />
                        </Box>
                        <Box w="100%">
                            <InfoLote
                                loteData={loteData}
                                estado={estado}
                                loteAprobado={aprobarLote}
                                loteEntregado={entregarLote}
                                rechazarLote={rechazarLote}
                                recibirLote={recibirLote}
                                actualizarPantalla={fetchLoteData}
                                enviarLoteAPlantinera={enviarLoteAPlantinera}
                                recibirLoteAPlantinera={recibirLoteAPlantinera}
                            />
                        </Box>
                    </SimpleGrid>
                    {/* Fila 2.1.2 */}

                    <Cronograma loteData={loteData.loteSemillas} />
                    <PanelAnotaciones modelo="loteGerminacion" id={loteData.id} />
                </Flex>
                {/* Columna 2 de Fila 2 */}

                <Flex w="100%" flexDirection="column" mt="8">
                    <CardRegistroBajas loteData={loteData} estado={estado} />
                    <Card p="30px" mb={5}>
                        <Flex justify="space-between" align="center" mb="5">
                            <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                                Documentos adjuntos
                            </Text>
                        </Flex>
                        <SimpleGrid
                            columns={{ sm: 1, md: 1, xl: 1 }}
                            spacing={{ base: '20px', xl: '5px' }}
                        >
                            {loteData.ordenSiembra && loteData.ordenSiembra.documento && (
                                <>
                                    <FilaInfoRegistro
                                        label={'Orden de siembra'}
                                        value="Descargar"
                                        click={() => {
                                            window.open(
                                                loteData.ordenSiembra.documento.archivo.url,
                                                '_blank'
                                            );
                                        }}
                                    />
                                    <Divider border="1px" borderColor="lightgray" />
                                </>
                            )}
                        </SimpleGrid>
                    </Card>
                    <MapCard gridArea="1 / 2 / 2 / 3" />
                </Flex>
            </Grid>
        </Box>
    );
}
