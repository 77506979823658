/*eslint-disable*/

import { Flex, Link, List, ListItem, Text, useColorModeValue } from '@chakra-ui/react';
import { useAppContext } from '../../contexts/AppContext';

export default function Footer() {
    const textColor = useColorModeValue('gray.400', 'white');
    const { appVersion } = useAppContext();

    return (
        <Flex
            zIndex="3"
            flexDirection={{
                base: 'column',
                xl: 'row'
            }}
            alignItems={{
                base: 'center',
                xl: 'start'
            }}
            justifyContent="space-between"
            px={{ base: '30px', md: '50px' }}
            pb="30px"
        >
            <Text
                color={textColor}
                textAlign={{
                    base: 'center',
                    xl: 'start'
                }}
                mb={{ base: '20px', xl: '0px' }}
            >
                {' '}
                &copy; {new Date().getFullYear()}
                <Text as="span" fontWeight="500" ms="4px">
                    Cannava {appVersion} x Growketing. Todos los derechos reservados.
                </Text>
            </Text>
            <List display="flex">
                <ListItem
                    me={{
                        base: '20px',
                        md: '44px'
                    }}
                >
                    <Link fontWeight="500" color={textColor} href="mailto:gonzalo@faro.works">
                        Soporte
                    </Link>
                </ListItem>
                <ListItem>
                    <Link
                        fontWeight="500"
                        color={textColor}
                        href="https://www.faro.works/?utm_source=cannava-app&utm_medium=app&utm_campaign=footer"
                    >
                        Growketing
                    </Link>
                </ListItem>
            </List>
        </Flex>
    );
}
