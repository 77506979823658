// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    useColorModeValue,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
    AlertTitle,
    useDisclosure
} from '@chakra-ui/react';
// Custom components
import Card from '../../../../../components/card/Card';
import InputField from '../../../../../components/fields/InputField';
import TextField from '../../../../../components/fields/TextField';
import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from '../../../../../config';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaApiService from '../../../../../utils/CannavaApiService';
import { useAppContext } from '../../../../../contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from '../../../../../components/fields/CannavaInputField';
import PageLoader from '../../../../../components/actions/PageLoader';
import DangerZone from '../../../../../components/cannava/dangerZone';
import { Ubicacion } from '../../../types/Ubicacion';
import { useLayoutContext } from '../../../../../contexts/LayoutContext';
import { useEnvioFormulario } from '../../../../../hooks/useEnvioFormulario';
import { useConfigurarNavegacion } from '../../../../../hooks/useConfigurarNavegacion';
import CannavaSelectField from '../../../../../components/fields/CannavaSelectField';
import CannavaTextareaField from '../../../../../components/fields/CannavaTextareaField';
import { Proveedor } from '../../../../genetica/types/Proveedor';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { Establecimiento } from '../../../types/Establecimiento';

type OpcionesSelect = {
    tipos: any[];
    establecimientos: any[];
    estados: any[];
};

export default function NewUbicacion() {
    // inicializacion form, id y estados
    const methods = useForm();
    const { id } = useParams();
    const isEditMode = !!id;
    const [opcionesSelect, setOpcionesSelect] = useState<OpcionesSelect>({
        tipos: [],
        establecimientos: [],
        estados: []
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);

    // variables de layout y estilo
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    // helpers
    const navigate = useNavigate();

    const transformData = (data: any[]): Establecimiento[] => {
        return data.map(proveedor => ({
            id: proveedor.id,
            nombre: proveedor.nombre,
            descripcion: proveedor.descripcion,
            slug: proveedor.slug,
            codigo: proveedor.codigo,
            latitud: proveedor.latitud,
            longitud: proveedor.longitud,
            superficie_total: proveedor.superficie_total,
            fecha_creacion: proveedor.fecha_creacion,
            fecha_actualizacion: proveedor.fecha_actualizacion,
            fecha_borrado: proveedor.fecha_borrado,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };
    const { data: establecimientosData, isLoading } = useFetchData<Establecimiento>(
        'establecimientos',
        transformData
    );

    // configuro layout
    const configurarNavegacion = useConfigurarNavegacion({
        breadcrumbs: [
            { label: 'Inicio', href: '/' },
            { label: 'Ubicaciones', href: '/admin/establecimientos/' },
            { label: 'Locaciones', href: '/admin/ubicaciones' }
        ],
        titulo: isEditMode ? 'Editar locación' : 'Nueva locación'
    });

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'locacion',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/ubicaciones');
        },
        onError: data => {
            //generalTab.current.click();
        }
    });

    // capturo el envio de formulario y puedo manipularla data para prepararla
    const onSubmit = (data: any) => {
        if (isEditMode) {
            useEnvioFormulario1.envioFormularioActualizar(id, data);
            return;
        }

        data['estado'] = 'draft';
        useEnvioFormulario1.envioFormularioCrear(data);
    };

    useEffect(() => {
        if (!establecimientosData) {
            return;
        }

        let opciones: OpcionesSelect = {
            tipos: [
                {
                    id: 1,
                    nombre: 'Invernadero'
                },
                {
                    id: 2,
                    nombre: 'Campo'
                },
                {
                    id: 3,
                    nombre: 'Almería'
                },
                {
                    id: 4,
                    nombre: 'Macro Túnel'
                },
                {
                    id: 5,
                    nombre: 'Plantinera'
                }
            ],
            estados: [
                {
                    id: 'activa',
                    nombre: 'Activa'
                }
            ],
            establecimientos: establecimientosData
        };

        setOpcionesSelect(opciones);
        console.log('opciones select', opciones);
    }, [establecimientosData]);

    // useEffect para precargar el formulario si estamos editando o terminar la carga
    useEffect(() => {
        if (!opcionesSelect) {
            return;
        }

        if (id) {
            useEnvioFormulario1.cargarFormulario(id);
        } else {
            useEnvioFormulario1.setIsLoading(false);
        }
    }, [opcionesSelect]);

    // useEffect para capturar errores del formulario
    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            // Error callback
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [useEnvioFormulario1.isLoading, methods.formState.submitCount]);

    // si estoy cargando muestro el spinner
    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de locación..." />;
    }

    if (isLoading) {
        return <PageLoader message="Cargando opciones de formulario..." />;
    }

    return (
        <>
            {!isDeleting && (
                <Flex
                    direction="column"
                    minH="100vh"
                    align="center"
                    pt={{ sm: '125px', lg: '75px' }}
                    position="relative"
                >
                    <Box
                        h="45vh"
                        bgGradient="linear(to-b, brand.400, brand.600)"
                        position="absolute"
                        w="100%"
                        borderRadius="20px"
                    />

                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Tabs
                                variant="unstyled"
                                zIndex="0"
                                mt={{ base: '60px', md: '165px' }}
                                display="flex"
                                flexDirection="column"
                            >
                                <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
                                    <TabPanel
                                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                                        p="0px"
                                        mx="auto"
                                    >
                                        <Card p="30px">
                                            <Text
                                                color={textColor}
                                                fontSize="2xl"
                                                fontWeight="700"
                                                mb="20px"
                                            >
                                                Información general
                                            </Text>
                                            {errorMessage && (
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    <AlertTitle>{errorMessage}</AlertTitle>
                                                </Alert>
                                            )}
                                            <Flex direction="column" w="100%">
                                                <Stack direction="column" spacing="20px">
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaSelectField
                                                            id={'establecimiento_id'}
                                                            label={'Establecimiento'}
                                                            mb={'20px'}
                                                            opciones={
                                                                opcionesSelect.establecimientos
                                                            }
                                                            placeholder={
                                                                'Seleccione un establecimiento'
                                                            }
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaSelectField
                                                            id={'tipo_id'}
                                                            label={'Tipo'}
                                                            mb={'20px'}
                                                            opciones={opcionesSelect.tipos}
                                                            placeholder={
                                                                'Seleccione un establecimiento'
                                                            }
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'nombre'}
                                                            label={'Nombre'}
                                                            mb={'20px'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaSelectField
                                                            id={'estado'}
                                                            label={'Estado'}
                                                            mb={'20px'}
                                                            opciones={opcionesSelect.estados}
                                                            placeholder={'Seleccione un estado'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>

                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2, lg: 3 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaInputField
                                                            id={'latitud'}
                                                            label={'Latitud'}
                                                            mb={'20px'}
                                                        />

                                                        <CannavaInputField
                                                            id={'longitud'}
                                                            label={'Longitud'}
                                                            mb={'20px'}
                                                        />

                                                        <CannavaInputField
                                                            id={'superficie_total'}
                                                            label={'Superficie (m2)'}
                                                            mb={'20px'}
                                                        />
                                                    </SimpleGrid>

                                                    <CannavaTextareaField
                                                        id={'descripcion'}
                                                        label={'Descripción'}
                                                        validationRules={{
                                                            required: 'Campo obligatorio'
                                                        }}
                                                    />
                                                </Stack>
                                                <Flex justify="space-between" mt="24px">
                                                    <Button
                                                        variant="darkBrand"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        isLoading={methods.formState.isSubmitting}
                                                        type="submit"
                                                    >
                                                        Enviar
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </form>
                    </FormProvider>
                    {/* Danger Zone */}
                    {isEditMode && (
                        <DangerZone
                            elemento={'locacion'}
                            onConfirmDelete={() => {
                                setIsDeleting(true);
                                useEnvioFormulario1.eliminarElemento(id);
                            }}
                        />
                    )}
                </Flex>
            )}

            {isDeleting && <PageLoader message="Eliminando ubicación..." />}
        </>
    );
}
