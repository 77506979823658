import { Flex, SimpleGrid, Text, useColorModeValue, Divider, Button, Box } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useState, useEffect } from 'react';
import { FilaInfoRegistro } from './FilaInfoRegistro';
import { useNavigate } from 'react-router-dom';
import { LotePlantaProductiva } from 'modules/trazabilidad/types/LotePlantaProductiva';

type InfoLoteProps = {
    loteData: LotePlantaProductiva;
    mostrarLoteSemillas?: boolean;
    estado: any;
    loteEntregado: CallableFunction;
    rechazarLote: CallableFunction;
    recibirLote: CallableFunction;
    actualizarPantalla: CallableFunction;
    enviarLoteAPlantinera: CallableFunction;
    recibirLoteAPlantinera: CallableFunction;
};

export default function InfoLote(props: InfoLoteProps) {
    const { loteData, loteEntregado } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const navigate = useNavigate();

    const [botonesOpcion, setBotonesOpcion] = useState([]);

    useEffect(() => {
        let opciones = [];

        switch (loteData.estado) {
            case 'en-espera':
                opciones.push({
                    nombre: 'Orden de aplicación',
                    callback: () => {
                        navigate('/lotes-plantas-productivas/orden-aplicacion/' + loteData.id);
                    }
                });
                opciones.push({
                    nombre: 'Entregar plantas',
                    callback: () => {
                        entregaLote();
                    }
                });
                break;
            case 'entregado':
                opciones.push({
                    nombre: 'Plantas recibidas',
                    callback: () => {
                        console.log(`ejecutar funcion 'recibirPlantas'`);
                    }
                });
                break;
            case 'recibido':
                opciones.push({
                    nombre: 'Iniciar regado',
                    callback: () => {
                        navigate(
                            '/lotes-plantas-productivas/riego-trasplante/' /* + loteData.id */
                        );
                    }
                });
                opciones.push({
                    nombre: 'Iniciar trasplante',
                    callback: () => {
                        navigate(
                            '/lotes-plantas-productivas/iniciar-trasplante/' /* + loteData.id */
                        );
                    }
                });
                break;
        }

        setBotonesOpcion(opciones);
    }, [loteData]);

    const entregaLote = () => {
        loteEntregado();
    };

    //Agrego lógica para que no rompa si no encuentra el nombre de la ubicacion ya que el 'type InfoLoteProps' no tiene la propiedad 'locacion_name'
    const ubicacion: string | null =
        null; /* loteData.lote_semillas.establecimiento.locaciones.find(
        locacion => locacion.id === loteData.locacion_id
    ); */
    const ubicacionNombre = ubicacion ? ubicacion : 'Ubicación no encontrada';

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center" mb="5">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Información de lote
                </Text>
                <Box>
                    {botonesOpcion.length > 0 &&
                        botonesOpcion.map((boton: any, index) => (
                            <Button colorScheme="green" m="1" onClick={boton.callback} key={index}>
                                {boton.nombre}
                            </Button>
                        ))}
                </Box>
            </Flex>
            <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing={{ base: '20px', xl: '5px' }}>
                <FilaInfoRegistro label={'Nº de Lote asignado'} value={loteData.codigo_lote} />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de procedencia'}
                    value={/* loteData.lote_padre_id */ 'LG-HardCoded'}
                    click={() => {
                        navigate('/admin/lotes-germinacion/ver/' /* + loteData.lote_padre_id */);
                    }}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Fecha de siembra'}
                    value={loteData.fecha_inicio_siembra}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro label={'Tipo'} value={'Planta'} />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de plantines estimados'}
                    value={/* loteData.plantines_estimados */ 1000}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Cantidad de plantines recibidos'}
                    value={/* loteData.plantines_recibidos */ 999}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de orden de trasplante'}
                    value={/* loteData.orden_trasplante */ '1234'}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de análisis de planta'}
                    value={/* loteData.analisis_planta */ '5678'}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Ubicación'}
                    value={ubicacionNombre}
                    click={() => {
                        navigate('/admin/ubicaciones/ver/' /* + loteData.locacion_id */);
                    }}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Sector'}
                    value={/* loteData.establecimiento_id */ 'Invernadero 6'}
                    click={() => {
                        navigate(
                            '/admin/establecimientos/ver/' /* + loteData.establecimiento_id */
                        );
                    }}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Uso de lote'}
                    value={/* loteData.tipo_uso */ 'Comercial'}
                />
            </SimpleGrid>
        </Card>
    );
}
