import { useState, useCallback } from 'react';
import { useAppContext } from '../../../contexts/AppContext';

type Permiso = {
    key: string;
    roles: string[];
    areas: string[];
};

export const useAutenticacionUsuario = (
    requiredRole?: string | string[],
    requiredArea?: string | string[]
) => {
    const { backendUserData } = useAppContext();
    const [error, setError] = useState<string | null>(null);

    const validarAutenticacion = (
        requiredRole?: string | string[],
        requiredArea?: string | string[]
    ) => {
        if (!backendUserData) {
            setError('not-logged-in');
            return false;
        }

        if (
            (!requiredRole && !requiredArea) ||
            backendUserData.roles.includes('super-administrador')
        ) {
            setError(null);
            return true;
        }

        // valido que tenga acceso al area
        if (requiredArea) {
            const areas = Array.isArray(requiredArea) ? requiredArea : [requiredArea];
            const areaFound = areas.some(area =>
                backendUserData.areas.some((userArea: any) => userArea.slug === area)
            );
            if (!areaFound) {
                setError('area');
                return false;
            }
        }

        if (requiredRole) {
            const userRoles = backendUserData.roles;

            if (!requiredRole.includes(userRoles)) {
                setError('rol');
                return false;
            }
        }

        setError(null);
        return true;
    };

    const obtenerPermisos = useCallback(
        (permisos: Permiso[]): Record<string, boolean> => {
            const resultados: Record<string, boolean> = {};
            permisos.forEach(permiso => {
                const resultado = validarAutenticacion(permiso.roles, permiso.areas);
                resultados[permiso.key] = resultado;
            });
            return resultados;
        },
        [backendUserData]
    );

    return { validarAutenticacion, obtenerPermisos, error };
};
