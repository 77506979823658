// Chakra imports
import {
    Box,
    Button,
    Flex,
    Text,
    useColorModeValue,
    Alert,
    AlertIcon,
    AlertTitle
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import { useLayoutContext } from 'contexts/LayoutContext';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import { useFetchData } from 'hooks/useFetchData';
import DangerZone from 'components/cannava/dangerZone';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import { LotePoderGerminativo } from 'modules/trazabilidad/types/LotePoderGerminativo';
import { LoteGerminacion } from 'modules/trazabilidad/types/LoteGerminacion';
import { LotePlantaProductiva } from 'modules/trazabilidad/types/LotePlantaProductiva';
import { Ubicacion } from 'modules/locacion/types/Ubicacion';

type OpcionesSelect = {
    lotesGerminacion: LoteGerminacion[];
    lotesPlantaProductiva: LotePlantaProductiva[];
    tipoLote: object[];
    tipoTrasplante: object[];
    ubicaciones: Ubicacion[];
};

type NuevoLoteData = {
    fecha_inicio_siembra: string;
    creado_por: number;
    tipo_trasplante: string;
    locacion_id: number;
    lotesPadre: { id: string; cantidad_plantas: number }[];
};

/**
 * Pantalla para crear un nuevo lote de planta productiva asociado a un lote de germinacion.
 * El diseno del mismo se encuentra aquí:
 * https://www.figma.com/proto/k3jQhs4SxEk6HRhwCXzUvD/%F0%9F%8C%B1-Cannava-wireframes?type=design&node-id=686-23033&t=SqbtRkKlA5g8L9Z5-0&scaling=scale-down-width&page-id=105%3A1102&starting-point-node-id=683%3A22318&show-proto-sidebar=1&hide-ui=1
 *
 * @constructor
 */
export default function NuevoLotePlantaProductiva() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { loteSemillasId } = useParams();
    const { setBreadcrumbs, setTitle } = useLayoutContext();

    const [isDeleting, setIsDeleting] = useState(false);

    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const navigate = useNavigate();
    const generalTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const permissionsTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);
    const [cargandoData, setCargandoData] = useState(false);
    const [loteData, setLoteData] = useState(null);
    const { setFlashMessage, setFlashType } = useAppContext();
    const { data: lotesGerminacionData, isLoading: isLoadingLotesGerminacion } =
        useFetchData<LoteGerminacion>('lotesGerminacion');
    const { data: lotesPlantaProductivaData, isLoading: isLoadingLotesPlantaProductiva } =
        useFetchData<LotePlantaProductiva>('lotesPlantaProductiva');
    const { data: ubicacionesData } = useFetchData<Ubicacion>('ubicaciones');

    //Uds. disponibles -> arreglar procedencia
    const unidadesDisponibles = 800;

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // configuro layout
    const configurarNavegacion = useConfigurarNavegacion({
        breadcrumbs: [
            { label: 'Inicio', href: '/' },
            /* arreglar breadcrumbs segun indice de lotes PP */
            { label: 'Lotes', href: '/admin/lote-semillas/' }
            /* { label: 'Lote Id', href: '/admin/lote-semillas/ver' } */
        ],
        titulo: 'Nuevo lote'
    });

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'lotePlantaProductiva',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/lotes-plantas-productivas');
        },
        onError: error => {
            if (generalTab.current != undefined) generalTab.current.click();
        },
        onErrorDelete: error => {
            navigate('/admin/lotes-plantas-productivas');
        }
    });

    const [opcionesSelect, setOpcionesSelect] = useState<OpcionesSelect>({
        lotesGerminacion: [],
        lotesPlantaProductiva: [],
        tipoLote: [],
        tipoTrasplante: [],
        ubicaciones: []
    });

    const onSubmit: SubmitHandler<NuevoLoteData> = (data: NuevoLoteData) => {
        data.creado_por = 2;
        data.fecha_inicio_siembra = '2024-04-01 00:00:00';

        const lotesPadreData: any[] = [];

        for (let i = 0; i < 1 /* asignar array para varios lotes (ej. monitoreo plagas) */; i++) {
            // Construct the property name dynamically
            const idDataKey = `idData_${i}`;
            const cantidadPlantasDataKey = `cantidadPlantasData_${i}`;

            // Access the corresponding sectorData_n property
            const lotePadreData = {
                id: (data as any)[idDataKey],
                cantidad_plantas: parseInt((data as any)[cantidadPlantasDataKey])
            };

            lotesPadreData.push(lotePadreData);
        }

        data.lotesPadre = lotesPadreData;
        useEnvioFormulario1.envioFormularioCrear(data);
        console.log(data);
    };

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    const onCancel: any = () => {
        navigate('/admin/lotes-germinacion');
    };

    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    useEffect(() => {
        if (!lotesGerminacionData) {
            return;
        }

        let opciones: OpcionesSelect = {
            lotesGerminacion: lotesGerminacionData,
            lotesPlantaProductiva: lotesPlantaProductivaData,
            tipoLote: [{ nombre: 'lote planta productiva' }],
            tipoTrasplante: [
                { nombre: 'invernadero' },
                { nombre: 'almeria' },
                { nombre: 'macrotunel' },
                { nombre: 'campo' }
            ],
            ubicaciones: ubicacionesData
        };

        setOpcionesSelect(opciones);
    }, [lotesGerminacionData, ubicacionesData]);

    if (
        useEnvioFormulario1.isLoading ||
        isLoadingLotesGerminacion ||
        isLoadingLotesPlantaProductiva
    ) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    if (cargandoData) {
        return <PageLoader message="Cargando opciones de formulario..." />;
    }

    return (
        <>
            {!isDeleting && (
                <Flex
                    direction="column"
                    minH="100vh"
                    align="center"
                    pt={{ sm: '125px', lg: '75px' }}
                    position="relative"
                >
                    <Box
                        h="45vh"
                        bgGradient="linear(to-b, brand.400, brand.600)"
                        position="absolute"
                        w="100%"
                        borderRadius="20px"
                    />

                    <FormProvider {...methods}>
                        <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <Card p="30px" mt={'60px'}>
                                    {errorMessage && (
                                        <Alert status="error">
                                            <AlertIcon />
                                            <AlertTitle>{errorMessage}</AlertTitle>
                                        </Alert>
                                    )}
                                    <Text
                                        color={textColor}
                                        fontSize="2xl"
                                        fontWeight="700"
                                        mb="20px"
                                    >
                                        Creación de lote
                                    </Text>
                                    <CannavaSelectField
                                        id={'tipo_lote'}
                                        label={'Tipo de lote'}
                                        opciones={opcionesSelect.tipoLote}
                                        placeholder={'Tipo de lote a crear'}
                                        validationRules={{
                                            required: 'Campo obligatorio'
                                        }}
                                    />
                                    {Array.from({ length: 1 }).map((_, index) => (
                                        <>
                                            <CannavaSelectField
                                                id={`idData_${index}`}
                                                label={
                                                    'Seleccioná el lote de procedencia de las plantas'
                                                }
                                                opciones={[
                                                    ...opcionesSelect.lotesGerminacion,
                                                    ...opcionesSelect.lotesPlantaProductiva
                                                ]}
                                                placeholder={'Seleccione un lote'}
                                                validationRules={{
                                                    required: 'Campo obligatorio'
                                                }}
                                            />
                                            <CannavaInputField
                                                id={`cantidadPlantasData_${index}`}
                                                label={
                                                    'Seleccioná la cantidad de plantas a trasplantar (%)'
                                                }
                                                validationRules={{
                                                    required: 'Campo obligatorio'
                                                }}
                                            />
                                        </>
                                    ))}
                                    <Flex
                                        justifyContent="space-between"
                                        ms="10px"
                                        color={textColorPrimary}
                                        fontWeight="bold"
                                        mb="30px"
                                    >
                                        <Text>Cantidad (Uds.) disponibles a trasplantar</Text>
                                        <Text>{unidadesDisponibles}</Text>
                                    </Flex>
                                    <CannavaSelectField
                                        id={'tipo_trasplante'}
                                        label={'Seleccioná el tipo del trasplante'}
                                        opciones={opcionesSelect.tipoTrasplante}
                                        placeholder={'Tipo de trasplante'}
                                        validationRules={{
                                            required: 'Campo obligatorio'
                                        }}
                                    />
                                    <CannavaSelectField
                                        id={'locacion_id'}
                                        label={'Seleccioná la ubicación del trasplante'}
                                        opciones={opcionesSelect.ubicaciones}
                                        placeholder={'Ubicación'}
                                        validationRules={{
                                            required: 'Campo obligatorio'
                                        }}
                                    />
                                    <Flex direction="column" w="100%">
                                        <Flex justify="space-between" mt="24px">
                                            <Button
                                                variant="outline"
                                                fontSize="sm"
                                                borderRadius="16px"
                                                w={{ base: '128px', md: '148px' }}
                                                h="46px"
                                                //navegar a lote
                                                onClick={onCancel}
                                                type="button"
                                            >
                                                Cancelar
                                            </Button>
                                            <Button
                                                variant="darkBrand"
                                                fontSize="sm"
                                                borderRadius="16px"
                                                w={{ base: '128px', md: '148px' }}
                                                h="46px"
                                                isLoading={methods.formState.isSubmitting}
                                                type="submit"
                                            >
                                                Continuar
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Card>
                            </form>
                        </Box>
                    </FormProvider>
                </Flex>
            )}

            {isDeleting && <PageLoader message="Eliminando lote de poder germinativo..." />}
        </>
    );
}
