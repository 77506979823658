import { ElementoBackend } from 'types/ElementosBackend';

type ConfigElementoBackend = {
    nombre: string;
    urlBase: string;
    expand?: string;
};

type ConfigElementosBackend = Record<ElementoBackend, ConfigElementoBackend>;

export const configElementosBackend: ConfigElementosBackend = {
    loteSemilla: {
        nombre: 'lote de semilla',
        urlBase: '/trazabilidad/api/lote-semillas'
    },
    loteGerminacion: {
        nombre: 'lote de germinacion',
        urlBase: '/trazabilidad/api/lote-germinacion',
        expand: 'loteSemillas'
    },
    lotePoderGerminativo: {
        nombre: 'lote para análisis de poder germinativo',
        urlBase: '/trazabilidad/api/lote-poder-germinativo',
        expand: 'loteSemillas'
    },
    reportePoderGerminativo: {
        nombre: 'reporte de análisis de poder germinativo',
        urlBase: '/trazabilidad/api/reporte-poder-germinativo'
    },
    reporteControlSiembra: {
        nombre: 'reporte de siembra',
        urlBase: '/trazabilidad/api/reporte-control-siembra'
    },
    reporteControlGerminacion: {
        nombre: 'reporte de germinación',
        urlBase: '/trazabilidad/api/reporte-control-germinacion',
        expand: 'lote'
    },
    reporteControlAmbiental: {
        nombre: 'reporte control ambiental de germinación',
        urlBase: '/trazabilidad/api/reporte-control-ambiental'
    },
    reporteControlCalidad: {
        nombre: 'reporte control calidad de plantinera',
        urlBase: '/trazabilidad/api/reporte-control-calidad'
    },
    proveedor: {
        nombre: 'proveedor',
        urlBase: '/genetica/api/proveedor',
        expand: 'geneticas'
    },
    cannabinoide: {
        nombre: 'cannabinoide',
        urlBase: '/genetica/api/cannabinoide'
    },
    locacion: {
        nombre: 'locación',
        urlBase: '/locacion/api/locacion',
        expand: 'establecimiento'
    },
    campana: {
        nombre: 'campaña',
        urlBase: '/planificacion/api/campanas'
    },
    genetica: {
        nombre: 'genética',
        urlBase: '/genetica/api/genetica'
    },
    planificacionCampana: {
        nombre: 'planificación de campaña',
        urlBase: '/planificacion/api/planificaciones'
    },
    objetivoCampana: {
        nombre: 'objetivo de campaña',
        urlBase: '/planificacion/api/objetivos-campana'
    },
    usuario: {
        nombre: 'usuario',
        urlBase: '/auth/api/usuarios'
    },
    area: {
        nombre: 'area',
        urlBase: '/auth/api/areas'
    },
    producto: {
        nombre: 'producto',
        urlBase: '/catalogo/api/productos'
    },
    establecimiento: {
        nombre: 'establecimiento',
        urlBase: '/locacion/api/establecimiento'
    },
    inventario: {
        nombre: 'inventario',
        urlBase: '/genetica/api/inventario'
    },
    anotacion: {
        nombre: 'anotacion',
        urlBase: '/anotacion/api/anotacion'
    },
    archivoSubido: {
        nombre: 'archivo subido',
        urlBase: '/uploader/api/archivos'
    },
    inicioPlantinera: {
        nombre: 'iniciar plantinera',
        urlBase: '/trazabilidad/api/plantinera'
    },
    monitoreoPlantines: {
        nombre: 'monitoreo plantines',
        urlBase: '/trazabilidad/api/monitoreo/plantinera'
    },
    monitoreoTemperaturaHumedad: {
        nombre: 'monitoreo temperatura humedad',
        urlBase: '/trazabilidad/api/monitoreo/temperatura-humedad'
    },
    monitoreoAmbiental: {
        nombre: 'monitoreo ambiental',
        urlBase: '/trazabilidad/api/monitoreo/control-ambiental'
    },
    monitoreoPlagas: {
        nombre: 'monitoreo plagas',
        urlBase: '/trazabilidad/api/monitoreo/plagas'
    },
    monitoreoEnfermedades: {
        nombre: 'monitoreo enfermedades',
        urlBase: '/trazabilidad/api/monitoreo/enfermedades'
    },
    lotePlantaProductiva: {
        nombre: 'lote planta productiva',
        urlBase: '/trazabilidad/api/lote-productivo'
    },
    ordenAplicacion: {
        nombre: 'orden aplicacion',
        urlBase: '/trazabilidad/api/orden-aplicacion-trasplante-l-p'
    },
    riegoTrasplante: {
        nombre: 'riego trasplante',
        urlBase: '/trazabilidad/api/riego-lote-productivo'
    },
    iniciarTrasplante: {
        nombre: 'iniciar trasplante',
        urlBase: '/trazabilidad/api/lote-productivo/iniciar-trasplante-lote'
    },
    insumo: {
        nombre: 'insumo',
        urlBase: '/catalogo/api/insumo'
    }
};
