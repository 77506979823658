// Chakra imports
import { Portal, Box, useDisclosure, useColorModeValue } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import { useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import routes from 'routes';
import AuthenticationGuard from 'components/auth/AuthenticationGuard';
import DashboardDefault from '../../views/admin/dashboards/default';
import UsuarioInvalidoCentered from 'views/auth/usuarioInvalido/UsuarioInvalidoCentered';
import FlashMessage from 'components/actions/FlashMessage';
import Welcome from '../../views/welcome';

// cannava imports
import CallbackAuth from '../../modules/auth/views/callbackAuth';
import UserNew from '../../modules/auth/views/users/newUser';
import { LayoutProvider } from '../../contexts/LayoutContext';
import VerLote from 'modules/trazabilidad/views/lotesSemillas/verLote';
import NewLote from 'modules/trazabilidad/views/lotesSemillas/newLote';
import CampanasView from '../../modules/planificacion/views/campanas/view';
import NuevaCampana from '../../modules/planificacion/views/campanas/create';
import GeneticaVer from '../../modules/genetica/views/variedades/view';
import NewVariedad from '../../modules/genetica/views/variedades/newVariedad';
import NewProveedor from '../../modules/genetica/views/proveedores/newProveedor';
import ProveedorVer from '../../modules/genetica/views/proveedores/view';
import NuevoEstablecimiento from '../../modules/locacion/views/establecimientos/create';
import EstablecimientoVer from '../../modules/locacion/views/establecimientos/view';
import NewUbicacion from '../../modules/locacion/views/ubicaciones/newUbicacion';
import NuevoProducto from '../../modules/catalogo/views/productos/create';
import ProductoVer from '../../modules/catalogo/views/productos/view';
import UbicacionVer from '../../modules/locacion/views/ubicaciones/view';
import VerLotePoderGenerativo from 'modules/trazabilidad/views/lotesPoderGerminativo/verLote';
import AsignarLoteAPlanificacion from 'modules/trazabilidad/views/lotesSemillas/asignarLoteAPlanificacion';
import VerLoteGerminacion from 'modules/trazabilidad/views/lotesGerminacion/verLote';
import IniciarSiembra from '../../modules/trazabilidad/views/lotesGerminacion/siembra/IniciarSiembra';
import Germinacion from '../../modules/trazabilidad/views/lotesGerminacion/germinacion';
import ControlAmbiental from '../../modules/trazabilidad/views/lotesGerminacion/controlAmbiental';
import FinalizacionGerminacion from '../../modules/trazabilidad/views/lotesGerminacion/finalizacionGerminacion';
import IniciarPlantinera from '../../modules/trazabilidad/views/lotesGerminacion/iniciarPlantinera';
import { useEffect } from 'react';
import useScrollToTop from '../../hooks/useScrollToTop';
import FinalizarPlantinera from 'modules/trazabilidad/views/lotesGerminacion/finalizarPlantinera';
import InformeCalidad from 'modules/trazabilidad/views/lotesGerminacion/informeCalidad';
import MonitoreoPlantines from 'modules/trazabilidad/views/lotesGerminacion/monitoreoPlantines';
import MonitoreoPlagas from 'modules/trazabilidad/views/lotesGerminacion/monitoreoPlagas';
import VerControlSiembra from 'modules/trazabilidad/views/lotesGerminacion/siembra/verControl';
import VerControlGerminacion from 'modules/trazabilidad/views/lotesGerminacion/controlLote/verControl';
import MonitoreoEnfermedades from 'modules/trazabilidad/views/lotesGerminacion/monitoreoEnfermedades';
import VerControlPlantinera from 'modules/trazabilidad/views/lotesGerminacion/controlPlantinera/verControl';
import MonitoreoTemperatura from 'modules/trazabilidad/views/lotesGerminacion/monitoreoTemperatura';
import MonitoreoAmbiental from 'modules/trazabilidad/views/lotesGerminacion/monitoreoAmbiental';
import PerfilUsuario from 'modules/auth/views/users/verUsuario';
import NuevaOrdenAplicacion from 'modules/trazabilidad/views/lotePlantaProductiva/ordenAplicacion';
import NuevoLotePlantaProductiva from 'modules/trazabilidad/views/lotePlantaProductiva/newLote';
import RegistroRiegoPreTrasplante from 'modules/trazabilidad/views/lotePlantaProductiva/riego/RiegoTrasplante';
import IniciarTrasplante from 'modules/trazabilidad/views/lotePlantaProductiva/trasplante/IniciarTrasplante';
import ConciliacionTrasplante from 'modules/trazabilidad/views/lotePlantaProductiva/conciliacion/ConciliacionTrasplante';
import ControlCalidad from 'modules/trazabilidad/views/lotePlantaProductiva/controlCalidad/ControlCalidad';
import BitacoraCultivo from 'modules/trazabilidad/views/lotePlantaProductiva/controlCalidad/BitacoraCultivo';
import ControlCultivo from 'modules/trazabilidad/views/lotePlantaProductiva/controlCalidad/ControlCultivo';
import ControlCultivoAdicional from 'modules/trazabilidad/views/lotePlantaProductiva/controlCalidad/ControlCultivoAdicional';
import ControlPodas from 'modules/trazabilidad/views/lotePlantaProductiva/controlCalidad/ControlPodas';
import ControlOtrasActividades from 'modules/trazabilidad/views/lotePlantaProductiva/controlCalidad/ControlOtrasActividades';
import CambioEtapa from 'modules/trazabilidad/views/lotePlantaProductiva/cambioEtapa/CambioEtapa';
import VerLotePlantaProductiva from 'modules/trazabilidad/views/lotePlantaProductiva/verLote';
import VerTrasplantePlantaProductiva from 'modules/trazabilidad/views/lotePlantaProductiva/verTrasplante';
import NewLotePG from 'modules/trazabilidad/views/lotesPoderGerminativo/newLote';
import FinalizarTrasplanteInvernadero from 'modules/trazabilidad/views/lotePlantaProductiva/trasplante/FinalizarTrasplanteInvernadero';
import FinalizarTrasplanteAlmeria from 'modules/trazabilidad/views/lotePlantaProductiva/trasplante/FinalizarTrasplanteAlmeria';
import FinalizarTrasplanteCampo from 'modules/trazabilidad/views/lotePlantaProductiva/trasplante/FinalizarTrasplanteCampo';
import FinalizarTrasplanteMacrotunel from 'modules/trazabilidad/views/lotePlantaProductiva/trasplante/FinalizarTrasplanteMacrotunel';
import LimpiezaAreaGerminacion from 'modules/trazabilidad/views/lotesGerminacion/limpiezaAreaGerminacion';
import VerDetallesLimpieza from 'modules/trazabilidad/views/lotesGerminacion/limpiezaAreaGerminacion/verDetallesLimpieza';
import PrepararBandejas from 'modules/trazabilidad/views/lotesGerminacion/siembra/PrepararBandejas';
import FinalizarBandejas from 'modules/trazabilidad/views/lotesGerminacion/siembra/FinalizarBandejas';
import IniciarSemillado from 'modules/trazabilidad/views/lotesGerminacion/siembra/IniciarSemillado';
import FinalizarSemillado from 'modules/trazabilidad/views/lotesGerminacion/siembra/FinalizarSemillado';
import EditarInsumo from 'modules/catalogo/views/insumos/editar';

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
    const { ...rest } = props;
    // states and functions
    const [fixed] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [mini, setMini] = useState(false);
    const [hovered, setHovered] = useState(false);

    // functions for changing the states from components
    const getRoute = () => {
        return window.location.pathname !== '/admin/full-screen-maps';
    };

    const getActiveRoute = (routes: RoutesType[]): string => {
        let activeRoute = 'Default Brand Text';
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].items);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };

    const getActiveNavbar = (routes: RoutesType[]): boolean => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveNavbar = getActiveNavbar(routes[i].items);
                if (collapseActiveNavbar !== activeNavbar) {
                    return collapseActiveNavbar;
                }
            } else {
                if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    return routes[i].secondary;
                }
            }
        }
        return activeNavbar;
    };
    const getRoutes = (routes: RoutesType[]): any => {
        return routes.map((route: RoutesType, key: number) => {
            if (route.layout === '/admin') {
                //console.log("configuro el authentication guard");
                //console.log(route);
                return <Route path={`${route.path}`} element={route.component} key={key} />;
                /*if ('requireAuth' in route && route.requireAuth) {
                  console.log("lo hice!");
                  return (
                      <Route path={`${route.path}`} element={<AuthenticationGuard component={route.component} />} key={key} />
                  );
                } else {
                  
                }*/
            } else if (route.path === '/callback') {
                return <Route path={`${route.path}`} element={route.component} key={key} />;
            }

            if (route.collapse) {
                return getRoutes(route.items);
            } else {
                return null;
            }
        });
    };
    // console.log(mini);
    document.documentElement.dir = 'ltr';
    const { onOpen } = useDisclosure();
    const bg = useColorModeValue('background.100', 'background.900');

    return (
        <Box bg={bg} h="100vh" w="100vw">
            <SidebarContext.Provider
                value={{
                    toggleSidebar,
                    setToggleSidebar
                }}
            >
                <Sidebar
                    hovered={hovered}
                    setHovered={setHovered}
                    mini={mini}
                    routes={routes}
                    display="none"
                    {...rest}
                />
                <Box
                    float="right"
                    minHeight="100vh"
                    height="100%"
                    overflow="auto"
                    position="relative"
                    maxHeight="100%"
                    w={
                        mini === false
                            ? { base: '100%', xl: 'calc( 100% - 290px )' }
                            : mini === true && hovered === true
                            ? { base: '100%', xl: 'calc( 100% - 290px )' }
                            : { base: '100%', xl: 'calc( 100% - 120px )' }
                    }
                    maxWidth={
                        mini === false
                            ? { base: '100%', xl: 'calc( 100% - 290px )' }
                            : mini === true && hovered === true
                            ? { base: '100%', xl: 'calc( 100% - 290px )' }
                            : { base: '100%', xl: 'calc( 100% - 120px )' }
                    }
                    transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
                    transitionDuration=".2s, .2s, .35s"
                    transitionProperty="top, bottom, width"
                    transitionTimingFunction="linear, linear, ease"
                >
                    <Portal>
                        <Box>
                            <Navbar
                                hovered={hovered}
                                setMini={setMini}
                                mini={mini}
                                onOpen={onOpen}
                                logoText={'Cannava'}
                                brandText={getActiveRoute(routes)}
                                secondary={getActiveNavbar(routes)}
                                theme={props.theme}
                                setTheme={props.setTheme}
                                fixed={fixed}
                                {...rest}
                            />
                        </Box>
                    </Portal>

                    {getRoute() ? (
                        <Box
                            mx="auto"
                            p={{ base: '20px', md: '30px' }}
                            pe="20px"
                            minH="100vh"
                            pt="50px"
                        >
                            <FlashMessage />
                            <Routes>
                                {getRoutes(routes)}
                                <Route
                                    path="/usuarios/editar/:id"
                                    element={<AuthenticationGuard component={UserNew} />}
                                />

                                <Route
                                    path="/usuarios/ver/:id"
                                    element={<AuthenticationGuard component={PerfilUsuario} />}
                                />

                                <Route
                                    path="/lotes-semillas/ver/:id"
                                    element={<AuthenticationGuard component={VerLote} />}
                                />

                                <Route
                                    path="/lotes-germinacion/ver/:id"
                                    element={<AuthenticationGuard component={VerLoteGerminacion} />}
                                />

                                <Route
                                    path="/lotes-germinacion/iniciar-siembra/:id"
                                    element={<AuthenticationGuard component={IniciarSiembra} />}
                                />

                                <Route
                                    path="/lotes-germinacion/preparar-bandejas/:id"
                                    element={<AuthenticationGuard component={PrepararBandejas} />}
                                />

                                <Route
                                    path="/lotes-germinacion/finalizar-bandejas/:id"
                                    element={<AuthenticationGuard component={FinalizarBandejas} />}
                                />

                                <Route
                                    path="/lotes-germinacion/iniciar-semillado/:id"
                                    element={<AuthenticationGuard component={IniciarSemillado} />}
                                />

                                <Route
                                    path="/lotes-germinacion/finalizar-semillado/:id"
                                    element={<AuthenticationGuard component={FinalizarSemillado} />}
                                />

                                <Route
                                    path="/lotes-germinacion/limpieza-area/:id"
                                    element={
                                        <AuthenticationGuard component={LimpiezaAreaGerminacion} />
                                    }
                                />

                                <Route
                                    path="/lotes-germinacion/limpieza-area-detalles/:id"
                                    element={
                                        <AuthenticationGuard component={VerDetallesLimpieza} />
                                    }
                                />

                                <Route
                                    path="/lotes-germinacion/iniciar-germinacion/:id"
                                    element={<AuthenticationGuard component={Germinacion} />}
                                />

                                <Route
                                    path="/lotes-germinacion/finalizar-germinacion/:id"
                                    element={
                                        <AuthenticationGuard component={FinalizacionGerminacion} />
                                    }
                                />

                                <Route
                                    path="/lotes-germinacion/iniciar-plantinera/:id"
                                    element={<AuthenticationGuard component={IniciarPlantinera} />}
                                />

                                <Route
                                    path="/lotes-germinacion/finalizar-plantinera/:id"
                                    element={
                                        <AuthenticationGuard component={FinalizarPlantinera} />
                                    }
                                />

                                <Route
                                    path="/lotes-germinacion/informe-calidad/:id"
                                    element={<AuthenticationGuard component={InformeCalidad} />}
                                />

                                <Route
                                    path="/lotes-germinacion/monitoreo-plantines/:id"
                                    element={<AuthenticationGuard component={MonitoreoPlantines} />}
                                />

                                <Route
                                    path="/lotes-germinacion/monitoreo-temperatura-humedad/:id"
                                    element={
                                        <AuthenticationGuard component={MonitoreoTemperatura} />
                                    }
                                />

                                <Route
                                    path="/lotes-germinacion/monitoreo-ambiental/:id"
                                    element={<AuthenticationGuard component={MonitoreoAmbiental} />}
                                />

                                <Route
                                    path="/lotes-germinacion/monitoreo-plagas/:id"
                                    element={<AuthenticationGuard component={MonitoreoPlagas} />}
                                />

                                <Route
                                    path="/lotes-germinacion/monitoreo-enfermedades/:id"
                                    element={
                                        <AuthenticationGuard component={MonitoreoEnfermedades} />
                                    }
                                />

                                <Route
                                    path="/lotes-germinacion/nuevo-control-ambiental/:id"
                                    element={<AuthenticationGuard component={ControlAmbiental} />}
                                />

                                <Route
                                    path="/lotes-germinacion/informe-control-siembra/:reporteSiembraId"
                                    element={<AuthenticationGuard component={VerControlSiembra} />}
                                />

                                <Route
                                    path="/lotes-germinacion/informe-control-germinacion/:id"
                                    element={
                                        <AuthenticationGuard component={VerControlGerminacion} />
                                    }
                                />

                                <Route
                                    path="/lotes-germinacion/informe-control-plantinera/:reportePlantineraId"
                                    element={
                                        <AuthenticationGuard component={VerControlPlantinera} />
                                    }
                                />

                                <Route
                                    path="/lotes-semillas/editar/:id"
                                    element={<AuthenticationGuard component={NewLote} />}
                                />

                                <Route
                                    path="/lotes-semillas/asignar-planificacion/:id"
                                    element={
                                        <AuthenticationGuard
                                            component={AsignarLoteAPlanificacion}
                                        />
                                    }
                                />

                                <Route
                                    path="/lotes-plantas-productivas/nuevo"
                                    element={
                                        <AuthenticationGuard
                                            component={NuevoLotePlantaProductiva}
                                        />
                                    }
                                />

                                <Route
                                    path="/lotes-plantas-productivas/ver/:id"
                                    element={
                                        <AuthenticationGuard component={VerLotePlantaProductiva} />
                                    }
                                />

                                <Route
                                    path="/trasplantes-plantas-productivas/ver/:id"
                                    element={
                                        <AuthenticationGuard
                                            component={VerTrasplantePlantaProductiva}
                                        />
                                    }
                                />

                                <Route
                                    path="/lotes-plantas-productivas/orden-aplicacion/:id"
                                    element={
                                        <AuthenticationGuard component={NuevaOrdenAplicacion} />
                                    }
                                />

                                <Route
                                    path="/lotes-plantas-productivas/riego-trasplante/:id/:tipo"
                                    element={
                                        <AuthenticationGuard
                                            component={RegistroRiegoPreTrasplante}
                                        />
                                    }
                                />

                                <Route
                                    path="/lotes-plantas-productivas/iniciar-trasplante/:id"
                                    element={<AuthenticationGuard component={IniciarTrasplante} />}
                                />

                                <Route
                                    path="/lotes-plantas-productivas/finalizar-trasplante-invernadero/:id"
                                    element={
                                        <AuthenticationGuard
                                            component={FinalizarTrasplanteInvernadero}
                                        />
                                    }
                                />

                                <Route
                                    path="/lotes-plantas-productivas/finalizar-trasplante-almeria/:id"
                                    element={
                                        <AuthenticationGuard
                                            component={FinalizarTrasplanteAlmeria}
                                        />
                                    }
                                />

                                <Route
                                    path="/lotes-plantas-productivas/finalizar-trasplante-campo/:id"
                                    element={
                                        <AuthenticationGuard component={FinalizarTrasplanteCampo} />
                                    }
                                />

                                <Route
                                    path="/lotes-plantas-productivas/finalizar-trasplante-macrotunel/:id"
                                    element={
                                        <AuthenticationGuard
                                            component={FinalizarTrasplanteMacrotunel}
                                        />
                                    }
                                />

                                <Route
                                    path="/lotes-plantas-productivas/conciliacion-trasplante/"
                                    element={
                                        <AuthenticationGuard component={ConciliacionTrasplante} />
                                    }
                                />

                                <Route
                                    path="/lotes-plantas-productivas/bitacora-cultivo/"
                                    element={<AuthenticationGuard component={BitacoraCultivo} />}
                                />

                                <Route
                                    path="/lotes-plantas-productivas/control-cultivo/"
                                    element={<AuthenticationGuard component={ControlCultivo} />}
                                />

                                <Route
                                    path="/lotes-plantas-productivas/control-cultivo-adicional/"
                                    element={
                                        <AuthenticationGuard component={ControlCultivoAdicional} />
                                    }
                                />

                                <Route
                                    path="/lotes-plantas-productivas/control-podas/"
                                    element={<AuthenticationGuard component={ControlPodas} />}
                                />

                                <Route
                                    path="/lotes-plantas-productivas/control-otras-actividades/"
                                    element={
                                        <AuthenticationGuard component={ControlOtrasActividades} />
                                    }
                                />

                                <Route
                                    path="/lotes-plantas-productivas/control-calidad/"
                                    element={<AuthenticationGuard component={ControlCalidad} />}
                                />

                                <Route
                                    path="/lotes-plantas-productivas/cambio-etapa/"
                                    element={<AuthenticationGuard component={CambioEtapa} />}
                                />

                                <Route
                                    path="/lotes-poder-germinativo/ver/:id"
                                    element={
                                        <AuthenticationGuard component={VerLotePoderGenerativo} />
                                    }
                                />

                                <Route
                                    path="/campanas/ver/:id"
                                    element={<AuthenticationGuard component={CampanasView} />}
                                />

                                <Route
                                    path="/campanas/editar/:id"
                                    element={<AuthenticationGuard component={NuevaCampana} />}
                                />

                                <Route
                                    path="/variedades/ver/:id"
                                    element={<AuthenticationGuard component={GeneticaVer} />}
                                />

                                <Route
                                    path="/variedades/editar/:id"
                                    element={<AuthenticationGuard component={NewVariedad} />}
                                />

                                <Route
                                    path="/proveedores/ver/:id"
                                    element={<AuthenticationGuard component={ProveedorVer} />}
                                />

                                <Route
                                    path="/proveedores/editar/:id"
                                    element={<AuthenticationGuard component={NewProveedor} />}
                                />

                                <Route
                                    path="/establecimientos/ver/:id"
                                    element={<AuthenticationGuard component={EstablecimientoVer} />}
                                />

                                <Route
                                    path="/establecimientos/editar/:id"
                                    element={
                                        <AuthenticationGuard component={NuevoEstablecimiento} />
                                    }
                                />

                                <Route
                                    path="/ubicaciones/ver/:id"
                                    element={<AuthenticationGuard component={UbicacionVer} />}
                                />

                                <Route
                                    path="/ubicaciones/editar/:id"
                                    element={<AuthenticationGuard component={NewUbicacion} />}
                                />

                                <Route
                                    path="/productos/ver/:id"
                                    element={<AuthenticationGuard component={ProductoVer} />}
                                />

                                <Route
                                    path="/productos/editar/:id"
                                    element={<AuthenticationGuard component={NuevoProducto} />}
                                />

                                <Route
                                    path="/insumos/nuevo/"
                                    element={<AuthenticationGuard component={EditarInsumo} />}
                                />

                                <Route
                                    path="/insumos/editar/:id"
                                    element={<AuthenticationGuard component={EditarInsumo} />}
                                />
                            </Routes>
                        </Box>
                    ) : null}
                    <Box>
                        <Footer />
                    </Box>
                </Box>
            </SidebarContext.Provider>
        </Box>
    );
}
