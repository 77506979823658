import { IconType } from 'react-icons';
import Card from 'components/card/Card';
import OrderStep from 'components/dataDisplay/OrderStep';
import IconBox from 'components/icons/IconBox';
import { Icon, Text, Flex, useColorModeValue } from '@chakra-ui/react';

export type Estado = {
    name: string;
    icon: IconType;
    done: boolean;
};

export type EstadosProps = {
    estados: Estado[];
    estadoSeleccionado: string;
    titulo: string;
    ms?: any;
    mt?: any;
    gridArea?: any;
    zIndex?: any;
};

export default function VisualizadorEstados(props: EstadosProps) {
    const { titulo, estados, estadoSeleccionado, ...rest } = props;
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const brandColor = useColorModeValue('brand.500', 'white');
    const completeBg = useColorModeValue(
        'white',
        'linear-gradient(180deg, #1F2A4F 0%, #18224D 50.63%, #111C44 100%)'
    );
    const completeShadow = useColorModeValue(
        '0px 18px 40px rgba(112, 144, 176, 0.12)',
        'inset 0px 4px 4px rgba(255, 255, 255, 0.2)'
    );
    const incompleteColor = useColorModeValue('secondaryGray.600', 'whiteAlpha.200');
    const incompleteShadow = useColorModeValue(
        'inset 0px 18px 22px rgba(112, 144, 176, 0.1)',
        'inset 0px 4px 4px #0B1437'
    );
    const lineColor = useColorModeValue('%23a3aed0', '%23FFFFFF1A');

    const getStatus = (stepName: string) => {
        const currentIndex = estados.findIndex(step => step.name === estadoSeleccionado);
        const stepIndex = estados.findIndex(step => step.name === stepName);

        if (stepIndex <= currentIndex) return 'done';
        if (stepIndex === currentIndex) return 'current';
        return 'pending';
    };

    return (
        <Card flexDirection="column" w="100%" pt="30px" pb="20px" {...rest}>
            <Text color={textColor} fontSize="2xl" fontWeight="700" lineHeight="100%" mb="40px">
                {titulo}
            </Text>

            <Flex position="relative" direction="column">
                <Flex
                    position="absolute"
                    left="32.5px"
                    h="100%"
                    w="2px"
                    bg={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${lineColor}' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`}
                    zIndex={1}
                />
                {estados.map((step, index) => (
                    <OrderStep
                        key={index}
                        mb={index === estados.length - 1 ? '0px' : '80px'}
                        name={step.name}
                        date={''}
                        status={getStatus(step.name)}
                        icon={
                            <IconBox
                                h="66px"
                                w="66px"
                                bg={getStatus(step.name) !== 'pending' ? completeBg : completeBg}
                                boxShadow={
                                    getStatus(step.name) !== 'pending'
                                        ? completeShadow
                                        : incompleteShadow
                                }
                                icon={
                                    <Icon
                                        as={step.icon}
                                        color={
                                            getStatus(step.name) !== 'pending'
                                                ? brandColor
                                                : incompleteColor
                                        }
                                        h="32px"
                                        w="32px"
                                    />
                                }
                            />
                        }
                    />
                ))}
            </Flex>
        </Card>
    );
}
