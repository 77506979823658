import { Box, Grid, Flex, Card, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import MapCard from 'views/admin/dashboards/smartHome/components/MapCard';
import MiniStatistics from 'components/card/MiniStatistics';
import PanelAnotaciones from 'components/chat/PanelAnotaciones';
import CardControlSiembra from '../germinacion/components/CardControlSiembra';
import CardControlGerminacion from '../germinacion/components/CardControlGerminacion';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import { FilaInfoRegistro } from '../verLote/components/FilaInfoRegistro';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLayoutContext } from 'contexts/LayoutContext';
import { useAppContext } from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import InfoControl from './components/InfoControl';
import PageLoader from 'components/actions/PageLoader';
import Observaciones from './components/Observaciones';
import Responsable from './components/Responsable';
import Adjuntos from './components/Adjuntos';
import InfoControlAmbiental from './components/InfoControlAmbiental';
import CannavaApiService from 'utils/CannavaApiService';
import InfoControlTerminado from './components/InfoControlTerminado';

export default function VerControlGerminacion() {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    const navigate = useNavigate();
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const { authToken } = useAppContext();
    const { id } = useParams();
    const [cargandoData, setCargandoData] = useState(false);
    const { setFlashMessage, setFlashType } = useAppContext();

    // Version TEST - Configurar carga de datos de la db!
    const [controlData, setControlData] = useState(null);
    const [usuario, setUsuario] = useState(null);

    const fetchReporteGerminacionData = async (id: string) => {
        setCargandoData(true);
        CannavaApiService.reporteGerminacionObtener(
            id,
            authToken,
            data => {
                console.log('data lote germinacion');
                console.log(data);
                setControlData(data);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion');
            }
        );
    };

    const fetchUsuario = async (id: number) => {
        setCargandoData(true);
        CannavaApiService.usuarioObtener(
            id,
            authToken,
            data => {
                console.log('usuario reporte germinacion');
                console.log(data);
                setUsuario(data);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion');
            }
        );
    };

    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: () => {
                let _breadcrumbs = [];
                if (!controlData) {
                    return [];
                } else {
                    _breadcrumbs = [
                        // Version TEST - Configurar rutas correctamente!
                        { label: 'Trazabilidad', href: '/admin/home/' },
                        { label: 'Lotes de germinación', href: '/admin/lotes-germinacion/' },
                        {
                            label: controlData.lote.codigo_lote,
                            href: '/admin/lotes-germinacion/ver/' + controlData.lote_id
                        }
                    ];
                }

                return _breadcrumbs;
            },
            titulo: `Germinación y control ambiental`
        },
        controlData
    );

    useEffect(() => {
        if (id && !controlData) {
            fetchReporteGerminacionData(id);
        }
        if (controlData) {
            fetchUsuario(controlData.creado_por);
        }
    }, [id, controlData]);

    if (cargandoData /* || !controlData */) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            {controlData && (
                <Grid
                    templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
                    gap={4}
                    mb={'30px'}
                >
                    {/* Fila 1 */}
                    <Box>
                        <MiniStatistics
                            name="Fecha de inicio del proceso"
                            value={
                                '26/02/2024' /* loteData.loteSemillas.genetica.cannabinoideDominante.slug.toUpperCase() */
                            }
                        />
                    </Box>
                    <Box>
                        <MiniStatistics
                            name="Variedad"
                            value={
                                controlData.lote.genetica.nombre /* loteData.cantidad_semillas */
                            }
                        />
                    </Box>
                    <Box>
                        <MiniStatistics
                            name="Estado del lote"
                            value={'Lote sano' /* loteData.cantidad_semillas */}
                        />
                    </Box>
                </Grid>
            )}

            {/* Fila 2 */}
            {controlData && (
                <Grid
                    templateColumns={{ base: 'repeat(1, 1fr)', md: '50% 50%' }}
                    gridColumn="span 4"
                    gap={4}
                >
                    {/* Columna 1 de Fila 2 */}
                    <Flex flexDirection="column" gap={2}>
                        {/* Fila 2.1.1 */}
                        <SimpleGrid columns={{ base: 1, md: 1 }} spacing={4} mb={2}>
                            <Flex
                                flexDirection={{ base: 'column' }}
                                justify="space-between"
                                gap={'20px'}
                            >
                                <Box w="100%">
                                    <InfoControl controlData={controlData} />
                                </Box>

                                {(controlData.lote.estado == 'germinacion-finalizada' ||
                                    controlData.lote.estado == 'enviado-a-plantinera' ||
                                    controlData.lote.estado == 'plantinera-recibida' ||
                                    controlData.lote.estado == 'plantinera-iniciada' ||
                                    controlData.lote.estado == 'plantinera-monitoreada' ||
                                    controlData.lote.estado == 'plantinera-finalizada') && (
                                    <Box w="100%">
                                        <InfoControlTerminado controlData={controlData} />
                                    </Box>
                                )}
                            </Flex>
                        </SimpleGrid>
                        {/* Fila 2.1.2 */}

                        {/* Version TEST - Editar para ver info reportes ambientales */}
                        {/* control ambiental &&  */}
                        <SimpleGrid columns={{ base: 1, md: 1 }} spacing={4} mb={2}>
                            <Flex
                                flexDirection={{ base: 'column', md: 'row' }}
                                justify="space-between"
                                gap={'20px'}
                            >
                                <Box w="100%">
                                    <InfoControlAmbiental idLote={controlData.lote_id} />
                                </Box>
                            </Flex>
                        </SimpleGrid>
                    </Flex>

                    {/* Columna 2 de Fila 2 */}
                    <Flex flexDirection="column" gap={4}>
                        {usuario && <Responsable usuarioData={usuario} />}
                        {usuario && (
                            <Observaciones usuarioData={usuario} controlData={controlData} />
                        )}
                    </Flex>
                </Grid>
            )}
        </Box>
    );
}
