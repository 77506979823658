// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    useColorModeValue,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useDisclosure
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import Index from 'components/cannava/dangerZone';
import { useLayoutContext } from 'contexts/LayoutContext';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import { Proveedor } from 'modules/genetica/types/Proveedor';
import { useFetchData } from 'hooks/useFetchData';
import { Variedad } from 'modules/genetica/types/Variedad';
import { useEnvioFormularioAntiguo } from 'hooks/useEnvioFormularioAntiguo';
import DangerZone from 'components/cannava/dangerZone';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import { Establecimiento } from 'modules/locacion/types/Establecimiento';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import { Campana } from 'modules/planificacion/types/Campana';
import { Planificacion } from 'modules/planificacion/types/Planificacion';

type UserFormData = {
    nombre: string;
    apellido: string;
    email: string;
    nombre_usuario: string;
    rol: string;
    fecha_nacimiento: string;
};

type LoteData = {
    lote_padre_id: string;
    tipo: string;
    cantidad_semillas: number;
    fecha_siembra: string;
    orden_siembra_id: number;
    lote_poder_germinativo_id: string;
    locacion_id: number;
    establecimiento_id: number;
    tipo_siembra: string;
    tipo_uso: string;
};

type OpcionesSelect = {
    lotesSemillas: any[];
    tipoLoteCrear: any[];
};

export default function NewLoteGerminacion() {
    // region Variables

    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const isEditMode = !!id;
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const [isDeleting, setIsDeleting] = useState(false);
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = useState({
        general: true,
        permissions: false
    });
    const navigate = useNavigate();
    const generalTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const permissionsTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);
    const [cargandoData, setCargandoData] = useState(false);
    const loteACrear = ['lote de germinación'];
    const mb: string = '0px';
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [opcionesSelect, setOpcionesSelect] = useState<OpcionesSelect>({
        lotesSemillas: [],
        tipoLoteCrear: []
    });
    const [planificaciones, setPlanificaciones] = useState<Planificacion[]>([]);
    const [opcionesCampana, setOpcionesCampana] = useState<any[]>([]);
    const loteSeleccionado = methods.watch('lote_padre_id');
    const campanaSeleccionada = methods.watch('campana_id');

    // endregion

    // region Hooks y callbacks

    const transformLote = (data: any[]): LoteSemillas[] => {
        return data.map(lote => ({
            id: lote.id,
            codigo_lote: lote.codigo_lote,
            peso: lote.peso,
            unidades: lote.unidades,
            fecha_recepcion: lote.fecha_recepcion,
            cantidad_bajas: lote.cantidad_bajas,
            peso_promedio: lote.peso_promedio,
            genetica_id: lote.genetica_id,
            genetica: lote.genetica,
            establecimiento: lote.establecimiento,
            establecimiento_id: lote.establecimiento_id,

            stock: lote.stock,
            actions: 'Actions'
        }));
    };

    const onSubmit: SubmitHandler<LoteData> = (data: LoteData) => {
        if (isEditMode) {
            useEnvioFormulario1.envioFormularioActualizar(id, data);
            return;
        }

        // temporal
        data.orden_siembra_id = 0;
        data.tipo_siembra = 'manual';
        data.tipo_uso = 'comercial';
        data.tipo = 'Lote de Semillas';
        //

        useEnvioFormulario1.envioFormularioCrear(data);
        console.log(data);
    };

    const { data: lotesData, isLoading } = useFetchData<LoteSemillas>('lotesSemillas');

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'loteGerminacion',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/lotes-germinacion');
        },
        onError: error => {
            console.log(error);
            if (generalTab.current != undefined) generalTab.current.click();
        },
        onErrorDelete: error => {
            navigate('/admin/lotes-germinacion');
        }
    });

    // endregion

    // region useEffect

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Trazabilidad', href: '/admin/trazabilidad/' },
            { label: 'Lotes de germinacion', href: '/admin/lotes-germinacion' }
        ]);

        if (isEditMode) {
            setTitle('Editar lote');
        } else {
            setTitle('Nuevo lote de germinación');
        }
    }, []);

    useEffect(() => {
        if (!loteSeleccionado || !lotesData) {
            return;
        }

        // cargo las campanas disponibles
        // looop through lotesData and get the lote selected
        methods.setValue('campana_id', 0);
        methods.setValue('cantidad_semillas', '');
        const lote = lotesData.find(lote => lote.id === loteSeleccionado);
        let campanas = [];
        if (lote) {
            // loop throught the planificaciones object and get the campana field the store it in an campaing array
            console.log('seleccionamos lote', lote);
            setPlanificaciones(lote.planificaciones);
        } else {
            setPlanificaciones([]);
        }

        // alert('se selecciono el lote ' + loteSeleccionado);
    }, [loteSeleccionado, lotesData]);

    useEffect(() => {
        if (!loteSeleccionado || !planificaciones) {
            return;
        }

        // loop throught planificaciones and create a array campanas with id and nombre
        let campanas: any[] = [];
        planificaciones.forEach(planificacion => {
            campanas.push({ id: planificacion.campana.id, nombre: planificacion.campana.nombre });
        });

        setOpcionesCampana(campanas);
    }, [planificaciones]);

    useEffect(() => {
        if (!campanaSeleccionada) {
            return;
        }

        const lote = lotesData.find(lote => lote.id === loteSeleccionado);
        const planificacion = lote?.planificaciones.find(
            planificacion => planificacion.campana_id == campanaSeleccionada
        );
        console.log('reviso las planificaciones', planificacion);
        if (planificacion) {
            methods.setValue(
                'cantidad_semillas',
                planificacion.cantidad > lote.unidades ? lote.unidades : planificacion.cantidad
            );
        }
    }, [campanaSeleccionada]);

    useEffect(() => {
        if (!lotesData) {
            return;
        }

        let opciones: OpcionesSelect = {
            lotesSemillas: lotesData,
            tipoLoteCrear: [{ nombre: 'lote germinación' }]
        };

        setOpcionesSelect(opciones);
    }, [lotesData]);

    useEffect(() => {
        if (!opcionesSelect) {
            return;
        }

        if (id) {
            useEnvioFormulario1.cargarFormulario(id);
        } else {
            useEnvioFormulario1.setIsLoading(false);
        }
    }, [opcionesSelect]);

    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    // endregion

    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    if (isLoading) {
        return <PageLoader message="Cargando opciones de formulario..." />;
    }

    return (
        <>
            {!isDeleting && (
                <Flex
                    direction="column"
                    minH="100vh"
                    align="center"
                    pt={{ sm: '125px', lg: '75px' }}
                    position="relative"
                >
                    <Box
                        h="45vh"
                        bgGradient="linear(to-b, brand.400, brand.600)"
                        position="absolute"
                        w="100%"
                        borderRadius="20px"
                    />

                    <FormProvider {...methods}>
                        <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <Card p="30px" mt={'60px'}>
                                    <Text
                                        color={textColor}
                                        fontSize="2xl"
                                        fontWeight="700"
                                        mb="20px"
                                    >
                                        Creación de lote
                                    </Text>
                                    {errorMessage && (
                                        <Alert status="error">
                                            <AlertIcon />
                                            <AlertTitle>{errorMessage}</AlertTitle>
                                        </Alert>
                                    )}
                                    <Flex direction="column" w="100%">
                                        <Stack direction="column">
                                            <SimpleGrid gap="20px">
                                                <CannavaSelectField
                                                    id={'lote_padre_id'}
                                                    label={
                                                        'Seleccioná el lote de procedencia de las semillas/esquejes'
                                                    }
                                                    opciones={opcionesSelect.lotesSemillas}
                                                    placeholder={'Seleccione un lote de semillas'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            </SimpleGrid>

                                            {loteSeleccionado && planificaciones && (
                                                <Box>
                                                    {planificaciones.length > 0 ? (
                                                        <SimpleGrid gap="20px">
                                                            <CannavaSelectField
                                                                id={'campana_id'}
                                                                label={'Campaña'}
                                                                opciones={opcionesCampana}
                                                                placeholder={
                                                                    'Seleccioná la campaña'
                                                                }
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />

                                                            <CannavaInputField
                                                                id={'cantidad_semillas'}
                                                                label={
                                                                    'seleccioná la cantidad de semillas para este lote (u.)'
                                                                }
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />
                                                        </SimpleGrid>
                                                    ) : (
                                                        <Alert status="info">
                                                            <AlertIcon />
                                                            <AlertTitle>
                                                                No hay campañas disponibles para el
                                                                lote seleccionado
                                                            </AlertTitle>
                                                        </Alert>
                                                    )}
                                                </Box>
                                            )}
                                        </Stack>
                                        <Flex justify="space-between" mt="24px">
                                            <Button
                                                variant="darkBrand"
                                                fontSize="sm"
                                                borderRadius="16px"
                                                w={{ base: '128px', md: '148px' }}
                                                h="46px"
                                                isLoading={methods.formState.isSubmitting}
                                                type="submit"
                                            >
                                                Enviar
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Card>
                            </form>
                        </Box>
                    </FormProvider>
                    {/* Danger Zone */}
                    {isEditMode && (
                        <DangerZone
                            elemento={'producto'}
                            onConfirmDelete={() => {
                                setIsDeleting(true);
                                useEnvioFormulario1.eliminarElemento(id);
                            }}
                        />
                    )}
                </Flex>
            )}

            {isDeleting && <PageLoader message="Eliminando lote de semillas..." />}
        </>
    );
}
