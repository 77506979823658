// Chakra imports
import { Flex, Tag, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';

import SearchTableLotesSemillas from './components/SearchTableLotesSemillas';

import React, { useEffect, useState } from 'react';
import { useLayoutContext } from 'contexts/LayoutContext';
import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import { useFetchData } from 'hooks/useFetchData';
import PageLoader from 'components/actions/PageLoader';
import { MenuItemProps } from 'types/MenuItemProps';
import { MdAddCircle } from 'react-icons/md';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import Listado, { crearCelda, crearCeldaConLink, crearHeader } from 'components/cannava/listado';
import { useAutenticacionUsuario } from '../../../../auth/hooks/useAutenticacionUsuario';
import { useAppContext } from 'contexts/AppContext';

export default function LotesSemillas() {
    const { backendUserData } = useAppContext();

    const transformLote = (data: any[]): LoteSemillas[] => {
        return data.map(lote => ({
            id: lote.id,
            codigo_lote: lote.codigo_lote,
            peso: lote.peso,
            unidades: lote.unidades,
            fecha_recepcion: lote.fecha_recepcion,
            cantidad_bajas: lote.cantidad_bajas,
            peso_promedio: lote.peso_promedio,
            genetica_id: lote.genetica_id,
            genetica: lote.genetica,
            lotePoderGerminativo: lote.lotePoderGerminativo,
            establecimiento: lote.establecimiento,
            establecimiento_id: lote.establecimiento_id,
            stock: lote.stock,
            semillasDisponibles: lote.semillasDisponibles,
            actions: 'Actions'
        }));
    };

    const { data: lotesData, isLoading } = useFetchData<LoteSemillas>(
        'lotesSemillas',
        transformLote
    );

    const { validarAutenticacion, error } = useAutenticacionUsuario();
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const navigate = useNavigate();

    const [menuItems, setMenuItems] = useState<MenuItemProps[]>([]);
    const columnHelper = createColumnHelper<LoteSemillas>();
    const columns = [
        columnHelper.accessor('codigo_lote', {
            id: 'codigo_lote',
            header: crearHeader('Lote'),
            cell: crearCelda()
        }),
        columnHelper.accessor('genetica_id', {
            id: 'genetica_id',
            header: crearHeader('Genetica'),
            cell: crearCelda((info: CellContext<any, any>) => {
                // console.log(info);
                return info.row.original.genetica.nombre;
            })
        }),
        columnHelper.accessor('genetica_id', {
            id: 'genetica_id',
            header: crearHeader('Genetica'),
            cell: crearCelda((info: CellContext<any, any>) => {
                if (
                    !info.row.original.lotePoderGerminativo ||
                    info.row.original.lotePoderGerminativo.length == 0
                ) {
                    return <Tag colorScheme={'orange'}>sin-pg</Tag>;
                }

                let lote = info.row.original.lotePoderGerminativo[0];
                if (lote.estado == 'en-curso') {
                    return <Tag colorScheme={'green'}>pg-en-curso</Tag>;
                } else if (lote.estado == 'finalizado') {
                    return <Tag colorScheme={'blue'}>pg-finalizado</Tag>;
                }

                return lote.estado;
            })
        }),

        columnHelper.accessor('establecimiento_id', {
            id: 'establecimiento_id',
            header: crearHeader('Establecimiento'),
            cell: crearCelda((info: CellContext<any, any>) => {
                //console.log();
                return info.row.original.establecimiento.nombre;
            })
        }),
        columnHelper.accessor('peso', {
            id: 'peso',
            header: crearHeader('Peso (gramos)'),
            cell: crearCelda()
        }),
        columnHelper.accessor('semillasDisponibles', {
            id: 'semillasDisponibles',
            header: crearHeader('Disponibles'),
            cell: crearCelda()
        }),
        columnHelper.accessor('fecha_recepcion', {
            id: 'fecha_recepcion',
            header: crearHeader('Fecha Recepción'),
            cell: crearCelda((info: CellContext<any, any>) => {
                return info.row.original.fecha_recepcion.split(' ')[0];
            })
        }),
        /*columnHelper.accessor('id', {
            id: 'editar',
            header: crearHeader("Acciones"),
            cell: crearCeldaConLink("/admin/lotes-semillas/editar", "Editar", navigate)
        }),*/
        columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/lotes-semillas/ver', 'Ver lote', navigate)
        })
    ];

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Trazabilidad', href: '/admin/trazabilidad/' },
            { label: 'Lotes de semillas', href: '/perfil' }
        ]);

        setTitle('Lotes de semillas');
    }, []);

    useEffect(() => {
        if (!backendUserData) return;

        if (validarAutenticacion(null, 'trazabilidad')) {
            setMenuItems([
                {
                    icono: MdAddCircle,
                    label: 'Agregar nuevo',
                    onClick: () => navigate('/admin/lotes-semillas/nuevo')
                }
            ]);
        }
    }, [backendUserData]);

    if (isLoading) {
        return <PageLoader message={'Cargando lotes'} />;
    }

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Card px="0px">
                <Listado tableData={lotesData} menuItems={menuItems} columns={columns} />
            </Card>
        </Flex>
    );
}
