import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect } from 'react';
import PageLoader from '../actions/PageLoader';
import { useAppContext } from '../../contexts/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAutenticacionUsuario } from '../../modules/auth/hooks/useAutenticacionUsuario';

const RoleValidator = ({ component: OriginalComponent, requiredRole, requiredArea = null }) => {
    const { backendUserData } = useAppContext();
    const navigate = useNavigate();
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [estaAutorizado, setEstaAutorizado] = useState(false);
    const { validarAutenticacion, error } = useAutenticacionUsuario(requiredRole, requiredArea);

    useEffect(() => {
        if (backendUserData) {
            setDataLoaded(true);

            let usuarioValido = validarAutenticacion(requiredRole, requiredArea);
            setEstaAutorizado(usuarioValido);
        }
    }, [backendUserData]);

    useEffect(() => {
        if (!estaAutorizado && error) {
            navigate('/no-autorizado?motivo=' + error);
            return;
        }
    }, [estaAutorizado, error]);

    if (!isDataLoaded || !estaAutorizado) {
        return <PageLoader />; // Muestra un loader mientras se carga backendUserData.
    }

    return <OriginalComponent />;
};

const AuthenticationGuard = ({ component, requiredRole = null, requiredArea = null }) => {
    const location = useLocation();

    const ComponentWithRoleValidation = props => (
        <RoleValidator
            component={component}
            requiredRole={requiredRole}
            requiredArea={requiredArea}
            {...props}
        />
    );

    const AuthenticatedComponent = withAuthenticationRequired(ComponentWithRoleValidation, {
        returnTo: '/callback',
        loginOptions: {},
        onRedirecting: () => {
            localStorage.setItem('prevPath', location.pathname);
            return (
                <div className="page-layout">
                    <PageLoader />
                </div>
            );
        }
    });

    return <AuthenticatedComponent />;
};

export default AuthenticationGuard;
