// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    useColorModeValue,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useDisclosure,
    Switch
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from 'config';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaApiService from 'utils/CannavaApiService';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import Index from 'components/cannava/dangerZone';
import { useLayoutContext } from 'contexts/LayoutContext';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import { Proveedor } from 'modules/genetica/types/Proveedor';
import { useFetchData } from 'hooks/useFetchData';
import { Variedad } from 'modules/genetica/types/Variedad';
import { useEnvioFormularioAntiguo } from 'hooks/useEnvioFormularioAntiguo';
import DangerZone from 'components/cannava/dangerZone';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import { Ubicacion } from 'modules/locacion/types/Ubicacion';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import CannavaTextAreaField from 'components/fields/CannavaTextareaField';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';

type InicioPlantineraData = {
    lote_germinacion_id: string;
    fecha_inicio: string;
    hora_inicio: string;
    numero_bandejas_ingresadas: number;
    ubicacion_plantinera_id: number;
    limpieza_area_plantinera: number;
    observaciones_generales: string;
};

type OpcionesSelect = {
    ubicaciones: any[];
};

export default function IniciarPlantinera() {
    // region Variables

    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const isEditMode = !!id;
    const { setBreadcrumbs, setTitle } = useLayoutContext();

    const [isDeleting, setIsDeleting] = useState(false);

    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const navigate = useNavigate();
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);
    const [cargandoData, setCargandoData] = useState(false);

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loteData, setLoteData] = useState(null);
    const { setFlashMessage, setFlashType } = useAppContext();

    // endregion

    // region Hooks y callbacks

    const fetchLoteData = async (loteId: string) => {
        setCargandoData(true);
        CannavaApiService.lotesGerminacionObtener(
            loteId,
            authToken,
            data => {
                console.log('data lote germinacion');
                console.log(data);

                // @todo: chequear que el estado del lote sea valido
                setLoteData(data);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion');
            }
        );
    };

    // configuro el layout
    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: [
                { label: 'Inicio', href: '/admin/home/' },
                { label: 'Trazabilidad', href: '/admin/home/' },
                { label: 'Lotes de germinación', href: '/admin/lotes-germinacion/' },
                { label: 'Iniciar Plantinera', href: '/admin/lotes-germinacion/' }
            ],
            titulo: () => {
                // Generar título basado en data cargada
                return !loteData ? '' : `Lote ${loteData.codigo_lote}`;
            }
        },
        loteData
    );

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'inicioPlantinera',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/lotes-germinacion');
        },
        onError: error => {
            console.log(error);
        },
        onErrorDelete: error => {
            navigate('/admin/lotes-germinacion');
        }
    });

    const [opcionesSelect, setOpcionesSelect] = useState<OpcionesSelect>({
        ubicaciones: []
    });

    const transformUbicaciones = (data: any[]): Ubicacion[] => {
        return data.map(ubicacion => ({
            id: ubicacion.id,
            nombre: ubicacion.nombre,
            estado: ubicacion.estado,
            tipo_id: ubicacion.tipo_id,
            establecimiento: ubicacion.establecimiento,
            codigo: ubicacion.codigo,
            latitud: ubicacion.latitud,
            longitud: ubicacion.longitud,
            superficie_total: ubicacion.superficie_total,
            establecimiento_id: ubicacion.establecimiento_id,
            fecha_creacion: ubicacion.fecha_creacion,
            fecha_actualizacion: ubicacion.fecha_actualizacion,
            fecha_borrado: ubicacion.fecha_borrado,
            actions: 'Actions'
        }));
    };

    const { data: ubicacionesData, isLoading } = useFetchData<Ubicacion>(
        'ubicaciones',
        transformUbicaciones
    );

    const onSubmit: SubmitHandler<InicioPlantineraData> = (data: InicioPlantineraData) => {
        setCargandoData(true);
        data.lote_germinacion_id = loteData.id;
        data.limpieza_area_plantinera = data.limpieza_area_plantinera ? 1 : 0;
        console.log(data);
        useEnvioFormulario1.envioFormularioCrear(data);
    };

    const onCancel: any = () => {
        navigate('/admin/lotes-germinacion');
    };

    // endregion

    // region useEffect

    useEffect(() => {
        if (!ubicacionesData) {
            return;
        }

        let opciones: OpcionesSelect = {
            ubicaciones: ubicacionesData
        };

        setOpcionesSelect(opciones);
    }, [ubicacionesData]);

    useEffect(() => {
        if (id) {
            fetchLoteData(id);
        }
    }, [id]);

    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    // endregion

    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    if (isLoading) {
        return <PageLoader message="Cargando opciones de formulario..." />;
    }

    return (
        <>
            <Flex
                direction="column"
                minH="100vh"
                align="center"
                pt={{ sm: '125px', lg: '75px' }}
                position="relative"
            >
                <Box
                    h="45vh"
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    position="absolute"
                    w="100%"
                    borderRadius="20px"
                />

                <FormProvider {...methods}>
                    <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Card p="30px" mt={'60px'}>
                                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                    Inicio etapa plantinera
                                </Text>
                                {errorMessage && (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertTitle>{errorMessage}</AlertTitle>
                                    </Alert>
                                )}
                                <Flex direction="column" w="100%">
                                    <Stack direction="column" spacing="20px">
                                        <SimpleGrid gap="20px">
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaDatePickerField
                                                    id={'fecha_inicio'}
                                                    label={'Fecha inicio'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'hora_inicio'}
                                                    label={'Hora inicio'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            </SimpleGrid>
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaInputField
                                                    id={'numero_bandejas_ingresadas'}
                                                    label={'Nº de bandejas ingresadas (u.)'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaSelectField
                                                    id={'ubicacion_plantinera_id'}
                                                    label={'Ubicación de la plantinera'}
                                                    opciones={opcionesSelect.ubicaciones}
                                                    placeholder={'Establecimiento'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            </SimpleGrid>
                                            <FormControl
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <FormLabel
                                                    mb="0"
                                                    fontSize="sm"
                                                    color={textColor}
                                                    ms="10px"
                                                    fontWeight="bold"
                                                >
                                                    ¿Se verificó la limpieza del área de plantinera?
                                                </FormLabel>
                                                <Switch
                                                    {...methods.register(
                                                        'limpieza_area_plantinera'
                                                    )}
                                                    id="limpieza_area_plantinera"
                                                />
                                            </FormControl>

                                            <CannavaTextAreaField
                                                id={'observaciones_generales'}
                                                label={'Observaciones detectadas'}
                                                placeholder={
                                                    '¿Tenés alguna observación para agregar?'
                                                }
                                                validationRules={{
                                                    required: 'Campo obligatorio'
                                                }}
                                                mb="10px"
                                            />
                                        </SimpleGrid>
                                    </Stack>
                                    <Flex justify="space-between" mt="24px">
                                        <Button
                                            variant="outline"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            //navegar a lote
                                            onClick={onCancel}
                                            type="button"
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="darkBrand"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            isLoading={methods.formState.isSubmitting}
                                            type="submit"
                                        >
                                            Continuar
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Card>
                        </form>
                    </Box>
                </FormProvider>
            </Flex>
        </>
    );
}
