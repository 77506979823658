import { ObjetivoCampana } from 'modules/planificacion/types/ObjetivoCampana';
import { Campana } from 'modules/planificacion/types/Campana';
import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import CannavaInputField from 'components/fields/CannavaInputField';
import React from 'react';

type FilaRepeticionProps = {
    numero: number;
    textColor: string;
    methods?: any;
};

export default function FilaRepeticion(props: FilaRepeticionProps) {
    const { numero, textColor, methods } = props;

    return (
        <Box>
            <Text color={textColor} fontSize="md" fontWeight="400" mb="20px">
                Repeticion #{numero}
            </Text>

            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                <CannavaInputField
                    id={numero + '_semillas_germinadas'}
                    label={'Semillas germinadas'}
                    validationRules={{
                        required: 'Campo obligatorio'
                    }}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                        const semillasGerminadas = +e.target.value;
                        let cantidad_semillas = methods.getValues().cantidad_semillas_repeticion;
                        methods.setValue(
                            numero + '_semillas_no_germinadas',
                            cantidad_semillas - semillasGerminadas
                        );
                    }}
                />

                <CannavaInputField
                    id={numero + '_semillas_no_germinadas'}
                    label={'Semillas no germinadas'}
                    validationRules={{
                        required: 'Campo obligatorio'
                    }}
                />
            </SimpleGrid>

            <CannavaInputField
                id={numero + '_observaciones'}
                label={'Observaciones'}
                validationRules={{
                    required: 'Campo obligatorio'
                }}
            />

            <hr />
        </Box>
    );
}
