// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormLabel,
    FormControl,
    useColorModeValue,
    Alert,
    AlertIcon,
    AlertTitle,
    Switch
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import { useLayoutContext } from 'contexts/LayoutContext';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import CannavaTextAreaField from 'components/fields/CannavaTextareaField';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import CannavaApiService from 'utils/CannavaApiService';

type FinalizarSemilladoData = {
    lote_id: string;
    id: number;
    fecha_finalizacion_semillado: string;
    hora_finalizacion_semillado: string;
    numero_semillas_usadas_finalizacion_semillado: number;
    numero_bandejas_armadas_finalizacion_semillado: number;
    numero_operarios_finalizacion_semillado: number;
    verificacion_orden_y_limpieza_area_finalizacion_semillado: number;
    inspeccion_visual_correcto_sembrado_finalizacion_semillado: number;
    descarte_finalizacion_semillado: number;
    numero_descarte_finalizacion_semillado: number;

    maxima_temperatura_aire_finalizacion_semillado: number;
    minima_temperatura_aire_finalizacion_semillado: number;
    maxima_hr_finalizacion_semillado: number;
    minima_hr_finalizacion_semillado: number;
    maxima_presion_atm_finalizacion_semillado: number;
    minima_presion_atm_finalizacion_semillado: number;
    observaciones: string;
};

export default function FinalizarSemillado() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const descarte = methods.watch('descarte', false);

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = useState({
        semillado: true,
        condicionesAmbientales: false
    });

    const navigate = useNavigate();

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const semilladoTab = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
    const condicionesAmbientalesTab = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Trazabilidad', href: '/admin/trazabilidad/' },
            { label: 'Lotes de germinación', href: '/admin/lotes-germinacion/ver/' + id },
            { label: 'Finalizar semillado', href: '#' }
        ]);

        setTitle('Control del lote');
    }, []);

    const [cargandoData, setCargandoData] = useState(false);
    const { setFlashMessage, setFlashType } = useAppContext();

    const onSubmit: SubmitHandler<FinalizarSemilladoData> = (data: FinalizarSemilladoData) => {
        data.verificacion_orden_y_limpieza_area_finalizacion_semillado =
            data.verificacion_orden_y_limpieza_area_finalizacion_semillado ? 1 : 0;
        data.inspeccion_visual_correcto_sembrado_finalizacion_semillado =
            data.inspeccion_visual_correcto_sembrado_finalizacion_semillado ? 1 : 0;
        data.descarte_finalizacion_semillado = descarte ? 1 : 0;
        data.numero_descarte_finalizacion_semillado = descarte
            ? data.numero_descarte_finalizacion_semillado
            : 0;
        data.lote_id = id;
        setCargandoData(true);
        CannavaApiService.request(
            'POST',
            `/trazabilidad/api/lote-germinacion/finalizar-semillado`,
            data,
            authToken,
            data => {
                console.log(data);
                setFlashMessage('Se finalizó el semillado correctamente.');
                setFlashType('success');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion/ver/' + id);
            },
            (error: any) => {
                if (error.response.status == 422) {
                    console.log(error.response.data);
                    for (let i = 0; i < error.response.data.length; i++) {
                        const errorValidacion: any = error.response.data[i];
                        methods.setError(errorValidacion.field, {
                            message: errorValidacion.message
                        });
                    }
                } else {
                    alert('Error desconocido');
                    console.log(error);
                }

                setCargandoData(false);
            }
        );
    };

    const onCancel: any = () => {
        navigate('/admin/lotes-germinacion/ver/' + id);
    };

    useEffect(() => {
        if (cargandoData) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [cargandoData, methods.formState.submitCount]);

    if (cargandoData) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            <Flex
                direction="column"
                minH="100vh"
                align="center"
                pt={{ sm: '125px', lg: '75px' }}
                position="relative"
            >
                <Box
                    h="45vh"
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    position="absolute"
                    w="100%"
                    borderRadius="20px"
                />

                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Tabs
                            variant="unstyled"
                            zIndex="0"
                            mt={{ base: '60px', md: '165px' }}
                            display="flex"
                            flexDirection="column"
                        >
                            <TabList
                                display="flex"
                                alignItems="center"
                                alignSelf="center"
                                justifySelf="center"
                            >
                                <Tab
                                    _focus={{ border: '0px', boxShadow: 'unset' }}
                                    ref={semilladoTab}
                                    w={{ sm: '120px', md: '250px', lg: '300px' }}
                                    onClick={() =>
                                        setActiveBullets({
                                            semillado: true,
                                            condicionesAmbientales: false
                                        })
                                    }
                                ></Tab>
                                <Tab
                                    _focus={{ border: '0px', boxShadow: 'unset' }}
                                    ref={condicionesAmbientalesTab}
                                    w={{ sm: '120px', md: '250px', lg: '300px' }}
                                    onClick={() =>
                                        setActiveBullets({
                                            semillado: true,
                                            condicionesAmbientales: true
                                        })
                                    }
                                ></Tab>
                            </TabList>
                            <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
                                <TabPanel
                                    w={{ sm: '330px', md: '700px', lg: '850px' }}
                                    p="0px"
                                    mx="auto"
                                >
                                    <Card p="30px" mt={'60px'}>
                                        <Text
                                            color={textColor}
                                            fontSize="2xl"
                                            fontWeight="700"
                                            mb="20px"
                                        >
                                            Limpieza de bandejas, bins y acoplados
                                        </Text>
                                        {errorMessage && (
                                            <Alert status="error">
                                                <AlertIcon />
                                                <AlertTitle>{errorMessage}</AlertTitle>
                                            </Alert>
                                        )}
                                        <Flex direction="column" w="100%">
                                            <Stack direction="column" spacing="20px">
                                                <SimpleGrid gap="20px">
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaDatePickerField
                                                            id={'fecha_finalizacion_semillado'}
                                                            label={'Fecha de finalización'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                        <CannavaInputField
                                                            id={'hora_finalizacion_semillado'}
                                                            label={'Hora de finalización'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaInputField
                                                            id={
                                                                'numero_semillas_usadas_finalizacion_semillado'
                                                            }
                                                            label={'Nº de semillas usadas (uds.)'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                        <CannavaInputField
                                                            id={
                                                                'numero_bandejas_armadas_finalizacion_semillado'
                                                            }
                                                            label={'Nº de bandejas armadas (u.)'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaInputField
                                                            id={
                                                                'numero_operarios_finalizacion_semillado'
                                                            }
                                                            label={'Nº de operarios'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                    <FormControl
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        <FormLabel
                                                            mb="0"
                                                            fontSize="sm"
                                                            color={textColor}
                                                            ms="10px"
                                                            fontWeight="bold"
                                                        >
                                                            ¿Se verificó el orden y limpieza del
                                                            área?
                                                        </FormLabel>
                                                        <Switch
                                                            {...methods.register(
                                                                'verificacion_orden_y_limpieza_area_finalizacion_semillado'
                                                            )}
                                                            id="verificacion_orden_y_limpieza_area_finalizacion_semillado"
                                                        />
                                                    </FormControl>
                                                    <FormControl
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        <FormLabel
                                                            mb="0"
                                                            fontSize="sm"
                                                            color={textColor}
                                                            ms="10px"
                                                            fontWeight="bold"
                                                        >
                                                            ¿Se realizó inspección visual para
                                                            verificar que todas las bandejas fueron
                                                            sembradas correctamente?
                                                        </FormLabel>
                                                        <Switch
                                                            {...methods.register(
                                                                'inspeccion_visual_correcto_sembrado_finalizacion_semillado'
                                                            )}
                                                            id="inspeccion_visual_correcto_sembrado_finalizacion_semillado"
                                                        />
                                                    </FormControl>
                                                    <FormControl
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        <FormLabel
                                                            mb="0"
                                                            fontSize="sm"
                                                            color={textColor}
                                                            ms="10px"
                                                            fontWeight="bold"
                                                        >
                                                            Descarte
                                                        </FormLabel>
                                                        <Switch
                                                            {...methods.register('descarte')}
                                                            id="descarte"
                                                        />
                                                    </FormControl>
                                                    {descarte && (
                                                        <CannavaInputField
                                                            id={
                                                                'numero_descarte_finalizacion_semillado'
                                                            }
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    )}
                                                </SimpleGrid>
                                            </Stack>
                                            <Flex justify="space-between" mt="24px">
                                                <Button
                                                    variant="outline"
                                                    fontSize="sm"
                                                    borderRadius="16px"
                                                    w={{ base: '128px', md: '148px' }}
                                                    h="46px"
                                                    //navegar a lote
                                                    onClick={onCancel}
                                                    type="button"
                                                >
                                                    Cancelar
                                                </Button>
                                                <Button
                                                    variant="darkBrand"
                                                    fontSize="sm"
                                                    borderRadius="16px"
                                                    w={{ base: '128px', md: '148px' }}
                                                    h="46px"
                                                    onClick={() => {
                                                        condicionesAmbientalesTab.current.click();
                                                    }}
                                                >
                                                    Continuar
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Card>
                                </TabPanel>
                                <TabPanel
                                    w={{ sm: '330px', md: '700px', lg: '850px' }}
                                    p="0px"
                                    mx="auto"
                                >
                                    <Card p="30px" mt={'60px'}>
                                        <Text
                                            color={textColor}
                                            fontSize="2xl"
                                            fontWeight="700"
                                            mb="20px"
                                        >
                                            Preparación de bandejas
                                        </Text>
                                        {errorMessage && (
                                            <Alert status="error">
                                                <AlertIcon />
                                                <AlertTitle>{errorMessage}</AlertTitle>
                                            </Alert>
                                        )}
                                        <Flex direction="column" w="100%">
                                            <Stack direction="column" spacing="20px">
                                                <SimpleGrid gap="20px">
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaInputField
                                                            id={
                                                                'maxima_temperatura_aire_finalizacion_semillado'
                                                            }
                                                            label={
                                                                'Temperatura del aire - Valor máximo'
                                                            }
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                        <CannavaInputField
                                                            id={
                                                                'minima_temperatura_aire_finalizacion_semillado'
                                                            }
                                                            label={
                                                                'Temperatura del aire - Valor mínimo'
                                                            }
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaInputField
                                                            id={'maxima_hr_finalizacion_semillado'}
                                                            label={'HR% - Valor máximo'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                        <CannavaInputField
                                                            id={'minima_hr_finalizacion_semillado'}
                                                            label={'HR% - Valor mínimo'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaInputField
                                                            id={
                                                                'maxima_presion_atm_finalizacion_semillado'
                                                            }
                                                            label={
                                                                'Presión atmosférica - Valor máximo'
                                                            }
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                        <CannavaInputField
                                                            id={
                                                                'minima_presion_atm_finalizacion_semillado'
                                                            }
                                                            label={
                                                                'Presión atmosférica - Valor mínimo'
                                                            }
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                    <CannavaTextAreaField
                                                        id={'observaciones'}
                                                        label={'Observaciones detectadas'}
                                                        placeholder={
                                                            '¿Tenés alguna observación para agregar?'
                                                        }
                                                        validationRules={{
                                                            required: 'Campo obligatorio'
                                                        }}
                                                        mb="10px"
                                                    />
                                                </SimpleGrid>
                                            </Stack>
                                            <Flex justify="space-between" mt="24px">
                                                <Button
                                                    variant="outline"
                                                    fontSize="sm"
                                                    borderRadius="16px"
                                                    w={{ base: '128px', md: '148px' }}
                                                    h="46px"
                                                    //navegar a lote
                                                    onClick={() => {
                                                        semilladoTab.current.click();
                                                    }}
                                                    type="button"
                                                >
                                                    Atrás
                                                </Button>
                                                <Button
                                                    variant="darkBrand"
                                                    fontSize="sm"
                                                    borderRadius="16px"
                                                    w={{ base: '128px', md: '148px' }}
                                                    h="46px"
                                                    isLoading={methods.formState.isSubmitting}
                                                    type="submit"
                                                >
                                                    Continuar
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Card>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </form>
                </FormProvider>
            </Flex>
        </>
    );
}
