import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    Alert,
    AlertIcon,
    AlertTitle,
    FormControl,
    FormLabel,
    Switch
} from '@chakra-ui/react';
// Custom components
import Card from '../../../../../components/card/Card';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import CannavaInputField from '../../../../../components/fields/CannavaInputField';
import PageLoader from '../../../../../components/actions/PageLoader';
import DangerZone from '../../../../../components/cannava/dangerZone';
import { useConfigurarNavegacion } from '../../../../../hooks/useConfigurarNavegacion';
import { useEnvioFormulario } from '../../../../../hooks/useEnvioFormulario';
import CannavaTextAreaField from 'components/fields/CannavaTextareaField';

export default function EditarInsumo() {
    const methods = useForm();
    const { id } = useParams();
    const isEditMode = !!id;
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);

    // variables de layout y estilo
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    // helpers
    const navigate = useNavigate();

    // configuro layout
    const configurarNavegacion = useConfigurarNavegacion({
        breadcrumbs: [
            { label: 'Inicio', href: '/' },
            { label: 'Home', href: '/admin/home/' },
            { label: 'Insumos', href: '/admin/insumos/' }
        ],
        titulo: 'Editar proveedor'
    });

    // inicializacion hook useEnvioFormulario
    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'insumo',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/insumos');
        },
        onError: error => {
            console.log(error);
        },
        onErrorDelete: error => {
            navigate('/admin/insumos');
        }
    });

    // capturo el envio de formulario y puedo manipularla data para prepararla
    const onSubmit = (data: any) => {
        if (isEditMode) {
            data.estado = data.estado ? 'activo' : 'inactivo';
            useEnvioFormulario1.envioFormularioActualizar(id, data);
            return;
        }

        useEnvioFormulario1.envioFormularioCrear(data);
    };

    const onCancel: any = () => {
        navigate('/admin/insumos');
    };

    useEffect(() => {
        if (id) {
            useEnvioFormulario1.cargarFormulario(id);
        } else {
            useEnvioFormulario1.setIsLoading(false);
        }
    }, [id]);

    // useEffect para capturar errores del formulario
    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [useEnvioFormulario1.isLoading, methods.formState.submitCount]);

    // si estoy cargando muestro el spinner
    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            {!isDeleting && (
                <Flex
                    direction="column"
                    minH="100vh"
                    align="center"
                    pt={{ sm: '125px', lg: '75px' }}
                    position="relative"
                >
                    <Box
                        h="45vh"
                        bgGradient="linear(to-b, brand.400, brand.600)"
                        position="absolute"
                        w="100%"
                        borderRadius="20px"
                    />

                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Tabs
                                variant="unstyled"
                                zIndex="0"
                                mt={{ base: '60px', md: '165px' }}
                                display="flex"
                                flexDirection="column"
                            >
                                <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
                                    <TabPanel
                                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                                        p="0px"
                                        mx="auto"
                                    >
                                        <Card p="30px">
                                            <Text
                                                color={textColor}
                                                fontSize="2xl"
                                                fontWeight="700"
                                                mb="20px"
                                            >
                                                Información general
                                            </Text>
                                            <Text
                                                color={textColor}
                                                fontSize="2xl"
                                                fontWeight="700"
                                                mb="20px"
                                            ></Text>
                                            {errorMessage && (
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    <AlertTitle>{errorMessage}</AlertTitle>
                                                </Alert>
                                            )}
                                            <Flex direction="column" w="100%">
                                                <Stack direction="column" spacing="20px">
                                                    <SimpleGrid columns={{ base: 1 }} gap="20px">
                                                        <CannavaInputField
                                                            id={'nombre'}
                                                            label={'Nombre'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                        {isEditMode && (
                                                            <FormControl
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="space-between"
                                                            >
                                                                <FormLabel
                                                                    mb="0"
                                                                    fontSize="sm"
                                                                    color={textColor}
                                                                    ms="10px"
                                                                    fontWeight="bold"
                                                                >
                                                                    Activar/desactivar insumo
                                                                </FormLabel>
                                                                <Switch
                                                                    {...methods.register('estado')}
                                                                    id="estado"
                                                                />
                                                            </FormControl>
                                                        )}

                                                        <CannavaTextAreaField
                                                            id={'observaciones'}
                                                            label={'Observaciones'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                            mb="10px"
                                                        />
                                                    </SimpleGrid>
                                                </Stack>
                                                <Flex justify="space-between" mt="24px">
                                                    <Button
                                                        variant="outline"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        //navegar a lote
                                                        onClick={onCancel}
                                                        type="button"
                                                    >
                                                        Cancelar
                                                    </Button>
                                                    <Button
                                                        variant="darkBrand"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        isLoading={methods.formState.isSubmitting}
                                                        type="submit"
                                                    >
                                                        Enviar
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </form>
                    </FormProvider>
                    {/* Danger Zone */}
                    {isEditMode && (
                        <DangerZone
                            elemento={'insumo'}
                            onConfirmDelete={() => {
                                setIsDeleting(true);
                                useEnvioFormulario1.eliminarElemento(id);
                            }}
                        />
                    )}
                </Flex>
            )}

            {/* {isDeleting && <PageLoader message="Eliminando proveedor..." />} */}
        </>
    );
}
