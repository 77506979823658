import { Flex, SimpleGrid, Text, useColorModeValue, Divider } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { FilaInfoRegistro } from './FilaInfoRegistro';
import { ControlSiembra } from 'modules/trazabilidad/types/ControlSiembra';

type InfoControlProps = {
    controlDataGeneral: ControlSiembra;
};

export default function InfoControlGeneral(props: InfoControlProps) {
    const { controlDataGeneral } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center" mb="5">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Información de lote
                </Text>
            </Flex>
            <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing={{ base: '20px', xl: '5px' }}>
                <FilaInfoRegistro
                    label={'Fecha inicial'}
                    value={controlDataGeneral.fecha_realizacion_siembra}
                />

                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Hora inicial'}
                    value={controlDataGeneral.hora_inicio_siembra}
                />

                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'¿Se verificó el orden y limpieza del área?'}
                    value={controlDataGeneral.verificacion_limpieza_area ? 'SI' : 'NO'}
                />

                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={
                        '¿Se verificó la limpieza y mantenimiento de la sembradora antes de iniciar el proceso?'
                    }
                    value={controlDataGeneral.verificacion_limpieza_sembradora ? 'SI' : 'NO'}
                />
            </SimpleGrid>
        </Card>
    );
}
