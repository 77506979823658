import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Flex, Box, List, ListItem } from '@chakra-ui/react'; // Asumiendo que estás usando Chakra UI
import { useAppContext } from '../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import CenteredAuth from '../layouts/auth/variants/Centered';
import { useLayoutContext } from '../contexts/LayoutContext';

const Welcome: React.FC = () => {
    const { backendUserData } = useAppContext();
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const { setBreadcrumbs, setTitle } = useLayoutContext();

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/admin/home/' },
            { label: 'Administración', href: '/admin/home/' }
        ]);

        setTitle('Bienvenido');
    }, []);

    useEffect(() => {
        async function handleAuthentication() {
            if (isLoading) {
                return;
            }

            if (isAuthenticated) {
                navigate('/admin/home', { replace: true });
            }
        }

        handleAuthentication();
    }, [isLoading, isAuthenticated, getAccessTokenSilently]);

    // @ts-ignore
    return (
        <CenteredAuth
            cardTop={{ base: '140px', md: '14vh' }}
            cardBottom={{ base: '50px', lg: '100px' }}
        >
            <Flex
                direction={{ base: 'column', xl: 'column' }}
                pt={{ base: '130px', md: '80px', xl: '80px' }}
            >
                <Box className={'flex'}>Estamos chequeando tus credenciales...</Box>
                {backendUserData && (
                    <Box className={'flex'}>
                        <List>
                            <ListItem>{backendUserData.name}</ListItem>
                            <ListItem>{backendUserData.email}</ListItem>
                            <ListItem>{backendUserData.picture}</ListItem>
                        </List>
                    </Box>
                )}
            </Flex>
        </CenteredAuth>
    );
};

export default Welcome;
