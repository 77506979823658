import { Box, Grid, Flex, Card, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import MapCard from 'views/admin/dashboards/smartHome/components/MapCard';
import MiniStatistics from 'components/card/MiniStatistics';
import PanelAnotaciones from 'components/chat/PanelAnotaciones';
import CardControlSiembra from '../germinacion/components/CardControlSiembra';
import CardControlGerminacion from '../germinacion/components/CardControlGerminacion';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import { FilaInfoRegistro } from '../verLote/components/FilaInfoRegistro';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLayoutContext } from 'contexts/LayoutContext';
import { useAppContext } from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import InfoControl from './components/InfoControl';
import PageLoader from 'components/actions/PageLoader';
import Observaciones from './components/Observaciones';
import Responsable from './components/Responsable';
import InfoControlAmbiental from './components/InfoControlAmbiental';
import InfoCondicionesAmbientales from './components/InfoCondicionesAmbientales';
import InfoControlCalidad from './components/InfoControlCalidad';
import CannavaApiService from 'utils/CannavaApiService';
import { Plantinera } from 'modules/trazabilidad/types/Plantinera';

export default function VerControlPlantinera() {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    const navigate = useNavigate();
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const { authToken } = useAppContext();
    const { reportePlantineraId } = useParams();
    const [cargandoData, setCargandoData] = useState(false);

    // Version TEST - Configurar carga de datos de la db!
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { setFlashMessage, setFlashType } = useAppContext();
    const [plantineraData, setPlantineraData] = useState<Plantinera | null>(null);

    /* El reporte plantinera se obtiene a traves del id del lote de germinacion y no del id del reporte de plantinera */

    const fetchPlantineraData = async (id: string) => {
        setCargandoData(true);
        CannavaApiService.reportePlantineraObtener(
            id,
            authToken,
            data => {
                console.log('data lote germinacion');
                console.log(data);
                setPlantineraData(data);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion');
            }
        );
    };
    /* const [controlData, setControlData] = useState({
        codigoControl: '0000-0000-0002',
        fechaInicio: '22/02/2024',
        numeroBandejasIngresadas: 10,
        ubicacionPlantinera: 1,
        limpiezaArea: true,
        monitoreoPlantines: false,
        fechaFinalizacion: '26/02/2024',
        numeroBandejasEgresadas: 9,
        numeroPlantinesBaja: 5,
        responsable: 'Responsable Cannava',
        observaciones_generales: 'Todo ok!',
        condicionesAmbientales: [
            {
                temperaturaMaxima: 30,
                temperaturaMinima: 22,
                temperaturaPromedio: 26,
                humedadRelativaMaxima: 98,
                humedadRelativaMinima: 90,
                humedadRelativaPromedio: 94,
                presionAtmosfericaMaxima: 10,
                presionAtmosfericaMinima: 8,
                presionAtmosfericaPromedio: 9
            }
        ]
    }); */

    // Version TEST - Configurar carga de datos de la db!
    const [controlAmbientalData, setControlAmbientalData] = useState([
        {
            codigoControl: '0000-0000-0001',
            fecha: '20/02/2024',
            hora: '10:45',
            temperatura: 12,
            humedad: 98,
            oscuridad: 'Apto'
        },
        {
            codigoControl: '0000-0000-0002',
            fecha: '21/02/2024',
            hora: '11:45',
            temperatura: 16,
            humedad: 87,
            oscuridad: 'No apto'
        },
        {
            codigoControl: '0000-0000-0003',
            fecha: '22/02/2024',
            hora: '09:45',
            temperatura: 15,
            humedad: 77,
            oscuridad: 'Apto'
        }
    ]);

    // Version TEST - Configurar carga de datos de la db!
    const [controlCalidadData, setControlCalidadData] = useState([
        {
            altura: '20 cm',
            diametro: '0.5 cm',
            numeroPlantas: 10,
            numeroFolios: 12
        }
    ]);

    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: [
                // Version TEST - Configurar rutas correctamente!
                { label: 'Lotes', href: '/admin/home/' },
                { label: 'Lote Test', href: '/admin/home/' },
                { label: 'Control plantinera', href: '/admin/lotes-germinacion/' }
            ],
            titulo: () => {
                // Version TEST - Generar título basado en data cargada
                return !plantineraData
                    ? 'Nº de control 0000-0000-0001'
                    : `Plantinera ${plantineraData.id}`;
            }
        },
        plantineraData
    );

    useEffect(() => {
        if (reportePlantineraId) {
            fetchPlantineraData(reportePlantineraId);
        }
    }, [reportePlantineraId]);

    // endregion

    if (cargandoData || !plantineraData) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            {plantineraData && (
                <Grid
                    templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
                    gap={4}
                    mb={'30px'}
                >
                    {/* Fila 1 */}
                    <Box>
                        <MiniStatistics
                            name="Fecha de inicio"
                            value={
                                plantineraData.fecha_inicio /* loteData.loteSemillas.genetica.cannabinoideDominante.slug.toUpperCase() */
                            }
                        />
                    </Box>
                    <Box>
                        <MiniStatistics
                            name="Variedad"
                            value={'SN023Test' /* loteData.cantidad_semillas */}
                        />
                    </Box>
                    <Box>
                        <MiniStatistics
                            name="Estado del lote"
                            value={'Lote sano' /* loteData.cantidad_semillas */}
                        />
                    </Box>
                </Grid>
            )}

            {/* Fila 2 */}
            {plantineraData && (
                <Grid
                    templateColumns={{ base: 'repeat(1, 1fr)', md: '50% 50%' }}
                    gridColumn="span 4"
                    gap={4}
                >
                    {/* Columna 1 de Fila 2 */}
                    <Flex flexDirection="column" gap={2}>
                        {/* Fila 2.1.1 */}
                        <SimpleGrid columns={{ base: 1, md: 1 }} spacing={4} mb={2}>
                            <Flex
                                flexDirection={{ base: 'column', md: 'row' }}
                                justify="space-between"
                                gap={'20px'}
                            >
                                <Box w="100%">
                                    <InfoControl controlData={plantineraData} />
                                </Box>
                            </Flex>
                        </SimpleGrid>
                        {/* Fila 2.1.2 */}
                        <SimpleGrid columns={{ base: 1, md: 1 }} spacing={4} mb={2}>
                            <Flex
                                flexDirection={{ base: 'column', md: 'row' }}
                                justify="space-between"
                                gap={'20px'}
                            >
                                <Box w="100%">
                                    <InfoControlCalidad controlCalidadData={controlCalidadData} />
                                </Box>
                            </Flex>
                        </SimpleGrid>
                        {/* Fila 2.1.3 */}
                        <SimpleGrid columns={{ base: 1, md: 1 }} spacing={4} mb={2}>
                            <Flex
                                flexDirection={{ base: 'column', md: 'row' }}
                                justify="space-between"
                                gap={'20px'}
                            >
                                <Box w="100%">
                                    <InfoControlAmbiental idLote={plantineraData.lote_id} />
                                </Box>
                            </Flex>
                        </SimpleGrid>
                    </Flex>

                    {/* Columna 2 de Fila 2 */}
                    <Flex flexDirection="column" gap={4}>
                        <Responsable controlData={plantineraData} />
                        {/* El endpoint no devuelve esta informacion
                     <InfoCondicionesAmbientales
                        condicionesAmbientales={controlData.condicionesAmbientales}
                    /> */}
                        <Observaciones controlData={plantineraData} />
                    </Flex>
                </Grid>
            )}
        </Box>
    );
}
