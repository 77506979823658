import { Anotaciones } from '../../modules/anotacion/types/Anotaciones';
import { Avatar, Flex, Image, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import FilePreview from '../cannava/uploaderZone/components/FilePreview';
import { FaFile } from 'react-icons/fa';
import { Usuario } from 'modules/auth/types/Usuario';
import { useNavigate } from 'react-router-dom';

type AnotacionProps = {
    index: number;
    anotacion: Anotaciones;
    onClick?: CallableFunction;
    usuarios?: Usuario[];
};

export default function FilaAnotacion({ anotacion, index, onClick, usuarios }: AnotacionProps) {
    const navigate = useNavigate();
    const isImage = (archivoMime: string) => {
        return archivoMime.startsWith('image/');
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target instanceof HTMLAnchorElement) {
            const mentionUser: string = event.target.textContent;
            const foundObject = usuarios.find(usuario => usuario.nombre_usuario === mentionUser);
            const mentionUserId = foundObject ? foundObject.id : undefined;
            console.log(mentionUserId);

            navigate('/admin/usuarios/ver/' + mentionUserId);
        }
    };

    useEffect(() => {
        console.log(usuarios);
        console.log(anotacion.textoConLinks);
    });

    return (
        <Flex key={index} my="20x" py={'10px'} borderBottom={'1px solid #ededed'}>
            <Avatar
                name={anotacion.usuario.nombre_usuario}
                h="40px"
                w="40px"
                mr="5"
                src={anotacion.usuario.imagen}
            />
            <Flex flexDirection="column">
                <Text
                    fontSize={'md'}
                    dangerouslySetInnerHTML={{ __html: anotacion.textoConLinks }}
                    onClick={handleClick}
                ></Text>

                {anotacion.adjuntos && anotacion.adjuntos.length > 0 && (
                    <Flex
                        direction={'row'}
                        my={'15px'}
                        alignItems={'flex-end'}
                        verticalAlign={'bottom'}
                    >
                        {anotacion.adjuntos.map((adjunto, index) => (
                            <Text key={index}>
                                {isImage(adjunto.archivo.mime) ? (
                                    <Image
                                        src={adjunto.archivo.url}
                                        alt={adjunto.archivo.nombre}
                                        boxSize={'128px'}
                                        objectFit="cover"
                                        title={adjunto.archivo.nombre}
                                        onClick={() =>
                                            onClick && onClick(adjunto.archivo.url, true)
                                        }
                                        mr={'10px'}
                                    />
                                ) : (
                                    <Text
                                        mr={'10px'}
                                        onClick={() =>
                                            onClick && onClick(adjunto.archivo.url, false)
                                        }
                                    >
                                        <FaFile size={'64px'} title={adjunto.archivo.nombre} />
                                    </Text>
                                )}
                            </Text>
                        ))}
                    </Flex>
                )}

                <Text fontSize={'sm'} my={'4px'}>
                    {anotacion.fecha_creacion} por {anotacion.usuario.nombre_usuario}
                </Text>
            </Flex>
        </Flex>
    );
}
