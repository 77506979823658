import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Text,
    FormLabel,
    FormControl,
    useColorModeValue,
    Alert,
    AlertIcon,
    AlertTitle,
    Switch
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from 'contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from 'components/fields/CannavaInputField';
import PageLoader from 'components/actions/PageLoader';
import { useLayoutContext } from 'contexts/LayoutContext';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import { ReporteControlSiembra } from 'modules/trazabilidad/types/ReporteControlSiembra';
import CannavaApiService from 'utils/CannavaApiService';

export default function IniciarSiembra() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const { setBreadcrumbs, setTitle } = useLayoutContext();

    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const navigate = useNavigate();
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [loteData, setLoteData] = useState(null);

    const [cargandoData, setCargandoData] = useState(false);
    const { setFlashMessage, setFlashType } = useAppContext();

    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: [
                { label: 'Inicio', href: '/admin/home/' },
                { label: 'Trazabilidad', href: '/admin/home/lotes-germinacion' },
                { label: 'Lotes de germinación', href: '/admin/lotes-germinacion/ver/' + id },
                { label: 'Iniciar Siembra', href: '#' }
            ],
            titulo: () => {
                // Generar título basado en data cargada
                return !loteData ? '' : `Lote ${loteData.codigo_lote}`;
            }
        },
        loteData
    );

    // const useEnvioFormulario1 = useEnvioFormulario({
    //     elemento: 'loteGerminacionIniciarSiembra',
    //     formMethods: methods,
    //     onSuccess: data => {
    //         navigate('/admin/lotes-germinacion/ver/' + id);
    //     },
    //     onError: error => {
    //         console.log(error);
    //     },
    //     onErrorDelete: error => {
    //         navigate('/admin/lotes-germinacion');
    //     }
    // });

    const onSubmit: SubmitHandler<ReporteControlSiembra> = (data: ReporteControlSiembra) => {
        data.lote_id = id;

        data.verificacion_limpieza_area = data.verificacion_limpieza_area ? 1 : 0;
        data.verificacion_limpieza_sembradora = data.verificacion_limpieza_sembradora ? 1 : 0;

        console.log(data);

        //useEnvioFormulario1.envioFormularioCrear(data);

        setCargandoData(true);
        /* data.bajas_plantines = parseInt(data.plantines);
        data.bandejas_egresadas = parseInt(data.bandejas); */
        //console.log(data);
        CannavaApiService.request(
            'POST',
            `/trazabilidad/api/lote-germinacion/iniciar-siembra`,
            data,
            authToken,
            data => {
                console.log(data);
                setFlashMessage('Se inició la siembra correctamente.');
                setFlashType('success');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion/ver/' + id);
            },
            (error: any) => {
                if (error.response.status == 422) {
                    console.log(error.response.data);
                    for (let i = 0; i < error.response.data.length; i++) {
                        const errorValidacion: any = error.response.data[i];
                        methods.setError(errorValidacion.field, {
                            message: errorValidacion.message
                        });
                    }
                } else {
                    alert('Error desconocido');
                    console.log(error);
                }

                setCargandoData(false);
            }
        );
    };

    const onCancel: any = () => {
        navigate('/admin/lotes-germinacion/ver/' + id);
    };

    useEffect(() => {
        // if (useEnvioFormulario1.isLoading) {
        //     return;
        // }
        if (cargandoData) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [/*enviandoFormulario, */ cargandoData, methods.formState.submitCount]);

    // if (useEnvioFormulario1.isLoading) {
    //     return <PageLoader message="Cargando datos de lote..." />;
    // }

    if (cargandoData) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            <Flex
                direction="column"
                minH="100vh"
                align="center"
                pt={{ sm: '125px', lg: '75px' }}
                position="relative"
            >
                <Box
                    h="45vh"
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    position="absolute"
                    w="100%"
                    borderRadius="20px"
                />

                <FormProvider {...methods}>
                    <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Card p="30px" mt={'160px'}>
                                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                    Siembra por semilla
                                </Text>
                                {errorMessage && (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertTitle>{errorMessage}</AlertTitle>
                                    </Alert>
                                )}
                                <Flex direction="column" w="100%">
                                    <Stack direction="column" spacing="20px">
                                        <SimpleGrid gap="20px">
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaDatePickerField
                                                    id={'fecha_realizacion_siembra'}
                                                    label={'Fecha de realización'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'hora_inicio_siembra'}
                                                    label={'Hora de inicio'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            </SimpleGrid>
                                            <FormControl
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <FormLabel
                                                    mb="0"
                                                    fontSize="sm"
                                                    color={textColor}
                                                    ms="10px"
                                                    fontWeight="bold"
                                                >
                                                    ¿Se verificó el orden y limpieza del área?
                                                </FormLabel>
                                                <Switch
                                                    {...methods.register(
                                                        'verificacion_limpieza_area'
                                                    )}
                                                    id="verificacion_limpieza_area"
                                                />
                                            </FormControl>
                                            <FormControl
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <FormLabel
                                                    mb="0"
                                                    fontSize="sm"
                                                    color={textColor}
                                                    ms="10px"
                                                    fontWeight="bold"
                                                >
                                                    ¿Se verificó la limpieza y mantenimiento de la
                                                    sembradora antes de iniciar el proceso?
                                                </FormLabel>
                                                <Switch
                                                    {...methods.register(
                                                        'verificacion_limpieza_sembradora'
                                                    )}
                                                    id="verificacion_limpieza_sembradora"
                                                />
                                            </FormControl>
                                        </SimpleGrid>
                                    </Stack>
                                    <Flex justify="space-between" mt="24px">
                                        <Button
                                            variant="outline"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            //navegar a lote
                                            onClick={onCancel}
                                            type="button"
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="darkBrand"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            isLoading={methods.formState.isSubmitting}
                                            type="submit"
                                        >
                                            Continuar
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Card>
                        </form>
                    </Box>
                </FormProvider>
            </Flex>
        </>
    );
}
