import { Flex, Box, SimpleGrid, Text, useColorModeValue, Divider } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { FilaInfoRegistro } from './FilaInfoRegistro';
import { ControlGerminacion } from 'modules/trazabilidad/types/ControlGerminacion';

type InfoControlProps = {
    controlData: ControlGerminacion;
};

export default function InfoControl(props: InfoControlProps) {
    const { controlData } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    const cumple_oscuridad_ingreso = controlData.cumple_oscuridad_ingreso == 0 ? 'NO' : 'SI';

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center" mb="5">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Inicio de germinación
                </Text>
            </Flex>
            <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing={{ base: '20px', xl: '5px' }}>
                <FilaInfoRegistro label={'Fecha'} value={controlData.fecha_ingreso} />
                <FilaInfoRegistro label={'Hora'} value={controlData.hora_final_ingreso} />
            </SimpleGrid>
            <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing={{ base: '20px', xl: '5px' }}>
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro label={'Responsable'} value={controlData.responsable_ingreso} />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de bandejas ingresadas'}
                    value={controlData.bandejas_ingreso}
                />
                <Divider border="1px" borderColor="lightgray" />
            </SimpleGrid>

            <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing={{ base: '20px', xl: '5px' }}>
                <FilaInfoRegistro
                    label={'Temperatura'}
                    value={controlData.temperatura_camara_ingreso}
                />
                <FilaInfoRegistro label={'Humedad'} value={controlData.hr_camara_ingreso} />
            </SimpleGrid>

            <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing={{ base: '20px', xl: '5px' }}>
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro label={'Oscuridad'} value={cumple_oscuridad_ingreso} />
            </SimpleGrid>
        </Card>
    );
}
