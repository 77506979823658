import { Flex, Text, Link, useColorModeValue, Grid, GridItem, Divider } from '@chakra-ui/react';
import React from 'react';
import { ControlAmbiental } from 'modules/trazabilidad/types/ControlAmbiental';

export type FilaInfoRegistroAmbientalProps = {
    controlData: ControlAmbiental;
};

export function FilaInfoRegistroAmbiental(props: FilaInfoRegistroAmbientalProps) {
    const { controlData } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColor = useColorModeValue('gray.700', 'white');
    const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');

    return (
        <>
            <Grid
                templateColumns={{ base: 'repeat(5, 1fr)', md: '15% 10% 30% 25% 20%' }}
                gap={0}
                height={10}
                justifyItems="center"
                alignItems="center"
            >
                <GridItem fontSize={13} textAlign="center">
                    {controlData.fecha_hora.split(' ')[0]}
                </GridItem>
                <GridItem fontSize={13} textAlign="center">
                    {controlData.fecha_hora.split(' ')[1]}
                </GridItem>
                <GridItem fontSize={13} textAlign="center">
                    {controlData.temperatura}ºC
                </GridItem>
                <GridItem fontSize={13} textAlign="center">
                    {controlData.humedad}%
                </GridItem>
                <GridItem fontSize={13} textAlign="center">
                    {controlData.cumple_oscuridad}
                </GridItem>
            </Grid>
            <Divider border="1px" borderColor="lightgray" />
        </>
    );
}
