import { Icon } from '@chakra-ui/react';
import {
    MdDashboard,
    MdHome,
    MdLock,
    MdOutlineShoppingCart,
    MdAdminPanelSettings,
    MdAccountBox,
    MdInventory,
    MdCalendarMonth,
    MdMap,
    MdAssignment
} from 'react-icons/md';

// Cannava imports
import CannavaHome from 'views/home';
import AuthenticationGuard from './components/auth/AuthenticationGuard';
import CallbackAuth from './modules/auth/views/callbackAuth';
import PlaygroundForm from './views/PlaygroundForm';

// Admin Imports
import DashboardsDefault from 'views/admin/dashboards/default';
import DashboardsRTLDefault from 'views/admin/dashboards/rtl';
import DashboardsCarInterface from 'views/admin/dashboards/carInterface';
import DashboardsSmartHome from 'views/admin/dashboards/smartHome';

// // NFT Imports
import NFTMarketplace from 'views/admin/nfts/marketplace';
import NFTPage from 'views/admin/nfts/page';
import NFTCollection from 'views/admin/nfts/collection';
import NFTProfile from 'views/admin/nfts/profile';

// Main Imports
import AccountBilling from 'views/admin/main/account/billing';
import AccountApplications from 'views/admin/main/account/application';
import AccountInvoice from 'views/admin/main/account/invoice';
import AccountSettings from 'views/admin/main/account/settings';
import AccountAllCourses from 'views/admin/main/account/courses';
import AccountCoursePage from 'views/admin/main/account/coursePage';

import UserNew from './modules/auth/views/users/newUser';
import UsersOverview from './modules/auth/views/users/overview';
import UsersReports from './modules/auth/views/users/reports';

import ProfileSettings from 'views/admin/main/profile/settings';
import ProfileOverview from 'views/admin/main/profile/overview';
import ProfileNewsfeed from 'views/admin/main/profile/newsfeed';

import ApplicationsKanban from 'views/admin/main/applications/kanban';
import ApplicationsDataTables from 'views/admin/main/applications/dataTables';
import ApplicationsCalendar from 'views/admin/main/applications/calendar';

import EcommerceNewProduct from 'views/admin/main/ecommerce/newProduct';
import EcommerceProductOverview from 'views/admin/main/ecommerce/overviewProduct';
import EcommerceProductSettings from 'views/admin/main/ecommerce/settingsProduct';
import EcommerceProductPage from 'views/admin/main/ecommerce/pageProduct';
import EcommerceOrderList from 'views/admin/main/ecommerce/orderList';
import EcommerceOrderDetails from 'views/admin/main/ecommerce/orderDetails';
import EcommerceReferrals from 'views/admin/main/ecommerce/referrals';

// Others
import OthersNotifications from 'views/admin/main/others/notifications';
import OthersPricing from 'views/admin/main/others/pricing';
import OthersError from 'views/admin/main/others/404';
import Messages from 'views/admin/main/others/messages';

// Auth Imports
import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered';
import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault';
import LockCentered from 'views/auth/lock/LockCentered';
import LockDefault from 'views/auth/lock/LockDefault';
import SignInCentered from 'views/auth/signIn/SignInCentered';
import SignInDefault from 'views/auth/signIn/SignInDefault';
import SignUpCentered from 'views/auth/signUp/SignUpCentered';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';
import VerificationCentered from 'views/auth/verification/VerificationCentered';
import VerificationDefault from 'views/auth/verification/VerificationDefault';

import withAuthGuard from 'components/auth/withAuthGuard';
import LotesSemillas from 'modules/trazabilidad/views/lotesSemillas/index';
import NewLote from 'modules/trazabilidad/views/lotesSemillas/newLote';
import NewLotePoderGerminativo from 'modules/trazabilidad/views/lotesPoderGerminativo/newLote';
import ProveedoresOverview from './modules/genetica/views/proveedores/overview';
import UbicacionesOverview from './modules/locacion/views/ubicaciones/overview';
import NewProveedor from './modules/genetica/views/proveedores/newProveedor';
import NewUbicacion from './modules/locacion/views/ubicaciones/newUbicacion';
import VariedadesOverview from './modules/genetica/views/variedades/overview';
import InventariosOverview from './modules/trazabilidad/views/inventarios/overview';
import NewVariedad from './modules/genetica/views/variedades/newVariedad';
import CampanasIndex from './modules/planificacion/views/campanas/index';
import NuevaCampana from './modules/planificacion/views/campanas/create';
import CannavaHomePrincipal from './views/home';
import EstablecimientosIndex from './modules/locacion/views/establecimientos/index';
import NuevoEstablecimiento from './modules/locacion/views/establecimientos/create';
import ProductosIndex from './modules/catalogo/views/productos/index';
import NuevoProducto from './modules/catalogo/views/productos/create';
import NuevoReportePoderGerminativo from 'modules/trazabilidad/views/lotesPoderGerminativo/newReporte';
import LotesPoderGerminativo from 'modules/trazabilidad/views/lotesPoderGerminativo/index';
import PlaygroundUploader from './views/playgroundUploader';
import LotesGerminacion from 'modules/trazabilidad/views/lotesGerminacion/index';
import NewLoteGerminacion from 'modules/trazabilidad/views/lotesGerminacion/newLote';
import VerLoteGerminacion from 'modules/trazabilidad/views/lotesGerminacion/verLote';
import Siembra from 'modules/trazabilidad/views/lotesGerminacion/siembra/IniciarSiembra';
import ControlLote from 'modules/trazabilidad/views/lotesGerminacion/controlLote';
import Germinacion from 'modules/trazabilidad/views/lotesGerminacion/germinacion';
import ControlAmbiental from 'modules/trazabilidad/views/lotesGerminacion/controlAmbiental';
import FinalizacionGerminacion from 'modules/trazabilidad/views/lotesGerminacion/finalizacionGerminacion';
import IniciarPlantinera from 'modules/trazabilidad/views/lotesGerminacion/iniciarPlantinera';
import InformeCalidad from 'modules/trazabilidad/views/lotesGerminacion/informeCalidad';
import FinalizarPlantinera from 'modules/trazabilidad/views/lotesGerminacion/finalizarPlantinera';
import MonitoreoPlantines from 'modules/trazabilidad/views/lotesGerminacion/monitoreoPlantines';
import VerControlSiembra from 'modules/trazabilidad/views/lotesGerminacion/siembra/verControl';
import PerfilUsuario from 'modules/auth/views/users/verUsuario';
import NewLotePlantaProductiva from 'modules/trazabilidad/views/lotePlantaProductiva/newLote';
import VerLotePlantaProductiva from 'modules/trazabilidad/views/lotePlantaProductiva/verLote';
import NuevaOrdenAplicacion from 'modules/trazabilidad/views/lotePlantaProductiva/ordenAplicacion';
import LotesPlantasProductivas from 'modules/trazabilidad/views/lotePlantaProductiva/index';
import VerControlGerminacion from 'modules/trazabilidad/views/lotesGerminacion/controlLote/verControl';
import InsumosIndex from 'modules/catalogo/views/insumos/index';

/** NO USAR **/
/*const routes_default = [
  // --- Dashboards ---
  {
    name: 'Dashboards',
    path: '/dashboards',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Main Dashboard',
        layout: '/admin',
        path: '/dashboards/default',
        component: <DashboardsDefault />, 
      },
      {
        name: 'Car Interface',
        layout: '/admin',
        path: '/dashboards/car-interface',
        component: <DashboardsCarInterface />,
      },
      {
        name: 'Smart Home',
        layout: '/admin',
        path: '/dashboards/smart-home',
        component: <DashboardsSmartHome />,
      },
      {
        name: 'RTL',
        layout: '/rtl',
        path: '/dashboards/rtl',
        component: <DashboardsRTLDefault />,
      },
    ],
  },
  // // --- NFTs ---
  {
    name: 'NFTs',
    path: '/nfts',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: true,
    items: [
      {
        name: 'Marketplace',
        layout: '/admin',
        path: '/nfts/marketplace',
        component: <NFTMarketplace />,
        secondary: true,
      },
      {
        name: 'Collection',
        layout: '/admin',
        path: '/nfts/collection',
        component: <NFTCollection />,
        secondary: true,
      },
      {
        name: 'NFT Page',
        layout: '/admin',
        path: '/nfts/page',
        component: <NFTPage />,
        secondary: true,
      },
      {
        name: 'Profile',
        layout: '/admin',
        path: '/nfts/profile',
        component: <NFTProfile />,
        secondary: true,
      },
    ],
  },
  // // --- Main pages ---
  {
    name: 'Main Pages',
    path: '/main',
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Account',
        path: '/main/account',
        collapse: true,
        items: [
          {
            name: 'Billing',
            layout: '/admin',
            path: '/main/account/billing',
            exact: false,
            component: <AccountBilling />,
          },
          {
            name: 'Application',
            layout: '/admin',
            path: '/main/account/application',
            exact: false,
            component: <AccountApplications />,
          },
          {
            name: 'Invoice',
            layout: '/admin',
            path: '/main/account/invoice',
            exact: false,
            component: <AccountInvoice />,
          },
          {
            name: 'Settings',
            layout: '/admin',
            path: '/main/account/settings',
            exact: false,
            component: <AccountSettings />,
          },
          {
            name: 'All Courses',
            layout: '/admin',
            path: '/main/account/all-courses',
            exact: false,
            component: <AccountAllCourses />,
          },
          {
            name: 'Course Page',
            layout: '/admin',
            path: '/main/account/course-page',
            exact: false,
            component: <AccountCoursePage />,
          },
        ],
      },
      {
        name: 'Ecommerce',
        path: '/main/ecommerce',
        collapse: true,
        items: [
          {
            name: 'New Product',
            layout: '/admin',
            path: '/main/ecommerce/new-prodcut',
            exact: false,
            component: <EcommerceNewProduct />,
          },
          {
            name: 'Product Overview',
            layout: '/admin',
            path: '/main/ecommerce/product-overview',
            exact: false,
            component: <EcommerceProductOverview />,
          },
          {
            name: 'Product Settings',
            layout: '/admin',
            path: '/main/ecommerce/settings',
            exact: false,
            component: <EcommerceProductSettings />,
          },
          {
            name: 'Product Page',
            layout: '/admin',
            path: '/main/ecommerce/page-example',
            exact: false,
            component: <EcommerceProductPage />,
          },
          {
            name: 'Order List',
            layout: '/admin',
            path: '/main/ecommerce/order-list',
            exact: false,
            component: <EcommerceOrderList />,
          },
          {
            name: 'Order Details',
            layout: '/admin',
            path: '/main/ecommerce/order-details',
            exact: false,
            component: <EcommerceOrderDetails />,
          },
          {
            name: 'Referrals',
            layout: '/admin',
            path: '/main/ecommerce/referrals',
            exact: false,
            component: <EcommerceReferrals />,
          },
        ],
      },
      {
        name: 'Users',
        path: '/main/users',
        collapse: true,
        items: [
          {
            name: 'New User',
            layout: '/admin',
            path: '/main/users/new-user',
            exact: false,
            component: <UserNew />,
          },
          {
            name: 'Users Overview',
            layout: '/admin',
            path: '/main/users/users-overview',
            exact: false,
            component: <UsersOverview />,
          },
          {
            name: 'Users Reports',
            layout: '/admin',
            path: '/main/users/users-reports',
            exact: false,
            component: <UsersReports />,
          },
        ],
      },
      {
        name: 'Applications',
        path: '/main/applications',
        collapse: true,
        items: [
          {
            name: 'Kanban',
            layout: '/admin',
            path: '/main/applications/kanban',
            exact: false,
            component: <ApplicationsKanban />,
          },
          {
            name: 'Data Tables',
            layout: '/admin',
            path: '/main/applications/data-tables',
            exact: false,
            component: <ApplicationsDataTables />,
          },
          {
            name: 'Calendar',
            layout: '/admin',
            path: '/main/applications/calendar',
            exact: false,
            component: <ApplicationsCalendar />,
          },
        ],
      },
      {
        name: 'Profile',
        path: '/main/profile',
        collapse: true,
        items: [
          {
            name: 'Profile Overview',
            layout: '/admin',
            path: '/main/profile/overview',
            exact: false,
            component: <ProfileOverview />,
          },
          {
            name: 'Profile Settings',
            layout: '/admin',
            path: '/main/profile/settings',
            exact: false,
            component: <ProfileSettings />,
          },
          {
            name: 'News Feed',
            layout: '/admin',
            path: '/main/profile/newsfeed',
            exact: false,
            component: <ProfileNewsfeed />,
          },
        ],
      },
      {
        name: 'Others',
        path: '/main/others',
        collapse: true,
        items: [
          {
            name: 'Notifications',
            layout: '/admin',
            path: '/main/others/notifications',
            exact: false,
            component: <OthersNotifications />,
          },
          {
            name: 'Pricing',
            layout: '/auth',
            path: '/main/others/pricing',
            exact: false,
            component: <OthersPricing />,
          },
          {
            name: '404',
            layout: '/admin',
            path: '/main/others/404',
            exact: false,
            component: <OthersError />,
          },
          {
            name: 'Messages',
            layout: '/admin',
            path: '/main/others/messages',
            exact: false,
            component: <Messages />,
          },
        ],
      },
    ],
  },
  // --- Authentication ---
  {
    name: 'Authentication',
    path: '/auth',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      // --- Sign In ---
      {
        name: 'Sign In',
        path: '/sign-in',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-in/default',
            component: <SignInDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/sign-in/centered',
            component: <SignInCentered />,
          },
        ],
      },
      // --- Sign Up ---
      {
        name: 'Sign Up',
        path: '/sign-up',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-up/default',
            component: <SignUpDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/sign-up/centered',
            component: <SignUpCentered />,
          },
        ],
      },
      // --- Verification ---
      {
        name: 'Verification',
        path: '/verification',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/verification/default',
            component: <VerificationDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/verification/centered',
            component: <VerificationCentered />,
          },
        ],
      },
      // --- Lock ---
      {
        name: 'Lock',
        path: '/lock',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/lock/default',
            component: <LockDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/lock/centered',
            component: <LockCentered />,
          },
        ],
      },
      // --- Forgot Password ---
      {
        name: 'Forgot Password',
        path: '/forgot-password',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/forgot-password/default',
            component: <ForgotPasswordDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/forgot-password/centered',
            component: <ForgotPasswordCentered />,
          },
        ],
      },
    ],
  },
];*/

const routes = [
    // --- Dashboards ---
    {
        name: 'Home',
        layout: '/admin',
        path: '/home',
        //component: <CannavaHome />,
        component: <AuthenticationGuard component={CannavaHomePrincipal} />,
        icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
        requireAuth: false,
        collapse: false
    },
    {
        name: 'Playground',
        layout: '/admin',
        path: '/playground',
        //component: <CannavaHome />,
        component: <AuthenticationGuard component={PlaygroundUploader} />,
        icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
        requireAuth: false,
        collapse: false,
        visible: false
    },
    {
        name: 'Planificación',
        path: '/admin',
        icon: <Icon as={MdCalendarMonth} width="20px" height="20px" color="inherit" />,
        collapse: true,
        visible: true, // aca
        exact: true,
        areas: ['gerencia', 'comercial', 'planificacion', 'finanzas', 'campo', 'poscosecha'],
        items: [
            {
                name: 'Campañas',
                layout: '/admin',
                path: '/campanas',
                component: (
                    <AuthenticationGuard
                        component={CampanasIndex}
                        requiredArea={[
                            'gerencia',
                            'comercial',
                            'planificacion',
                            'finanzas',
                            'campo',
                            'poscosecha'
                        ]}
                    />
                ),
                secondary: true,
                brandText: 'Lotes',
                exact: false
            },
            {
                name: 'Nueva campaña',
                brandText: 'Nuevo campaña',
                layout: '/admin',
                path: '/campanas/nuevo',
                component: (
                    <AuthenticationGuard
                        component={NuevaCampana}
                        requiredArea={['planificacion', 'finanzas']}
                    />
                ),
                secondary: true,
                visible: false
            }
        ]
    },
    {
        name: 'Lotes',
        path: '/admin',
        icon: <Icon as={MdInventory} width="20px" height="20px" color="inherit" />,
        collapse: true,
        exact: true,
        areas: [
            'gerencia',
            'laboratorio',
            'comercial',
            'aseguramiento-calidad',
            'control-calidad',
            'trazabilidad',
            'planificacion',
            'finanzas',
            'campo',
            'poscosecha'
        ],
        items: [
            {
                name: 'Semillas',
                layout: '/admin',
                path: '/lotes-semillas',
                component: (
                    <AuthenticationGuard
                        component={LotesSemillas}
                        requiredArea={[
                            'gerencia',
                            'comercial',
                            'aseguramiento-calidad',
                            'control-calidad',
                            'trazabilidad',
                            'planificacion',
                            'finanzas',
                            'campo',
                            'poscosecha'
                        ]}
                    />
                ),
                secondary: true,
                brandText: 'Lotes',
                exact: false
            },
            {
                name: 'Poder germinativo',
                layout: '/admin',
                path: '/lotes-poder-germinativo',
                component: (
                    <AuthenticationGuard
                        component={LotesPoderGerminativo}
                        requiredArea={[
                            'gerencia',
                            'comercial',
                            'aseguramiento-calidad',
                            'control-calidad',
                            'trazabilidad',
                            'planificacion',
                            'finanzas',
                            'campo',
                            'poscosecha'
                        ]}
                    />
                ),
                secondary: true,
                brandText: 'Lotes',
                exact: false
            },
            {
                name: 'Germinacion',
                layout: '/admin',
                path: '/lotes-germinacion',
                component: (
                    <AuthenticationGuard
                        component={LotesGerminacion}
                        requiredArea={[
                            'gerencia',
                            'comercial',
                            'aseguramiento-calidad',
                            'control-calidad',
                            'trazabilidad',
                            'planificacion',
                            'finanzas',
                            'campo',
                            'poscosecha'
                        ]}
                    />
                ),
                secondary: true,
                brandText: 'Lotes',
                exact: false
            },
            {
                name: 'Plantas productivas',
                layout: '/admin',
                path: '/lotes-plantas-productivas',
                component: (
                    <AuthenticationGuard
                        component={LotesPlantasProductivas}
                        requiredArea={[
                            'gerencia',
                            'comercial',
                            'aseguramiento-calidad',
                            'control-calidad',
                            'trazabilidad',
                            'planificacion',
                            'finanzas',
                            'campo',
                            'poscosecha'
                        ]}
                    />
                ),
                secondary: true,
                brandText: 'Lotes',
                exact: false
            },
            {
                name: 'Plantas productivas',
                layout: '/admin',
                path: '/lotes-plantas-productivas/ver',
                component: (
                    <AuthenticationGuard
                        component={VerLotePlantaProductiva}
                        requiredArea={[
                            'gerencia',
                            'comercial',
                            'aseguramiento-calidad',
                            'control-calidad',
                            'trazabilidad',
                            'planificacion',
                            'finanzas',
                            'campo',
                            'poscosecha'
                        ]}
                    />
                ),
                secondary: true,
                brandText: 'Lotes',
                exact: false,
                visible: false
            },
            {
                name: 'Nuevo lote',
                brandText: 'Nuevo lote',
                layout: '/admin',
                path: '/lotes-semillas/nuevo',
                component: (
                    <AuthenticationGuard component={NewLote} requiredArea={'trazabilidad'} />
                ),
                secondary: true,
                visible: false
            },
            {
                name: 'Nuevo analisis de semillas',
                brandText: 'Nuevo lote',
                layout: '/admin',
                path: '/lotes-poder-germinativo/nuevo/:loteSemillasId',
                component: (
                    <AuthenticationGuard
                        component={NewLotePoderGerminativo}
                        requiredArea={'trazabilidad'}
                    />
                ),
                secondary: true,
                visible: false
            },
            {
                name: 'Nuevo reporte de poder germinativo',
                brandText: 'Nuevo reporte',
                layout: '/admin',
                path: '/reportes-poder-germinativo/nuevo/:lotePoderGerminativoId',
                component: (
                    <AuthenticationGuard
                        component={NuevoReportePoderGerminativo}
                        requiredArea={'trazabilidad'}
                    />
                ),
                secondary: true,
                visible: false
            },
            {
                name: 'Nuevo lote germinacion',
                brandText: 'Nuevo lote germinacion',
                layout: '/admin',
                path: '/lotes-germinacion/nuevo',
                component: (
                    <AuthenticationGuard
                        component={NewLoteGerminacion}
                        requiredArea={'trazabilidad'}
                    />
                ),
                secondary: true,
                visible: false
            },
            {
                name: 'Control de lote',
                brandText: 'Control de lote',
                layout: '/admin',
                path: '/lotes-germinacion/control-lote',
                component: (
                    <AuthenticationGuard component={ControlLote} requiredArea={'trazabilidad'} />
                ),
                secondary: true,
                visible: false
            }
        ]
    },
    {
        name: 'Ubicaciones',
        path: '/admin',
        icon: <Icon as={MdMap} width="20px" height="20px" color="inherit" />,
        collapse: true,
        exact: true,
        areas: ['finanzas', 'trazabilidad'],
        items: [
            {
                name: 'Establecimientos',
                layout: '/admin',
                path: '/establecimientos',
                component: (
                    <AuthenticationGuard
                        component={EstablecimientosIndex}
                        requiredArea={['finanzas', 'trazabilidad']}
                    />
                ),
                secondary: true,
                brandText: 'Lotes',
                exact: false
            },
            {
                name: 'Nueva establecimiento',
                layout: '/admin',
                path: '/establecimientos/nuevo',
                component: (
                    <AuthenticationGuard
                        component={NuevoEstablecimiento}
                        // requiredRole={['administrador', 'super-administrador']}
                        requiredArea={['finanzas', 'trazabilidad']}
                    />
                ),
                secondary: true,
                visible: false
            },
            {
                name: 'Ubicaciones',
                layout: '/admin',
                path: '/ubicaciones',
                component: (
                    <AuthenticationGuard
                        component={UbicacionesOverview}
                        // requiredRole={['administrador', 'super-administrador']}
                        requiredArea={['finanzas', 'trazabilidad']}
                    />
                ),
                secondary: true
            },
            {
                name: 'Nueva ubicación',
                layout: '/admin',
                path: '/ubicaciones/nuevo',
                component: (
                    <AuthenticationGuard
                        component={NewUbicacion}
                        // requiredRole={['administrador', 'super-administrador']}
                        requiredArea={['finanzas', 'trazabilidad']}
                    />
                ),
                secondary: true,
                visible: false
            }
        ]
    },
    {
        name: 'Inventario',
        path: '/admin',
        icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
        collapse: true,
        exact: true,
        areas: [
            'gerencia',
            'comercial',
            'aseguramiento-calidad',
            'control-calidad',
            'trazabilidad',
            'planificacion',
            'finanzas',
            'campo',
            'poscosecha'
        ],
        rol: ['usuario-basico', 'administrador', 'super-administrador'],
        items: [
            {
                name: 'Registro',
                layout: '/admin',
                path: '/inventarios',
                component: (
                    <AuthenticationGuard
                        component={InventariosOverview}
                        requiredRole={['administrador', 'super-administrador']}
                        requiredArea={['comercial', 'planificacion', 'finanzas']}
                    />
                ),
                secondary: true,
                exact: false
            },
            {
                name: 'Proveedores',
                layout: '/admin',
                path: '/proveedores',
                component: (
                    <AuthenticationGuard
                        component={ProveedoresOverview}
                        requiredRole={['administrador', 'super-administrador']}
                        requiredArea={['finanzas', 'trazabilidad']}
                    />
                ),
                secondary: true,
                exact: false
            },
            {
                name: 'Nuevo proveedor',
                layout: '/admin',
                path: '/proveedores/nuevo',
                component: (
                    <AuthenticationGuard
                        component={NewProveedor}
                        requiredRole={['administrador', 'super-administrador']}
                        requiredArea={['finanzas', 'trazabilidad']}
                    />
                ),
                secondary: true,
                visible: false
            },
            {
                name: 'Genéticas',
                layout: '/admin',
                path: '/variedades',
                component: (
                    <AuthenticationGuard
                        component={VariedadesOverview}
                        requiredRole={['usuario-basico', 'administrador', 'super-administrador']}
                        requiredArea={[
                            'gerencia',
                            'comercial',
                            'aseguramiento-calidad',
                            'control-calidad',
                            'trazabilidad',
                            'planificacion',
                            'finanzas',
                            'campo',
                            'poscosecha'
                        ]}
                    />
                ),
                secondary: true,
                exact: false
            },
            {
                name: 'Nueva genética',
                layout: '/admin',
                path: '/variedades/nuevo',
                component: (
                    <AuthenticationGuard
                        component={NewVariedad}
                        requiredRole={['administrador', 'super-administrador']}
                        requiredArea={['trazabilidad', 'finanzas', 'campo']}
                    />
                ),
                secondary: true,
                visible: false
            },
            {
                name: 'Productos',
                layout: '/admin',
                path: '/productos',
                component: (
                    <AuthenticationGuard
                        component={ProductosIndex}
                        requiredRole={['administrador', 'super-administrador']}
                    />
                ),
                secondary: true,
                visible: false,
                exact: false
            },
            {
                name: 'Nueva producto',
                layout: '/admin',
                path: '/productos/nuevo',
                component: (
                    <AuthenticationGuard
                        component={NuevoProducto}
                        requiredRole={['administrador', 'super-administrador']}
                    />
                ),
                secondary: true,
                visible: false
            },
            {
                name: 'Insumos',
                layout: '/admin',
                path: '/insumos',
                component: (
                    <AuthenticationGuard
                        component={InsumosIndex}
                        requiredRole={['usuario-basico', 'administrador', 'super-administrador']}
                        requiredArea={[
                            'gerencia',
                            'laboratorio',
                            'comercial',
                            'aseguramiento-calidad',
                            'control-calidad',
                            'trazabilidad',
                            'planificacion',
                            'finanzas',
                            'campo',
                            'poscosecha'
                        ]}
                    />
                ),
                secondary: true,
                exact: false
            }
        ]
    },
    {
        name: 'Administración',
        path: '/admin',
        icon: <Icon as={MdAdminPanelSettings} width="20px" height="20px" color="inherit" />,
        collapse: true,
        exact: true,
        areas: [
            'laboratorio',
            'gerencia',
            'comercial',
            'aseguramiento-calidad',
            'control-calidad',
            'trazabilidad',
            'planificacion',
            'finanzas',
            'campo',
            'poscosecha'
        ],
        rol: ['administrador', 'super-administrador'],
        items: [
            {
                name: 'Usuarios',
                layout: '/admin',
                path: '/usuarios',
                component: (
                    <AuthenticationGuard
                        component={UsersOverview}
                        requiredRole={['administrador', 'super-administrador']}
                        requiredArea={['trazabilidad', 'finanzas']}
                    />
                ),
                secondary: true,
                exact: false
            },
            {
                name: 'Nuevo usuario',
                layout: '/admin',
                path: '/usuarios/nuevo',
                component: (
                    <AuthenticationGuard
                        component={UserNew}
                        requiredRole={['administrador', 'super-administrador']}
                        requiredArea={['trazabilidad', 'finanzas']}
                    />
                ),
                secondary: true,
                visible: false
            },
            // {
            //     name: 'Nueva ubicación',
            //     layout: '/admin',
            //     path: '/ubicaciones/nuevo',
            //     component: (
            //         <AuthenticationGuard
            //             component={NewUbicacion}
            //             requiredRole={['administrador', 'super-administrador']}
            //         />
            //     ),
            //     secondary: true,
            //     visible: false
            // },
            {
                name: 'Perfil usuario',
                layout: '/admin',
                path: '/usuarios/perfil',
                component: (
                    <AuthenticationGuard
                        component={PerfilUsuario}
                        requiredArea={[
                            'laboratorio',
                            'gerencia',
                            'comercial',
                            'aseguramiento-calidad',
                            'control-calidad',
                            'trazabilidad',
                            'planificacion',
                            'finanzas',
                            'campo',
                            'poscosecha'
                        ]}
                    />
                ),
                secondary: true
            }
        ]
    }
];

export default routes;
