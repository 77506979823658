/* eslint-disable */
// import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useAppContext } from '../../../contexts/AppContext';
// chakra imports
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    HStack,
    Text,
    List,
    Icon,
    ListItem,
    useColorModeValue
} from '@chakra-ui/react';
// Assets
import { FaCircle } from 'react-icons/fa';

export function SidebarLinks(props: { routes: RoutesType[]; [x: string]: any }) {
    //   Chakra color mode
    let location = useLocation();
    let activeColor = useColorModeValue('gray.700', 'white');
    let inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
    let activeIcon = useColorModeValue('brand.500', 'white');
    // const { backendUserData } = useAppContext();
    // const [ menuVisible, setMenuVisible ] = useState([]);

    const { routes, hovered, mini } = props;

    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName: string, exact: boolean = false) => {
        if (exact) {
            //console.log("pathname: "+ location.pathname + " routeName: " + routeName);
            return location.pathname === routeName || location.pathname === '/admin' + routeName;
        } else {
            return location.pathname.includes(routeName);
        }
    };

    // // Verifica los elementos de menu a mostrar segun permisos de rol y area
    // useEffect(() => {
    //     routes.map((route) => {

    //         // Filtro por rol y area los menues a mostrar
    //         if (route.items) {
    //             const filtroPorRol = route.items.flatMap((item) => {
    //                 const requiredRole = item.component.props.requiredRole;
    //                 if (requiredRole) {
    //                     const roles = Array.isArray(requiredRole) ? requiredRole : [requiredRole];
    //                     if (roles.includes(backendUserData.roles)) {
    //                         return item;
    //                     }
    //                 } else {
    //                     return item;
    //                 }
    //                 return [];
    //             });

    //             const itemsVisibles = filtroPorRol.flatMap((item, key) => {
    //                 const requiredArea = item.component.props.requiredArea;
    //                 if (requiredArea) {
    //                     const areas = Array.isArray(requiredArea) ? requiredArea : [requiredArea];
    //                     if (areas.some((area: string) =>
    //                         backendUserData.areas.some((userArea: { slug: string }) => userArea.slug === area)
    //                     )) {
    //                         return item;
    //                     }
    //                 }
    //                 return [];
    //             });
    //             route.items = itemsVisibles;
    //         }
    //     });
    //     setMenuVisible(routes);
    // }, [backendUserData, routes]);

    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routes: RoutesType[]) => {
        return routes.map((route, key) => {
            if ('visible' in route && route.visible === false) {
                return;
            }

            if (route.collapse) {
                return (
                    <Accordion allowToggle key={key}>
                        <AccordionItem maxW="100%" border="none" key={key}>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                _hover={{
                                    bg: 'unset'
                                }}
                                _focus={{
                                    boxShadow: 'none'
                                }}
                                borderRadius="8px"
                                w={{
                                    sm: '100%',
                                    xl: '100%',
                                    '2xl': '95%'
                                }}
                                px={route.icon ? null : '0px'}
                                py="0px"
                                bg={'transparent'}
                                ms={0}
                                color={'gray.400'}
                                _expanded={{ color: activeColor }}
                            >
                                {route.icon ? (
                                    <Flex
                                        align="center"
                                        justifyContent={
                                            mini === false
                                                ? 'space-between'
                                                : mini === true && hovered === true
                                                ? 'space-between'
                                                : 'center'
                                        }
                                        w="100%"
                                    >
                                        <HStack
                                            mb="6px"
                                            spacing={
                                                activeRoute(route.path.toLowerCase())
                                                    ? '22px'
                                                    : '26px'
                                            }
                                        >
                                            <Flex
                                                w="100%"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Box
                                                    color={activeIcon}
                                                    me={
                                                        mini === false
                                                            ? '12px'
                                                            : mini === true && hovered === true
                                                            ? '12px'
                                                            : '0px'
                                                    }
                                                    mt="6px"
                                                >
                                                    {route.icon}
                                                </Box>
                                                <Text
                                                    display={
                                                        mini === false
                                                            ? 'block'
                                                            : mini === true && hovered === true
                                                            ? 'block'
                                                            : 'none'
                                                    }
                                                    me="auto"
                                                    fontWeight="500"
                                                    fontSize="md"
                                                >
                                                    {route.name}
                                                </Text>
                                            </Flex>
                                        </HStack>
                                        <AccordionIcon
                                            display={
                                                mini === false
                                                    ? 'block'
                                                    : mini === true && hovered === true
                                                    ? 'block'
                                                    : 'none'
                                            }
                                            ms="auto"
                                            color={'secondaryGray.600'}
                                            transform={route.icon ? null : 'translateX(-70%)'}
                                        />
                                    </Flex>
                                ) : (
                                    <Flex
                                        pt="0px"
                                        pb="10px"
                                        justify={'center'}
                                        alignItems="center"
                                        w="100%"
                                    >
                                        <HStack
                                            spacing={
                                                activeRoute(route.path.toLowerCase())
                                                    ? '22px'
                                                    : '26px'
                                            }
                                            ps={
                                                mini === false
                                                    ? '34px'
                                                    : mini === true && hovered === true
                                                    ? '34px'
                                                    : '0px'
                                            }
                                        >
                                            <Text
                                                me="auto"
                                                color={
                                                    activeRoute(route.path.toLowerCase())
                                                        ? 'secondaryGray.600'
                                                        : inactiveColor
                                                }
                                                fontWeight="500"
                                                fontSize="sm"
                                            >
                                                {mini === false
                                                    ? route.name
                                                    : mini === true && hovered === true
                                                    ? route.name
                                                    : route.name[0]}
                                            </Text>
                                        </HStack>
                                        <AccordionIcon
                                            display={
                                                mini === false
                                                    ? 'block'
                                                    : mini === true && hovered === true
                                                    ? 'block'
                                                    : 'none'
                                            }
                                            ms="auto"
                                            color={'secondaryGray.600'}
                                            transform={null}
                                        />
                                    </Flex>
                                )}
                            </AccordionButton>
                            <AccordionPanel
                                display={
                                    mini === false
                                        ? 'block'
                                        : mini === true && hovered === true
                                        ? 'block'
                                        : 'flex'
                                }
                                justifyContent="center"
                                alignItems="center"
                                flexDirection={'column'}
                                // bg="blue"
                                pe={route.icon ? '14px !important' : '0px'}
                                py="0px"
                                ps={route.icon ? '14px !important' : '8px'}
                            >
                                <List>
                                    {
                                        route.icon
                                            ? createLinks(route.items) // for bullet accordion links
                                            : createAccordionLinks(route.items) // for non-bullet accordion links
                                    }
                                </List>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                );
            } else {
                return (
                    <NavLink to={route.layout + route.path} key={key}>
                        {route.icon ? (
                            <Flex
                                align="center"
                                justifyContent="space-between"
                                w="100%"
                                ps="17px"
                                mb="0px"
                            >
                                <HStack
                                    mb="6px"
                                    spacing={
                                        activeRoute(route.path.toLowerCase()) ? '22px' : '26px'
                                    }
                                >
                                    <Flex w="100%" alignItems="center" justifyContent="center">
                                        <Box color={activeIcon} me="12px" mt="6px">
                                            {route.icon}
                                        </Box>
                                        <Text
                                            me="auto"
                                            color={
                                                activeRoute(
                                                    route.path.toLowerCase(),
                                                    'exact' in route ? Boolean(route.exact) : false
                                                )
                                                    ? activeColor
                                                    : inactiveColor
                                            }
                                            fontWeight="500"
                                        >
                                            {mini === false
                                                ? route.name
                                                : mini === true && hovered === true
                                                ? route.name
                                                : route.name[0]}
                                        </Text>
                                    </Flex>
                                </HStack>
                            </Flex>
                        ) : (
                            <ListItem ms={null}>
                                <Flex
                                    ps={
                                        mini === false
                                            ? '34px'
                                            : mini === true && hovered === true
                                            ? '34px'
                                            : '0px'
                                    }
                                    alignItems="center"
                                    mb="8px"
                                >
                                    <Text
                                        color={
                                            activeRoute(
                                                route.path.toLowerCase(),
                                                'exact' in route ? Boolean(route.exact) : false
                                            )
                                                ? activeColor
                                                : inactiveColor
                                        }
                                        fontWeight="500"
                                        fontSize="sm"
                                    >
                                        {mini === false
                                            ? route.name
                                            : mini === true && hovered === true
                                            ? route.name
                                            : route.name[0]}
                                    </Text>
                                </Flex>
                            </ListItem>
                        )}
                    </NavLink>
                );
            }
        });
    };
    // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
    const createAccordionLinks = (routes: RoutesType[]) => {
        return routes.map((route: RoutesType, key: number) => {
            return (
                <NavLink to={route.layout + route.path} key={key}>
                    <ListItem
                        ms={
                            mini === false
                                ? '28px'
                                : mini === true && hovered === true
                                ? '28px'
                                : '0px'
                        }
                        display="flex"
                        alignItems="center"
                        mb="10px"
                        key={key}
                    >
                        <Icon w="6px" h="6px" me="8px" as={FaCircle} color={activeIcon} />
                        <Text
                            // display={
                            //   mini === false
                            //     ? 'block'
                            //     : mini === true && hovered === true
                            //     ? 'block'
                            //     : 'none'
                            // }
                            color={
                                activeRoute(
                                    route.path.toLowerCase(),
                                    'exact' in route ? Boolean(route.exact) : false
                                )
                                    ? activeColor
                                    : inactiveColor
                            }
                            fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}
                            fontSize="sm"
                        >
                            {mini === false
                                ? route.name
                                : mini === true && hovered === true
                                ? route.name
                                : route.name[0]}
                        </Text>
                    </ListItem>
                </NavLink>
            );
        });
    };
    //  BRAND
    return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
