// Chakra imports
import { Flex } from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import { Variedad } from '../../../../genetica/types/Variedad';
import { useFetchData } from '../../../../../hooks/useFetchData';
import PageLoader from '../../../../../components/actions/PageLoader';
import { MenuItemProps } from '../../../../../types/MenuItemProps';
import { MdAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import Listado, {
    crearCelda,
    crearCeldaConLink,
    crearHeader
} from '../../../../../components/cannava/listado';
import { Insumos } from '../../../types/Insumos';
import { useConfigurarNavegacion } from '../../../../../hooks/useConfigurarNavegacion';

export default function InsumosIndex() {
    const transformData = (data: any[]): Insumos[] => {
        return data.map(data => ({
            id: data.id,
            nombre: data.nombre,
            estado: data.estado,
            observaciones: data.observaciones,
            fecha_creacion: data.fecha_creacion,
            fecha_actualizacion: data.fecha_actualizacion,
            fecha_borrado: data.fecha_borrado,
            creado_por: data.creado_por,
            actualizado_por: data.actualizado_por,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };

    const { data: insumosData, isLoading } = useFetchData<Insumos>('insumos', transformData);
    const navigate = useNavigate();

    // configuro layout
    const configurarNavegacion = useConfigurarNavegacion({
        breadcrumbs: [
            { label: 'Inicio', href: '/' },
            { label: 'Catálogo', href: '/admin/home/' }
        ],
        titulo: 'Insumos'
    });

    if (isLoading) {
        return <PageLoader message={'Cargando insumos'} />;
    }

    const menuItems: MenuItemProps[] = [
        {
            icono: MdAddCircle,
            label: 'Agregar nuevo',
            onClick: () => navigate('/admin/insumos/nuevo')
        }
    ];

    const columnHelper = createColumnHelper<Insumos>();
    const columns = [
        columnHelper.accessor('nombre', {
            id: 'nombre',
            header: crearHeader('Producto'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('ID'),
            cell: crearCelda()
        }),
        columnHelper.accessor('estado', {
            id: 'estado',
            header: crearHeader('Estado'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'action_editar',
            header: crearHeader('Editar'),
            cell: crearCeldaConLink('/admin/insumos/editar', 'Editar', navigate)
        })
    ];

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Card px="0px">
                <Listado tableData={insumosData} menuItems={menuItems} columns={columns} />
            </Card>
        </Flex>
    );
}
