// Chakra imports
import { Avatar, Flex, Tag, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';

import React, { useEffect, useState } from 'react';
import { useLayoutContext } from '../../../../../contexts/LayoutContext';
import { useFetchData } from '../../../../../hooks/useFetchData';
import PageLoader from 'components/actions/PageLoader';
import { MenuItemProps } from '../../../../../types/MenuItemProps';
import { MdAddCircle } from 'react-icons/md';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import Listado, { crearCelda, crearCeldaConLink, crearHeader } from 'components/cannava/listado';
import { Campana } from '../../../types/Campana';

export default function CampanasIndex() {
    const transformUsuarios = (data: any[]): Campana[] => {
        return data.map(campana => {
            return {
                id: campana.id,
                nombre: campana.nombre,
                slug: campana.slug,
                estado: campana.estado,
                descripcion: campana.descripcion,
                tipo: campana.tipo,
                data_json: campana.data_json,
                fecha_inicio: campana.fecha_inicio,
                fecha_fin: campana.fecha_fin,
                fecha_creacion: campana.fecha_creacion,
                fecha_actualizacion: campana.fecha_actualizacion,
                fecha_borrado: campana.fecha_borrado,
                actions: 'Actions' // Esto es es
            };
        });
    };

    const { data: campanasData, isLoading } = useFetchData<Campana>('campanas', transformUsuarios);
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const navigate = useNavigate();

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Planificación', href: '/admin/campanas/' },
            { label: 'Campañas', href: '/admin/campanas/' }
        ]);

        setTitle('Campañas');
    }, []);

    if (isLoading) {
        return <PageLoader message={'Cargando campañas'} />;
    }

    const menuItems: MenuItemProps[] = [
        {
            icono: MdAddCircle,
            label: 'Agregar nueva',
            onClick: () => navigate('/admin/campanas/nuevo')
        }
    ];

    const textColor = 'navy.700';
    const columnHelper = createColumnHelper<Campana>();
    const columns = [
        columnHelper.accessor('nombre', {
            id: 'nombre',
            header: crearHeader('Nombre'),
            cell: crearCelda()
        }),
        columnHelper.accessor('estado', {
            id: 'estado',
            header: crearHeader('estado'),
            cell: crearCelda((info: CellContext<any, any>) => {
                switch (info.row.original.estado) {
                    case 'activa':
                        return <Tag colorScheme={'green'}>activa</Tag>;
                    case 'draft':
                        return <Tag colorScheme={'yellow'}>draft</Tag>;
                    case 'finalizada':
                        return <Tag colorScheme={'blue'}>finalizada</Tag>;
                    default:
                        return <Tag>info.row.original.estado</Tag>;
                }
            })
        }),
        columnHelper.accessor('fecha_inicio', {
            id: 'fecha_inicio',
            header: crearHeader('Fecha Inicio'),
            cell: crearCelda()
        }),
        columnHelper.accessor('fecha_fin', {
            id: 'fecha_fin',
            header: crearHeader('Fecha Fin'),
            cell: crearCelda()
        }),
        columnHelper.accessor('id', {
            id: 'editar',
            header: crearHeader('Editar'),
            cell: crearCeldaConLink('/admin/campanas/editar', 'Editar', navigate)
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/campanas/ver', 'Ver campaña', navigate)
        })
    ];

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Card px="0px">
                <Listado tableData={campanasData} menuItems={menuItems} columns={columns} />
            </Card>
        </Flex>
    );
}
