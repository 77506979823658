import { Spinner, Box, Center } from '@chakra-ui/react';

const PageLoader = ({ message = 'Redirigiendo para autenticación...' }) => (
    <Center height="100vh">
        <Box textAlign="center">
            <Spinner size="xl" />
            <Box mt={4}>{message}</Box>
        </Box>
    </Center>
);

export default PageLoader;
