import {
    Box,
    Flex,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { MdNotificationsNone, MdNotificationsActive } from 'react-icons/md';
import { ItemContent } from '../menu/ItemContent';
import { useFetchData } from '../../hooks/useFetchData';
import { LoteSemillas } from '../../modules/trazabilidad/types/LoteSemilllas';
import { Notificacion } from '../../modules/notificaciones/types/Notificacion';
import { useNavigate } from 'react-router-dom';
import FilaNotificacion from './FilaNotificacion';
import CannavaApiService from '../../utils/CannavaApiService';
import { useAppContext } from '../../contexts/AppContext';

type PanelNotificacionesProps = {};

/*
 * PanelNotificaciones: Componente que muestra las notificaciones del usuario en un div flotante desde el navbar
 */
export default function PanelNotificaciones(props: PanelNotificacionesProps) {
    const { authToken, setFlashMessage, setFlashType } = useAppContext();
    const {
        data: notificacionesData,
        setData,
        isLoading
    } = useFetchData<Notificacion>('notificaciones');
    const navigate = useNavigate();

    // Chakra Color Mode
    // Estado para rastrear si hay notificaciones o no
    const [hasNotifications, setHasNotifications] = React.useState(true);

    // Icono y color basados en el estado de las notificaciones
    const navbarIcon = useColorModeValue('gray.400', 'white');

    let menuBg = useColorModeValue('white', 'navy.800');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const textColorBrand = useColorModeValue('brand.700', 'brand.400');

    const notificationIcon = hasNotifications ? MdNotificationsActive : MdNotificationsNone;
    const iconColor = hasNotifications ? textColorBrand : navbarIcon;

    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
    );

    // Función para marcar una notificación como leída
    const markAsRead = (id: number) => {
        console.log('marcamos como leida');

        // la actualizo en frontend
        const updatedNotifications = notificacionesData.map(notificacion =>
            notificacion.id === id ? { ...notificacion, seen: 1 } : notificacion
        );

        setData(updatedNotifications);

        // la actualizo en backend
        marcarComoVistaEnBackend(id);
    };

    const marcarComoVistaEnBackend = async (id: number) => {
        await CannavaApiService.request(
            'GET',
            '/notificaciones/marcar-como-vista/' + id.toString(),
            {},
            authToken,
            data => {
                // no hago nada
            },
            error => {
                //actualizarCampana(true);
            }
        );
    };

    // Verificar si hay notificaciones sin leer
    useEffect(() => {
        if (notificacionesData) {
            const hasUnseenNotifications = notificacionesData.some(
                notificacion => notificacion.seen === 0
            );
            setHasNotifications(hasUnseenNotifications);
        }
    }, [notificacionesData]);

    if (isLoading) {
        return <></>;
    }

    return (
        <Menu>
            <MenuButton p="0px">
                <Icon
                    mt="6px"
                    as={notificationIcon}
                    color={iconColor}
                    w="18px"
                    h="18px"
                    me="10px"
                />
            </MenuButton>
            <MenuList
                boxShadow={shadow}
                p="20px"
                borderRadius="20px"
                bg={menuBg}
                border="none"
                mt="22px"
                me={{ base: '30px', md: 'unset' }}
                minW={{ base: 'unset', md: '400px', xl: '450px' }}
                maxW={{ base: '360px', md: 'unset' }}
            >
                <Flex w="100%" mb="20px">
                    <Text fontSize="md" fontWeight="600" color={textColor}>
                        Notifications
                    </Text>
                    <Text
                        fontSize="sm"
                        fontWeight="500"
                        color={textColorBrand}
                        ms="auto"
                        cursor="pointer"
                    >
                        Mark all read
                    </Text>
                </Flex>
                {notificacionesData && notificacionesData.length > 0 ? (
                    <Flex flexDirection="column">
                        {notificacionesData.map((notificacion: Notificacion, index: number) => (
                            <FilaNotificacion
                                key={index}
                                notificacion={notificacion}
                                onClick={() => alert('vamo')}
                                marcarComoVista={markAsRead}
                            />
                        ))}
                    </Flex>
                ) : (
                    <Box>No tienes notificaciones</Box>
                )}
            </MenuList>
        </Menu>
    );
}
