import { createContext, useContext, ReactNode, useState } from 'react';

type BreadcrumbItem = {
    label: string;
    href: string;
};

type LayoutContextType = {
    breadcrumbs: BreadcrumbItem[];
    title: string;
    setBreadcrumbs: (items: BreadcrumbItem[]) => void;
    setTitle: (title: string) => void;
};

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export const useLayoutContext = () => {
    const context = useContext(LayoutContext);
    if (!context) {
        throw new Error('useLayoutContext must be used within a LayoutProvider');
    }
    return context;
};

export const LayoutProvider = ({ children }: { children: ReactNode }) => {
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
    const [title, setTitle] = useState<string>('');

    return (
        <LayoutContext.Provider value={{ breadcrumbs, title, setBreadcrumbs, setTitle }}>
            {children}
        </LayoutContext.Provider>
    );
};
