// Chakra imports
import { Flex, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';

import SearchTableLotesSemillas from './components/SearchTableLotesSemillas';

import React, { useEffect, useState } from 'react';
import { useLayoutContext } from 'contexts/LayoutContext';
import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import { useFetchData } from 'hooks/useFetchData';
import PageLoader from 'components/actions/PageLoader';
import { MenuItemProps } from 'types/MenuItemProps';
import { MdAddCircle } from 'react-icons/md';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import Listado, { crearCelda, crearCeldaConLink, crearHeader } from 'components/cannava/listado';
import { LotePoderGerminativo } from 'modules/trazabilidad/types/LotePoderGerminativo';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';

export default function LotesPoderGerminativo() {
    const transformLote = (data: any[]): LotePoderGerminativo[] => {
        return data;
    };

    const { data: lotesData, isLoading } = useFetchData<LotePoderGerminativo>(
        'lotesPoderGerminativo',
        transformLote
    );
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const navigate = useNavigate();

    const configurarNavegacion = useConfigurarNavegacion({
        breadcrumbs: [
            { label: 'Inicio', href: '/admin/home/' },
            { label: 'Trazabilidad', href: '/admin/home/' },
            { label: 'Lotes de poder germinativo', href: '/admin/lotes-poder-germinativo/' }
        ],
        titulo: 'Lotes de poder germinativo'
    });

    const menuItems: MenuItemProps[] = [
        /*{
            icono: MdAddCircle,
            label: 'Agregar nuevo',
            onClick: () => navigate('/admin/lotes-semillas/nuevo')
        }*/
    ];

    const columnHelper = createColumnHelper<LotePoderGerminativo>();
    const columns = [
        columnHelper.accessor('codigo_lote', {
            id: 'codigo_lote',
            header: crearHeader('ID Lote'),
            cell: crearCelda()
        }),
        columnHelper.accessor('lote_semillas_id', {
            id: 'lote_semilla_id',
            header: crearHeader('Lote Semillas'),
            cell: crearCelda((info: CellContext<any, any>) => {
                return info.row.original.loteSemillas.codigo_lote;
            })
        }),

        columnHelper.accessor('estado', {
            id: 'estado',
            header: crearHeader('Estado'),
            cell: crearCelda()
        }),
        columnHelper.accessor('fecha_finalizacion', {
            id: 'fecha_finalizacion',
            header: crearHeader('Fecha finalización'),
            cell: crearCelda()
        }),
        /*columnHelper.accessor('id', {
            id: 'editar',
            header: crearHeader("Acciones"),
            cell: crearCeldaConLink("/admin/lotes-semillas/editar", "Editar", navigate)
        }),*/
        columnHelper.accessor('id', {
            id: 'id',
            header: crearHeader('Acciones'),
            cell: crearCeldaConLink('/admin/lotes-poder-germinativo/ver', 'Ver lote', navigate)
        })
    ];

    if (isLoading) {
        return <PageLoader message={'Cargando lotes'} />;
    }

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Card px="0px">
                <Listado tableData={lotesData} menuItems={menuItems} columns={columns} />
            </Card>
        </Flex>
    );
}
