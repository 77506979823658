import React, { useState, useEffect } from 'react';
import { LoteGerminacion } from 'modules/trazabilidad/types/LoteGerminacion';
import { Box, Button, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import Card from 'components/card/Card';
import { FilaInfoRegistro } from '../../verLote/components/FilaInfoRegistro';
import { useNavigate } from 'react-router-dom';
// Custom hooks
import { useAutenticacionUsuario } from '../../../../../../modules/auth/hooks/useAutenticacionUsuario';

type CardControlPlantineraProps = {
    lote: LoteGerminacion;
};

export default function CardControlPlantinera(props: CardControlPlantineraProps) {
    const { lote } = props;

    // Gestion de accesos por componente
    const [permisosAcceso, setPermisosAcceso] = useState<Record<string, boolean>>({});
    const { obtenerPermisos } = useAutenticacionUsuario();

    useEffect(() => {
        const permisos = [
            {
                key: 'selectorMonitoreos',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            },
            {
                key: 'botonFinalizarPlantinera',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            }
        ];
        const nuevosPermisos = obtenerPermisos(permisos);
        setPermisosAcceso(nuevosPermisos);
    }, [obtenerPermisos]);

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const estadosValidos = [
        'plantinera-iniciada',
        'plantinera-monitoreada',
        'plantinera-finalizada'
    ];
    const navigate = useNavigate();

    const generarCodigoReporte = (num: number) => {
        let strNum = num.toString();
        return strNum.padStart(4, '0');
    };

    const finalizarPlantinera = () => {
        navigate('/admin/lotes-germinacion/finalizar-plantinera/' + lote.id);
    };

    const controlCalidad = () => {
        navigate('/admin/lotes-germinacion/informe-calidad/' + lote.id);
    };

    const monitoreoPlantines = () => {
        navigate('/admin/lotes-germinacion/monitoreo-plantines/' + lote.id);
    };

    const monitoreoPlagas = () => {
        navigate('/admin/lotes-germinacion/monitoreo-plagas/' + lote.id);
    };

    const monitoreoEnfermedades = () => {
        navigate('/admin/lotes-germinacion/monitoreo-enfermedades/' + lote.id);
    };

    const verReportePlantinera = () => {
        console.log('this', lote);
        navigate('/admin/lotes-germinacion/informe-control-plantinera/' + lote.plantinera.id);
    };

    if (!estadosValidos.includes(lote.estado)) return <></>;

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center" mb="5">
                <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                    Plantinera
                </Text>
                {lote.estado !== 'plantinera-finalizada' &&
                    (permisosAcceso.selectorMonitoreos ||
                        permisosAcceso.botonFinalizarPlantinera) && (
                        <Flex gap={2}>
                            {permisosAcceso.selectorMonitoreos && (
                                <Menu>
                                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                        Agregar monitoreo
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem onClick={monitoreoPlagas}>
                                            Monitoreo de plagas
                                        </MenuItem>
                                        <MenuItem onClick={monitoreoEnfermedades}>
                                            Monitoreo de enfermedades
                                        </MenuItem>
                                        <MenuItem onClick={monitoreoPlantines}>
                                            Monitoreo de plantinera
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            )}
                            {permisosAcceso.botonFinalizarPlantinera && (
                                <Button onClick={finalizarPlantinera} colorScheme="green">
                                    Finalizar plantinera
                                </Button>
                            )}
                            {/*<Button colorScheme="green">*/}
                            {/*    Iniciar conciliación*/}
                            {/*</Button>*/}
                            {/*<Button onClick={controlCalidad} colorScheme="green">*/}
                            {/*    Control de calidad*/}
                            {/*</Button>*/}
                        </Flex>
                    )}
            </Flex>

            <FilaInfoRegistro
                label={'Nro. de control'}
                value={generarCodigoReporte(lote.plantinera.id)}
                click={verReportePlantinera}
            />
        </Card>
    );
}
