// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormErrorMessage,
    FormLabel,
    FormControl,
    useColorModeValue,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
    AlertTitle,
    useDisclosure,
    Checkbox
} from '@chakra-ui/react';
// Custom components
import Card from '../../../../../components/card/Card';
import InputField from '../../../../../components/fields/InputField';
import TextField from '../../../../../components/fields/TextField';
import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from '../../../../../config';
import axios, { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaApiService from '../../../../../utils/CannavaApiService';
import { useAppContext } from '../../../../../contexts/AppContext';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaInputField from '../../../../../components/fields/CannavaInputField';
import PageLoader from '../../../../../components/actions/PageLoader';
import DangerZone from './DangerZone';
import { Usuario } from '../../../types/Usuario';
import { useLayoutContext } from '../../../../../contexts/LayoutContext';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { LoteSemillas } from '../../../../trazabilidad/types/LoteSemilllas';
import { Area } from '../../../types/Area';

export default function NewUser() {
    // region Variables

    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const isEditMode = !!id;
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = useState({
        general: true,
        permissions: false
    });

    const [isDeleting, setIsDeleting] = useState(false);

    const navigate = useNavigate();
    const generalTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const permissionsTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const { setFlashMessage, setFlashType } = useAppContext();
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);
    const [cargandoData, setCargandoData] = useState(false);
    const { setBreadcrumbs, setTitle } = useLayoutContext();

    const mb: string = '0px';
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    // endregion

    // region Hooks y callbacks

    const { data: areasData, isLoading } = useFetchData<Area>('areas');

    const handleDeleteUser = () => {
        CannavaApiService.usuarioEliminar(
            parseInt(id),
            authToken,
            data => {
                // Setear los valores en los campos del formulario
                setFlashMessage('Se eliminó el usuario correctamente!');
                setFlashType('success');
                navigate('/admin/usuarios');
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al eliminar el usuario.');
                setFlashType('error');
                navigate('/admin/usuarios');
            }
        );
    };

    const fetchUserData = async (userId: number) => {
        setCargandoData(true);
        CannavaApiService.usuarioObtener(
            userId,
            authToken,
            data => {
                // Setear los valores en los campos del formulario
                methods.setValue('nombre', data.nombre);
                methods.setValue('apellido', data.apellido);
                methods.setValue('email', data.email);
                methods.setValue('nombre_usuario', data.nombre_usuario);
                methods.setValue('rol', data.rol);
                methods.setValue('fecha_nacimiento', data.fecha_nacimiento);

                let areasIds: number[] = [];

                // Marcar las áreas seleccionadas
                if (data.areas && Array.isArray(data.areas)) {
                    data.areas.forEach((area: Area) => {
                        methods.setValue(`areasIds.${area.id}`, true);
                    });
                }

                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/usuarios');
            }
        );
    };

    // Función para manejar el envío del formulario
    const envioFormulario = async (data: Usuario) => {
        // Validación simple
        console.log(data.areasIds);
        let areasSeleccionadas: number[] = [];

        Object.keys(data.areasIds).forEach(function (key: any) {
            if (data.areasIds[key]) {
                areasSeleccionadas.push(key);
            }
        });

        // Preparar los datos para enviar al backend
        const formData = {
            nombre: data.nombre,
            apellido: data.apellido,
            email: data.email,
            nombre_usuario: data.nombre_usuario,
            rol: data.rol,
            areasIds: areasSeleccionadas,
            fecha_nacimiento: data.fecha_nacimiento,
            password: '12345678' // no se usa
        };

        setEnviandoFormulario(true);
        CannavaApiService.usuarioCrear(
            formData,
            authToken,
            data => {
                setFlashMessage('Se guardó el usuario exitosamente!');
                setFlashType('success');
                navigate('/admin/usuarios');

                setEnviandoFormulario(false);
            },
            (error: any) => {
                if (error.response.status == 422) {
                    console.log(error.response.data);
                    for (let i = 0; i < error.response.data.length; i++) {
                        const errorValidacion: any = error.response.data[i];
                        methods.setError(errorValidacion.field, {
                            message: errorValidacion.message
                        });
                    }
                } else {
                    alert('Error desconocido');
                    console.log(error);
                }
                setEnviandoFormulario(false);
            }
        );
    };

    const envioFormularioUpdate = async (data: Usuario) => {
        // Preparar los datos para enviar al backend
        console.log(data.areasIds);
        let areasSeleccionadas: number[] = [];

        Object.keys(data.areasIds).forEach(function (key: any) {
            if (data.areasIds[key]) {
                areasSeleccionadas.push(key);
            }
        });

        const formData = {
            nombre: data.nombre,
            apellido: data.apellido,
            email: data.email,
            nombre_usuario: data.nombre_usuario,
            areasIds: areasSeleccionadas,
            rol: data.rol,
            fecha_nacimiento: data.fecha_nacimiento
        };

        setEnviandoFormulario(true);

        // Asumiendo que el endpoint para actualizar es '/auth/api/usuarios/update/{id}'
        // y que el método es PUT
        CannavaApiService.usuarioActualizar(
            parseInt(id),
            formData,
            authToken,
            data => {
                setFlashMessage('Se guardó el usuario exitosamente!');
                setFlashType('success');
                navigate('/admin/usuarios');

                setEnviandoFormulario(false);
            },
            (error: any) => {
                if (error.response.status == 422) {
                    console.log(error.response.data);
                    for (let i = 0; i < error.response.data.length; i++) {
                        const errorValidacion: any = error.response.data[i];
                        methods.setError(errorValidacion.field, {
                            message: errorValidacion.message
                        });
                    }
                } else {
                    alert('Error desconocido');
                    console.log(error);
                }
                setEnviandoFormulario(false);
            }
        );
    };

    const onSubmit: SubmitHandler<Usuario> = (data: Usuario) => {
        if (isEditMode) {
            envioFormularioUpdate(data);
            return;
        }

        envioFormulario(data);
    };

    // endregion

    // region useEffect

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', href: '/' },
            { label: 'Administración', href: '/admin/home/' },
            { label: 'Usuarios', href: '/admin/usuarios/' }
        ]);

        if (!isEditMode) {
            setTitle('Nuevo usuario');
        } else {
            setTitle('Editar usuario');
        }
    }, []);

    useEffect(() => {
        if (enviandoFormulario) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            // Error callback
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    useEffect(() => {
        console.log('cargo el use effect por id');
        if (id) {
            fetchUserData(parseInt(id));
        }
    }, [id]);

    // endregion

    if (enviandoFormulario) {
        return <PageLoader message="Creando usuario..." />;
    }

    if (isEditMode && cargandoData) {
        return <PageLoader message="Cargando datos de usuario..." />;
    }

    return (
        <>
            {!isDeleting && (
                <Flex
                    direction="column"
                    minH="100vh"
                    align="center"
                    pt={{ sm: '125px', lg: '75px' }}
                    position="relative"
                >
                    <Box
                        h="45vh"
                        bgGradient="linear(to-b, brand.400, brand.600)"
                        position="absolute"
                        w="100%"
                        borderRadius="20px"
                    />

                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Tabs
                                variant="unstyled"
                                zIndex="0"
                                mt={{ base: '60px', md: '165px' }}
                                display="flex"
                                flexDirection="column"
                            >
                                <TabList
                                    display="flex"
                                    alignItems="center"
                                    alignSelf="center"
                                    justifySelf="center"
                                >
                                    <Tab
                                        _focus={{ border: '0px', boxShadow: 'unset' }}
                                        ref={generalTab}
                                        w={{ sm: '120px', md: '250px', lg: '300px' }}
                                        onClick={() =>
                                            setActiveBullets({
                                                general: true,
                                                permissions: false
                                            })
                                        }
                                    >
                                        <Flex
                                            direction="column"
                                            justify="center"
                                            align="center"
                                            position="relative"
                                            _before={{
                                                content: "''",
                                                width: { sm: '120px', md: '250px', lg: '300px' },
                                                height: '3px',
                                                bg: activeBullets.permissions
                                                    ? 'white'
                                                    : 'brand.400',
                                                left: { sm: '12px', md: '80px' },
                                                top: {
                                                    sm: activeBullets.general ? '6px' : '4px',
                                                    md: null
                                                },
                                                position: 'absolute',
                                                bottom: activeBullets.general ? '40px' : '38px',

                                                transition: 'all .3s ease'
                                            }}
                                        >
                                            <Box
                                                zIndex="1"
                                                border="2px solid"
                                                borderColor={
                                                    activeBullets.general ? 'white' : 'brand.400'
                                                }
                                                bgGradient="linear(to-b, brand.400, brand.600)"
                                                w="16px"
                                                h="16px"
                                                mb="8px"
                                                borderRadius="50%"
                                            />
                                            <Text
                                                color={activeBullets.general ? 'white' : 'gray.300'}
                                                fontWeight={
                                                    activeBullets.general ? 'bold' : 'normal'
                                                }
                                                display={{ sm: 'none', md: 'block' }}
                                            >
                                                Información general
                                            </Text>
                                        </Flex>
                                    </Tab>
                                    <Tab
                                        _focus={{ border: '0px', boxShadow: 'unset' }}
                                        ref={permissionsTab}
                                        w={{ sm: '120px', md: '250px', lg: '300px' }}
                                        onClick={() =>
                                            setActiveBullets({
                                                general: true,
                                                permissions: true
                                            })
                                        }
                                    >
                                        <Flex
                                            direction="column"
                                            justify="center"
                                            align="center"
                                            position="relative"
                                        >
                                            <Box
                                                zIndex="1"
                                                border="2px solid"
                                                borderColor={
                                                    activeBullets.permissions
                                                        ? 'white'
                                                        : 'brand.400'
                                                }
                                                bgGradient="linear(to-b, brand.400, brand.600)"
                                                w="16px"
                                                h="16px"
                                                mb="8px"
                                                borderRadius="50%"
                                            />
                                            <Text
                                                color={
                                                    activeBullets.permissions ? 'white' : 'gray.300'
                                                }
                                                fontWeight={
                                                    activeBullets.permissions ? 'bold' : 'normal'
                                                }
                                                display={{ sm: 'none', md: 'block' }}
                                            >
                                                Permisos
                                            </Text>
                                        </Flex>
                                    </Tab>
                                </TabList>
                                <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
                                    <TabPanel
                                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                                        p="0px"
                                        mx="auto"
                                    >
                                        <Card p="30px">
                                            <Text
                                                color={textColor}
                                                fontSize="2xl"
                                                fontWeight="700"
                                                mb="20px"
                                            >
                                                Información general
                                            </Text>
                                            {errorMessage && (
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    <AlertTitle>{errorMessage}</AlertTitle>
                                                </Alert>
                                            )}
                                            <Flex direction="column" w="100%">
                                                <Stack direction="column" spacing="20px">
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaInputField
                                                            id={'nombre'}
                                                            label={'Nombre'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'apellido'}
                                                            label={'Apellido'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'email'}
                                                            label={'Email'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'nombre_usuario'}
                                                            label={'Nombre de usuario'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />

                                                        <CannavaInputField
                                                            id={'fecha_nacimiento'}
                                                            label={'Fecha de nacimiento'}
                                                        />
                                                    </SimpleGrid>
                                                </Stack>
                                                <Flex justify="space-between" mt="24px">
                                                    <Button
                                                        variant="darkBrand"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        ms="auto"
                                                        onClick={() => {
                                                            permissionsTab.current.click();
                                                        }}
                                                    >
                                                        Siguiente
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    </TabPanel>
                                    <TabPanel
                                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                                        p="0px"
                                        mx="auto"
                                    >
                                        <Card p="30px">
                                            <Text
                                                color={textColor}
                                                fontSize="2xl"
                                                fontWeight="700"
                                                mb="20px"
                                            >
                                                Permisos
                                            </Text>
                                            {errorMessage && (
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    <AlertTitle>{errorMessage}</AlertTitle>
                                                </Alert>
                                            )}
                                            <Flex direction="column" w="100%">
                                                <Stack direction="column" spacing="20px">
                                                    <FormControl
                                                        isInvalid={
                                                            'rol' in methods.formState.errors
                                                        }
                                                    >
                                                        <FormLabel
                                                            display="flex"
                                                            ms="10px"
                                                            fontSize="sm"
                                                            color={textColorPrimary}
                                                            fontWeight="bold"
                                                            _hover={{ cursor: 'pointer' }}
                                                            htmlFor="rol"
                                                        >
                                                            Rol
                                                        </FormLabel>
                                                        <Select
                                                            mb="0px"
                                                            id="rol"
                                                            placeholder="Seleccione un rol"
                                                            {...methods.register('rol', {
                                                                required: 'Campo obligatorio'
                                                            })}
                                                        >
                                                            <option value="usuario-basico">
                                                                Usuario
                                                            </option>
                                                            <option value="administrador">
                                                                Administrador
                                                            </option>
                                                            <option value="super-administrador">
                                                                Super Administrador
                                                            </option>
                                                            {/* ... otros roles que necesites */}
                                                        </Select>

                                                        <FormErrorMessage>
                                                            {'rol' in methods.formState.errors &&
                                                                (methods.formState.errors as any)
                                                                    .rol.message}
                                                        </FormErrorMessage>
                                                    </FormControl>

                                                    <FormControl>
                                                        <FormLabel
                                                            display="flex"
                                                            ms="10px"
                                                            fontSize="sm"
                                                            color={textColorPrimary}
                                                            fontWeight="bold"
                                                            _hover={{ cursor: 'pointer' }}
                                                            htmlFor="areas"
                                                        >
                                                            Áreas
                                                        </FormLabel>
                                                        <Flex
                                                            direction={['column']}
                                                            rowGap={4}
                                                            wrap={'wrap'}
                                                        >
                                                            {areasData &&
                                                                areasData.length > 0 &&
                                                                areasData.map((area: Area) => (
                                                                    <Checkbox
                                                                        {...methods.register(
                                                                            `areasIds.${area.id}`
                                                                        )}
                                                                        key={area.id}
                                                                        value={area.id}
                                                                    >
                                                                        <Text
                                                                            fontWeight={700}
                                                                            display={'inline-block'}
                                                                            mr={1}
                                                                        >
                                                                            {area.nombre}:
                                                                        </Text>
                                                                        {area.descripcion}
                                                                    </Checkbox>
                                                                ))}
                                                        </Flex>
                                                    </FormControl>
                                                </Stack>

                                                <Flex justify="space-between" mt="24px">
                                                    <Button
                                                        variant="light"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        onClick={() => generalTab.current.click()}
                                                    >
                                                        Anterior
                                                    </Button>
                                                    <Button
                                                        variant="darkBrand"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '128px', md: '148px' }}
                                                        h="46px"
                                                        isLoading={methods.formState.isSubmitting}
                                                        type="submit"
                                                    >
                                                        Enviar
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </form>
                    </FormProvider>
                    {/* Danger Zone */}
                    {isEditMode && (
                        <DangerZone
                            onConfirmDelete={() => {
                                setIsDeleting(true);
                                handleDeleteUser();
                            }}
                        />
                    )}
                </Flex>
            )}

            {isDeleting && <PageLoader message="Eliminando usuario..." />}
        </>
    );
}
