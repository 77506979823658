import { LoteSemillas } from 'modules/trazabilidad/types/LoteSemilllas';
import { Flex, SimpleGrid, Text, useColorModeValue, Divider, Button, Box } from '@chakra-ui/react';
import Card from 'components/card/Card';
import React, { useState, useEffect } from 'react';
import { FilaInfoRegistro } from './FilaInfoRegistro';
import { useNavigate } from 'react-router-dom';
import { LoteGerminacion } from 'modules/trazabilidad/types/LoteGerminacion';
// Custom hooks
import { useAutenticacionUsuario } from '../../../../../../modules/auth/hooks/useAutenticacionUsuario';

type InfoLoteProps = {
    loteData: LoteGerminacion;
    mostrarLoteSemillas?: boolean;
    estado: any;
    loteAprobado: CallableFunction;
    loteEntregado: CallableFunction;
    rechazarLote: CallableFunction;
    recibirLote: CallableFunction;
    actualizarPantalla: CallableFunction;
    enviarLoteAPlantinera: CallableFunction;
    recibirLoteAPlantinera: CallableFunction;
};

export default function InfoLote(props: InfoLoteProps /* : Props */) {
    const {
        loteData,
        mostrarLoteSemillas,
        estado,
        loteAprobado,
        loteEntregado,
        rechazarLote,
        recibirLote,
        actualizarPantalla,
        enviarLoteAPlantinera,
        recibirLoteAPlantinera
    } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const navigate = useNavigate();

    // Gestion de accesos por componente
    const [permisosAcceso, setPermisosAcceso] = useState<Record<string, boolean>>({});
    const { obtenerPermisos } = useAutenticacionUsuario();

    useEffect(() => {
        const permisos = [
            {
                key: 'botonAprobarLote',
                roles: ['usuario-basico', 'administrador'],
                areas: ['trazabilidad']
            },
            {
                key: 'botonEntregarSemillas',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            },
            {
                key: 'botonRecibirSemillas',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            },
            {
                key: 'botonIniciarSiembra',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            },
            {
                key: 'botonPrepararBandejas',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            },
            {
                key: 'botonFinalizarBandejas',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            },
            {
                key: 'botonIniciarSemillado',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            },
            {
                key: 'botonFinalizarSemillado',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            },
            {
                key: 'botonIniciarGerminacion',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            },
            {
                key: 'botonControlAmbiental',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            },
            {
                key: 'botonFinalizarGerminacion',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            },
            {
                key: 'botonEnviarAPlantinera',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            },
            {
                key: 'botonRecibirLote',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            },
            {
                key: 'botonIniciarLimpiezaArea',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            },
            {
                key: 'botonIniciarPlantinera',
                roles: ['usuario-basico', 'administrador'],
                areas: ['campo']
            }
        ];
        const nuevosPermisos = obtenerPermisos(permisos);
        setPermisosAcceso(nuevosPermisos);
    }, [obtenerPermisos]);

    // create a botonesOpcion array with useState
    const [botonesOpcion, setBotonesOpcion] = useState([]);

    useEffect(() => {
        let opcionesPosibles = [
            { id: 1, nombre: 'Rechazar Lote', mostrar: false },
            { id: 2, nombre: 'Aprobar Lote', mostrar: false },
            { id: 3, nombre: 'Entregar semillas', mostrar: false },
            { id: 4, nombre: 'Semillas recibidas', mostrar: false },
            { id: 5, nombre: 'Iniciar siembra', mostrar: false },
            { id: 6, nombre: 'Iniciar plantinera', mostrar: false },
            { id: 7, nombre: 'Iniciar limpieza de área', mostrar: false }
        ];

        let opciones = [];

        switch (loteData.estado) {
            case 'sin-aprobacion':
                if (permisosAcceso.botonAprobarLote) {
                    opciones.push({
                        nombre: 'Aprobar Lote',
                        callback: aprobacionLote
                    });
                    opciones.push({
                        nombre: 'Rechazar Lote',
                        callback: rechazarLote
                    });
                }
                break;
            case 'aprobado':
                if (permisosAcceso.botonEntregarSemillas) {
                    opciones.push({
                        nombre: 'Entregar semillas',
                        callback: entregaLote
                    });
                }
                break;
            case 'entregado':
                if (permisosAcceso.botonRecibirSemillas) {
                    opciones.push({
                        nombre: 'Semillas recibidas',
                        callback: recibirLote
                    });
                }
                break;
            case 'recibido':
                if (permisosAcceso.botonIniciarSiembra) {
                    opciones.push({
                        nombre: 'Iniciar limpieza de área',
                        callback: () => {
                            navigate('/admin/lotes-germinacion/limpieza-area/' + loteData.id);
                        }
                    });
                    opciones.push({
                        nombre: 'Iniciar siembra',
                        callback: () => {
                            navigate('/admin/lotes-germinacion/iniciar-siembra/' + loteData.id);
                        }
                    });
                }
                break;
            case 'siembra-iniciada':
                if (permisosAcceso.botonPrepararBandejas) {
                    opciones.push({
                        nombre: 'Preparar bandejas',
                        callback: () => {
                            navigate('/admin/lotes-germinacion/preparar-bandejas/' + loteData.id);
                        }
                    });
                }
                break;
            case 'bandejas-preparadas':
                if (permisosAcceso.botonFinalizarBandejas) {
                    opciones.push({
                        nombre: 'Finalizar bandejas',
                        callback: () => {
                            navigate('/admin/lotes-germinacion/finalizar-bandejas/' + loteData.id);
                        }
                    });
                }
                break;
            case 'bandejas-finalizadas':
                if (permisosAcceso.botonIniciarSemillado) {
                    opciones.push({
                        nombre: 'Iniciar semillado',
                        callback: () => {
                            navigate('/admin/lotes-germinacion/iniciar-semillado/' + loteData.id);
                        }
                    });
                }
                break;
            case 'semillado-iniciado':
                if (permisosAcceso.botonFinalizarSemillado) {
                    opciones.push({
                        nombre: 'Finalizar semillado',
                        callback: () => {
                            navigate('/admin/lotes-germinacion/finalizar-semillado/' + loteData.id);
                        }
                    });
                }
                break;
            case 'semillado-finalizado':
                if (permisosAcceso.botonIniciarGerminacion) {
                    opciones.push({
                        nombre: 'Iniciar germinación',
                        callback: () => {
                            navigate('/admin/lotes-germinacion/iniciar-germinacion/' + loteData.id);
                        }
                    });
                }
                break;
            case 'germinacion-iniciada':
                if (permisosAcceso.botonControlAmbiental) {
                    opciones.push({
                        nombre: 'Control Ambiental',
                        callback: () => {
                            navigate(
                                '/admin/lotes-germinacion/nuevo-control-ambiental/' + loteData.id
                            );
                        }
                    });
                }
                if (permisosAcceso.botonFinalizarGerminacion) {
                    opciones.push({
                        nombre: 'Finalizar germinación',
                        callback: () => {
                            navigate(
                                '/admin/lotes-germinacion/finalizar-germinacion/' + loteData.id
                            );
                        }
                    });
                }
                break;
            case 'germinacion-finalizada':
                if (permisosAcceso.botonEnviarAPlantinera) {
                    opciones.push({
                        nombre: 'Enviar a plantinera',
                        callback: enviarLoteAPlantinera
                    });
                }
                break;
            case 'enviado-a-plantinera':
                if (permisosAcceso.botonRecibirLote) {
                    opciones.push({
                        nombre: 'Recibir lote',
                        callback: recibirLoteAPlantinera
                    });
                }
                break;
            case 'plantinera-recibida':
                if (permisosAcceso.botonIniciarLimpiezaArea) {
                    opciones.push({
                        nombre: 'Iniciar limpieza de área'
                        /* callback: rechazarLote */
                    });
                }
                if (permisosAcceso.botonIniciarPlantinera) {
                    opciones.push({
                        nombre: 'Iniciar plantinera',
                        callback: () => {
                            navigate('/admin/lotes-germinacion/iniciar-plantinera/' + loteData.id);
                        }
                    });
                }
                break;
        }

        setBotonesOpcion(opciones);
    }, [loteData, permisosAcceso]);

    const aprobacionLote = () => {
        loteAprobado();
    };

    const entregaLote = () => {
        loteEntregado();
    };

    //Agrego lógica para que no rompa si no encuentra el nombre de la ubicacion ya que el 'type InfoLoteProps' no tiene la propiedad 'locacion_name'
    const ubicacion = loteData.loteSemillas.establecimiento.locaciones.find(
        locacion => locacion.id === loteData.locacion_id
    );
    const ubicacionNombre = ubicacion ? ubicacion.nombre : 'Ubicación no encontrada';

    return (
        <Card p="30px">
            <Flex justify="space-between" align="center" mb="5">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Información de lote
                </Text>
                <Box>
                    {botonesOpcion.length > 0 &&
                        botonesOpcion.map((boton: any, index) => (
                            <Button colorScheme="green" m="1" onClick={boton.callback} key={index}>
                                {boton.nombre}
                            </Button>
                        ))}
                </Box>
            </Flex>
            <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing={{ base: '20px', xl: '5px' }}>
                <FilaInfoRegistro label={'Estado'} value={loteData.estado} />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro label={'Nº de lote asignado'} value={loteData.codigo_lote} />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Lote de procedencia'}
                    value={loteData.loteSemillas.codigo_lote}
                    click={() => {
                        navigate('/admin/lotes-semillas/ver/' + loteData.loteSemillas.id);
                    }}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro label={'Fecha de siembra'} value={loteData.fecha_siembra} />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro label={'Tipo'} value={loteData.tipo} />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de bandejas estimadas'}
                    value={loteData.bandejas_estimadas}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Cantidad de semillas solicitadas'}
                    value={loteData.cantidad_semillas}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Nº de orden de siembra'}
                    value={loteData.orden_siembra_id}
                />
                <Divider border="1px" borderColor="lightgray" />
                {loteData.lote_poder_germinativo_id && (
                        <FilaInfoRegistro
                            label={'Nº de análisis de semilla'}
                            value={loteData.lote_poder_germinativo_id}
                        />
                    ) && <Divider border="1px" borderColor="lightgray" />}

                <FilaInfoRegistro
                    label={'Ubicación'}
                    value={ubicacionNombre}
                    click={() => {
                        navigate('/admin/ubicaciones/ver/' + loteData.locacion_id);
                    }}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro
                    label={'Sector'}
                    value={loteData.loteSemillas.establecimiento.nombre}
                    click={() => {
                        navigate('/admin/establecimientos/ver/' + loteData.establecimiento_id);
                    }}
                />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro label={'Tipo de siembra'} value={loteData.tipo_siembra} />
                <Divider border="1px" borderColor="lightgray" />
                <FilaInfoRegistro label={'Uso de lote'} value={loteData.tipo_uso} />
            </SimpleGrid>
        </Card>
    );
}
