/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from 'react';

// Chakra imports
import { Box, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import CenteredAuth from 'layouts/auth/variants/Centered';

function UsuarioInvalidoCentered() {
    // Chakra color mode
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    // const [show, setShow] = React.useState(false);
    // const handleClick = () => setShow(!show);
    return (
        <CenteredAuth
            cardTop={{ base: '140px', md: '14vh' }}
            cardBottom={{ base: '50px', lg: '100px' }}
        >
            <Flex
                maxW="max-content"
                mx={{ base: 'auto', lg: '0px' }}
                me="auto"
                justifyContent="center"
                px={{ base: '20px', md: '0px' }}
                flexDirection="column"
            >
                <Box me="auto">
                    <Heading color={textColor} fontSize={{ base: '34px', lg: '36px' }} mb="10px">
                        Tu usuario no es válido
                    </Heading>
                    <Text
                        mb="36px"
                        ms="4px"
                        color={textColorSecondary}
                        fontWeight="400"
                        fontSize="md"
                    >
                        Revisá el correo con el que fuiste agregado sistema y volvé a intentar.
                    </Text>
                </Box>
            </Flex>
        </CenteredAuth>
    );
}

export default UsuarioInvalidoCentered;
