import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CannavaApiService from 'utils/CannavaApiService';
import { Variedad } from '../modules/genetica/types/Variedad';
import { useAppContext } from 'contexts/AppContext';

type TransformFunction<T> = (data: any) => T[];

export const useFetchData = <T>(
    modelo: string,
    transform?: TransformFunction<T>,
    filterParams: [string, string][] | null = null
) => {
    const { authToken } = useAppContext();
    const [data, setData] = useState<T[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { isAuthenticated } = useAuth0();

    const fetchData = useCallback(async () => {
        if (authToken === null) {
            return;
        }

        setIsLoading(true);

        let onSuccess = (fetchedData: any) => {
            const adaptedData = transform ? transform(fetchedData) : fetchedData;
            setData(adaptedData as T[]);
            setIsLoading(false);
        };

        // @ts-ignore
        let onError = error => {
            setIsLoading(false);

            // console.log('error', error);
            if (error.response.status === 401) {
                alert('No estás logueado');
            } else if (error.response.status === 403) {
                alert('No tienes permisos para realizar esta acción');
            }
        };

        switch (modelo) {
            case 'productos':
                await CannavaApiService.productosObtenerTodos(authToken, onSuccess, onError);
                break;
            case 'insumos':
                await CannavaApiService.insumosObtenerTodos(authToken, onSuccess, onError);
                break;
            case 'usuarios':
                await CannavaApiService.usuarioObtenerTodos(authToken, onSuccess, onError);
                break;
            case 'establecimientos':
                await CannavaApiService.establecimientosObtenerTodos(authToken, onSuccess, onError);
                break;
            case 'variedades':
                await CannavaApiService.variedadesObtenerTodos(authToken, onSuccess, onError);
                break;
            case 'ubicaciones':
                await CannavaApiService.ubicacionesObtenerTodos(authToken, onSuccess, onError);
                break;
            case 'proveedores':
                await CannavaApiService.proveedoresObtenerTodos(authToken, onSuccess, onError);
                break;
            case 'areas':
                await CannavaApiService.areaObtenerTodas(authToken, onSuccess, onError);
                break;
            case 'cannabinoides':
                await CannavaApiService.cannabinoidesObtenerTodos(authToken, onSuccess, onError);
                break;
            case 'lotesSemillas':
                await CannavaApiService.lotesSemillasObtenerTodos(
                    authToken,
                    onSuccess,
                    onError,
                    filterParams ? filterParams[0] : null
                );
                break;
            case 'lotesGerminacion':
                await CannavaApiService.lotesGerminacionObtenerTodos(
                    authToken,
                    onSuccess,
                    onError,
                    filterParams ? filterParams[0] : null
                );
                break;
            case 'lotesPoderGerminativo':
                await CannavaApiService.lotesPoderGerminativoObtenerTodos(
                    authToken,
                    onSuccess,
                    onError,
                    filterParams ? filterParams[0] : null
                );
                break;
            case 'lotesPlantaProductiva':
                await CannavaApiService.lotesPlantaProductivaObtenerTodos(
                    authToken,
                    onSuccess,
                    onError,
                    filterParams ? filterParams[0] : null
                );
                break;
            case 'trasplantesLoteProductivo':
                await CannavaApiService.trasplantesPlantaProductivaObtenerTodos(
                    authToken,
                    onSuccess,
                    onError,
                    filterParams ? filterParams[0] : null
                );
                break;
            case 'campanas':
                await CannavaApiService.campanasObtenerTodos(authToken, onSuccess, onError);
                break;
            case 'inventarios':
                await CannavaApiService.inventariosObtenerTodos(
                    authToken,
                    onSuccess,
                    onError,
                    filterParams ? filterParams[0] : null
                );
                break;
            case 'anotaciones':
                await CannavaApiService.anotacionesObtenerTodos(
                    authToken,
                    onSuccess,
                    onError,
                    filterParams ? [filterParams[0], filterParams[1]] : null
                );
                break;
            case 'notificaciones':
                await CannavaApiService.notificacionesObtenerTodas(
                    authToken,
                    onSuccess,
                    onError,
                    filterParams ? filterParams[0] : null
                );
                break;
            default:
                alert('modelo invalido');
                return;
        }
    }, [authToken, modelo, filterParams]);

    useEffect(() => {
        if (isAuthenticated) {
            fetchData();
        }
    }, [isAuthenticated, authToken]);

    return { data, isLoading, refetch: fetchData, setIsLoading, setData };
};
