import { useLayoutContext } from '../contexts/LayoutContext';
import { useEffect } from 'react';

type ConfigurarNavegacionProps = {
    breadcrumbs: any[] | CallableFunction;
    titulo: string | CallableFunction;
};

/**
 * Hook que se encarga de configurar los elementos de navegacion tales como:
 *  + breadcrumbs
 *  + titulo
 *
 * El formato de los breadcrumbs es el siguiente:
 * ```
 *      [
 *         {label: 'Inicio', href: '/'},
 *         {label: 'Planificación', href: '/admin/campanas/'},
 *         {label: 'Campañas', href: '/admin/campanas'},
 *     ]
 * ```
 *
 * @param props
 */
export const useConfigurarNavegacion = (props: ConfigurarNavegacionProps, data?: any) => {
    const { breadcrumbs, titulo } = props;
    const { setBreadcrumbs, setTitle } = useLayoutContext();

    useEffect(() => {
        if (typeof breadcrumbs === 'function') {
            setBreadcrumbs(breadcrumbs());
        } else {
            setBreadcrumbs(breadcrumbs);
        }

        if (typeof titulo === 'function') {
            setTitle(titulo());
        } else {
            setTitle(titulo);
        }
    }, [data]);
};
