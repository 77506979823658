import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Card,
    Flex,
    FormControl,
    FormLabel,
    SimpleGrid,
    Stack,
    Switch,
    Text
} from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import PageLoader from 'components/actions/PageLoader';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import CannavaInputField from 'components/fields/CannavaInputField';
import { useAppContext } from 'contexts/AppContext';
import { useLayoutContext } from 'contexts/LayoutContext';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaTextAreaField from 'components/fields/CannavaTextareaField';
import { ControlOtrasActividadesTrasplante } from 'modules/trazabilidad/types/ControlOtrasActividadesTrasplante';
import CannavaSelectField from 'components/fields/CannavaSelectField';

export default function ControlOtrasActividades() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const navigate = useNavigate();
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);
    const [loteData, setLoteData] = useState(null);

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // configuro el layout
    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: [
                { label: 'Inicio', href: '/admin/home/' },
                { label: 'Trazabilidad', href: '/admin/home/' },
                //arreglar breadcrumbs
                { label: 'Lotes de planta productiva', href: '/admin/lotes-germinacion/' },
                { label: 'Verificación de trasplante', href: '/admin/lotes-germinacion/' }
            ],
            titulo: () => {
                // Generar título basado en data cargada
                return !loteData ? 'Lote' : `Lote ${loteData.codigo_lote}`;
            }
        },
        loteData
    );

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'reporteControlAmbiental',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/lotes-germinacion/ver/' + id);
        },
        onError: error => {
            console.log(error);
        },
        onErrorDelete: error => {
            navigate('/admin/lotes-germinacion');
        }
    });

    const onSubmit: SubmitHandler<ControlOtrasActividadesTrasplante> = (
        data: ControlOtrasActividadesTrasplante
    ) => {
        data.lote_id = id;
        //useEnvioFormulario1.envioFormularioCrear(data);
        console.log(data);
    };

    const onCancel: any = () => {
        navigate('/admin/lotes-plantas-productivas/ver');
    };

    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    // endregion

    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            <Flex
                direction="column"
                minH="100vh"
                align="center"
                pt={{ sm: '125px', lg: '75px' }}
                position="relative"
            >
                <Box
                    h="45vh"
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    position="absolute"
                    w="100%"
                    borderRadius="20px"
                />

                <FormProvider {...methods}>
                    <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Card p="30px" mt={'60px'}>
                                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                    Otras actividades
                                </Text>
                                {errorMessage && (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertTitle>{errorMessage}</AlertTitle>
                                    </Alert>
                                )}
                                <Flex direction="column" w="100%">
                                    <Stack direction="column" spacing="20px">
                                        <SimpleGrid gap="20px">
                                            <FormControl
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <FormLabel
                                                    mb="0"
                                                    fontSize="sm"
                                                    color={textColor}
                                                    ms="10px"
                                                    fontWeight="bold"
                                                >
                                                    ¿Se verificó la aplicación de sistemas de
                                                    conducción de las plantas según bitácora?
                                                </FormLabel>
                                                <Switch
                                                    {...methods.register('sistemas_conduccion')}
                                                    id="sistemas_conduccion"
                                                />
                                            </FormControl>
                                            <FormControl
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <FormLabel
                                                    mb="0"
                                                    fontSize="sm"
                                                    color={textColor}
                                                    ms="10px"
                                                    fontWeight="bold"
                                                >
                                                    ¿Se verificaron los riegos durante las etapas
                                                    vegetativa y reproductiva?
                                                </FormLabel>
                                                <Switch
                                                    {...methods.register('verificacion_riegos')}
                                                    id="verificacion_riegos"
                                                />
                                            </FormControl>
                                            <FormControl
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <FormLabel
                                                    mb="0"
                                                    fontSize="sm"
                                                    color={textColor}
                                                    ms="10px"
                                                    fontWeight="bold"
                                                >
                                                    ¿Se realizó aporcado?
                                                </FormLabel>
                                                <Switch
                                                    {...methods.register('aporcado')}
                                                    id="aporcado"
                                                />
                                            </FormControl>
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaInputField
                                                    id={'temperatura'}
                                                    label={'Temperatura (ºC)'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'humedad'}
                                                    label={'Humedad (%)'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaSelectField
                                                    id={'cumple_oscuridad'}
                                                    label={'Oscuridad'}
                                                    opciones={[
                                                        { id: 1, nombre: 'Cumple' },
                                                        { id: 0, nombre: 'No cumple' }
                                                    ]}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            </SimpleGrid>
                                        </SimpleGrid>
                                    </Stack>
                                    <Flex justify="space-between" mt="24px">
                                        <Button
                                            variant="outline"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            //navegar a lote
                                            onClick={onCancel}
                                            type="button"
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="darkBrand"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            isLoading={methods.formState.isSubmitting}
                                            type="submit"
                                        >
                                            Continuar
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Card>
                        </form>
                    </Box>
                </FormProvider>
            </Flex>
        </>
    );
}
