import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Card,
    Flex,
    GridItem,
    SimpleGrid,
    Stack,
    Text
} from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import PageLoader from 'components/actions/PageLoader';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import CannavaInputField from 'components/fields/CannavaInputField';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import { useAppContext } from 'contexts/AppContext';
import { useLayoutContext } from 'contexts/LayoutContext';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import { OrdenAplicacion } from 'modules/trazabilidad/types/OrdenAplicacion';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaTextAreaField from 'components/fields/CannavaTextareaField';
import CannavaApiService from 'utils/CannavaApiService';

export default function NuevaOrdenAplicacion() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    // para multiples aplicaciones
    const [gruposAplicaciones, setGruposAplicaciones] = useState<number>(1);

    const añadirGrupo = () => {
        setGruposAplicaciones(gruposAplicaciones + 1);
    };
    const quitarGrupo = () => {
        if (gruposAplicaciones > 1) {
            setGruposAplicaciones(gruposAplicaciones - 1);
        }
    };

    const navigate = useNavigate();
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);
    const [loteData, setLoteData] = useState(null);
    const { setFlashMessage, setFlashType } = useAppContext();
    const [cargandoData, setCargandoData] = useState(false);

    const fetchLoteData = async (loteId: string) => {
        setCargandoData(true);
        CannavaApiService.lotesPlantaProductivaObtener(
            loteId,
            authToken,
            data => {
                console.log('data lote productivo');
                console.log(data);
                setLoteData(data);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-plantas-productivas');
            }
        );
    };

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // configuro el layout
    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: [
                { label: 'Inicio', href: '/admin/home/' },
                { label: 'Trazabilidad', href: '/admin/home/' },
                //arreglar breadcrumbs
                { label: 'Lotes de planta productiva', href: '/admin/lotes-germinacion/' },
                { label: 'Verificación de trasplante', href: '/admin/lotes-germinacion/' }
            ],
            titulo: () => {
                // Generar título basado en data cargada
                return !loteData ? 'Lote' : `Lote ${loteData.codigo_lote}`;
            }
        },
        loteData
    );

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'ordenAplicacion',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/lotes-plantas-productivas/ver/' + id);
        },
        onError: error => {
            console.log(error);
        },
        onErrorDelete: error => {
            navigate('/admin/lotes-plantas-productivas');
        }
    });

    const onSubmit: SubmitHandler<OrdenAplicacion> = (data: OrdenAplicacion) => {
        data.lote_productivo_id = id;
        data.creado_por = 2;

        const aplicacionesData: any[] = [];

        for (let i = 0; i < gruposAplicaciones; i++) {
            // Construct the property name dynamically
            const principioActivoDataKey = `principioActivoData_${i}`;
            const nombreComercialDataKey = `nombreComercialData_${i}`;
            const horaDataKey = `horaData_${i}`;
            const equipoDataKey = `equipoData_${i}`;
            const motivoDataKey = `motivoData_${i}`;
            const dosisDataKey = `dosisData_${i}`;

            // Acceso a la informacion en funcion de cuantas aplicaciones disponibles hay en el formulario
            const aplicacionData = {
                principio_activo: (data as any)[principioActivoDataKey],
                nombre_comercial: (data as any)[nombreComercialDataKey],
                hora: (data as any)[horaDataKey],
                equipo: (data as any)[equipoDataKey],
                motivo: (data as any)[motivoDataKey],
                dosis: parseInt((data as any)[dosisDataKey])
            };

            aplicacionesData.push(aplicacionData);
        }

        data.aplicaciones = aplicacionesData;
        useEnvioFormulario1.envioFormularioCrear(data);
        console.log(data);
    };

    const onCancel: any = () => {
        navigate('/admin/lotes-plantas-productivas/ver/' + id);
    };

    useEffect(() => {
        if (id) {
            fetchLoteData(id);
        }
    }, [id]);

    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    // endregion

    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    if (cargandoData || !loteData) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            <Flex
                direction="column"
                minH="100vh"
                align="center"
                pt={{ sm: '125px', lg: '75px' }}
                position="relative"
            >
                <Box
                    h="45vh"
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    position="absolute"
                    w="100%"
                    borderRadius="20px"
                />

                <FormProvider {...methods}>
                    <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Card p="30px" mt={'60px'}>
                                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                    Orden de aplicación
                                </Text>
                                {errorMessage && (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertTitle>{errorMessage}</AlertTitle>
                                    </Alert>
                                )}
                                <Flex direction="column" w="100%">
                                    <Stack direction="column" spacing="20px">
                                        <SimpleGrid gap="20px">
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaDatePickerField
                                                    id={'fecha'}
                                                    label={'Fecha'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'responsable'}
                                                    label={'Responsable'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                {Array.from({ length: gruposAplicaciones }).map(
                                                    (_, index) => (
                                                        <>
                                                            <GridItem colSpan={{ base: 1, md: 2 }}>
                                                                <Text
                                                                    color={textColor}
                                                                    fontSize="lg"
                                                                    fontWeight="700"
                                                                >
                                                                    Aplicación {index + 1}
                                                                </Text>
                                                            </GridItem>
                                                            <CannavaInputField
                                                                id={`principioActivoData_${index}`}
                                                                label={'Principio Activo/Fórmula'}
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />
                                                            <CannavaInputField
                                                                id={`nombreComercialData_${index}`}
                                                                label={'Nombre comercial'}
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />
                                                            <CannavaInputField
                                                                id={`dosisData_${index}`}
                                                                label={'Dosis (gr o cc/L)'}
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />
                                                            <CannavaInputField
                                                                id={`motivoData_${index}`}
                                                                label={'Motivo'}
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />
                                                            <CannavaInputField
                                                                id={`equipoData_${index}`}
                                                                label={'Equipo'}
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />
                                                            <CannavaInputField
                                                                id={`horaData_${index}`}
                                                                label={'Hora del control'}
                                                                validationRules={{
                                                                    required: 'Campo obligatorio'
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                )}
                                            </SimpleGrid>
                                            <Flex alignItems="flex-end" flexDirection="column">
                                                <Text>Agregar o quitar orden de aplicación</Text>
                                                <Flex gap={4}>
                                                    <Button
                                                        variant="outline"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '46px' }}
                                                        h="46px"
                                                        onClick={quitarGrupo}
                                                        type="button"
                                                    >
                                                        -
                                                    </Button>
                                                    <Button
                                                        variant="darkBrand"
                                                        fontSize="sm"
                                                        borderRadius="16px"
                                                        w={{ base: '46px' }}
                                                        h="46px"
                                                        onClick={añadirGrupo}
                                                        type="button"
                                                    >
                                                        +
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                            <CannavaTextAreaField
                                                id={'observaciones'}
                                                label={'Observaciones'}
                                                placeholder={
                                                    '¿Tenés alguna observación para agregar?'
                                                }
                                                validationRules={{
                                                    required: 'Campo obligatorio'
                                                }}
                                                mb="10px"
                                            />
                                        </SimpleGrid>
                                    </Stack>
                                    <Flex justify="space-between" mt="24px">
                                        <Button
                                            variant="outline"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            //navegar a lote
                                            onClick={onCancel}
                                            type="button"
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="darkBrand"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            isLoading={methods.formState.isSubmitting}
                                            type="submit"
                                        >
                                            Continuar
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Card>
                        </form>
                    </Box>
                </FormProvider>
            </Flex>
        </>
    );
}
