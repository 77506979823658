import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Card,
    Flex,
    FormControl,
    FormLabel,
    SimpleGrid,
    Stack,
    Switch,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text
} from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import PageLoader from 'components/actions/PageLoader';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import CannavaInputField from 'components/fields/CannavaInputField';
import { useAppContext } from 'contexts/AppContext';
import { useLayoutContext } from 'contexts/LayoutContext';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaTextAreaField from 'components/fields/CannavaTextareaField';
import { FinalizacionTrasplanteMacrotunel } from 'modules/trazabilidad/types/FinalizacionTrasplanteMacrotunel';
import React from 'react';
import CannavaApiService from 'utils/CannavaApiService';

export default function FinalizarTrasplanteMacrotunel() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { id } = useParams();
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const colocacionPlantines = methods.watch('verificacion_plantines_en_maceta', false);
    const descartesPlantines = methods.watch('confirmacion_descarte', false);

    const navigate = useNavigate();
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);
    const [loteData, setLoteData] = useState(null);

    const [activeBullets, setActiveBullets] = useState({
        general: true,
        trasplante: false
    });

    const generalTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const trasplanteTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const { setFlashMessage, setFlashType } = useAppContext();
    const [cargandoData, setCargandoData] = useState(false);

    const mb: string = '0px';
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // configuro el layout
    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: [
                { label: 'Inicio', href: '/admin/home/' },
                { label: 'Trazabilidad', href: '/admin/home/' },
                //arreglar breadcrumbs
                { label: 'Lotes de planta productiva', href: '/admin/lotes-plantas-productivas/' }
            ],
            titulo: () => {
                // Generar título basado en data cargada
                return !loteData ? 'Lote' : `Lote ${loteData.codigo_lote}`;
            }
        },
        loteData
    );

    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'reporteControlAmbiental',
        formMethods: methods,
        onSuccess: data => {
            /* Añadir un switch en funcion del establecimiento para ir al formulario de Invernadero, Almería o Campo */
            navigate('/admin/lotes-germinacion/ver/' + id);
        },
        onError: error => {
            console.log(error);
        },
        onErrorDelete: error => {
            navigate('/admin/lotes-germinacion');
        }
    });

    const finalizarTrasplanteMacrotunel = async (data: FinalizacionTrasplanteMacrotunel) => {
        const formData = {
            fecha_fin_trasplante: data.fecha + ' ' + data.hora + ':00',
            verificacion_vestimenta_operador: data.verificacion_vestimenta_operador ? 1 : 0,
            verificacion_riego_con_solucion: data.verificacion_riego_con_solucion ? 1 : 0,
            descarte: data.descarte ? data.descarte : 0,
            observaciones: data.observaciones,
            verificacion_plantines_en_maceta: data.verificacion_plantines_en_maceta ? 1 : 0,
            observaciones_verificacion_plantines_en_maceta:
                data.observaciones_verificacion_plantines_en_maceta,
            verificacion_sustrato_humedecido: data.verificacion_sustrato_humedecido ? 1 : 0,
            verificacion_maceta_rellena: data.verificacion_maceta_rellena ? 1 : 0
        };

        setEnviandoFormulario(true);
        await CannavaApiService.request(
            'POST',
            '/trazabilidad/api/lote-productivo/finalizar-trasplante-lote/' + id,
            formData,
            authToken,
            data => {
                setFlashMessage('trasplante finalizado correctamente.');
                setFlashType('success');
                navigate('/admin/lotes-plantas-productivas/ver/' + id);
                setEnviandoFormulario(false);
            },
            (error: any) => {
                if (error.response.status == 422) {
                    console.log(error.response.data);
                    for (let i = 0; i < error.response.data.length; i++) {
                        const errorValidacion: any = error.response.data[i];
                        methods.setError(errorValidacion.field, {
                            message: errorValidacion.message
                        });
                    }
                } else {
                    alert('Error desconocido');
                    console.log(error);
                }
                setEnviandoFormulario(false);
            }
        );
    };

    const onSubmit: SubmitHandler<FinalizacionTrasplanteMacrotunel> = (
        data: FinalizacionTrasplanteMacrotunel
    ) => {
        finalizarTrasplanteMacrotunel(data);
        console.log(data);
    };

    const onCancel: any = () => {
        navigate('/admin/lotes-plantas-productivas/ver/' + id);
    };

    /* useEffect(() => {
        if (setEnviandoFormulario) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]); */

    // endregion

    if (enviandoFormulario) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            <Flex
                direction="column"
                minH="100vh"
                align="center"
                pt={{ sm: '125px', lg: '75px' }}
                position="relative"
            >
                <Box
                    h="45vh"
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    position="absolute"
                    w="100%"
                    borderRadius="20px"
                />

                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Tabs
                            variant="unstyled"
                            zIndex="0"
                            mt={{ base: '60px', md: '165px' }}
                            display="flex"
                            flexDirection="column"
                        >
                            <TabList
                                display="flex"
                                alignItems="center"
                                alignSelf="center"
                                justifySelf="center"
                            >
                                <Tab
                                    _focus={{ border: '0px', boxShadow: 'unset' }}
                                    ref={generalTab}
                                    w={{ sm: '120px', md: '250px', lg: '300px' }}
                                    onClick={() =>
                                        setActiveBullets({
                                            general: true,
                                            trasplante: false
                                        })
                                    }
                                >
                                    <Flex
                                        direction="column"
                                        justify="center"
                                        align="center"
                                        position="relative"
                                        _before={{
                                            content: "''",
                                            width: { sm: '120px', md: '250px', lg: '300px' },
                                            height: '3px',
                                            bg: activeBullets.trasplante ? 'white' : 'brand.400',
                                            left: { sm: '12px', md: '80px' },
                                            top: {
                                                sm: activeBullets.general ? '6px' : '4px',
                                                md: null
                                            },
                                            position: 'absolute',
                                            bottom: activeBullets.general ? '40px' : '38px',

                                            transition: 'all .3s ease'
                                        }}
                                    >
                                        <Box
                                            zIndex="1"
                                            border="2px solid"
                                            borderColor={
                                                activeBullets.general ? 'white' : 'brand.400'
                                            }
                                            bgGradient="linear(to-b, brand.400, brand.600)"
                                            w="16px"
                                            h="16px"
                                            mb="8px"
                                            borderRadius="50%"
                                        />
                                        <Text
                                            color={activeBullets.general ? 'white' : 'gray.300'}
                                            fontWeight={activeBullets.general ? 'bold' : 'normal'}
                                            display={{ sm: 'none', md: 'block' }}
                                        >
                                            Información general
                                        </Text>
                                    </Flex>
                                </Tab>
                                <Tab
                                    _focus={{ border: '0px', boxShadow: 'unset' }}
                                    ref={trasplanteTab}
                                    w={{ sm: '120px', md: '250px', lg: '300px' }}
                                    onClick={() =>
                                        setActiveBullets({
                                            general: true,
                                            trasplante: true
                                        })
                                    }
                                >
                                    <Flex
                                        direction="column"
                                        justify="center"
                                        align="center"
                                        position="relative"
                                    >
                                        <Box
                                            zIndex="1"
                                            border="2px solid"
                                            borderColor={
                                                activeBullets.trasplante ? 'white' : 'brand.400'
                                            }
                                            bgGradient="linear(to-b, brand.400, brand.600)"
                                            w="16px"
                                            h="16px"
                                            mb="8px"
                                            borderRadius="50%"
                                        />
                                        <Text
                                            color={activeBullets.trasplante ? 'white' : 'gray.300'}
                                            fontWeight={
                                                activeBullets.trasplante ? 'bold' : 'normal'
                                            }
                                            display={{ sm: 'none', md: 'block' }}
                                        >
                                            Informacion macrotunel
                                        </Text>
                                    </Flex>
                                </Tab>
                            </TabList>
                            <TabPanels maxW={{ md: '90%', lg: '100%' }} mx="auto">
                                <TabPanel
                                    w={{ sm: '330px', md: '700px', lg: '850px' }}
                                    p="0px"
                                    mx="auto"
                                >
                                    <Card p="30px" mt={'60px'}>
                                        <Text
                                            color={textColor}
                                            fontSize="2xl"
                                            fontWeight="700"
                                            mb="20px"
                                        >
                                            Finalizar trasplante
                                        </Text>
                                        {errorMessage && (
                                            <Alert status="error">
                                                <AlertIcon />
                                                <AlertTitle>{errorMessage}</AlertTitle>
                                            </Alert>
                                        )}
                                        <Flex direction="column" w="100%">
                                            <Stack direction="column" spacing="20px">
                                                <SimpleGrid gap="20px">
                                                    <SimpleGrid
                                                        columns={{ base: 1, md: 2 }}
                                                        gap="20px"
                                                    >
                                                        <CannavaDatePickerField
                                                            id={'fecha'}
                                                            label={'Fecha'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                        <CannavaInputField
                                                            id={'hora'}
                                                            label={'Hora del control'}
                                                            validationRules={{
                                                                required: 'Campo obligatorio'
                                                            }}
                                                        />
                                                    </SimpleGrid>
                                                    <FormControl
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        <FormLabel
                                                            mb="0"
                                                            fontSize="sm"
                                                            color={textColor}
                                                            ms="10px"
                                                            fontWeight="bold"
                                                        >
                                                            ¿El operario cumplía con la vestimenta
                                                            estipulada en el "POE-11.05 vestimenta
                                                            en áreas de cultivo"?
                                                        </FormLabel>
                                                        <Switch
                                                            {...methods.register(
                                                                'verificacion_vestimenta_operador'
                                                            )}
                                                            id="verificacion_vestimenta_operador"
                                                        />
                                                    </FormControl>
                                                    <FormControl
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        <FormLabel
                                                            mb="0"
                                                            fontSize="sm"
                                                            color={textColor}
                                                            ms="10px"
                                                            fontWeight="bold"
                                                        >
                                                            ¿Se realizó el riego de los plantines
                                                            por botalón o inmersión de bandejas con
                                                            la solución nutritiva y enraizante
                                                            previo al trasplante?
                                                        </FormLabel>
                                                        <Switch
                                                            {...methods.register(
                                                                'verificacion_riego_con_solucion'
                                                            )}
                                                            id="verificacion_riego_con_solucion"
                                                        />
                                                    </FormControl>
                                                </SimpleGrid>
                                            </Stack>
                                            <Flex justify="space-between" mt="24px">
                                                <Button
                                                    variant="darkBrand"
                                                    fontSize="sm"
                                                    borderRadius="16px"
                                                    w={{ base: '128px', md: '148px' }}
                                                    h="46px"
                                                    ms="auto"
                                                    onClick={() => {
                                                        trasplanteTab.current.click();
                                                    }}
                                                >
                                                    Siguiente
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Card>
                                </TabPanel>
                                <TabPanel
                                    w={{ sm: '330px', md: '700px', lg: '850px' }}
                                    p="0px"
                                    mx="auto"
                                >
                                    <Card p="30px" mt={'60px'}>
                                        <Text
                                            color={textColor}
                                            fontSize="2xl"
                                            fontWeight="700"
                                            mb="20px"
                                        >
                                            Trasplante Macrotunel
                                        </Text>
                                        {errorMessage && (
                                            <Alert status="error">
                                                <AlertIcon />
                                                <AlertTitle>{errorMessage}</AlertTitle>
                                            </Alert>
                                        )}
                                        <Flex direction="column" w="100%">
                                            <Stack direction="column" spacing="20px">
                                                <FormControl
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <FormLabel
                                                        mb="0"
                                                        fontSize="sm"
                                                        color={textColor}
                                                        ms="10px"
                                                        fontWeight="bold"
                                                    >
                                                        ¿Los plantines fueron correctamente
                                                        colocados en las macetas con cobertura de
                                                        sustrato y las riegas según el registro de
                                                        riego?
                                                    </FormLabel>
                                                    <Switch
                                                        {...methods.register(
                                                            'verificacion_plantines_en_maceta'
                                                        )}
                                                        id="verificacion_plantines_en_maceta"
                                                    />
                                                </FormControl>
                                                {!colocacionPlantines && (
                                                    <CannavaTextAreaField
                                                        id={
                                                            'observaciones_verificacion_plantines_en_maceta'
                                                        }
                                                        label={'Observaciones'}
                                                        placeholder={
                                                            '¿Tenés alguna observación para agregar?'
                                                        }
                                                        validationRules={{
                                                            required: 'Campo obligatorio'
                                                        }}
                                                        mb="10px"
                                                    />
                                                )}
                                                <FormControl
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <FormLabel
                                                        mb="0"
                                                        fontSize="sm"
                                                        color={textColor}
                                                        ms="10px"
                                                        fontWeight="bold"
                                                    >
                                                        ¿Se humedeció el sustrato con 24hs de
                                                        anticipación según el registro de riego?
                                                    </FormLabel>
                                                    <Switch
                                                        {...methods.register(
                                                            'verificacion_sustrato_humedecido'
                                                        )}
                                                        id="verificacion_sustrato_humedecido"
                                                    />
                                                </FormControl>
                                                <FormControl
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <FormLabel
                                                        mb="0"
                                                        fontSize="sm"
                                                        color={textColor}
                                                        ms="10px"
                                                        fontWeight="bold"
                                                    >
                                                        ¿Se rellenó la maceta con sustrato hasta un
                                                        80% de su capacidad y se aconficionó para la
                                                        posterior colocación del plantín?
                                                    </FormLabel>
                                                    <Switch
                                                        {...methods.register(
                                                            'verificacion_maceta_rellena'
                                                        )}
                                                        id="verificacion_maceta_rellena"
                                                    />
                                                </FormControl>
                                                <FormControl
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <FormLabel
                                                        mb="0"
                                                        fontSize="sm"
                                                        color={textColor}
                                                        ms="10px"
                                                        fontWeight="bold"
                                                    >
                                                        Descarte
                                                    </FormLabel>
                                                    <Switch
                                                        {...methods.register(
                                                            'confirmacion_descarte'
                                                        )}
                                                        id="confirmacion_descarte"
                                                    />
                                                </FormControl>
                                                {descartesPlantines && (
                                                    <CannavaInputField
                                                        id={'descarte'}
                                                        placeholder={'nº descartes (u.)'}
                                                        validationRules={{
                                                            required: 'Campo obligatorio'
                                                        }}
                                                        mb="10px"
                                                    />
                                                )}
                                                <CannavaTextAreaField
                                                    id={'observaciones'}
                                                    label={'Observaciones'}
                                                    placeholder={
                                                        '¿Tenés alguna observación para agregar?'
                                                    }
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                    mb="10px"
                                                />
                                            </Stack>
                                            <Flex justify="space-between" mt="24px">
                                                <Button
                                                    variant="outline"
                                                    fontSize="sm"
                                                    borderRadius="16px"
                                                    w={{ base: '128px', md: '148px' }}
                                                    h="46px"
                                                    //navegar a lote
                                                    onClick={onCancel}
                                                    type="button"
                                                >
                                                    Cancelar
                                                </Button>
                                                <Button
                                                    variant="darkBrand"
                                                    fontSize="sm"
                                                    borderRadius="16px"
                                                    w={{ base: '128px', md: '148px' }}
                                                    h="46px"
                                                    isLoading={methods.formState.isSubmitting}
                                                    type="submit"
                                                >
                                                    Continuar
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Card>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </form>
                </FormProvider>
            </Flex>
        </>
    );
}
