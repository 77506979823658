import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Card,
    Flex,
    SimpleGrid,
    Stack,
    Text
} from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import PageLoader from 'components/actions/PageLoader';
import CannavaDatePickerField from 'components/fields/CannavaDatePickerField';
import CannavaInputField from 'components/fields/CannavaInputField';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import { useAppContext } from 'contexts/AppContext';
import { useLayoutContext } from 'contexts/LayoutContext';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import { useEnvioFormulario } from 'hooks/useEnvioFormulario';
import { OrdenAplicacion } from 'modules/trazabilidad/types/OrdenAplicacion';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import CannavaTextAreaField from 'components/fields/CannavaTextareaField';
import { RiegoTrasplante } from 'modules/trazabilidad/types/RiegoTrasplante';

export default function RegistroRiegoPreTrasplante() {
    const { authToken } = useAppContext();
    const methods = useForm();
    const { id, tipo } = useParams();
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const navigate = useNavigate();
    const [enviandoFormulario, setEnviandoFormulario] = useState(false);
    const [loteData, setLoteData] = useState(null);
    const { setFlashMessage, setFlashType } = useAppContext();
    const [cargandoData, setCargandoData] = useState(false);

    //Alert
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // configuro el layout
    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: [
                { label: 'Inicio', href: '/admin/home/' },
                { label: 'Trazabilidad', href: '/admin/home/' },
                //arreglar breadcrumbs
                { label: 'Lotes de planta productiva', href: '/admin/lotes-germinacion/' },
                { label: 'Verificación de trasplante', href: '/admin/lotes-germinacion/' }
            ],
            titulo: () => {
                // Generar título basado en data cargada
                return !loteData ? 'Lote' : `Lote ${loteData.codigo_lote}`;
            }
        },
        loteData
    );

    /* Formulario en funcion de si es pre-trasplante o post-trasplante */
    const useEnvioFormulario1 = useEnvioFormulario({
        elemento: 'riegoTrasplante',
        formMethods: methods,
        onSuccess: data => {
            navigate('/admin/lotes-plantas-productivas/ver/' + id);
        },
        onError: error => {
            console.log(error);
        },
        onErrorDelete: error => {
            navigate('/admin/lotes-plantas-productivas/ver/' + id);
        }
    });

    const onSubmit: SubmitHandler<RiegoTrasplante> = (data: RiegoTrasplante) => {
        data.lote_productivo_id = id;
        data.tipo_riego = parseInt(tipo);
        data.creado_por = 2;
        console.log(data);

        useEnvioFormulario1.envioFormularioCrear(data);
    };

    const onCancel: any = () => {
        navigate('/admin/lotes-plantas-productivas/ver');
    };

    useEffect(() => {
        if (useEnvioFormulario1.isLoading) {
            return;
        }
        if (methods.formState.isSubmitted && !methods.formState.isValid) {
            //Alert test
            setErrorMessage('Hubo errores en la validación del formulario');
        }
    }, [enviandoFormulario, methods.formState.submitCount]);

    // endregion

    if (useEnvioFormulario1.isLoading) {
        return <PageLoader message="Cargando datos de lote..." />;
    }

    return (
        <>
            <Flex
                direction="column"
                minH="100vh"
                align="center"
                pt={{ sm: '125px', lg: '75px' }}
                position="relative"
            >
                <Box
                    h="45vh"
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    position="absolute"
                    w="100%"
                    borderRadius="20px"
                />

                <FormProvider {...methods}>
                    <Box w={{ sm: '330px', md: '700px', lg: '850px' }}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Card p="30px" mt={'60px'}>
                                {/* Modificar titulo en función de si es pre o post trasplante */}
                                {tipo === '0' && (
                                    <Text
                                        color={textColor}
                                        fontSize="2xl"
                                        fontWeight="700"
                                        mb="20px"
                                    >
                                        Registro de riego pre-trasplante
                                    </Text>
                                )}
                                {tipo === '1' && (
                                    <Text
                                        color={textColor}
                                        fontSize="2xl"
                                        fontWeight="700"
                                        mb="20px"
                                    >
                                        Registro de riego post-trasplante
                                    </Text>
                                )}
                                {errorMessage && (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertTitle>{errorMessage}</AlertTitle>
                                    </Alert>
                                )}
                                <Flex direction="column" w="100%">
                                    <Stack direction="column" spacing="20px">
                                        <SimpleGrid gap="20px">
                                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                                <CannavaDatePickerField
                                                    id={'fecha'}
                                                    label={'Fecha'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'hora'}
                                                    label={'Hora del control'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'cantidad_dosis'}
                                                    label={'Cantidad de la dosis (gr o cc/L)'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'volumen_total_caldo'}
                                                    label={'Volumen total del caldo (cc/L)'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'cantidad_producto'}
                                                    label={'Cantidad de producto (cc/L)'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'volumen_total_agua'}
                                                    label={'Volumen total de agua (m3)'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'ph_agua'}
                                                    label={'PH del agua'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'ce_agua'}
                                                    label={'CE del agua'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                                <CannavaInputField
                                                    id={'responsable'}
                                                    label={'Responsable'}
                                                    validationRules={{
                                                        required: 'Campo obligatorio'
                                                    }}
                                                />
                                            </SimpleGrid>
                                            <CannavaTextAreaField
                                                id={'observaciones'}
                                                label={'Observaciones'}
                                                placeholder={
                                                    '¿Tenés alguna observación para agregar?'
                                                }
                                                validationRules={{
                                                    required: 'Campo obligatorio'
                                                }}
                                                mb="10px"
                                            />
                                        </SimpleGrid>
                                    </Stack>
                                    <Flex justify="space-between" mt="24px">
                                        <Button
                                            variant="outline"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            //navegar a lote
                                            onClick={onCancel}
                                            type="button"
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="darkBrand"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{ base: '128px', md: '148px' }}
                                            h="46px"
                                            isLoading={methods.formState.isSubmitting}
                                            type="submit"
                                        >
                                            Continuar
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Card>
                        </form>
                    </Box>
                </FormProvider>
            </Flex>
        </>
    );
}
