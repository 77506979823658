import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Flex,
    Icon,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    SimpleGrid,
    Spinner,
    useDisclosure
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import OrderStep from 'components/dataDisplay/OrderStep';
import IconBox from 'components/icons/IconBox';
import FilaObjetivo from './FilaObjetivo';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, Button } from '@chakra-ui/react';
import { MdAddCircle, MdChevronLeft } from 'react-icons/md';
import CannavaInputField from 'components/fields/CannavaInputField';
import { useForm, FormProvider, useFormContext, SubmitHandler } from 'react-hook-form';
import CannavaSelectField from 'components/fields/CannavaSelectField';
import { Variedad } from '../../../../../genetica/types/Variedad';
import { useFetchData } from 'hooks/useFetchData';
import { Producto } from '../../../../../catalogo/types/Producto';
import { useAppContext } from 'contexts/AppContext';
import { Campana } from '../../../../types/Campana';
import CannavaApiService from 'utils/CannavaApiService';
import Card from 'components/card/Card';
import { Planificacion } from '../../../../types/Planificacion';
import { ObjetivoCampana } from '../../../../types/ObjetivoCampana';
import { useAutenticacionUsuario } from '../../../../../../modules/auth/hooks/useAutenticacionUsuario';

type ListadoObjetivosProps = {
    campana: Campana;
    actualizarCampana: CallableFunction;
    [x: string]: any;
};

type OpcionesSelect = {
    productos: any[];
    geneticas: any[];
};

export default function ListadoObjetivos(props: ListadoObjetivosProps) {
    const { campana, actualizarCampana, ...rest } = props;
    const { authToken } = useAppContext();
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'secondaryGray.600';
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const alertEliminar = useDisclosure();
    const cancelRef = React.useRef();

    const methods = useForm();
    const [opcionesSelect, setOpcionesSelect] = useState<OpcionesSelect>({
        productos: [],
        geneticas: []
    });
    const { setFlashMessage, setFlashType } = useAppContext();
    const [enviandoLoading, setEnviandoLoading] = useState(false);
    const [productosDisponibles, setProductosDisponibles] = useState([]);
    const [objetivoParaEliminar, setObjetivoParaEliminar] = useState<ObjetivoCampana | null>(null);

    const geneticaId = methods.watch('genetica_id');

    const transformProductos = (data: any[]): Producto[] => {
        return data.map(producto => ({
            id: producto.id,
            tipo_producto_id: producto.nombre,
            genetica_id: producto.genetica_id,
            nombre: producto.nombre,
            slug: producto.slug,
            descripcion: producto.descripcion,
            json_data: producto.json_data,
            fecha_creacion: producto.fecha_creacion,
            fecha_actualizacion: producto.fecha_actualizacion,
            fecha_borrado: producto.fecha_borrado,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };

    const transformVariedades = (data: any[]): Variedad[] => {
        return data.map(variedad => ({
            id: variedad.id,
            nombre: variedad.nombre,
            slug: variedad.slug,
            proveedor_id: variedad.proveedor_id,
            proveedor: variedad.proveedor,
            cannabinoide_dominante_id: variedad.nombre,
            cannabinoideDominante: variedad.cannabinoideDominante,
            estimaciones: variedad.estimaciones,
            poder_germinativo_promedio: variedad.descripcion,
            productosDisponibles: variedad.productosDisponibles,
            cantidad_semillas_por_100_gramos: variedad.json_data,
            densidad_siembra_planta_m2: variedad.fecha_creacion,
            actions: 'Actions' // Esto es estático, pero puedes adaptarlo según tus necesidades
        }));
    };

    const { data: productosData, isLoading } = useFetchData<Producto>(
        'productos',
        transformProductos
    );

    const { data: geneticasData } = useFetchData<Variedad>('variedades', transformVariedades);

    const enviarFormulario = async (data: any) => {
        setEnviandoLoading(true);

        // Preparar los datos para enviar al backend
        let dataFilas = [];
        for (let i = 0; i < productosDisponibles.length; i++) {
            const producto = productosDisponibles[i];
            dataFilas.push({
                tipo_producto_id: producto.id,
                cantidad: data['cantidad_' + producto.id]
            });
        }

        // @ts-ignore
        const formData = {
            campana_id: campana.id,
            genetica_id: data.genetica_id,
            dataFilas: dataFilas
        };

        CannavaApiService.objetivosCampanaCrear(
            formData,
            authToken,
            data => {
                // Setear los valores en los campos del formulario
                setFlashMessage('Se agregó el objetivo correctamente!');
                setFlashType('success');

                setEnviandoLoading(false);
                onClose();
                actualizarCampana(false);
            },
            (error: any) => {
                if (error.response.status == 422) {
                    console.log(error.response.data);
                    for (let i = 0; i < error.response.data.length; i++) {
                        const errorValidacion: any = error.response.data[i];
                        methods.setError(errorValidacion.field, {
                            message: errorValidacion.message
                        });
                    }
                } else {
                    alert('Error desconocido');
                    console.log(error);
                }

                setEnviandoLoading(false);
            }
        );
    };

    useEffect(() => {
        if (!productosData || !geneticasData) {
            return;
        }
        let opciones: OpcionesSelect = {
            productos: productosData,
            geneticas: geneticasData
        };

        setOpcionesSelect(opciones);
        console.log('opciones select', opciones);
    }, [productosData, geneticasData]);

    useEffect(() => {
        for (let i = 0; i < opcionesSelect.geneticas.length; i++) {
            const genetica = opcionesSelect.geneticas[i];
            if (genetica.id == geneticaId) {
                console.log('seteo productos', genetica.productosDisponibles);
                setProductosDisponibles(genetica.productosDisponibles);
            }
        }
    }, [geneticaId]);

    const eliminarObjetivo = (objetivo: ObjetivoCampana) => {
        setObjetivoParaEliminar(objetivo);
        alertEliminar.onOpen();
    };

    const confirmarEliminarObjetivo = () => {
        if (objetivoParaEliminar) {
            CannavaApiService.objetivosCampanaEliminar(
                objetivoParaEliminar.id,
                authToken,
                data => {
                    // Setear los valores en los campos del formulario
                    setFlashMessage('Se eliminó el objetivo correctamente!');
                    setFlashType('success');
                    actualizarCampana(false);
                    setObjetivoParaEliminar(null);
                },
                error => {
                    //throw new Error(`HTTP error! Status: ${error}`);
                    setFlashMessage('Hubo un error al eliminar el objetivo.');
                    setFlashType('error');
                    actualizarCampana(false);
                }
            );
        }

        alertEliminar.onClose();
    };

    const [permisosAcceso, setPermisosAcceso] = useState<Record<string, boolean>>({});
    const { obtenerPermisos } = useAutenticacionUsuario();

    useEffect(() => {
        const permisos = [
            {
                key: 'botonEliminarObjetivo',
                roles: ['usuario-basico', 'administrador'],
                areas: ['planificacion', 'finanzas']
            },
            {
                key: 'botonAgregarObjetivo',
                roles: ['usuario-basico', 'administrador'],
                areas: ['planificacion', 'finanzas']
            }
        ];
        const nuevosPermisos = obtenerPermisos(permisos);
        setPermisosAcceso(nuevosPermisos);
    }, [obtenerPermisos]);

    return (
        <Card colorScheme="whiteAlpha" mb="40px" p="24px" pb={0}>
            <Flex direction="column" mb="30px" ms="10px">
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Objetivos
                </Text>
                <Text fontSize="md" color={textColorSecondary}>
                    Aquí encontrarás los objetivos establecidos para la campaña
                </Text>

                <Table my={'10px'} mx={'0'} w={'100%'} variant="simple" color="gray.500" mt="12px">
                    <Thead>
                        <Tr>
                            <Th borderColor={borderColor}>Genética</Th>
                            <Th borderColor={borderColor}>Cantidad</Th>
                            <Th borderColor={borderColor}>Planificado</Th>
                            <Th borderColor={borderColor} minW={'150px'}>
                                &nbsp;
                            </Th>
                            {campana.estado == 'draft' && (
                                <Th borderColor={borderColor} w={'50px'}>
                                    &nbsp;
                                </Th>
                            )}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {campana.objetivos.map((objetivo, index) => (
                            <FilaObjetivo
                                key={index}
                                objetivo={objetivo}
                                campana={campana}
                                permisoEliminar={permisosAcceso.botonEliminarObjetivo}
                                callbackEliminar={eliminarObjetivo}
                            />
                        ))}
                    </Tbody>
                </Table>

                {campana.estado == 'draft' && permisosAcceso.botonAgregarObjetivo && (
                    <Box display="flex" justifyContent="flex-end">
                        <Button onClick={onOpen}>
                            <Icon as={MdAddCircle} w="16px" h="16px" /> Agregar objetivo
                        </Button>
                    </Box>
                )}
            </Flex>

            {permisosAcceso.botonEliminarObjetivo && (
                <AlertDialog
                    isOpen={alertEliminar.isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={alertEliminar.onClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                Eliminar Objetivo
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                ¿Estás seguro de que deseas eliminar este objetivo? Eliminarás todas
                                las planificaciones asociadas. Esta acción es irreversible.
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={alertEliminar.onClose}>
                                    Cancelar
                                </Button>
                                <Button
                                    colorScheme="red"
                                    onClick={confirmarEliminarObjetivo}
                                    ml={3}
                                >
                                    Eliminar
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            )}

            {permisosAcceso.botonAgregarObjetivo && (
                <Modal isOpen={isOpen} onClose={onClose} size={'md'}>
                    <ModalOverlay />
                    <ModalContent>
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(enviarFormulario)}>
                                <ModalHeader>Agregar Objetivo</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    {enviandoLoading ? (
                                        <Box display={'flex'} mx={'auto'} mb="30px">
                                            <Spinner />
                                        </Box>
                                    ) : (
                                        <SimpleGrid columns={{ base: 1, md: 1 }} gap="20px">
                                            <CannavaSelectField
                                                id={'genetica_id'}
                                                label={'Genética'}
                                                opciones={opcionesSelect.geneticas}
                                                mb={'10px'}
                                                placeholder={'Seleccione una genética'}
                                                validationRules={{ required: 'Campo obligatorio' }}
                                            />

                                            {geneticaId && (
                                                <Box>
                                                    {productosDisponibles &&
                                                        productosDisponibles.length > 0 &&
                                                        productosDisponibles.map(
                                                            (producto, index) => (
                                                                <CannavaInputField
                                                                    id={'cantidad_' + producto.id}
                                                                    label={
                                                                        producto.nombre + ' (kg)'
                                                                    }
                                                                    mb={'10px'}
                                                                    validationRules={{
                                                                        required:
                                                                            'Campo obligatorio',
                                                                        validate: (value: any) =>
                                                                            !isNaN(value) ||
                                                                            'Debe ser un número'
                                                                    }}
                                                                    key={index}
                                                                />
                                                            )
                                                        )}
                                                </Box>
                                            )}
                                        </SimpleGrid>
                                    )}
                                </ModalBody>

                                {!enviandoLoading && (
                                    <ModalFooter>
                                        <Button
                                            colorScheme="green"
                                            mr={3}
                                            isLoading={methods.formState.isSubmitting}
                                            type="submit"
                                        >
                                            Guardar
                                        </Button>
                                        <Button variant="ghost" onClick={onClose}>
                                            Cancelar
                                        </Button>
                                    </ModalFooter>
                                )}
                            </form>
                        </FormProvider>
                    </ModalContent>
                </Modal>
            )}
        </Card>
    );
}
