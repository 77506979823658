import { Box, Grid, Flex, Card, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import MapCard from 'views/admin/dashboards/smartHome/components/MapCard';
import MiniStatistics from 'components/card/MiniStatistics';
import PanelAnotaciones from 'components/chat/PanelAnotaciones';
import CardControlSiembra from '../germinacion/components/CardControlSiembra';
import CardControlGerminacion from '../germinacion/components/CardControlGerminacion';
import { useConfigurarNavegacion } from 'hooks/useConfigurarNavegacion';
import { FilaInfoRegistro } from '../verLote/components/FilaInfoRegistro';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLayoutContext } from 'contexts/LayoutContext';
import { useAppContext } from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import InfoControl from './components/InfoControlGeneral';
import PageLoader from 'components/actions/PageLoader';
import Observaciones from './components/Observaciones';
import Responsable from './components/Responsable';
import Adjuntos from './components/Adjuntos';
import { ControlSiembra } from 'modules/trazabilidad/types/ControlSiembra';
import InfoControlGeneral from './components/InfoControlGeneral';
import InfoControlBandejas from './components/InfoControlBandejas';
import InfoControlAmbiental from './components/InfoCondicionesAmbientales';
import CannavaApiService from 'utils/CannavaApiService';
import InfoControlSemillado from './components/InfoControlSemillado';

export default function VerControlSiembra() {
    const { setFlashMessage, setFlashType } = useAppContext();
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const { reporteSiembraId } = useParams();

    const navigate = useNavigate();
    const { setBreadcrumbs, setTitle } = useLayoutContext();
    const { authToken } = useAppContext();
    const [cargandoData, setCargandoData] = useState(false);
    const [usuario, setUsuario] = useState(null);

    //
    const [reporteSiembra, setReporteSiembra] = useState<ControlSiembra>();

    const fetchReporteData = async (loteId: string) => {
        setCargandoData(true);
        CannavaApiService.reporteSiembraObtener(
            loteId,
            authToken,
            data => {
                console.log('data reporte siembra');
                console.log(data);
                setReporteSiembra(data);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del reporte.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion');
            }
        );
    };

    const fetchUsuario = async (id: number) => {
        setCargandoData(true);
        CannavaApiService.usuarioObtener(
            id,
            authToken,
            data => {
                console.log('usuario reporte germinacion');
                console.log(data);
                setUsuario(data);
                setCargandoData(false);
            },
            error => {
                //throw new Error(`HTTP error! Status: ${error}`);
                setFlashMessage('Hubo un error al obtener los datos del usuario.');
                setFlashType('error');
                setCargandoData(false);
                navigate('/admin/lotes-germinacion');
            }
        );
    };

    // Version TEST - Configurar carga de datos de la db!
    /* const [controlData, setControlData] = useState<ControlSiembra>({
        general: [
            {
                fecha_inicial: "2024-04-02",
                hora_inicial: "10:00",
                limpieza_area: true,
                limpieza_sembradora: true,
            }
        ],
        bandejas: [
            {
                fecha_inicial: "2024-04-02",
                hora_inicial: "10:00",
                operarios: 2,
                fecha_final: "2024-04-02",
                hora_final: "12:00",
                bandejas_armadas: 10,
                semillas_usadas: 200,
            }
        ],
        semillado: [
            {
                fecha_inicial: "2024-04-02",
                hora_inicial: "10:00",
                operarios: 2,
                fecha_final: "2024-04-02",
                hora_final: "12:00",
                bandejas_armadas: 10,
                semillas_usadas: 200,
                limpieza_area: true,
                inspeccion_visual: true,
            }
        ],
        condicionesAmbientales: [
            {
                temperaturaMaxima: 30,
                temperaturaMinima: 22,
                temperaturaPromedio: 26,
                humedadRelativaMaxima: 98,
                humedadRelativaMinima: 90,
                humedadRelativaPromedio: 94,
                presionAtmosfericaMaxima: 10,
                presionAtmosfericaMinima: 8,
                presionAtmosfericaPromedio: 9,
            }
        ],
        observaciones_generales: "Todo bien!",
        creado_por: 2
    }); */

    const configurarNavegacion = useConfigurarNavegacion(
        {
            breadcrumbs: () => {
                let _breadcrumbs = [];
                if (!reporteSiembra) {
                    return [];
                } else {
                    _breadcrumbs = [
                        // Version TEST - Configurar rutas correctamente!
                        { label: 'Trazabilidad', href: '/admin/home/' },
                        { label: 'Lotes de germinación', href: '/admin/lotes-germinacion/' },
                        {
                            label: reporteSiembra.lote.codigo_lote,
                            href: '/admin/lotes-germinacion/ver/' + reporteSiembra.lote_id
                        }
                    ];
                }

                return _breadcrumbs;
            },
            titulo: `Control de siembra`
        },
        reporteSiembra
    );

    useEffect(() => {
        if (reporteSiembraId && !reporteSiembra) {
            fetchReporteData(reporteSiembraId);
        }
        if (reporteSiembra) {
            fetchUsuario(reporteSiembra.creado_por);
        }
    }, [reporteSiembraId, reporteSiembra]);

    if (cargandoData) {
        return <PageLoader message="Cargando datos de reporte..." />;
    }

    if (reporteSiembra) {
        return (
            <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
                {reporteSiembra && (
                    <Grid
                        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
                        gap={4}
                        mb={'30px'}
                    >
                        {/* Fila 1 */}
                        <Box>
                            <MiniStatistics
                                name="Fecha de creación"
                                value={reporteSiembra.fecha_creacion.split(' ')[0]}
                            />
                        </Box>
                        <Box>
                            <MiniStatistics
                                name="Variedad"
                                value={
                                    reporteSiembra.lote.genetica
                                        .nombre /* loteData.cantidad_semillas */
                                }
                            />
                        </Box>
                        <Box>
                            <MiniStatistics
                                name="Estado del lote"
                                value={'Lote sano' /* loteData.cantidad_semillas */}
                            />
                        </Box>
                    </Grid>
                )}

                {/* Fila 2 */}
                {reporteSiembra && (
                    <Grid
                        templateColumns={{ base: 'repeat(1, 1fr)', md: '50% 50%' }}
                        gridColumn="span 4"
                        gap={4}
                    >
                        {/* Columna 1 de Fila 2 */}
                        <Flex flexDirection="column" gap={2}>
                            {/* Fila 2.1.1 */}
                            <SimpleGrid columns={{ base: 1, md: 1 }} spacing={4} mb={2}>
                                <Flex
                                    flexDirection={{ base: 'column', md: 'row' }}
                                    justify="space-between"
                                    gap={'20px'}
                                >
                                    <Box w="100%">
                                        <InfoControlGeneral controlDataGeneral={reporteSiembra} />
                                    </Box>
                                </Flex>
                            </SimpleGrid>
                            <SimpleGrid columns={{ base: 1, md: 1 }} spacing={4} mb={2}>
                                <Flex
                                    flexDirection={{ base: 'column', md: 'row' }}
                                    justify="space-between"
                                    gap={'20px'}
                                >
                                    <Box w="100%">
                                        <InfoControlBandejas
                                            label={'Preparación de bandejas'}
                                            controlDataBandejas={reporteSiembra}
                                        />
                                    </Box>
                                </Flex>
                            </SimpleGrid>
                            <SimpleGrid columns={{ base: 1, md: 1 }} spacing={4} mb={2}>
                                <Flex
                                    flexDirection={{ base: 'column', md: 'row' }}
                                    justify="space-between"
                                    gap={'20px'}
                                >
                                    <Box w="100%">
                                        <InfoControlSemillado
                                            label={'Semillado'}
                                            controlDataSemillado={reporteSiembra}
                                        />
                                    </Box>
                                </Flex>
                            </SimpleGrid>
                        </Flex>

                        {/* Columna 2 de Fila 2 */}
                        <Flex flexDirection="column" gap={4}>
                            {usuario && <Responsable usuarioData={usuario} />}
                            {/* el endpoint no aporta esta informacion para el control ambiental de la siembra */}
                            {/* <InfoControlAmbiental condicionesAmbientales={controlData.condicionesAmbientales}/> */}
                            {usuario && (
                                <Observaciones usuarioData={usuario} controlData={reporteSiembra} />
                            )}
                            {/* Se desactivan los adjuntos como en el wireframe */}
                            {/* <Adjuntos controlData={reporteSiembra} /> */}

                            {/* {loteData.ordenSiembra && loteData.ordenSiembra.documento && (
                            <Card p="30px" mb={5}>
                                <Flex justify="space-between" align="center" mb="5">
                                    <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                                        Documentos adjuntos
                                    </Text>
                                </Flex>
                                <SimpleGrid
                                    columns={{ sm: 1, md: 1, xl: 1 }}
                                    spacing={{ base: '20px', xl: '5px' }}
                                >
                                    
                                    <Divider border="1px" borderColor="lightgray" />
                                </SimpleGrid>
                            </Card>
                        )} */}
                        </Flex>
                    </Grid>
                )}
            </Box>
        );
    }
}
